import { StreamInterceptor, UnaryInterceptor } from 'grpc-web';

declare const __gRPC_devtools__:
  | undefined
  | {
      gRPCDevtoolsUnaryInterceptor: UnaryInterceptor<unknown, unknown>;
      gRPCDevtoolsStreamInterceptor: StreamInterceptor<unknown, unknown>;
    };

const isDevtoolsAvailable = typeof __gRPC_devtools__ === 'object' && __gRPC_devtools__ !== null;
// const isCorrectStage = IS_LOCAL_ENV || IS_DEV_OR_PREVIEW_ENV;
const isCorrectStage = true;

export const grpcDevtoolsUnaryInterceptor =
  isDevtoolsAvailable && isCorrectStage ? [__gRPC_devtools__.gRPCDevtoolsUnaryInterceptor] : [];

export const grpcDevtoolsStreamInterceptor =
  isDevtoolsAvailable && isCorrectStage ? [__gRPC_devtools__.gRPCDevtoolsStreamInterceptor] : [];
