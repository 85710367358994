import { AccessManagementClient as GrpcWebAccessManagementClient } from '@ig/platform_sdk_web/src/modules/access_management/Access_managementServiceClientPb';
import {
  AddCommunityAdminRequest,
  AddUserToCommunityRequest,
  CheckCommunityMAUsRequest,
  CheckCommunityMAUsResponse,
  CheckIfUsernameIsAvailableRequest,
  Community,
  CommunityCancelInviteRequest,
  CommunityRoomConfigurationUpdateRequest,
  CommunitySendInviteRequest,
  CommunityUser,
  ConnectUserRequest,
  ConnectedUserEvent,
  CreateCommunityRequest,
  CreateCommunityResponse,
  CreateCommunityRoomRequest,
  CreateCommunityRoomResponse,
  CreateUserRequest,
  CreateUserResponse,
  CustomerType,
  DeleteCommunityRequest,
  DeleteCommunityRoomRequest,
  DeleteUserRequest,
  DoneDownloadingGameRequest,
  GameNight,
  GetBillingInfoRequest,
  GetBillingInfoResponse,
  GetCommunityByIdRequest,
  GetCommunityBySlugRequest,
  GetCommunityMetricsRequest,
  GetCommunityMetricsResponse,
  GetCommunityProfileBySlugRequest,
  GetCommunityProfileBySlugResponse,
  GetCommunityResponse,
  GetCommunityRoomByIdRequest,
  GetCommunityRoomBySlugRequest,
  GetCommunityRoomResponse,
  GetInitialRoomStateBySlugRequest,
  GetInitialRoomStateRequest,
  GetInitialRoomStateResponse,
  GetUserByExternalAuthIdRequest,
  GetUserByIdRequest,
  GetUserResponse,
  Room as GrpcRoom,
  JoinCommunityRequest,
  LeaveCommunityRequest,
  ListCommunityRoomsFromSlugRequest,
  ListCommunityRoomsRequest,
  ListCommunityRoomsResponse,
  ListCommunityUsersRequest,
  ListUserCommunitiesRequest,
  RemoveCommunityAdminRequest,
  RemoveUserFromCommunityRequest,
  RequestBillingSessionLinkResponse,
  RoomEvent,
  RoomType,
  StartOrContinueGameDownloadRequest,
  SubscribeToRoomRequest,
  UpdateCommunityRequest,
  UpdateCommunityResponse,
  UpdateCommunityRoomRequest,
  UpdateCommunityRoomResponse,
  UpdateRoomHostRequest,
  UpdateUserRequest,
  UpdateUserResponse,
  RequestBillingSessionLinkRequest,
  BillingSession,
  BillingPortalSession,
  BillingSubscriptionCheckoutSession,
  GetProSubscriptionPlanRequest,
  GetProSubscriptionPlanResponse,
  ReportUserStateRequest,
  ReportUserStateResponse,
  GetUserStatesByIdRequest,
  GetUserStatesByIdResponse,
  GetUsersByIdRequest,
  GetUsersByIdResponse,
  UpdateAvailablePlansRequest,
  UpdateAvailablePlansResponse,
  GetSubscriptionPlansRequest,
  GetSubscriptionPlansResponse,
  GetCommunityAvailablePlansRequest,
  GetCommunityAvailablePlansResponse,
} from '@ig/platform_sdk_web/src/modules/access_management/access_management_pb';
import { BoolValue, StringValue } from 'google-protobuf/google/protobuf/wrappers_pb';
import { ClientReadableStream } from 'grpc-web';
import { SortedRoomList } from '~/types/AccessManagement';
import { refreshAccessToken } from '~/utils/auth';
import { toRPCGatesParam } from '~/utils/grpc/community';
import { GRPC_API_ENDPOINT } from '~/utils/constants';
// import { delay } from '~/utils/delay';
import { AuthInterceptorStream, AuthInterceptorUnary } from './interceptors/AuthInterceptor';
import {
  grpcDevtoolsStreamInterceptor,
  grpcDevtoolsUnaryInterceptor,
} from './interceptors/GrpcDevtoolInterceptor';
import { toRPCStatesParam } from '~/utils/grpc/user';

interface IAccessManagement {
  // ----- User
  createUser: ({}: CreateUserRequest.AsObject) => Promise<CreateUserResponse.AsObject>;
  updateUser: ({}: UpdateUserRequest.AsObject) => Promise<UpdateUserResponse.AsObject>;
  deleteUser: ({}: DeleteUserRequest.AsObject) => Promise<void>;
  checkIfUsernameIsAvailable: (username: string) => Promise<boolean>;
  getUserById: (id: string) => Promise<GetUserResponse.AsObject>;
  getUsersById: ({}: GetUsersByIdRequest.AsObject) => Promise<GetUsersByIdResponse.AsObject>;
  getUserByExternalId: (externalAuthId: string) => Promise<GetUserResponse.AsObject>;
  listUserCommunities: (userId: string) => Promise<Array<Community.AsObject>>;
  connectUser: () => Promise<ClientReadableStream<ConnectedUserEvent>>;
  joinCommunity(communityId: string): Promise<void>;
  leaveCommunity(communityId: string): Promise<void>;
  reportState({}: ReportUserStateRequest.AsObject): Promise<ReportUserStateResponse.AsObject>;
  getUserStatesById({}: GetUserStatesByIdRequest.AsObject): Promise<GetUserStatesByIdResponse.AsObject>;

  // ----- Community
  createCommunity: ({}: CreateCommunityRequest.AsObject) => Promise<CreateCommunityResponse.AsObject>;
  updateCommunity: ({}: UpdateCommunityRequest.AsObject) => Promise<UpdateCommunityResponse.AsObject>;
  deleteCommunity: (communityId: string) => Promise<void>;
  getCommunityById: ({}: GetCommunityByIdRequest.AsObject) => Promise<GetCommunityResponse.AsObject>;
  getCommunityBySlug: (slug: string) => Promise<GetCommunityResponse.AsObject>;
  getCommunityMetrics: (communityId: string) => Promise<GetCommunityMetricsResponse.AsObject>;
  listCommunityUsers(communityId: string): Promise<Array<CommunityUser.AsObject>>;
  addUserToCommunity({}: AddUserToCommunityRequest.AsObject): Promise<void>;
  removeUserFromCommunity({}: RemoveUserFromCommunityRequest.AsObject): Promise<void>;
  addCommunityAdmin({}: AddCommunityAdminRequest.AsObject): Promise<void>;
  removeCommunityAdmin({}: RemoveCommunityAdminRequest.AsObject): Promise<void>;
  sendCommunityInvite({}: CommunitySendInviteRequest.AsObject): Promise<void>;
  cancelCommunityInvite({}: CommunityCancelInviteRequest.AsObject): Promise<void>;

  // ----- Room
  subscribeToRoom({}: SubscribeToRoomRequest.AsObject): Promise<ClientReadableStream<RoomEvent>>;
  getInitialRoomState: (roomId: string) => Promise<GetInitialRoomStateResponse.AsObject>;
  getInitialRoomStateFromSlug({
    communitySlug,
    roomSlug,
  }: GetInitialRoomStateBySlugRequest.AsObject): Promise<GetInitialRoomStateResponse.AsObject>;
  getRoomBySlug: ({}: GetCommunityRoomBySlugRequest.AsObject) => Promise<GetCommunityRoomResponse.AsObject>;
  getRoomById: (roomId: string) => Promise<GetCommunityRoomResponse.AsObject>;
  createRoom: ({}: CreateCommunityRoomRequest.AsObject) => Promise<CreateCommunityRoomResponse.AsObject>;
  listCommunityRooms: (communityId: string) => Promise<ListCommunityRoomsResponse.AsObject>;
  listCommunityRoomsFromSlug: (slug: string) => Promise<SortedRoomList>;
  startOrContinueGameDownload({}: StartOrContinueGameDownloadRequest.AsObject): Promise<void>;
  doneDownloadingGame({}: DoneDownloadingGameRequest.AsObject): Promise<void>;
  updateRoomConfig({}: CommunityRoomConfigurationUpdateRequest.AsObject): Promise<void>;
  updateRoomHost({}: UpdateRoomHostRequest.AsObject): Promise<void>;
  updateRoom({}: Partial<UpdateCommunityRoomRequest.AsObject>): Promise<UpdateCommunityRoomResponse.AsObject>;
  deleteRoom({}: DeleteCommunityRoomRequest.AsObject): Promise<void>;

  // ----- BILLING
  getBillingInfo: (communityId: string) => Promise<GetBillingInfoResponse.AsObject>;
  getBillingPortalSessionLink: (
    customerId: string
  ) => Promise<RequestBillingSessionLinkResponse.AsObject>;
  getBillingSubscriptionCheckoutSessionLink: ({}: {
    customerId: string;
    productId: string;
  }) => Promise<RequestBillingSessionLinkResponse.AsObject>;
  checkCommunityMAUs: ({}: CheckCommunityMAUsRequest.AsObject) => Promise<CheckCommunityMAUsResponse.AsObject>;
  getProSubscriptionPlan: ({}: GetProSubscriptionPlanRequest.AsObject) => Promise<GetProSubscriptionPlanResponse.AsObject>;
  updateAvailablePlans: ({}: UpdateAvailablePlansRequest.AsObject) => Promise<UpdateAvailablePlansResponse.AsObject>;
  getSubscriptionPlans: ({}: GetSubscriptionPlansRequest.AsObject) => Promise<GetSubscriptionPlansResponse.AsObject>;
  getCommunityAvailablePlans: ({}: GetCommunityAvailablePlansRequest.AsObject) => Promise<GetCommunityAvailablePlansResponse.AsObject>;
}

export class AccessManagementClient implements IAccessManagement {
  private static instance: AccessManagementClient;

  private client: GrpcWebAccessManagementClient;

  private constructor() {
    const authInterceptorUnary = new AuthInterceptorUnary();
    const authInterceptorStream = new AuthInterceptorStream();
    const options = {
      unaryInterceptors: [authInterceptorUnary, ...grpcDevtoolsUnaryInterceptor],
      streamInterceptors: [authInterceptorStream, ...grpcDevtoolsStreamInterceptor],
    };

    this.client = new GrpcWebAccessManagementClient(GRPC_API_ENDPOINT, null, options);
  }

  public static getInstance(): AccessManagementClient {
    if (!AccessManagementClient.instance) {
      AccessManagementClient.instance = new AccessManagementClient();
    }

    return AccessManagementClient.instance;
  }

  // ----------------------------------- User -----------------------------------

  async createUser({
    name,
    displayName,
    username,
    externalAuthId,
    bestRegion,
  }: CreateUserRequest.AsObject): Promise<CreateUserResponse.AsObject> {
    const request = new CreateUserRequest();

    request.setName(name);
    if (displayName) {
      request.setDisplayName(new StringValue().setValue(displayName.value));
    }
    request.setUsername(username);
    request.setExternalAuthId(externalAuthId);
    request.setBestRegion(bestRegion);

    const res = await this.client.createUser(request, null);

    return res.toObject();
  }

  async updateUser({
    id,
    username,
    name,
    displayName,
    bestRegion,
    avatarVersion,
  }: UpdateUserRequest.AsObject): Promise<UpdateUserResponse.AsObject> {
    const request = new UpdateUserRequest();

    request.setId(id);
    request.setUsername(username);
    request.setName(name);
    request.setBestRegion(bestRegion);
    request.setAvatarVersion(avatarVersion);
    if (displayName) {
      request.setDisplayName(new StringValue().setValue(displayName.value));
    }

    const res = await this.client.updateUser(request, null);
    return res.toObject();
  }

  async deleteUser({ id }: DeleteUserRequest.AsObject): Promise<void> {
    const request = new DeleteUserRequest();
    request.setId(id);

    await this.client.deleteUser(request, null);
  }

  async checkIfUsernameIsAvailable(username: string): Promise<boolean> {
    const request = new CheckIfUsernameIsAvailableRequest();
    request.setUsername(username);

    const res = await this.client.checkIfUsernameIsAvailable(request, null);
    return res.getAvailable();
  }

  async getUserByExternalId(externalAuthId: string): Promise<GetUserResponse.AsObject> {
    const request = new GetUserByExternalAuthIdRequest();
    request.setId(externalAuthId);

    const res = await this.client.getUserByExternalAuthId(request, null);
    return res.toObject();
  }

  async getUserById(id: string): Promise<GetUserResponse.AsObject> {
    const request = new GetUserByIdRequest();
    request.setId(id);

    const res = await this.client.getUserById(request, null);
    return res.toObject();
  }

  async getUsersById({
    idsList,
  }: GetUsersByIdRequest.AsObject): Promise<GetUsersByIdResponse.AsObject> {
    const request = new GetUsersByIdRequest();
    request.setIdsList(idsList);

    const res = await this.client.getUsersById(request, null);
    return res.toObject();
  }

  async listUserCommunities(userId: string): Promise<Array<Community.AsObject>> {
    try {
      const request = new ListUserCommunitiesRequest();
      request.setId(userId);

      const res = await this.client.listUserCommunities(request, null);

      const communities = res.getCommunitiesList().map((community) => community.toObject());

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const mockBillingMapFree: [string, string][] = [
        ['is_frozen', 'false'],
        ['current_plan', 'free'],
      ];

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const mockBillingMapPro: [string, string][] = [
        ['is_frozen', 'false'],
        ['current_plan', 'pro'],
      ];

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const mockBillingMapOverCapacity: [string, string][] = [
        ['is_frozen', 'true'],
        ['reasoning_for_freeze', 'over_capacity'],
        ['current_plan', 'free'],
      ];

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const mockBillingMapOverdue: [string, string][] = [
        ['is_frozen', 'true'],
        ['reasoning_for_freeze', 'overdue'],
        ['current_plan', 'pro'],
      ];

      // if (communities.length > 0) {
      //   const community = communities[0];
      //   // mock data
      //   const mockCommunities: Community.AsObject[] = [
      //     {
      //       ...community,
      //       billingInfoMap: mockBillingMapPro,
      //     },

      //     {
      //       ...community,
      //       admin: true,
      //       billingInfoMap: mockBillingMapOverdue,
      //     },

      //     {
      //       ...community,
      //       admin: false,
      //       billingInfoMap: mockBillingMapOverdue,
      //     },

      //     {
      //       ...community,
      //       admin: true,
      //       billingInfoMap: mockBillingMapOverCapacity,
      //     },
      //     {
      //       ...community,
      //       admin: false,
      //       billingInfoMap: mockBillingMapOverCapacity,
      //     },
      //   ];

      //   return mockCommunities;
      // }

      return communities;
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  async connectUser(): Promise<ClientReadableStream<ConnectedUserEvent>> {
    await refreshAccessToken(); // need to refresh token before subscribing to room since the Stream interceptor cannot be async

    const request = new ConnectUserRequest();
    return this.client.connectUser(request, undefined);
  }

  async joinCommunity(communityId: string): Promise<void> {
    const request = new JoinCommunityRequest();
    request.setCommunityId(communityId);
    await this.client.joinCommunity(request, null);
  }

  async leaveCommunity(communityId: string): Promise<void> {
    const request = new LeaveCommunityRequest();
    request.setCommunityId(communityId);
    await this.client.leaveCommunity(request, null);
  }

  async reportState({
    userStateUpdatesList,
  }: ReportUserStateRequest.AsObject): Promise<ReportUserStateResponse.AsObject> {
    const request = new ReportUserStateRequest();
    request.setUserStateUpdatesList(toRPCStatesParam(userStateUpdatesList));

    const res = await this.client.reportUserState(request, null);
    return res.toObject();
  }

  async getUserStatesById({
    userIdsList,
  }: GetUserStatesByIdRequest.AsObject): Promise<GetUserStatesByIdResponse.AsObject> {
    const request = new GetUserStatesByIdRequest();
    request.setUserIdsList(userIdsList);

    const res = await this.client.getUserStatesById(request, null);
    return res.toObject();
  }

  // ----------------------------------- Community -----------------------------------

  async createCommunity({
    name,
    description,
    gatesList,
    logoVersion,
    bannerVersion,
    gameNight,
    gameNightEnabled,
    communityType,
  }: CreateCommunityRequest.AsObject): Promise<CreateCommunityResponse.AsObject> {
    const request = new CreateCommunityRequest();
    request.setName(name);
    request.setDescription(description);
    request.setGatesList(toRPCGatesParam(gatesList));
    request.setLogoVersion(logoVersion);
    request.setBannerVersion(bannerVersion);
    request.setGameNightEnabled(gameNightEnabled);
    request.setCommunityType(communityType);
    if (gameNight) {
      const gn = new GameNight();
      gn.setFrequency(gameNight.frequency);
      gn.setWeekday(gameNight.weekday);
      gn.setHour(gameNight.hour);
      gn.setMinute(gameNight.minute);
      gn.setTimestamp(gameNight.timestamp);
      request.setGameNight(gn);
    }

    const res = await this.client.createCommunity(request, null);
    return res.toObject();
  }

  async updateCommunity({
    id,
    name,
    description,
    gatesList,
    logoVersion,
    bannerVersion,
    gameNight,
    gameNightEnabled,
    version,
    communityType,
  }: UpdateCommunityRequest.AsObject): Promise<UpdateCommunityResponse.AsObject> {
    const request = new UpdateCommunityRequest();

    request.setId(id);
    request.setName(name);
    request.setDescription(description);
    request.setGatesList(toRPCGatesParam(gatesList));
    request.setLogoVersion(logoVersion);
    request.setBannerVersion(bannerVersion);
    request.setGameNightEnabled(gameNightEnabled);
    request.setVersion(version);
    request.setCommunityType(communityType);

    if (gameNight) {
      const gn = new GameNight();
      gn.setFrequency(gameNight.frequency);
      gn.setWeekday(gameNight.weekday);
      gn.setHour(gameNight.hour);
      gn.setMinute(gameNight.minute);
      gn.setTimestamp(gameNight.timestamp);
      request.setGameNight(gn);
    }

    const res = await this.client.updateCommunity(request, null);
    return res.toObject();
  }

  async deleteCommunity(communityId: string): Promise<void> {
    const request = new DeleteCommunityRequest();
    request.setId(communityId);
    await this.client.deleteCommunity(request, null);
  }

  async getCommunityById({
    id,
  }: GetCommunityByIdRequest.AsObject): Promise<GetCommunityResponse.AsObject> {
    const request = new GetCommunityByIdRequest();
    request.setId(id);

    const res = await this.client.getCommunityById(request, null);
    return res.toObject();
  }

  async getCommunityBySlug(slug: string): Promise<GetCommunityResponse.AsObject> {
    if (slug === '') {
      throw new Error('getCommunityBySlug: slug is required');
    }
    const request = new GetCommunityBySlugRequest();
    request.setSlug(slug);

    const res = await this.client.getCommunityBySlug(request, null);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const mockBillingMapFree: [string, string][] = [
      ['is_frozen', 'false'],
      ['current_plan', 'free'],
    ];

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const mockBillingMapPro: [string, string][] = [
      ['is_frozen', 'false'],
      ['current_plan', 'pro'],
    ];

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const mockBillingMapOverCapacity: [string, string][] = [
      ['is_frozen', 'true'],
      ['reasoning_for_freeze', 'over_capacity'],
      ['current_plan', 'free'],
    ];

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const mockBillingMapOverdue: [string, string][] = [
      ['is_frozen', 'true'],
      ['reasoning_for_freeze', 'overdue'],
      ['current_plan', 'pro'],
    ];

    // return { ...res.toObject(), billingInfoMap: mockBillingMapOverdue };
    return res.toObject();
  }

  async getCommunityProfileBySlug(
    slug: string
  ): Promise<GetCommunityProfileBySlugResponse.AsObject> {
    const request = new GetCommunityProfileBySlugRequest();
    request.setSlug(slug);

    const res = await this.client.getCommunityProfileBySlug(request, null);
    return res.toObject();
  }

  async getCommunityMetrics(communityId: string): Promise<GetCommunityMetricsResponse.AsObject> {
    try {
      const request = new GetCommunityMetricsRequest();
      request.setId(communityId);

      const res = await this.client.getCommunityMetrics(request, null);
      return res.toObject();
    } catch (err) {
      console.log(err);
      return {
        id: '',
        onlineMembers: 0,
        totalMembers: 0,
        roomCount: 0,
        mau: 0,
      };
    }
  }

  async listCommunityUsers(communityId: string): Promise<Array<CommunityUser.AsObject>> {
    try {
      const request = new ListCommunityUsersRequest();
      request.setId(communityId);

      const res = await this.client.listCommunityUsers(request, null);
      const users = res.getUsersList().map((user) => user.toObject());
      const invitedUsers = res.getInvitedUsersList().map((user) => user.toObject());
      invitedUsers.forEach((invitedUser) => {
        users.push({
          id: invitedUser.id,
          role: 'invited',
          lastActive: 0,
          name: '',
          username: invitedUser.email,
          displayName: '',
          avatarUrl: '',
        });
      });
      return users;
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  async addUserToCommunity({
    communityId,
    userId,
  }: AddUserToCommunityRequest.AsObject): Promise<void> {
    const request = new AddUserToCommunityRequest();
    request.setCommunityId(communityId);
    request.setUserId(userId);

    await this.client.addUserToCommunity(request, null);
  }

  async removeUserFromCommunity({
    communityId,
    userId,
  }: RemoveUserFromCommunityRequest.AsObject): Promise<void> {
    const request = new RemoveUserFromCommunityRequest();
    request.setCommunityId(communityId);
    request.setUserId(userId);

    await this.client.removeUserFromCommunity(request, null);
  }

  async addCommunityAdmin({
    communityId,
    userId,
  }: AddCommunityAdminRequest.AsObject): Promise<void> {
    const request = new AddCommunityAdminRequest();
    request.setCommunityId(communityId);
    request.setUserId(userId);

    await this.client.addCommunityAdmin(request, null);
  }

  async removeCommunityAdmin({
    communityId,
    userId,
  }: RemoveCommunityAdminRequest.AsObject): Promise<void> {
    const request = new RemoveCommunityAdminRequest();
    request.setCommunityId(communityId);
    request.setUserId(userId);

    await this.client.removeCommunityAdmin(request, null);
  }

  async sendCommunityInvite({
    communityId,
    email,
    isAdmin,
  }: CommunitySendInviteRequest.AsObject): Promise<void> {
    const request = new CommunitySendInviteRequest();
    request.setCommunityId(communityId);
    request.setEmail(email);
    request.setIsAdmin(isAdmin);

    await this.client.communitySendInvite(request, null);
  }

  async cancelCommunityInvite({
    communityId,
    inviteId,
  }: CommunityCancelInviteRequest.AsObject): Promise<void> {
    const request = new CommunityCancelInviteRequest();
    request.setCommunityId(communityId);
    request.setInviteId(inviteId);

    await this.client.communityCancelInvite(request, null);
  }

  // ----------------------------------- ROOMS -----------------------------------

  async subscribeToRoom({
    roomId,
    communitySlug,
    roomSlug,
  }: SubscribeToRoomRequest.AsObject): Promise<ClientReadableStream<RoomEvent>> {
    await refreshAccessToken(); // need to refresh token before subscribing to room since the Stream interceptor cannot be async

    const request = new SubscribeToRoomRequest();
    if (roomId && roomId !== '') {
      request.setRoomId(roomId);
    }

    if (communitySlug && roomSlug && communitySlug !== '' && roomSlug !== '') {
      request.setCommunitySlug(communitySlug);
      request.setRoomSlug(roomSlug);
    }

    return this.client.subscribeToRoom(request, undefined);
  }

  async getInitialRoomState(roomId: string): Promise<GetInitialRoomStateResponse.AsObject> {
    const request = new GetInitialRoomStateRequest();
    request.setRoomId(roomId);

    const res = await this.client.getInitialRoomState(request, null);
    return res.toObject();
  }

  async getInitialRoomStateFromSlug({
    communitySlug,
    roomSlug,
  }: GetInitialRoomStateBySlugRequest.AsObject): Promise<GetInitialRoomStateResponse.AsObject> {
    const request = new GetInitialRoomStateBySlugRequest();
    request.setCommunitySlug(communitySlug);
    request.setRoomSlug(roomSlug);

    const res = await this.client.getInitialRoomStateBySlug(request, null);
    return res.toObject();
  }

  async getRoomBySlug({
    communitySlug,
    slug,
    communityId,
  }: GetCommunityRoomBySlugRequest.AsObject): Promise<GetCommunityRoomResponse.AsObject> {
    const request = new GetCommunityRoomBySlugRequest();
    request.setCommunitySlug(communitySlug);
    request.setSlug(slug);
    request.setCommunityId(communityId);

    const res = await this.client.getCommunityRoomBySlug(request, null);
    return res.toObject();
  }

  async getRoomById(roomId: string): Promise<GetCommunityRoomResponse.AsObject> {
    const request = new GetCommunityRoomByIdRequest();
    request.setId(roomId);
    const res = await this.client.getCommunityRoomById(request, null);
    return res.toObject();
  }

  async createRoom({
    communityId,
    host,
    name,
    pinned,
    pb_private,
    roomType,
    isTournament,
  }: CreateCommunityRoomRequest.AsObject): Promise<CreateCommunityRoomResponse.AsObject> {
    const request = new CreateCommunityRoomRequest();
    request.setCommunityId(communityId);
    request.setHost(host);
    request.setName(name);
    if (pinned) {
      request.setPinned(new BoolValue().setValue(pinned.value));
    }
    if (pb_private) {
      request.setPrivate(new BoolValue().setValue(pb_private.value));
    }
    request.setRoomType(roomType);
    request.setIsTournament(isTournament);

    const res = await this.client.createCommunityRoom(request, null);
    return res.toObject();
  }

  async listCommunityRooms(communityId: string): Promise<ListCommunityRoomsResponse.AsObject> {
    const request = new ListCommunityRoomsRequest();
    request.setCommunityId(communityId);
    return (await this.client.listCommunityRooms(request, null)).toObject();
  }

  async listCommunityRoomsFromSlug(slug: string): Promise<SortedRoomList> {
    const request = new ListCommunityRoomsFromSlugRequest();
    request.setSlug(slug);

    const { roomsList } = (await this.client.listCommunityRoomsFromSlug(request, null)).toObject();

    let gameNight: GrpcRoom.AsObject | undefined = undefined;
    const pinnedRooms: Array<GrpcRoom.AsObject> = [];
    const officialRooms: Array<GrpcRoom.AsObject> = [];
    const personalRooms: Array<GrpcRoom.AsObject> = [];

    roomsList.forEach((r) => {
      switch (r.roomType) {
        case RoomType.ROOM_TYPE_GAME_NIGHT:
          gameNight = r;
          break;
        case RoomType.ROOM_TYPE_OFFICIAL:
          if (r.pinned) {
            pinnedRooms.push(r);
          } else {
            officialRooms.push(r);
          }
          break;
        case RoomType.ROOM_TYPE_PERSONAL:
          personalRooms.push(r);
          break;
        case RoomType.ROOM_TYPE_UNKNOWN: // treat unknown as official
          if (r.pinned) {
            pinnedRooms.push(r);
          } else {
            officialRooms.push(r);
          }
      }
    });

    return {
      gameNight,
      pinned: pinnedRooms,
      official: officialRooms,
      personal: personalRooms,
    };
  }

  async startOrContinueGameDownload({
    roomId,
    game,
    version,
  }: StartOrContinueGameDownloadRequest.AsObject): Promise<void> {
    const request = new StartOrContinueGameDownloadRequest();
    request.setRoomId(roomId);
    request.setGame(game);
    request.setVersion(version);

    await this.client.startOrContinueGameDownload(request, null);
  }

  async doneDownloadingGame({
    roomId,
    game,
    version,
  }: DoneDownloadingGameRequest.AsObject): Promise<void> {
    const request = new DoneDownloadingGameRequest();
    request.setRoomId(roomId);
    request.setGame(game);
    request.setVersion(version);

    await this.client.doneDownloadingGame(request, null);
  }

  async updateRoomConfig({
    configMap,
    id,
  }: CommunityRoomConfigurationUpdateRequest.AsObject): Promise<void> {
    const request = new CommunityRoomConfigurationUpdateRequest();
    request.setId(id);

    const config = request.getConfigMap();
    for (const [key, value] of configMap) {
      config.set(key, value);
    }

    await this.client.communityRoomConfigurationUpdate(request, null);
  }

  async updateRoomHost({ roomId, userId }: UpdateRoomHostRequest.AsObject): Promise<void> {
    const request = new UpdateRoomHostRequest();
    request.setRoomId(roomId);
    request.setUserId(userId);
    await this.client.updateRoomHost(request, null);
  }

  async updateRoom({
    id,
    host,
    name,
    pinned,
    pb_private,
  }: Partial<UpdateCommunityRoomRequest.AsObject>): Promise<UpdateCommunityRoomResponse.AsObject> {
    const request = new UpdateCommunityRoomRequest();

    if (!id || id === '') {
      throw new Error('Update Room: ID is required');
    }

    request.setId(id);
    if (host) {
      request.setHost(host);
    }
    if (name) {
      request.setName(name);
    }
    if (pb_private) {
      request.setPrivate(new BoolValue().setValue(pb_private.value));
    }
    if (pinned) {
      request.setPinned(new BoolValue().setValue(pinned.value));
    }

    const res = await this.client.updateCommunityRoom(request, null);
    return res.toObject();
  }

  async deleteRoom({ id }: DeleteCommunityRoomRequest.AsObject): Promise<void> {
    const request = new DeleteCommunityRoomRequest();
    request.setId(id);
    await this.client.deleteCommunityRoom(request, null);
  }

  // BILLING
  async getBillingInfo(communityId: string): Promise<GetBillingInfoResponse.AsObject> {
    const request = new GetBillingInfoRequest();
    request.setId(communityId);
    request.setCustomerType(CustomerType.CUSTOMER_TYPE_COMMUNITY);

    const res = await this.client.getBillingInfo(request, null);
    return res.toObject();
  }

  async getBillingPortalSessionLink(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    customerId: string
  ): Promise<RequestBillingSessionLinkResponse.AsObject> {
    const request = new RequestBillingSessionLinkRequest();
    request.setCustomerId(customerId);

    const billingSession = new BillingSession();
    const portalSession = new BillingPortalSession();
    billingSession.setPortal(portalSession);
    request.setSession(billingSession);

    const res = await this.client.requestBillingSessionLink(request, null);
    return res.toObject();

    // await delay(500);

    // return { redirectUrl: 'https://www.youtube.com/watch?v=d8s1t1SsjyE' };
  }

  async getBillingSubscriptionCheckoutSessionLink({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    customerId,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    productId,
  }: {
    customerId: string;
    productId: string;
  }): Promise<RequestBillingSessionLinkResponse.AsObject> {
    const request = new RequestBillingSessionLinkRequest();
    request.setCustomerId(customerId);

    const billingSession = new BillingSession();
    const subscriptionCheckoutSession = new BillingSubscriptionCheckoutSession();
    subscriptionCheckoutSession.setProductId(productId);
    billingSession.setSubscriptionCheckout(subscriptionCheckoutSession);
    request.setSession(billingSession);

    const res = await this.client.requestBillingSessionLink(request, null);
    return res.toObject();

    // return { redirectUrl: 'https://www.youtube.com/watch?v=d8s1t1SsjyE' };
  }

  async checkCommunityMAUs({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    communityId,
    userIdsList,
  }: CheckCommunityMAUsRequest.AsObject): Promise<CheckCommunityMAUsResponse.AsObject> {
    const request = new CheckCommunityMAUsRequest();
    request.setCommunityId(communityId);
    request.setUserIdsList(userIdsList);

    const res = await this.client.checkCommunityMAUs(request, null);
    return res.toObject();

    // // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // const mockDataAllActive = {
    //   mausList: userIdsList.map((id) => ({ userId: id })),
    // };

    // // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // const mockDataSomeActive = {
    //   mausList: userIdsList.slice(0, userIdsList.length / 2).map((id) => ({ userId: id })),
    // };

    // // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // const mockDataNoActive = { mausList: [] };

    // return mockDataSomeActive;
  }

  async getProSubscriptionPlan({}: GetProSubscriptionPlanRequest.AsObject): Promise<GetProSubscriptionPlanResponse.AsObject> {
    const request = new GetProSubscriptionPlanRequest();

    const res = await this.client.getProSubscriptionPlan(request, null);
    return res.toObject();
  }

  async updateAvailablePlans({
    customerId,
    planIdsList,
    include,
  }: UpdateAvailablePlansRequest.AsObject): Promise<UpdateAvailablePlansResponse.AsObject> {
    const request = new UpdateAvailablePlansRequest();
    request.setPlanIdsList(planIdsList);
    request.setCustomerId(customerId);
    request.setInclude(include);

    const res = await this.client.updateAvailablePlans(request, null);
    return res.toObject();
  }

  async getSubscriptionPlans({}: GetSubscriptionPlansRequest.AsObject): Promise<GetSubscriptionPlansResponse.AsObject> {
    const request = new GetSubscriptionPlansRequest();

    const res = await this.client.getSubscriptionPlans(request, null);
    return res.toObject();
  }

  async getCommunityAvailablePlans({
    communityId,
  }: GetCommunityAvailablePlansRequest.AsObject): Promise<GetCommunityAvailablePlansResponse.AsObject> {
    const request = new GetCommunityAvailablePlansRequest();
    request.setCommunityId(communityId);

    const res = await this.client.getCommunityAvailablePlans(request, null);
    return res.toObject();
  }
}
