import {
  Muted,
  SlowInternetConnection,
  UserState,
  VoiceChatOptOut,
} from '@ig/platform_sdk_web/src/modules/access_management/access_management_pb';

export const toRPCStatesParam = (states: Array<UserState.AsObject>): Array<UserState> => {
  return states.map((state) => {
    const stateRPC = new UserState();

    if (state.slowInternetConnection) {
      const slowInternetState = new SlowInternetConnection();
      slowInternetState.setSlow(state.slowInternetConnection.slow);
      stateRPC.setSlowInternetConnection(slowInternetState);
    } else if (state.muted) {
      const muted = new Muted();
      muted.setMuted(state.muted.muted);
      stateRPC.setMuted(muted);
    } else if (state.voiceChatOptout) {
      const voiceChatOptOut = new VoiceChatOptOut();
      voiceChatOptOut.setVoiceChatOptOut(state.voiceChatOptout.voiceChatOptOut);
      stateRPC.setVoiceChatOptout(voiceChatOptOut);
    }

    return stateRPC;
  });
};
