// source: src/modules/tournaments/tournaments.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var validate_validate_pb = require('../../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.tournaments.AddTournamentRoundRequest', null, global);
goog.exportSymbol('proto.tournaments.CreateAttemptRequest', null, global);
goog.exportSymbol('proto.tournaments.CreateAttemptResponse', null, global);
goog.exportSymbol('proto.tournaments.CreateTournamentRequest', null, global);
goog.exportSymbol('proto.tournaments.DeleteTournamentRequest', null, global);
goog.exportSymbol('proto.tournaments.DeleteTournamentResponse', null, global);
goog.exportSymbol('proto.tournaments.DeleteTournamentRoundRequest', null, global);
goog.exportSymbol('proto.tournaments.EndTournamentRequest', null, global);
goog.exportSymbol('proto.tournaments.EndTournamentResponse', null, global);
goog.exportSymbol('proto.tournaments.EndTournamentRoundRequest', null, global);
goog.exportSymbol('proto.tournaments.EndTournamentRoundResponse', null, global);
goog.exportSymbol('proto.tournaments.Gating', null, global);
goog.exportSymbol('proto.tournaments.GatingMethod', null, global);
goog.exportSymbol('proto.tournaments.GatingMethod.KeyValuePair', null, global);
goog.exportSymbol('proto.tournaments.GatingMethodArray', null, global);
goog.exportSymbol('proto.tournaments.GatingType', null, global);
goog.exportSymbol('proto.tournaments.GetTournamentLeaderboardsRequest', null, global);
goog.exportSymbol('proto.tournaments.GetTournamentLeaderboardsResponse', null, global);
goog.exportSymbol('proto.tournaments.GetTournamentRequest', null, global);
goog.exportSymbol('proto.tournaments.GetTournamentResponse', null, global);
goog.exportSymbol('proto.tournaments.GetTournamentResultSummaryByOwnerIdRequest', null, global);
goog.exportSymbol('proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse', null, global);
goog.exportSymbol('proto.tournaments.GetTournamentsRequest', null, global);
goog.exportSymbol('proto.tournaments.GetTournamentsResponse', null, global);
goog.exportSymbol('proto.tournaments.InternalGetTournamentRequest', null, global);
goog.exportSymbol('proto.tournaments.InternalGetTournamentResponse', null, global);
goog.exportSymbol('proto.tournaments.LeaderboardRecord', null, global);
goog.exportSymbol('proto.tournaments.LeaderboardRecordType', null, global);
goog.exportSymbol('proto.tournaments.LeaderboardResult', null, global);
goog.exportSymbol('proto.tournaments.LeaderboardResultRecord', null, global);
goog.exportSymbol('proto.tournaments.ReopenTournamentRequest', null, global);
goog.exportSymbol('proto.tournaments.ReopenTournamentResponse', null, global);
goog.exportSymbol('proto.tournaments.ResetTournamentRequest', null, global);
goog.exportSymbol('proto.tournaments.ResetTournamentResponse', null, global);
goog.exportSymbol('proto.tournaments.Round', null, global);
goog.exportSymbol('proto.tournaments.RoundApp', null, global);
goog.exportSymbol('proto.tournaments.RoundConfiguration', null, global);
goog.exportSymbol('proto.tournaments.RoundParams', null, global);
goog.exportSymbol('proto.tournaments.RoundRecord', null, global);
goog.exportSymbol('proto.tournaments.RoundResultSummary', null, global);
goog.exportSymbol('proto.tournaments.RoundScoringSettings', null, global);
goog.exportSymbol('proto.tournaments.RoundScoringType', null, global);
goog.exportSymbol('proto.tournaments.ScoreRecord', null, global);
goog.exportSymbol('proto.tournaments.StartTournamentRequest', null, global);
goog.exportSymbol('proto.tournaments.StartTournamentResponse', null, global);
goog.exportSymbol('proto.tournaments.Status', null, global);
goog.exportSymbol('proto.tournaments.SubmitScoreRequest', null, global);
goog.exportSymbol('proto.tournaments.SubmitScoreResponse', null, global);
goog.exportSymbol('proto.tournaments.TournamentConfiguration', null, global);
goog.exportSymbol('proto.tournaments.TournamentDetails', null, global);
goog.exportSymbol('proto.tournaments.TournamentEventTypes', null, global);
goog.exportSymbol('proto.tournaments.TournamentMode', null, global);
goog.exportSymbol('proto.tournaments.TournamentResultSummary', null, global);
goog.exportSymbol('proto.tournaments.TournamentScoringSettings', null, global);
goog.exportSymbol('proto.tournaments.TournamentScoringType', null, global);
goog.exportSymbol('proto.tournaments.TournamentsJobType', null, global);
goog.exportSymbol('proto.tournaments.UpdateTournamentRequest', null, global);
goog.exportSymbol('proto.tournaments.UpdateTournamentRoundRequest', null, global);
goog.exportSymbol('proto.tournaments.UpdateTournamentStatusRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.TournamentScoringSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.TournamentScoringSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.TournamentScoringSettings.displayName = 'proto.tournaments.TournamentScoringSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.TournamentConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.TournamentConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.TournamentConfiguration.displayName = 'proto.tournaments.TournamentConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.RoundScoringSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.RoundScoringSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.RoundScoringSettings.displayName = 'proto.tournaments.RoundScoringSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.RoundConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.RoundConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.RoundConfiguration.displayName = 'proto.tournaments.RoundConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.LeaderboardRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.LeaderboardRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.LeaderboardRecord.displayName = 'proto.tournaments.LeaderboardRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.RoundApp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.RoundApp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.RoundApp.displayName = 'proto.tournaments.RoundApp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.RoundParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.RoundParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.RoundParams.displayName = 'proto.tournaments.RoundParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.Round = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.Round, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.Round.displayName = 'proto.tournaments.Round';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.ScoreRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.ScoreRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.ScoreRecord.displayName = 'proto.tournaments.ScoreRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.RoundRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tournaments.RoundRecord.repeatedFields_, null);
};
goog.inherits(proto.tournaments.RoundRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.RoundRecord.displayName = 'proto.tournaments.RoundRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.GatingMethod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tournaments.GatingMethod.repeatedFields_, null);
};
goog.inherits(proto.tournaments.GatingMethod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.GatingMethod.displayName = 'proto.tournaments.GatingMethod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.GatingMethod.KeyValuePair = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.GatingMethod.KeyValuePair, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.GatingMethod.KeyValuePair.displayName = 'proto.tournaments.GatingMethod.KeyValuePair';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.GatingMethodArray = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tournaments.GatingMethodArray.repeatedFields_, null);
};
goog.inherits(proto.tournaments.GatingMethodArray, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.GatingMethodArray.displayName = 'proto.tournaments.GatingMethodArray';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.Gating = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tournaments.Gating.repeatedFields_, null);
};
goog.inherits(proto.tournaments.Gating, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.Gating.displayName = 'proto.tournaments.Gating';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.CreateTournamentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tournaments.CreateTournamentRequest.repeatedFields_, null);
};
goog.inherits(proto.tournaments.CreateTournamentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.CreateTournamentRequest.displayName = 'proto.tournaments.CreateTournamentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.UpdateTournamentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tournaments.UpdateTournamentRequest.repeatedFields_, null);
};
goog.inherits(proto.tournaments.UpdateTournamentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.UpdateTournamentRequest.displayName = 'proto.tournaments.UpdateTournamentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.UpdateTournamentStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.UpdateTournamentStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.UpdateTournamentStatusRequest.displayName = 'proto.tournaments.UpdateTournamentStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.DeleteTournamentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.DeleteTournamentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.DeleteTournamentRequest.displayName = 'proto.tournaments.DeleteTournamentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.DeleteTournamentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.DeleteTournamentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.DeleteTournamentResponse.displayName = 'proto.tournaments.DeleteTournamentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.ResetTournamentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.ResetTournamentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.ResetTournamentRequest.displayName = 'proto.tournaments.ResetTournamentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.ResetTournamentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tournaments.ResetTournamentResponse.repeatedFields_, null);
};
goog.inherits(proto.tournaments.ResetTournamentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.ResetTournamentResponse.displayName = 'proto.tournaments.ResetTournamentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.GetTournamentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.GetTournamentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.GetTournamentRequest.displayName = 'proto.tournaments.GetTournamentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.GetTournamentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tournaments.GetTournamentResponse.repeatedFields_, null);
};
goog.inherits(proto.tournaments.GetTournamentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.GetTournamentResponse.displayName = 'proto.tournaments.GetTournamentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.TournamentDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tournaments.TournamentDetails.repeatedFields_, null);
};
goog.inherits(proto.tournaments.TournamentDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.TournamentDetails.displayName = 'proto.tournaments.TournamentDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.GetTournamentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tournaments.GetTournamentsRequest.repeatedFields_, null);
};
goog.inherits(proto.tournaments.GetTournamentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.GetTournamentsRequest.displayName = 'proto.tournaments.GetTournamentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.GetTournamentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tournaments.GetTournamentsResponse.repeatedFields_, null);
};
goog.inherits(proto.tournaments.GetTournamentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.GetTournamentsResponse.displayName = 'proto.tournaments.GetTournamentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.AddTournamentRoundRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.AddTournamentRoundRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.AddTournamentRoundRequest.displayName = 'proto.tournaments.AddTournamentRoundRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.DeleteTournamentRoundRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.DeleteTournamentRoundRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.DeleteTournamentRoundRequest.displayName = 'proto.tournaments.DeleteTournamentRoundRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.UpdateTournamentRoundRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.UpdateTournamentRoundRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.UpdateTournamentRoundRequest.displayName = 'proto.tournaments.UpdateTournamentRoundRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.CreateAttemptRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.CreateAttemptRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.CreateAttemptRequest.displayName = 'proto.tournaments.CreateAttemptRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.CreateAttemptResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.CreateAttemptResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.CreateAttemptResponse.displayName = 'proto.tournaments.CreateAttemptResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.SubmitScoreRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.SubmitScoreRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.SubmitScoreRequest.displayName = 'proto.tournaments.SubmitScoreRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.SubmitScoreResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.SubmitScoreResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.SubmitScoreResponse.displayName = 'proto.tournaments.SubmitScoreResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.GetTournamentResultSummaryByOwnerIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.GetTournamentResultSummaryByOwnerIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.GetTournamentResultSummaryByOwnerIdRequest.displayName = 'proto.tournaments.GetTournamentResultSummaryByOwnerIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.TournamentResultSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.TournamentResultSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.TournamentResultSummary.displayName = 'proto.tournaments.TournamentResultSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.RoundResultSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.RoundResultSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.RoundResultSummary.displayName = 'proto.tournaments.RoundResultSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse.repeatedFields_, null);
};
goog.inherits(proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse.displayName = 'proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.GetTournamentLeaderboardsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.GetTournamentLeaderboardsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.GetTournamentLeaderboardsRequest.displayName = 'proto.tournaments.GetTournamentLeaderboardsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.LeaderboardResultRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.LeaderboardResultRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.LeaderboardResultRecord.displayName = 'proto.tournaments.LeaderboardResultRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.LeaderboardResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tournaments.LeaderboardResult.repeatedFields_, null);
};
goog.inherits(proto.tournaments.LeaderboardResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.LeaderboardResult.displayName = 'proto.tournaments.LeaderboardResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.GetTournamentLeaderboardsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tournaments.GetTournamentLeaderboardsResponse.repeatedFields_, null);
};
goog.inherits(proto.tournaments.GetTournamentLeaderboardsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.GetTournamentLeaderboardsResponse.displayName = 'proto.tournaments.GetTournamentLeaderboardsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.StartTournamentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.StartTournamentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.StartTournamentRequest.displayName = 'proto.tournaments.StartTournamentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.StartTournamentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.StartTournamentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.StartTournamentResponse.displayName = 'proto.tournaments.StartTournamentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.EndTournamentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.EndTournamentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.EndTournamentRequest.displayName = 'proto.tournaments.EndTournamentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.EndTournamentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.EndTournamentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.EndTournamentResponse.displayName = 'proto.tournaments.EndTournamentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.EndTournamentRoundRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.EndTournamentRoundRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.EndTournamentRoundRequest.displayName = 'proto.tournaments.EndTournamentRoundRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.EndTournamentRoundResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.EndTournamentRoundResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.EndTournamentRoundResponse.displayName = 'proto.tournaments.EndTournamentRoundResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.ReopenTournamentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.ReopenTournamentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.ReopenTournamentRequest.displayName = 'proto.tournaments.ReopenTournamentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.ReopenTournamentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.ReopenTournamentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.ReopenTournamentResponse.displayName = 'proto.tournaments.ReopenTournamentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.InternalGetTournamentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tournaments.InternalGetTournamentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.InternalGetTournamentRequest.displayName = 'proto.tournaments.InternalGetTournamentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tournaments.InternalGetTournamentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tournaments.InternalGetTournamentResponse.repeatedFields_, null);
};
goog.inherits(proto.tournaments.InternalGetTournamentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tournaments.InternalGetTournamentResponse.displayName = 'proto.tournaments.InternalGetTournamentResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.TournamentScoringSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.TournamentScoringSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.TournamentScoringSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.TournamentScoringSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startingPoints: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.TournamentScoringSettings}
 */
proto.tournaments.TournamentScoringSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.TournamentScoringSettings;
  return proto.tournaments.TournamentScoringSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.TournamentScoringSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.TournamentScoringSettings}
 */
proto.tournaments.TournamentScoringSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.tournaments.TournamentScoringType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setStartingPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.TournamentScoringSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.TournamentScoringSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.TournamentScoringSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.TournamentScoringSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getStartingPoints();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional TournamentScoringType type = 1;
 * @return {!proto.tournaments.TournamentScoringType}
 */
proto.tournaments.TournamentScoringSettings.prototype.getType = function() {
  return /** @type {!proto.tournaments.TournamentScoringType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.tournaments.TournamentScoringType} value
 * @return {!proto.tournaments.TournamentScoringSettings} returns this
 */
proto.tournaments.TournamentScoringSettings.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional float starting_points = 2;
 * @return {number}
 */
proto.tournaments.TournamentScoringSettings.prototype.getStartingPoints = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.TournamentScoringSettings} returns this
 */
proto.tournaments.TournamentScoringSettings.prototype.setStartingPoints = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.TournamentConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.TournamentConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.TournamentConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.TournamentConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoringSettings: (f = msg.getScoringSettings()) && proto.tournaments.TournamentScoringSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.TournamentConfiguration}
 */
proto.tournaments.TournamentConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.TournamentConfiguration;
  return proto.tournaments.TournamentConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.TournamentConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.TournamentConfiguration}
 */
proto.tournaments.TournamentConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.tournaments.TournamentScoringSettings;
      reader.readMessage(value,proto.tournaments.TournamentScoringSettings.deserializeBinaryFromReader);
      msg.setScoringSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.TournamentConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.TournamentConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.TournamentConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.TournamentConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoringSettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.tournaments.TournamentScoringSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional TournamentScoringSettings scoring_settings = 1;
 * @return {?proto.tournaments.TournamentScoringSettings}
 */
proto.tournaments.TournamentConfiguration.prototype.getScoringSettings = function() {
  return /** @type{?proto.tournaments.TournamentScoringSettings} */ (
    jspb.Message.getWrapperField(this, proto.tournaments.TournamentScoringSettings, 1));
};


/**
 * @param {?proto.tournaments.TournamentScoringSettings|undefined} value
 * @return {!proto.tournaments.TournamentConfiguration} returns this
*/
proto.tournaments.TournamentConfiguration.prototype.setScoringSettings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tournaments.TournamentConfiguration} returns this
 */
proto.tournaments.TournamentConfiguration.prototype.clearScoringSettings = function() {
  return this.setScoringSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tournaments.TournamentConfiguration.prototype.hasScoringSettings = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.RoundScoringSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.RoundScoringSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.RoundScoringSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.RoundScoringSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startingPoints: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    elimination: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    eliminationPct: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.RoundScoringSettings}
 */
proto.tournaments.RoundScoringSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.RoundScoringSettings;
  return proto.tournaments.RoundScoringSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.RoundScoringSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.RoundScoringSettings}
 */
proto.tournaments.RoundScoringSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.tournaments.RoundScoringType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setStartingPoints(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setElimination(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setEliminationPct(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.RoundScoringSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.RoundScoringSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.RoundScoringSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.RoundScoringSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getStartingPoints();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getElimination();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getEliminationPct();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional RoundScoringType type = 1;
 * @return {!proto.tournaments.RoundScoringType}
 */
proto.tournaments.RoundScoringSettings.prototype.getType = function() {
  return /** @type {!proto.tournaments.RoundScoringType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.tournaments.RoundScoringType} value
 * @return {!proto.tournaments.RoundScoringSettings} returns this
 */
proto.tournaments.RoundScoringSettings.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional float starting_points = 2;
 * @return {number}
 */
proto.tournaments.RoundScoringSettings.prototype.getStartingPoints = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.RoundScoringSettings} returns this
 */
proto.tournaments.RoundScoringSettings.prototype.setStartingPoints = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional bool elimination = 3;
 * @return {boolean}
 */
proto.tournaments.RoundScoringSettings.prototype.getElimination = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.tournaments.RoundScoringSettings} returns this
 */
proto.tournaments.RoundScoringSettings.prototype.setElimination = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional float elimination_pct = 4;
 * @return {number}
 */
proto.tournaments.RoundScoringSettings.prototype.getEliminationPct = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.RoundScoringSettings} returns this
 */
proto.tournaments.RoundScoringSettings.prototype.setEliminationPct = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.RoundConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.RoundConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.RoundConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.RoundConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoringSettings: (f = msg.getScoringSettings()) && proto.tournaments.RoundScoringSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.RoundConfiguration}
 */
proto.tournaments.RoundConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.RoundConfiguration;
  return proto.tournaments.RoundConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.RoundConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.RoundConfiguration}
 */
proto.tournaments.RoundConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.tournaments.RoundScoringSettings;
      reader.readMessage(value,proto.tournaments.RoundScoringSettings.deserializeBinaryFromReader);
      msg.setScoringSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.RoundConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.RoundConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.RoundConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.RoundConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoringSettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.tournaments.RoundScoringSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional RoundScoringSettings scoring_settings = 1;
 * @return {?proto.tournaments.RoundScoringSettings}
 */
proto.tournaments.RoundConfiguration.prototype.getScoringSettings = function() {
  return /** @type{?proto.tournaments.RoundScoringSettings} */ (
    jspb.Message.getWrapperField(this, proto.tournaments.RoundScoringSettings, 1));
};


/**
 * @param {?proto.tournaments.RoundScoringSettings|undefined} value
 * @return {!proto.tournaments.RoundConfiguration} returns this
*/
proto.tournaments.RoundConfiguration.prototype.setScoringSettings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tournaments.RoundConfiguration} returns this
 */
proto.tournaments.RoundConfiguration.prototype.clearScoringSettings = function() {
  return this.setScoringSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tournaments.RoundConfiguration.prototype.hasScoringSettings = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.LeaderboardRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.LeaderboardRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.LeaderboardRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.LeaderboardRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.LeaderboardRecord}
 */
proto.tournaments.LeaderboardRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.LeaderboardRecord;
  return proto.tournaments.LeaderboardRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.LeaderboardRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.LeaderboardRecord}
 */
proto.tournaments.LeaderboardRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.tournaments.LeaderboardRecordType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.LeaderboardRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.LeaderboardRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.LeaderboardRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.LeaderboardRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.tournaments.LeaderboardRecord.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.LeaderboardRecord} returns this
 */
proto.tournaments.LeaderboardRecord.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional LeaderboardRecordType type = 2;
 * @return {!proto.tournaments.LeaderboardRecordType}
 */
proto.tournaments.LeaderboardRecord.prototype.getType = function() {
  return /** @type {!proto.tournaments.LeaderboardRecordType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.tournaments.LeaderboardRecordType} value
 * @return {!proto.tournaments.LeaderboardRecord} returns this
 */
proto.tournaments.LeaderboardRecord.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.RoundApp.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.RoundApp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.RoundApp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.RoundApp.toObject = function(includeInstance, msg) {
  var f, obj = {
    appId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    appName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    appVersion: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.RoundApp}
 */
proto.tournaments.RoundApp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.RoundApp;
  return proto.tournaments.RoundApp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.RoundApp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.RoundApp}
 */
proto.tournaments.RoundApp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.RoundApp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.RoundApp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.RoundApp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.RoundApp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAppName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAppVersion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string app_id = 1;
 * @return {string}
 */
proto.tournaments.RoundApp.prototype.getAppId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.RoundApp} returns this
 */
proto.tournaments.RoundApp.prototype.setAppId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string app_name = 2;
 * @return {string}
 */
proto.tournaments.RoundApp.prototype.getAppName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.RoundApp} returns this
 */
proto.tournaments.RoundApp.prototype.setAppName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string app_version = 3;
 * @return {string}
 */
proto.tournaments.RoundApp.prototype.getAppVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.RoundApp} returns this
 */
proto.tournaments.RoundApp.prototype.setAppVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.RoundParams.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.RoundParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.RoundParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.RoundParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    duration: jspb.Message.getFieldWithDefault(msg, 1, 0),
    buffer: jspb.Message.getFieldWithDefault(msg, 2, 0),
    gameType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    maxAttempts: jspb.Message.getFieldWithDefault(msg, 4, 0),
    configuration: (f = msg.getConfiguration()) && proto.tournaments.RoundConfiguration.toObject(includeInstance, f),
    appId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.RoundParams}
 */
proto.tournaments.RoundParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.RoundParams;
  return proto.tournaments.RoundParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.RoundParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.RoundParams}
 */
proto.tournaments.RoundParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDuration(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBuffer(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxAttempts(value);
      break;
    case 5:
      var value = new proto.tournaments.RoundConfiguration;
      reader.readMessage(value,proto.tournaments.RoundConfiguration.deserializeBinaryFromReader);
      msg.setConfiguration(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.RoundParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.RoundParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.RoundParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.RoundParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBuffer();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getGameType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMaxAttempts();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getConfiguration();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.tournaments.RoundConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getAppId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int64 duration = 1;
 * @return {number}
 */
proto.tournaments.RoundParams.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.RoundParams} returns this
 */
proto.tournaments.RoundParams.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 buffer = 2;
 * @return {number}
 */
proto.tournaments.RoundParams.prototype.getBuffer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.RoundParams} returns this
 */
proto.tournaments.RoundParams.prototype.setBuffer = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string game_type = 3;
 * @return {string}
 */
proto.tournaments.RoundParams.prototype.getGameType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.RoundParams} returns this
 */
proto.tournaments.RoundParams.prototype.setGameType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 max_attempts = 4;
 * @return {number}
 */
proto.tournaments.RoundParams.prototype.getMaxAttempts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.RoundParams} returns this
 */
proto.tournaments.RoundParams.prototype.setMaxAttempts = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional RoundConfiguration configuration = 5;
 * @return {?proto.tournaments.RoundConfiguration}
 */
proto.tournaments.RoundParams.prototype.getConfiguration = function() {
  return /** @type{?proto.tournaments.RoundConfiguration} */ (
    jspb.Message.getWrapperField(this, proto.tournaments.RoundConfiguration, 5));
};


/**
 * @param {?proto.tournaments.RoundConfiguration|undefined} value
 * @return {!proto.tournaments.RoundParams} returns this
*/
proto.tournaments.RoundParams.prototype.setConfiguration = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tournaments.RoundParams} returns this
 */
proto.tournaments.RoundParams.prototype.clearConfiguration = function() {
  return this.setConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tournaments.RoundParams.prototype.hasConfiguration = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string app_id = 6;
 * @return {string}
 */
proto.tournaments.RoundParams.prototype.getAppId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.RoundParams} returns this
 */
proto.tournaments.RoundParams.prototype.setAppId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.Round.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.Round.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.Round} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.Round.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    buffer: jspb.Message.getFieldWithDefault(msg, 4, 0),
    gameType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    maxAttempts: jspb.Message.getFieldWithDefault(msg, 6, 0),
    configuration: (f = msg.getConfiguration()) && proto.tournaments.RoundConfiguration.toObject(includeInstance, f),
    leaderboardId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    appId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    appVersion: jspb.Message.getFieldWithDefault(msg, 10, ""),
    status: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.Round}
 */
proto.tournaments.Round.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.Round;
  return proto.tournaments.Round.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.Round} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.Round}
 */
proto.tournaments.Round.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndAt(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBuffer(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameType(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxAttempts(value);
      break;
    case 7:
      var value = new proto.tournaments.RoundConfiguration;
      reader.readMessage(value,proto.tournaments.RoundConfiguration.deserializeBinaryFromReader);
      msg.setConfiguration(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeaderboardId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppVersion(value);
      break;
    case 11:
      var value = /** @type {!proto.tournaments.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.Round.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.Round.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.Round} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.Round.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEndAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getBuffer();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getGameType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMaxAttempts();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getConfiguration();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.tournaments.RoundConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getLeaderboardId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAppId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAppVersion();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.tournaments.Round.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.Round} returns this
 */
proto.tournaments.Round.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 start_at = 2;
 * @return {number}
 */
proto.tournaments.Round.prototype.getStartAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.Round} returns this
 */
proto.tournaments.Round.prototype.setStartAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 end_at = 3;
 * @return {number}
 */
proto.tournaments.Round.prototype.getEndAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.Round} returns this
 */
proto.tournaments.Round.prototype.setEndAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 buffer = 4;
 * @return {number}
 */
proto.tournaments.Round.prototype.getBuffer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.Round} returns this
 */
proto.tournaments.Round.prototype.setBuffer = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string game_type = 5;
 * @return {string}
 */
proto.tournaments.Round.prototype.getGameType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.Round} returns this
 */
proto.tournaments.Round.prototype.setGameType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 max_attempts = 6;
 * @return {number}
 */
proto.tournaments.Round.prototype.getMaxAttempts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.Round} returns this
 */
proto.tournaments.Round.prototype.setMaxAttempts = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional RoundConfiguration configuration = 7;
 * @return {?proto.tournaments.RoundConfiguration}
 */
proto.tournaments.Round.prototype.getConfiguration = function() {
  return /** @type{?proto.tournaments.RoundConfiguration} */ (
    jspb.Message.getWrapperField(this, proto.tournaments.RoundConfiguration, 7));
};


/**
 * @param {?proto.tournaments.RoundConfiguration|undefined} value
 * @return {!proto.tournaments.Round} returns this
*/
proto.tournaments.Round.prototype.setConfiguration = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tournaments.Round} returns this
 */
proto.tournaments.Round.prototype.clearConfiguration = function() {
  return this.setConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tournaments.Round.prototype.hasConfiguration = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string leaderboard_id = 8;
 * @return {string}
 */
proto.tournaments.Round.prototype.getLeaderboardId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.Round} returns this
 */
proto.tournaments.Round.prototype.setLeaderboardId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string app_id = 9;
 * @return {string}
 */
proto.tournaments.Round.prototype.getAppId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.Round} returns this
 */
proto.tournaments.Round.prototype.setAppId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string app_version = 10;
 * @return {string}
 */
proto.tournaments.Round.prototype.getAppVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.Round} returns this
 */
proto.tournaments.Round.prototype.setAppVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional Status status = 11;
 * @return {!proto.tournaments.Status}
 */
proto.tournaments.Round.prototype.getStatus = function() {
  return /** @type {!proto.tournaments.Status} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.tournaments.Status} value
 * @return {!proto.tournaments.Round} returns this
 */
proto.tournaments.Round.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.ScoreRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.ScoreRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.ScoreRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.ScoreRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    roundId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    matchId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    score: jspb.Message.getFieldWithDefault(msg, 5, 0),
    rank: jspb.Message.getFieldWithDefault(msg, 6, 0),
    metadataMap: (f = msg.getMetadataMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.ScoreRecord}
 */
proto.tournaments.ScoreRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.ScoreRecord;
  return proto.tournaments.ScoreRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.ScoreRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.ScoreRecord}
 */
proto.tournaments.ScoreRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoundId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatchId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScore(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRank(value);
      break;
    case 7:
      var value = msg.getMetadataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.ScoreRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.ScoreRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.ScoreRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.ScoreRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRoundId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMatchId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getScore();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getRank();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getMetadataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(7, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.tournaments.ScoreRecord.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.ScoreRecord} returns this
 */
proto.tournaments.ScoreRecord.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string owner_id = 2;
 * @return {string}
 */
proto.tournaments.ScoreRecord.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.ScoreRecord} returns this
 */
proto.tournaments.ScoreRecord.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string round_id = 3;
 * @return {string}
 */
proto.tournaments.ScoreRecord.prototype.getRoundId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.ScoreRecord} returns this
 */
proto.tournaments.ScoreRecord.prototype.setRoundId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string match_id = 4;
 * @return {string}
 */
proto.tournaments.ScoreRecord.prototype.getMatchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.ScoreRecord} returns this
 */
proto.tournaments.ScoreRecord.prototype.setMatchId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 score = 5;
 * @return {number}
 */
proto.tournaments.ScoreRecord.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.ScoreRecord} returns this
 */
proto.tournaments.ScoreRecord.prototype.setScore = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 rank = 6;
 * @return {number}
 */
proto.tournaments.ScoreRecord.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.ScoreRecord} returns this
 */
proto.tournaments.ScoreRecord.prototype.setRank = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * map<string, string> metadata = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.tournaments.ScoreRecord.prototype.getMetadataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 7, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.tournaments.ScoreRecord} returns this
 */
proto.tournaments.ScoreRecord.prototype.clearMetadataMap = function() {
  this.getMetadataMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tournaments.RoundRecord.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.RoundRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.RoundRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.RoundRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.RoundRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    roundId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    maxAttempts: jspb.Message.getFieldWithDefault(msg, 4, 0),
    ongoingAttempts: jspb.Message.getFieldWithDefault(msg, 5, 0),
    attempts: jspb.Message.getFieldWithDefault(msg, 6, 0),
    scoresList: jspb.Message.toObjectList(msg.getScoresList(),
    proto.tournaments.ScoreRecord.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.RoundRecord}
 */
proto.tournaments.RoundRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.RoundRecord;
  return proto.tournaments.RoundRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.RoundRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.RoundRecord}
 */
proto.tournaments.RoundRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoundId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxAttempts(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOngoingAttempts(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAttempts(value);
      break;
    case 7:
      var value = new proto.tournaments.ScoreRecord;
      reader.readMessage(value,proto.tournaments.ScoreRecord.deserializeBinaryFromReader);
      msg.addScores(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.RoundRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.RoundRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.RoundRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.RoundRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRoundId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMaxAttempts();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getOngoingAttempts();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getAttempts();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getScoresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.tournaments.ScoreRecord.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.tournaments.RoundRecord.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.RoundRecord} returns this
 */
proto.tournaments.RoundRecord.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string owner_id = 2;
 * @return {string}
 */
proto.tournaments.RoundRecord.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.RoundRecord} returns this
 */
proto.tournaments.RoundRecord.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string round_id = 3;
 * @return {string}
 */
proto.tournaments.RoundRecord.prototype.getRoundId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.RoundRecord} returns this
 */
proto.tournaments.RoundRecord.prototype.setRoundId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 max_attempts = 4;
 * @return {number}
 */
proto.tournaments.RoundRecord.prototype.getMaxAttempts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.RoundRecord} returns this
 */
proto.tournaments.RoundRecord.prototype.setMaxAttempts = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 ongoing_attempts = 5;
 * @return {number}
 */
proto.tournaments.RoundRecord.prototype.getOngoingAttempts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.RoundRecord} returns this
 */
proto.tournaments.RoundRecord.prototype.setOngoingAttempts = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 attempts = 6;
 * @return {number}
 */
proto.tournaments.RoundRecord.prototype.getAttempts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.RoundRecord} returns this
 */
proto.tournaments.RoundRecord.prototype.setAttempts = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated ScoreRecord scores = 7;
 * @return {!Array<!proto.tournaments.ScoreRecord>}
 */
proto.tournaments.RoundRecord.prototype.getScoresList = function() {
  return /** @type{!Array<!proto.tournaments.ScoreRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tournaments.ScoreRecord, 7));
};


/**
 * @param {!Array<!proto.tournaments.ScoreRecord>} value
 * @return {!proto.tournaments.RoundRecord} returns this
*/
proto.tournaments.RoundRecord.prototype.setScoresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.tournaments.ScoreRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tournaments.ScoreRecord}
 */
proto.tournaments.RoundRecord.prototype.addScores = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.tournaments.ScoreRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tournaments.RoundRecord} returns this
 */
proto.tournaments.RoundRecord.prototype.clearScoresList = function() {
  return this.setScoresList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tournaments.GatingMethod.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.GatingMethod.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.GatingMethod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.GatingMethod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.GatingMethod.toObject = function(includeInstance, msg) {
  var f, obj = {
    gatingType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    metadataList: jspb.Message.toObjectList(msg.getMetadataList(),
    proto.tournaments.GatingMethod.KeyValuePair.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.GatingMethod}
 */
proto.tournaments.GatingMethod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.GatingMethod;
  return proto.tournaments.GatingMethod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.GatingMethod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.GatingMethod}
 */
proto.tournaments.GatingMethod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.tournaments.GatingType} */ (reader.readEnum());
      msg.setGatingType(value);
      break;
    case 2:
      var value = new proto.tournaments.GatingMethod.KeyValuePair;
      reader.readMessage(value,proto.tournaments.GatingMethod.KeyValuePair.deserializeBinaryFromReader);
      msg.addMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.GatingMethod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.GatingMethod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.GatingMethod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.GatingMethod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGatingType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMetadataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.tournaments.GatingMethod.KeyValuePair.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.GatingMethod.KeyValuePair.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.GatingMethod.KeyValuePair.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.GatingMethod.KeyValuePair} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.GatingMethod.KeyValuePair.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.GatingMethod.KeyValuePair}
 */
proto.tournaments.GatingMethod.KeyValuePair.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.GatingMethod.KeyValuePair;
  return proto.tournaments.GatingMethod.KeyValuePair.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.GatingMethod.KeyValuePair} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.GatingMethod.KeyValuePair}
 */
proto.tournaments.GatingMethod.KeyValuePair.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.GatingMethod.KeyValuePair.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.GatingMethod.KeyValuePair.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.GatingMethod.KeyValuePair} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.GatingMethod.KeyValuePair.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.tournaments.GatingMethod.KeyValuePair.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.GatingMethod.KeyValuePair} returns this
 */
proto.tournaments.GatingMethod.KeyValuePair.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.tournaments.GatingMethod.KeyValuePair.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.GatingMethod.KeyValuePair} returns this
 */
proto.tournaments.GatingMethod.KeyValuePair.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional GatingType gating_type = 1;
 * @return {!proto.tournaments.GatingType}
 */
proto.tournaments.GatingMethod.prototype.getGatingType = function() {
  return /** @type {!proto.tournaments.GatingType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.tournaments.GatingType} value
 * @return {!proto.tournaments.GatingMethod} returns this
 */
proto.tournaments.GatingMethod.prototype.setGatingType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated KeyValuePair metadata = 2;
 * @return {!Array<!proto.tournaments.GatingMethod.KeyValuePair>}
 */
proto.tournaments.GatingMethod.prototype.getMetadataList = function() {
  return /** @type{!Array<!proto.tournaments.GatingMethod.KeyValuePair>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tournaments.GatingMethod.KeyValuePair, 2));
};


/**
 * @param {!Array<!proto.tournaments.GatingMethod.KeyValuePair>} value
 * @return {!proto.tournaments.GatingMethod} returns this
*/
proto.tournaments.GatingMethod.prototype.setMetadataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.tournaments.GatingMethod.KeyValuePair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tournaments.GatingMethod.KeyValuePair}
 */
proto.tournaments.GatingMethod.prototype.addMetadata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.tournaments.GatingMethod.KeyValuePair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tournaments.GatingMethod} returns this
 */
proto.tournaments.GatingMethod.prototype.clearMetadataList = function() {
  return this.setMetadataList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tournaments.GatingMethodArray.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.GatingMethodArray.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.GatingMethodArray.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.GatingMethodArray} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.GatingMethodArray.toObject = function(includeInstance, msg) {
  var f, obj = {
    arrayList: jspb.Message.toObjectList(msg.getArrayList(),
    proto.tournaments.GatingMethod.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.GatingMethodArray}
 */
proto.tournaments.GatingMethodArray.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.GatingMethodArray;
  return proto.tournaments.GatingMethodArray.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.GatingMethodArray} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.GatingMethodArray}
 */
proto.tournaments.GatingMethodArray.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.tournaments.GatingMethod;
      reader.readMessage(value,proto.tournaments.GatingMethod.deserializeBinaryFromReader);
      msg.addArray(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.GatingMethodArray.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.GatingMethodArray.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.GatingMethodArray} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.GatingMethodArray.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArrayList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.tournaments.GatingMethod.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GatingMethod array = 1;
 * @return {!Array<!proto.tournaments.GatingMethod>}
 */
proto.tournaments.GatingMethodArray.prototype.getArrayList = function() {
  return /** @type{!Array<!proto.tournaments.GatingMethod>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tournaments.GatingMethod, 1));
};


/**
 * @param {!Array<!proto.tournaments.GatingMethod>} value
 * @return {!proto.tournaments.GatingMethodArray} returns this
*/
proto.tournaments.GatingMethodArray.prototype.setArrayList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.tournaments.GatingMethod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tournaments.GatingMethod}
 */
proto.tournaments.GatingMethodArray.prototype.addArray = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.tournaments.GatingMethod, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tournaments.GatingMethodArray} returns this
 */
proto.tournaments.GatingMethodArray.prototype.clearArrayList = function() {
  return this.setArrayList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tournaments.Gating.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.Gating.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.Gating.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.Gating} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.Gating.toObject = function(includeInstance, msg) {
  var f, obj = {
    gatingMethodsList: jspb.Message.toObjectList(msg.getGatingMethodsList(),
    proto.tournaments.GatingMethodArray.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.Gating}
 */
proto.tournaments.Gating.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.Gating;
  return proto.tournaments.Gating.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.Gating} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.Gating}
 */
proto.tournaments.Gating.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.tournaments.GatingMethodArray;
      reader.readMessage(value,proto.tournaments.GatingMethodArray.deserializeBinaryFromReader);
      msg.addGatingMethods(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.Gating.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.Gating.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.Gating} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.Gating.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGatingMethodsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.tournaments.GatingMethodArray.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GatingMethodArray gating_methods = 1;
 * @return {!Array<!proto.tournaments.GatingMethodArray>}
 */
proto.tournaments.Gating.prototype.getGatingMethodsList = function() {
  return /** @type{!Array<!proto.tournaments.GatingMethodArray>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tournaments.GatingMethodArray, 1));
};


/**
 * @param {!Array<!proto.tournaments.GatingMethodArray>} value
 * @return {!proto.tournaments.Gating} returns this
*/
proto.tournaments.Gating.prototype.setGatingMethodsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.tournaments.GatingMethodArray=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tournaments.GatingMethodArray}
 */
proto.tournaments.Gating.prototype.addGatingMethods = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.tournaments.GatingMethodArray, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tournaments.Gating} returns this
 */
proto.tournaments.Gating.prototype.clearGatingMethodsList = function() {
  return this.setGatingMethodsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tournaments.CreateTournamentRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.CreateTournamentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.CreateTournamentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.CreateTournamentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.CreateTournamentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    startAt: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mode: jspb.Message.getFieldWithDefault(msg, 4, 0),
    gating: (f = msg.getGating()) && proto.tournaments.Gating.toObject(includeInstance, f),
    roundsList: jspb.Message.toObjectList(msg.getRoundsList(),
    proto.tournaments.RoundParams.toObject, includeInstance),
    configuration: (f = msg.getConfiguration()) && proto.tournaments.TournamentConfiguration.toObject(includeInstance, f),
    source: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.CreateTournamentRequest}
 */
proto.tournaments.CreateTournamentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.CreateTournamentRequest;
  return proto.tournaments.CreateTournamentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.CreateTournamentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.CreateTournamentRequest}
 */
proto.tournaments.CreateTournamentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartAt(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {!proto.tournaments.TournamentMode} */ (reader.readEnum());
      msg.setMode(value);
      break;
    case 5:
      var value = new proto.tournaments.Gating;
      reader.readMessage(value,proto.tournaments.Gating.deserializeBinaryFromReader);
      msg.setGating(value);
      break;
    case 6:
      var value = new proto.tournaments.RoundParams;
      reader.readMessage(value,proto.tournaments.RoundParams.deserializeBinaryFromReader);
      msg.addRounds(value);
      break;
    case 7:
      var value = new proto.tournaments.TournamentConfiguration;
      reader.readMessage(value,proto.tournaments.TournamentConfiguration.deserializeBinaryFromReader);
      msg.setConfiguration(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.CreateTournamentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.CreateTournamentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.CreateTournamentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.CreateTournamentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartAt();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMode();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getGating();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.tournaments.Gating.serializeBinaryToWriter
    );
  }
  f = message.getRoundsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.tournaments.RoundParams.serializeBinaryToWriter
    );
  }
  f = message.getConfiguration();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.tournaments.TournamentConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int64 start_at = 1;
 * @return {number}
 */
proto.tournaments.CreateTournamentRequest.prototype.getStartAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.CreateTournamentRequest} returns this
 */
proto.tournaments.CreateTournamentRequest.prototype.setStartAt = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.tournaments.CreateTournamentRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.CreateTournamentRequest} returns this
 */
proto.tournaments.CreateTournamentRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.tournaments.CreateTournamentRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.CreateTournamentRequest} returns this
 */
proto.tournaments.CreateTournamentRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional TournamentMode mode = 4;
 * @return {!proto.tournaments.TournamentMode}
 */
proto.tournaments.CreateTournamentRequest.prototype.getMode = function() {
  return /** @type {!proto.tournaments.TournamentMode} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.tournaments.TournamentMode} value
 * @return {!proto.tournaments.CreateTournamentRequest} returns this
 */
proto.tournaments.CreateTournamentRequest.prototype.setMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional Gating gating = 5;
 * @return {?proto.tournaments.Gating}
 */
proto.tournaments.CreateTournamentRequest.prototype.getGating = function() {
  return /** @type{?proto.tournaments.Gating} */ (
    jspb.Message.getWrapperField(this, proto.tournaments.Gating, 5));
};


/**
 * @param {?proto.tournaments.Gating|undefined} value
 * @return {!proto.tournaments.CreateTournamentRequest} returns this
*/
proto.tournaments.CreateTournamentRequest.prototype.setGating = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tournaments.CreateTournamentRequest} returns this
 */
proto.tournaments.CreateTournamentRequest.prototype.clearGating = function() {
  return this.setGating(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tournaments.CreateTournamentRequest.prototype.hasGating = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated RoundParams rounds = 6;
 * @return {!Array<!proto.tournaments.RoundParams>}
 */
proto.tournaments.CreateTournamentRequest.prototype.getRoundsList = function() {
  return /** @type{!Array<!proto.tournaments.RoundParams>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tournaments.RoundParams, 6));
};


/**
 * @param {!Array<!proto.tournaments.RoundParams>} value
 * @return {!proto.tournaments.CreateTournamentRequest} returns this
*/
proto.tournaments.CreateTournamentRequest.prototype.setRoundsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.tournaments.RoundParams=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tournaments.RoundParams}
 */
proto.tournaments.CreateTournamentRequest.prototype.addRounds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.tournaments.RoundParams, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tournaments.CreateTournamentRequest} returns this
 */
proto.tournaments.CreateTournamentRequest.prototype.clearRoundsList = function() {
  return this.setRoundsList([]);
};


/**
 * optional TournamentConfiguration configuration = 7;
 * @return {?proto.tournaments.TournamentConfiguration}
 */
proto.tournaments.CreateTournamentRequest.prototype.getConfiguration = function() {
  return /** @type{?proto.tournaments.TournamentConfiguration} */ (
    jspb.Message.getWrapperField(this, proto.tournaments.TournamentConfiguration, 7));
};


/**
 * @param {?proto.tournaments.TournamentConfiguration|undefined} value
 * @return {!proto.tournaments.CreateTournamentRequest} returns this
*/
proto.tournaments.CreateTournamentRequest.prototype.setConfiguration = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tournaments.CreateTournamentRequest} returns this
 */
proto.tournaments.CreateTournamentRequest.prototype.clearConfiguration = function() {
  return this.setConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tournaments.CreateTournamentRequest.prototype.hasConfiguration = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string source = 8;
 * @return {string}
 */
proto.tournaments.CreateTournamentRequest.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.CreateTournamentRequest} returns this
 */
proto.tournaments.CreateTournamentRequest.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tournaments.UpdateTournamentRequest.repeatedFields_ = [6,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.UpdateTournamentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.UpdateTournamentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.UpdateTournamentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.UpdateTournamentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    leaderboardsList: jspb.Message.toObjectList(msg.getLeaderboardsList(),
    proto.tournaments.LeaderboardRecord.toObject, includeInstance),
    mode: jspb.Message.getFieldWithDefault(msg, 7, 0),
    gating: (f = msg.getGating()) && proto.tournaments.Gating.toObject(includeInstance, f),
    roundsList: jspb.Message.toObjectList(msg.getRoundsList(),
    proto.tournaments.Round.toObject, includeInstance),
    configuration: (f = msg.getConfiguration()) && proto.tournaments.TournamentConfiguration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.UpdateTournamentRequest}
 */
proto.tournaments.UpdateTournamentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.UpdateTournamentRequest;
  return proto.tournaments.UpdateTournamentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.UpdateTournamentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.UpdateTournamentRequest}
 */
proto.tournaments.UpdateTournamentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = new proto.tournaments.LeaderboardRecord;
      reader.readMessage(value,proto.tournaments.LeaderboardRecord.deserializeBinaryFromReader);
      msg.addLeaderboards(value);
      break;
    case 7:
      var value = /** @type {!proto.tournaments.TournamentMode} */ (reader.readEnum());
      msg.setMode(value);
      break;
    case 8:
      var value = new proto.tournaments.Gating;
      reader.readMessage(value,proto.tournaments.Gating.deserializeBinaryFromReader);
      msg.setGating(value);
      break;
    case 9:
      var value = new proto.tournaments.Round;
      reader.readMessage(value,proto.tournaments.Round.deserializeBinaryFromReader);
      msg.addRounds(value);
      break;
    case 10:
      var value = new proto.tournaments.TournamentConfiguration;
      reader.readMessage(value,proto.tournaments.TournamentConfiguration.deserializeBinaryFromReader);
      msg.setConfiguration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.UpdateTournamentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.UpdateTournamentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.UpdateTournamentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.UpdateTournamentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEndAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLeaderboardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.tournaments.LeaderboardRecord.serializeBinaryToWriter
    );
  }
  f = message.getMode();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getGating();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.tournaments.Gating.serializeBinaryToWriter
    );
  }
  f = message.getRoundsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.tournaments.Round.serializeBinaryToWriter
    );
  }
  f = message.getConfiguration();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.tournaments.TournamentConfiguration.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.tournaments.UpdateTournamentRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.UpdateTournamentRequest} returns this
 */
proto.tournaments.UpdateTournamentRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 start_at = 2;
 * @return {number}
 */
proto.tournaments.UpdateTournamentRequest.prototype.getStartAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.UpdateTournamentRequest} returns this
 */
proto.tournaments.UpdateTournamentRequest.prototype.setStartAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 end_at = 3;
 * @return {number}
 */
proto.tournaments.UpdateTournamentRequest.prototype.getEndAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.UpdateTournamentRequest} returns this
 */
proto.tournaments.UpdateTournamentRequest.prototype.setEndAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.tournaments.UpdateTournamentRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.UpdateTournamentRequest} returns this
 */
proto.tournaments.UpdateTournamentRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.tournaments.UpdateTournamentRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.UpdateTournamentRequest} returns this
 */
proto.tournaments.UpdateTournamentRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated LeaderboardRecord leaderboards = 6;
 * @return {!Array<!proto.tournaments.LeaderboardRecord>}
 */
proto.tournaments.UpdateTournamentRequest.prototype.getLeaderboardsList = function() {
  return /** @type{!Array<!proto.tournaments.LeaderboardRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tournaments.LeaderboardRecord, 6));
};


/**
 * @param {!Array<!proto.tournaments.LeaderboardRecord>} value
 * @return {!proto.tournaments.UpdateTournamentRequest} returns this
*/
proto.tournaments.UpdateTournamentRequest.prototype.setLeaderboardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.tournaments.LeaderboardRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tournaments.LeaderboardRecord}
 */
proto.tournaments.UpdateTournamentRequest.prototype.addLeaderboards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.tournaments.LeaderboardRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tournaments.UpdateTournamentRequest} returns this
 */
proto.tournaments.UpdateTournamentRequest.prototype.clearLeaderboardsList = function() {
  return this.setLeaderboardsList([]);
};


/**
 * optional TournamentMode mode = 7;
 * @return {!proto.tournaments.TournamentMode}
 */
proto.tournaments.UpdateTournamentRequest.prototype.getMode = function() {
  return /** @type {!proto.tournaments.TournamentMode} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.tournaments.TournamentMode} value
 * @return {!proto.tournaments.UpdateTournamentRequest} returns this
 */
proto.tournaments.UpdateTournamentRequest.prototype.setMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional Gating gating = 8;
 * @return {?proto.tournaments.Gating}
 */
proto.tournaments.UpdateTournamentRequest.prototype.getGating = function() {
  return /** @type{?proto.tournaments.Gating} */ (
    jspb.Message.getWrapperField(this, proto.tournaments.Gating, 8));
};


/**
 * @param {?proto.tournaments.Gating|undefined} value
 * @return {!proto.tournaments.UpdateTournamentRequest} returns this
*/
proto.tournaments.UpdateTournamentRequest.prototype.setGating = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tournaments.UpdateTournamentRequest} returns this
 */
proto.tournaments.UpdateTournamentRequest.prototype.clearGating = function() {
  return this.setGating(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tournaments.UpdateTournamentRequest.prototype.hasGating = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated Round rounds = 9;
 * @return {!Array<!proto.tournaments.Round>}
 */
proto.tournaments.UpdateTournamentRequest.prototype.getRoundsList = function() {
  return /** @type{!Array<!proto.tournaments.Round>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tournaments.Round, 9));
};


/**
 * @param {!Array<!proto.tournaments.Round>} value
 * @return {!proto.tournaments.UpdateTournamentRequest} returns this
*/
proto.tournaments.UpdateTournamentRequest.prototype.setRoundsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.tournaments.Round=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tournaments.Round}
 */
proto.tournaments.UpdateTournamentRequest.prototype.addRounds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.tournaments.Round, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tournaments.UpdateTournamentRequest} returns this
 */
proto.tournaments.UpdateTournamentRequest.prototype.clearRoundsList = function() {
  return this.setRoundsList([]);
};


/**
 * optional TournamentConfiguration configuration = 10;
 * @return {?proto.tournaments.TournamentConfiguration}
 */
proto.tournaments.UpdateTournamentRequest.prototype.getConfiguration = function() {
  return /** @type{?proto.tournaments.TournamentConfiguration} */ (
    jspb.Message.getWrapperField(this, proto.tournaments.TournamentConfiguration, 10));
};


/**
 * @param {?proto.tournaments.TournamentConfiguration|undefined} value
 * @return {!proto.tournaments.UpdateTournamentRequest} returns this
*/
proto.tournaments.UpdateTournamentRequest.prototype.setConfiguration = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tournaments.UpdateTournamentRequest} returns this
 */
proto.tournaments.UpdateTournamentRequest.prototype.clearConfiguration = function() {
  return this.setConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tournaments.UpdateTournamentRequest.prototype.hasConfiguration = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.UpdateTournamentStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.UpdateTournamentStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.UpdateTournamentStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.UpdateTournamentStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.UpdateTournamentStatusRequest}
 */
proto.tournaments.UpdateTournamentStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.UpdateTournamentStatusRequest;
  return proto.tournaments.UpdateTournamentStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.UpdateTournamentStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.UpdateTournamentStatusRequest}
 */
proto.tournaments.UpdateTournamentStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.tournaments.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.UpdateTournamentStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.UpdateTournamentStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.UpdateTournamentStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.UpdateTournamentStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.tournaments.UpdateTournamentStatusRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.UpdateTournamentStatusRequest} returns this
 */
proto.tournaments.UpdateTournamentStatusRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Status status = 2;
 * @return {!proto.tournaments.Status}
 */
proto.tournaments.UpdateTournamentStatusRequest.prototype.getStatus = function() {
  return /** @type {!proto.tournaments.Status} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.tournaments.Status} value
 * @return {!proto.tournaments.UpdateTournamentStatusRequest} returns this
 */
proto.tournaments.UpdateTournamentStatusRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.DeleteTournamentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.DeleteTournamentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.DeleteTournamentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.DeleteTournamentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.DeleteTournamentRequest}
 */
proto.tournaments.DeleteTournamentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.DeleteTournamentRequest;
  return proto.tournaments.DeleteTournamentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.DeleteTournamentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.DeleteTournamentRequest}
 */
proto.tournaments.DeleteTournamentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.DeleteTournamentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.DeleteTournamentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.DeleteTournamentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.DeleteTournamentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.tournaments.DeleteTournamentRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.DeleteTournamentRequest} returns this
 */
proto.tournaments.DeleteTournamentRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.DeleteTournamentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.DeleteTournamentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.DeleteTournamentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.DeleteTournamentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.DeleteTournamentResponse}
 */
proto.tournaments.DeleteTournamentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.DeleteTournamentResponse;
  return proto.tournaments.DeleteTournamentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.DeleteTournamentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.DeleteTournamentResponse}
 */
proto.tournaments.DeleteTournamentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.DeleteTournamentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.DeleteTournamentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.DeleteTournamentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.DeleteTournamentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.ResetTournamentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.ResetTournamentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.ResetTournamentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.ResetTournamentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.ResetTournamentRequest}
 */
proto.tournaments.ResetTournamentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.ResetTournamentRequest;
  return proto.tournaments.ResetTournamentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.ResetTournamentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.ResetTournamentRequest}
 */
proto.tournaments.ResetTournamentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.ResetTournamentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.ResetTournamentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.ResetTournamentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.ResetTournamentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.tournaments.ResetTournamentRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.ResetTournamentRequest} returns this
 */
proto.tournaments.ResetTournamentRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tournaments.ResetTournamentResponse.repeatedFields_ = [6,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.ResetTournamentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.ResetTournamentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.ResetTournamentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.ResetTournamentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    leaderboardsList: jspb.Message.toObjectList(msg.getLeaderboardsList(),
    proto.tournaments.LeaderboardRecord.toObject, includeInstance),
    mode: jspb.Message.getFieldWithDefault(msg, 7, 0),
    gating: (f = msg.getGating()) && proto.tournaments.Gating.toObject(includeInstance, f),
    roundsList: jspb.Message.toObjectList(msg.getRoundsList(),
    proto.tournaments.Round.toObject, includeInstance),
    configuration: (f = msg.getConfiguration()) && proto.tournaments.TournamentConfiguration.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.ResetTournamentResponse}
 */
proto.tournaments.ResetTournamentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.ResetTournamentResponse;
  return proto.tournaments.ResetTournamentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.ResetTournamentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.ResetTournamentResponse}
 */
proto.tournaments.ResetTournamentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = new proto.tournaments.LeaderboardRecord;
      reader.readMessage(value,proto.tournaments.LeaderboardRecord.deserializeBinaryFromReader);
      msg.addLeaderboards(value);
      break;
    case 7:
      var value = /** @type {!proto.tournaments.TournamentMode} */ (reader.readEnum());
      msg.setMode(value);
      break;
    case 8:
      var value = new proto.tournaments.Gating;
      reader.readMessage(value,proto.tournaments.Gating.deserializeBinaryFromReader);
      msg.setGating(value);
      break;
    case 9:
      var value = new proto.tournaments.Round;
      reader.readMessage(value,proto.tournaments.Round.deserializeBinaryFromReader);
      msg.addRounds(value);
      break;
    case 10:
      var value = new proto.tournaments.TournamentConfiguration;
      reader.readMessage(value,proto.tournaments.TournamentConfiguration.deserializeBinaryFromReader);
      msg.setConfiguration(value);
      break;
    case 11:
      var value = /** @type {!proto.tournaments.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.ResetTournamentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.ResetTournamentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.ResetTournamentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.ResetTournamentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEndAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLeaderboardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.tournaments.LeaderboardRecord.serializeBinaryToWriter
    );
  }
  f = message.getMode();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getGating();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.tournaments.Gating.serializeBinaryToWriter
    );
  }
  f = message.getRoundsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.tournaments.Round.serializeBinaryToWriter
    );
  }
  f = message.getConfiguration();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.tournaments.TournamentConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.tournaments.ResetTournamentResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.ResetTournamentResponse} returns this
 */
proto.tournaments.ResetTournamentResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 start_at = 2;
 * @return {number}
 */
proto.tournaments.ResetTournamentResponse.prototype.getStartAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.ResetTournamentResponse} returns this
 */
proto.tournaments.ResetTournamentResponse.prototype.setStartAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 end_at = 3;
 * @return {number}
 */
proto.tournaments.ResetTournamentResponse.prototype.getEndAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.ResetTournamentResponse} returns this
 */
proto.tournaments.ResetTournamentResponse.prototype.setEndAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.tournaments.ResetTournamentResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.ResetTournamentResponse} returns this
 */
proto.tournaments.ResetTournamentResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.tournaments.ResetTournamentResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.ResetTournamentResponse} returns this
 */
proto.tournaments.ResetTournamentResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated LeaderboardRecord leaderboards = 6;
 * @return {!Array<!proto.tournaments.LeaderboardRecord>}
 */
proto.tournaments.ResetTournamentResponse.prototype.getLeaderboardsList = function() {
  return /** @type{!Array<!proto.tournaments.LeaderboardRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tournaments.LeaderboardRecord, 6));
};


/**
 * @param {!Array<!proto.tournaments.LeaderboardRecord>} value
 * @return {!proto.tournaments.ResetTournamentResponse} returns this
*/
proto.tournaments.ResetTournamentResponse.prototype.setLeaderboardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.tournaments.LeaderboardRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tournaments.LeaderboardRecord}
 */
proto.tournaments.ResetTournamentResponse.prototype.addLeaderboards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.tournaments.LeaderboardRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tournaments.ResetTournamentResponse} returns this
 */
proto.tournaments.ResetTournamentResponse.prototype.clearLeaderboardsList = function() {
  return this.setLeaderboardsList([]);
};


/**
 * optional TournamentMode mode = 7;
 * @return {!proto.tournaments.TournamentMode}
 */
proto.tournaments.ResetTournamentResponse.prototype.getMode = function() {
  return /** @type {!proto.tournaments.TournamentMode} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.tournaments.TournamentMode} value
 * @return {!proto.tournaments.ResetTournamentResponse} returns this
 */
proto.tournaments.ResetTournamentResponse.prototype.setMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional Gating gating = 8;
 * @return {?proto.tournaments.Gating}
 */
proto.tournaments.ResetTournamentResponse.prototype.getGating = function() {
  return /** @type{?proto.tournaments.Gating} */ (
    jspb.Message.getWrapperField(this, proto.tournaments.Gating, 8));
};


/**
 * @param {?proto.tournaments.Gating|undefined} value
 * @return {!proto.tournaments.ResetTournamentResponse} returns this
*/
proto.tournaments.ResetTournamentResponse.prototype.setGating = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tournaments.ResetTournamentResponse} returns this
 */
proto.tournaments.ResetTournamentResponse.prototype.clearGating = function() {
  return this.setGating(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tournaments.ResetTournamentResponse.prototype.hasGating = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated Round rounds = 9;
 * @return {!Array<!proto.tournaments.Round>}
 */
proto.tournaments.ResetTournamentResponse.prototype.getRoundsList = function() {
  return /** @type{!Array<!proto.tournaments.Round>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tournaments.Round, 9));
};


/**
 * @param {!Array<!proto.tournaments.Round>} value
 * @return {!proto.tournaments.ResetTournamentResponse} returns this
*/
proto.tournaments.ResetTournamentResponse.prototype.setRoundsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.tournaments.Round=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tournaments.Round}
 */
proto.tournaments.ResetTournamentResponse.prototype.addRounds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.tournaments.Round, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tournaments.ResetTournamentResponse} returns this
 */
proto.tournaments.ResetTournamentResponse.prototype.clearRoundsList = function() {
  return this.setRoundsList([]);
};


/**
 * optional TournamentConfiguration configuration = 10;
 * @return {?proto.tournaments.TournamentConfiguration}
 */
proto.tournaments.ResetTournamentResponse.prototype.getConfiguration = function() {
  return /** @type{?proto.tournaments.TournamentConfiguration} */ (
    jspb.Message.getWrapperField(this, proto.tournaments.TournamentConfiguration, 10));
};


/**
 * @param {?proto.tournaments.TournamentConfiguration|undefined} value
 * @return {!proto.tournaments.ResetTournamentResponse} returns this
*/
proto.tournaments.ResetTournamentResponse.prototype.setConfiguration = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tournaments.ResetTournamentResponse} returns this
 */
proto.tournaments.ResetTournamentResponse.prototype.clearConfiguration = function() {
  return this.setConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tournaments.ResetTournamentResponse.prototype.hasConfiguration = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Status status = 11;
 * @return {!proto.tournaments.Status}
 */
proto.tournaments.ResetTournamentResponse.prototype.getStatus = function() {
  return /** @type {!proto.tournaments.Status} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.tournaments.Status} value
 * @return {!proto.tournaments.ResetTournamentResponse} returns this
 */
proto.tournaments.ResetTournamentResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.GetTournamentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.GetTournamentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.GetTournamentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.GetTournamentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.GetTournamentRequest}
 */
proto.tournaments.GetTournamentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.GetTournamentRequest;
  return proto.tournaments.GetTournamentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.GetTournamentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.GetTournamentRequest}
 */
proto.tournaments.GetTournamentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.GetTournamentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.GetTournamentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.GetTournamentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.GetTournamentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.tournaments.GetTournamentRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.GetTournamentRequest} returns this
 */
proto.tournaments.GetTournamentRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tournaments.GetTournamentResponse.repeatedFields_ = [6,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.GetTournamentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.GetTournamentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.GetTournamentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.GetTournamentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    leaderboardsList: jspb.Message.toObjectList(msg.getLeaderboardsList(),
    proto.tournaments.LeaderboardRecord.toObject, includeInstance),
    mode: jspb.Message.getFieldWithDefault(msg, 7, 0),
    gating: (f = msg.getGating()) && proto.tournaments.Gating.toObject(includeInstance, f),
    roundsList: jspb.Message.toObjectList(msg.getRoundsList(),
    proto.tournaments.Round.toObject, includeInstance),
    configuration: (f = msg.getConfiguration()) && proto.tournaments.TournamentConfiguration.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.GetTournamentResponse}
 */
proto.tournaments.GetTournamentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.GetTournamentResponse;
  return proto.tournaments.GetTournamentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.GetTournamentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.GetTournamentResponse}
 */
proto.tournaments.GetTournamentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = new proto.tournaments.LeaderboardRecord;
      reader.readMessage(value,proto.tournaments.LeaderboardRecord.deserializeBinaryFromReader);
      msg.addLeaderboards(value);
      break;
    case 7:
      var value = /** @type {!proto.tournaments.TournamentMode} */ (reader.readEnum());
      msg.setMode(value);
      break;
    case 8:
      var value = new proto.tournaments.Gating;
      reader.readMessage(value,proto.tournaments.Gating.deserializeBinaryFromReader);
      msg.setGating(value);
      break;
    case 9:
      var value = new proto.tournaments.Round;
      reader.readMessage(value,proto.tournaments.Round.deserializeBinaryFromReader);
      msg.addRounds(value);
      break;
    case 10:
      var value = new proto.tournaments.TournamentConfiguration;
      reader.readMessage(value,proto.tournaments.TournamentConfiguration.deserializeBinaryFromReader);
      msg.setConfiguration(value);
      break;
    case 11:
      var value = /** @type {!proto.tournaments.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.GetTournamentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.GetTournamentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.GetTournamentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.GetTournamentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEndAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLeaderboardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.tournaments.LeaderboardRecord.serializeBinaryToWriter
    );
  }
  f = message.getMode();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getGating();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.tournaments.Gating.serializeBinaryToWriter
    );
  }
  f = message.getRoundsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.tournaments.Round.serializeBinaryToWriter
    );
  }
  f = message.getConfiguration();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.tournaments.TournamentConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.tournaments.GetTournamentResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.GetTournamentResponse} returns this
 */
proto.tournaments.GetTournamentResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 start_at = 2;
 * @return {number}
 */
proto.tournaments.GetTournamentResponse.prototype.getStartAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.GetTournamentResponse} returns this
 */
proto.tournaments.GetTournamentResponse.prototype.setStartAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 end_at = 3;
 * @return {number}
 */
proto.tournaments.GetTournamentResponse.prototype.getEndAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.GetTournamentResponse} returns this
 */
proto.tournaments.GetTournamentResponse.prototype.setEndAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.tournaments.GetTournamentResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.GetTournamentResponse} returns this
 */
proto.tournaments.GetTournamentResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.tournaments.GetTournamentResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.GetTournamentResponse} returns this
 */
proto.tournaments.GetTournamentResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated LeaderboardRecord leaderboards = 6;
 * @return {!Array<!proto.tournaments.LeaderboardRecord>}
 */
proto.tournaments.GetTournamentResponse.prototype.getLeaderboardsList = function() {
  return /** @type{!Array<!proto.tournaments.LeaderboardRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tournaments.LeaderboardRecord, 6));
};


/**
 * @param {!Array<!proto.tournaments.LeaderboardRecord>} value
 * @return {!proto.tournaments.GetTournamentResponse} returns this
*/
proto.tournaments.GetTournamentResponse.prototype.setLeaderboardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.tournaments.LeaderboardRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tournaments.LeaderboardRecord}
 */
proto.tournaments.GetTournamentResponse.prototype.addLeaderboards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.tournaments.LeaderboardRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tournaments.GetTournamentResponse} returns this
 */
proto.tournaments.GetTournamentResponse.prototype.clearLeaderboardsList = function() {
  return this.setLeaderboardsList([]);
};


/**
 * optional TournamentMode mode = 7;
 * @return {!proto.tournaments.TournamentMode}
 */
proto.tournaments.GetTournamentResponse.prototype.getMode = function() {
  return /** @type {!proto.tournaments.TournamentMode} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.tournaments.TournamentMode} value
 * @return {!proto.tournaments.GetTournamentResponse} returns this
 */
proto.tournaments.GetTournamentResponse.prototype.setMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional Gating gating = 8;
 * @return {?proto.tournaments.Gating}
 */
proto.tournaments.GetTournamentResponse.prototype.getGating = function() {
  return /** @type{?proto.tournaments.Gating} */ (
    jspb.Message.getWrapperField(this, proto.tournaments.Gating, 8));
};


/**
 * @param {?proto.tournaments.Gating|undefined} value
 * @return {!proto.tournaments.GetTournamentResponse} returns this
*/
proto.tournaments.GetTournamentResponse.prototype.setGating = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tournaments.GetTournamentResponse} returns this
 */
proto.tournaments.GetTournamentResponse.prototype.clearGating = function() {
  return this.setGating(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tournaments.GetTournamentResponse.prototype.hasGating = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated Round rounds = 9;
 * @return {!Array<!proto.tournaments.Round>}
 */
proto.tournaments.GetTournamentResponse.prototype.getRoundsList = function() {
  return /** @type{!Array<!proto.tournaments.Round>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tournaments.Round, 9));
};


/**
 * @param {!Array<!proto.tournaments.Round>} value
 * @return {!proto.tournaments.GetTournamentResponse} returns this
*/
proto.tournaments.GetTournamentResponse.prototype.setRoundsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.tournaments.Round=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tournaments.Round}
 */
proto.tournaments.GetTournamentResponse.prototype.addRounds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.tournaments.Round, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tournaments.GetTournamentResponse} returns this
 */
proto.tournaments.GetTournamentResponse.prototype.clearRoundsList = function() {
  return this.setRoundsList([]);
};


/**
 * optional TournamentConfiguration configuration = 10;
 * @return {?proto.tournaments.TournamentConfiguration}
 */
proto.tournaments.GetTournamentResponse.prototype.getConfiguration = function() {
  return /** @type{?proto.tournaments.TournamentConfiguration} */ (
    jspb.Message.getWrapperField(this, proto.tournaments.TournamentConfiguration, 10));
};


/**
 * @param {?proto.tournaments.TournamentConfiguration|undefined} value
 * @return {!proto.tournaments.GetTournamentResponse} returns this
*/
proto.tournaments.GetTournamentResponse.prototype.setConfiguration = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tournaments.GetTournamentResponse} returns this
 */
proto.tournaments.GetTournamentResponse.prototype.clearConfiguration = function() {
  return this.setConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tournaments.GetTournamentResponse.prototype.hasConfiguration = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Status status = 11;
 * @return {!proto.tournaments.Status}
 */
proto.tournaments.GetTournamentResponse.prototype.getStatus = function() {
  return /** @type {!proto.tournaments.Status} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.tournaments.Status} value
 * @return {!proto.tournaments.GetTournamentResponse} returns this
 */
proto.tournaments.GetTournamentResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tournaments.TournamentDetails.repeatedFields_ = [6,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.TournamentDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.TournamentDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.TournamentDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.TournamentDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    leaderboardsList: jspb.Message.toObjectList(msg.getLeaderboardsList(),
    proto.tournaments.LeaderboardRecord.toObject, includeInstance),
    mode: jspb.Message.getFieldWithDefault(msg, 7, 0),
    gating: (f = msg.getGating()) && proto.tournaments.Gating.toObject(includeInstance, f),
    roundsList: jspb.Message.toObjectList(msg.getRoundsList(),
    proto.tournaments.Round.toObject, includeInstance),
    configuration: (f = msg.getConfiguration()) && proto.tournaments.TournamentConfiguration.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.TournamentDetails}
 */
proto.tournaments.TournamentDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.TournamentDetails;
  return proto.tournaments.TournamentDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.TournamentDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.TournamentDetails}
 */
proto.tournaments.TournamentDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = new proto.tournaments.LeaderboardRecord;
      reader.readMessage(value,proto.tournaments.LeaderboardRecord.deserializeBinaryFromReader);
      msg.addLeaderboards(value);
      break;
    case 7:
      var value = /** @type {!proto.tournaments.TournamentMode} */ (reader.readEnum());
      msg.setMode(value);
      break;
    case 8:
      var value = new proto.tournaments.Gating;
      reader.readMessage(value,proto.tournaments.Gating.deserializeBinaryFromReader);
      msg.setGating(value);
      break;
    case 9:
      var value = new proto.tournaments.Round;
      reader.readMessage(value,proto.tournaments.Round.deserializeBinaryFromReader);
      msg.addRounds(value);
      break;
    case 10:
      var value = new proto.tournaments.TournamentConfiguration;
      reader.readMessage(value,proto.tournaments.TournamentConfiguration.deserializeBinaryFromReader);
      msg.setConfiguration(value);
      break;
    case 11:
      var value = /** @type {!proto.tournaments.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.TournamentDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.TournamentDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.TournamentDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.TournamentDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEndAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLeaderboardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.tournaments.LeaderboardRecord.serializeBinaryToWriter
    );
  }
  f = message.getMode();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getGating();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.tournaments.Gating.serializeBinaryToWriter
    );
  }
  f = message.getRoundsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.tournaments.Round.serializeBinaryToWriter
    );
  }
  f = message.getConfiguration();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.tournaments.TournamentConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.tournaments.TournamentDetails.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.TournamentDetails} returns this
 */
proto.tournaments.TournamentDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 start_at = 2;
 * @return {number}
 */
proto.tournaments.TournamentDetails.prototype.getStartAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.TournamentDetails} returns this
 */
proto.tournaments.TournamentDetails.prototype.setStartAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 end_at = 3;
 * @return {number}
 */
proto.tournaments.TournamentDetails.prototype.getEndAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.TournamentDetails} returns this
 */
proto.tournaments.TournamentDetails.prototype.setEndAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.tournaments.TournamentDetails.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.TournamentDetails} returns this
 */
proto.tournaments.TournamentDetails.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.tournaments.TournamentDetails.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.TournamentDetails} returns this
 */
proto.tournaments.TournamentDetails.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated LeaderboardRecord leaderboards = 6;
 * @return {!Array<!proto.tournaments.LeaderboardRecord>}
 */
proto.tournaments.TournamentDetails.prototype.getLeaderboardsList = function() {
  return /** @type{!Array<!proto.tournaments.LeaderboardRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tournaments.LeaderboardRecord, 6));
};


/**
 * @param {!Array<!proto.tournaments.LeaderboardRecord>} value
 * @return {!proto.tournaments.TournamentDetails} returns this
*/
proto.tournaments.TournamentDetails.prototype.setLeaderboardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.tournaments.LeaderboardRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tournaments.LeaderboardRecord}
 */
proto.tournaments.TournamentDetails.prototype.addLeaderboards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.tournaments.LeaderboardRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tournaments.TournamentDetails} returns this
 */
proto.tournaments.TournamentDetails.prototype.clearLeaderboardsList = function() {
  return this.setLeaderboardsList([]);
};


/**
 * optional TournamentMode mode = 7;
 * @return {!proto.tournaments.TournamentMode}
 */
proto.tournaments.TournamentDetails.prototype.getMode = function() {
  return /** @type {!proto.tournaments.TournamentMode} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.tournaments.TournamentMode} value
 * @return {!proto.tournaments.TournamentDetails} returns this
 */
proto.tournaments.TournamentDetails.prototype.setMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional Gating gating = 8;
 * @return {?proto.tournaments.Gating}
 */
proto.tournaments.TournamentDetails.prototype.getGating = function() {
  return /** @type{?proto.tournaments.Gating} */ (
    jspb.Message.getWrapperField(this, proto.tournaments.Gating, 8));
};


/**
 * @param {?proto.tournaments.Gating|undefined} value
 * @return {!proto.tournaments.TournamentDetails} returns this
*/
proto.tournaments.TournamentDetails.prototype.setGating = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tournaments.TournamentDetails} returns this
 */
proto.tournaments.TournamentDetails.prototype.clearGating = function() {
  return this.setGating(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tournaments.TournamentDetails.prototype.hasGating = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated Round rounds = 9;
 * @return {!Array<!proto.tournaments.Round>}
 */
proto.tournaments.TournamentDetails.prototype.getRoundsList = function() {
  return /** @type{!Array<!proto.tournaments.Round>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tournaments.Round, 9));
};


/**
 * @param {!Array<!proto.tournaments.Round>} value
 * @return {!proto.tournaments.TournamentDetails} returns this
*/
proto.tournaments.TournamentDetails.prototype.setRoundsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.tournaments.Round=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tournaments.Round}
 */
proto.tournaments.TournamentDetails.prototype.addRounds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.tournaments.Round, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tournaments.TournamentDetails} returns this
 */
proto.tournaments.TournamentDetails.prototype.clearRoundsList = function() {
  return this.setRoundsList([]);
};


/**
 * optional TournamentConfiguration configuration = 10;
 * @return {?proto.tournaments.TournamentConfiguration}
 */
proto.tournaments.TournamentDetails.prototype.getConfiguration = function() {
  return /** @type{?proto.tournaments.TournamentConfiguration} */ (
    jspb.Message.getWrapperField(this, proto.tournaments.TournamentConfiguration, 10));
};


/**
 * @param {?proto.tournaments.TournamentConfiguration|undefined} value
 * @return {!proto.tournaments.TournamentDetails} returns this
*/
proto.tournaments.TournamentDetails.prototype.setConfiguration = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tournaments.TournamentDetails} returns this
 */
proto.tournaments.TournamentDetails.prototype.clearConfiguration = function() {
  return this.setConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tournaments.TournamentDetails.prototype.hasConfiguration = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Status status = 11;
 * @return {!proto.tournaments.Status}
 */
proto.tournaments.TournamentDetails.prototype.getStatus = function() {
  return /** @type {!proto.tournaments.Status} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.tournaments.Status} value
 * @return {!proto.tournaments.TournamentDetails} returns this
 */
proto.tournaments.TournamentDetails.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tournaments.GetTournamentsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.GetTournamentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.GetTournamentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.GetTournamentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.GetTournamentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.GetTournamentsRequest}
 */
proto.tournaments.GetTournamentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.GetTournamentsRequest;
  return proto.tournaments.GetTournamentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.GetTournamentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.GetTournamentsRequest}
 */
proto.tournaments.GetTournamentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.GetTournamentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.GetTournamentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.GetTournamentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.GetTournamentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.tournaments.GetTournamentsRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.tournaments.GetTournamentsRequest} returns this
 */
proto.tournaments.GetTournamentsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.tournaments.GetTournamentsRequest} returns this
 */
proto.tournaments.GetTournamentsRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tournaments.GetTournamentsRequest} returns this
 */
proto.tournaments.GetTournamentsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tournaments.GetTournamentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.GetTournamentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.GetTournamentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.GetTournamentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.GetTournamentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tournamentsList: jspb.Message.toObjectList(msg.getTournamentsList(),
    proto.tournaments.TournamentDetails.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.GetTournamentsResponse}
 */
proto.tournaments.GetTournamentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.GetTournamentsResponse;
  return proto.tournaments.GetTournamentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.GetTournamentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.GetTournamentsResponse}
 */
proto.tournaments.GetTournamentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.tournaments.TournamentDetails;
      reader.readMessage(value,proto.tournaments.TournamentDetails.deserializeBinaryFromReader);
      msg.addTournaments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.GetTournamentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.GetTournamentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.GetTournamentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.GetTournamentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTournamentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.tournaments.TournamentDetails.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TournamentDetails tournaments = 1;
 * @return {!Array<!proto.tournaments.TournamentDetails>}
 */
proto.tournaments.GetTournamentsResponse.prototype.getTournamentsList = function() {
  return /** @type{!Array<!proto.tournaments.TournamentDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tournaments.TournamentDetails, 1));
};


/**
 * @param {!Array<!proto.tournaments.TournamentDetails>} value
 * @return {!proto.tournaments.GetTournamentsResponse} returns this
*/
proto.tournaments.GetTournamentsResponse.prototype.setTournamentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.tournaments.TournamentDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tournaments.TournamentDetails}
 */
proto.tournaments.GetTournamentsResponse.prototype.addTournaments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.tournaments.TournamentDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tournaments.GetTournamentsResponse} returns this
 */
proto.tournaments.GetTournamentsResponse.prototype.clearTournamentsList = function() {
  return this.setTournamentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.AddTournamentRoundRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.AddTournamentRoundRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.AddTournamentRoundRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.AddTournamentRoundRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tournamentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    round: (f = msg.getRound()) && proto.tournaments.Round.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.AddTournamentRoundRequest}
 */
proto.tournaments.AddTournamentRoundRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.AddTournamentRoundRequest;
  return proto.tournaments.AddTournamentRoundRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.AddTournamentRoundRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.AddTournamentRoundRequest}
 */
proto.tournaments.AddTournamentRoundRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTournamentId(value);
      break;
    case 2:
      var value = new proto.tournaments.Round;
      reader.readMessage(value,proto.tournaments.Round.deserializeBinaryFromReader);
      msg.setRound(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.AddTournamentRoundRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.AddTournamentRoundRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.AddTournamentRoundRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.AddTournamentRoundRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTournamentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRound();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.tournaments.Round.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tournament_id = 1;
 * @return {string}
 */
proto.tournaments.AddTournamentRoundRequest.prototype.getTournamentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.AddTournamentRoundRequest} returns this
 */
proto.tournaments.AddTournamentRoundRequest.prototype.setTournamentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Round round = 2;
 * @return {?proto.tournaments.Round}
 */
proto.tournaments.AddTournamentRoundRequest.prototype.getRound = function() {
  return /** @type{?proto.tournaments.Round} */ (
    jspb.Message.getWrapperField(this, proto.tournaments.Round, 2));
};


/**
 * @param {?proto.tournaments.Round|undefined} value
 * @return {!proto.tournaments.AddTournamentRoundRequest} returns this
*/
proto.tournaments.AddTournamentRoundRequest.prototype.setRound = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tournaments.AddTournamentRoundRequest} returns this
 */
proto.tournaments.AddTournamentRoundRequest.prototype.clearRound = function() {
  return this.setRound(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tournaments.AddTournamentRoundRequest.prototype.hasRound = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.DeleteTournamentRoundRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.DeleteTournamentRoundRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.DeleteTournamentRoundRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.DeleteTournamentRoundRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tournamentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roundId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.DeleteTournamentRoundRequest}
 */
proto.tournaments.DeleteTournamentRoundRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.DeleteTournamentRoundRequest;
  return proto.tournaments.DeleteTournamentRoundRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.DeleteTournamentRoundRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.DeleteTournamentRoundRequest}
 */
proto.tournaments.DeleteTournamentRoundRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTournamentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoundId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.DeleteTournamentRoundRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.DeleteTournamentRoundRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.DeleteTournamentRoundRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.DeleteTournamentRoundRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTournamentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoundId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tournament_id = 1;
 * @return {string}
 */
proto.tournaments.DeleteTournamentRoundRequest.prototype.getTournamentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.DeleteTournamentRoundRequest} returns this
 */
proto.tournaments.DeleteTournamentRoundRequest.prototype.setTournamentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string round_id = 2;
 * @return {string}
 */
proto.tournaments.DeleteTournamentRoundRequest.prototype.getRoundId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.DeleteTournamentRoundRequest} returns this
 */
proto.tournaments.DeleteTournamentRoundRequest.prototype.setRoundId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.UpdateTournamentRoundRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.UpdateTournamentRoundRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.UpdateTournamentRoundRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.UpdateTournamentRoundRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tournamentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    round: (f = msg.getRound()) && proto.tournaments.Round.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.UpdateTournamentRoundRequest}
 */
proto.tournaments.UpdateTournamentRoundRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.UpdateTournamentRoundRequest;
  return proto.tournaments.UpdateTournamentRoundRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.UpdateTournamentRoundRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.UpdateTournamentRoundRequest}
 */
proto.tournaments.UpdateTournamentRoundRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTournamentId(value);
      break;
    case 2:
      var value = new proto.tournaments.Round;
      reader.readMessage(value,proto.tournaments.Round.deserializeBinaryFromReader);
      msg.setRound(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.UpdateTournamentRoundRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.UpdateTournamentRoundRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.UpdateTournamentRoundRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.UpdateTournamentRoundRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTournamentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRound();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.tournaments.Round.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tournament_id = 1;
 * @return {string}
 */
proto.tournaments.UpdateTournamentRoundRequest.prototype.getTournamentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.UpdateTournamentRoundRequest} returns this
 */
proto.tournaments.UpdateTournamentRoundRequest.prototype.setTournamentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Round round = 2;
 * @return {?proto.tournaments.Round}
 */
proto.tournaments.UpdateTournamentRoundRequest.prototype.getRound = function() {
  return /** @type{?proto.tournaments.Round} */ (
    jspb.Message.getWrapperField(this, proto.tournaments.Round, 2));
};


/**
 * @param {?proto.tournaments.Round|undefined} value
 * @return {!proto.tournaments.UpdateTournamentRoundRequest} returns this
*/
proto.tournaments.UpdateTournamentRoundRequest.prototype.setRound = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tournaments.UpdateTournamentRoundRequest} returns this
 */
proto.tournaments.UpdateTournamentRoundRequest.prototype.clearRound = function() {
  return this.setRound(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tournaments.UpdateTournamentRoundRequest.prototype.hasRound = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.CreateAttemptRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.CreateAttemptRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.CreateAttemptRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.CreateAttemptRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roundId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    practice: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.CreateAttemptRequest}
 */
proto.tournaments.CreateAttemptRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.CreateAttemptRequest;
  return proto.tournaments.CreateAttemptRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.CreateAttemptRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.CreateAttemptRequest}
 */
proto.tournaments.CreateAttemptRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoundId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPractice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.CreateAttemptRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.CreateAttemptRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.CreateAttemptRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.CreateAttemptRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoundId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPractice();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string round_id = 1;
 * @return {string}
 */
proto.tournaments.CreateAttemptRequest.prototype.getRoundId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.CreateAttemptRequest} returns this
 */
proto.tournaments.CreateAttemptRequest.prototype.setRoundId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string owner_id = 2;
 * @return {string}
 */
proto.tournaments.CreateAttemptRequest.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.CreateAttemptRequest} returns this
 */
proto.tournaments.CreateAttemptRequest.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool practice = 3;
 * @return {boolean}
 */
proto.tournaments.CreateAttemptRequest.prototype.getPractice = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.tournaments.CreateAttemptRequest} returns this
 */
proto.tournaments.CreateAttemptRequest.prototype.setPractice = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.CreateAttemptResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.CreateAttemptResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.CreateAttemptResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.CreateAttemptResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.CreateAttemptResponse}
 */
proto.tournaments.CreateAttemptResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.CreateAttemptResponse;
  return proto.tournaments.CreateAttemptResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.CreateAttemptResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.CreateAttemptResponse}
 */
proto.tournaments.CreateAttemptResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.CreateAttemptResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.CreateAttemptResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.CreateAttemptResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.CreateAttemptResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.SubmitScoreRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.SubmitScoreRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.SubmitScoreRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.SubmitScoreRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roundId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    matchId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    score: jspb.Message.getFieldWithDefault(msg, 4, 0),
    rank: jspb.Message.getFieldWithDefault(msg, 5, 0),
    metadataMap: (f = msg.getMetadataMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.SubmitScoreRequest}
 */
proto.tournaments.SubmitScoreRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.SubmitScoreRequest;
  return proto.tournaments.SubmitScoreRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.SubmitScoreRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.SubmitScoreRequest}
 */
proto.tournaments.SubmitScoreRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoundId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatchId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScore(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRank(value);
      break;
    case 6:
      var value = msg.getMetadataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.SubmitScoreRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.SubmitScoreRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.SubmitScoreRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.SubmitScoreRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoundId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMatchId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getScore();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getRank();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getMetadataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string round_id = 1;
 * @return {string}
 */
proto.tournaments.SubmitScoreRequest.prototype.getRoundId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.SubmitScoreRequest} returns this
 */
proto.tournaments.SubmitScoreRequest.prototype.setRoundId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string owner_id = 2;
 * @return {string}
 */
proto.tournaments.SubmitScoreRequest.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.SubmitScoreRequest} returns this
 */
proto.tournaments.SubmitScoreRequest.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string match_id = 3;
 * @return {string}
 */
proto.tournaments.SubmitScoreRequest.prototype.getMatchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.SubmitScoreRequest} returns this
 */
proto.tournaments.SubmitScoreRequest.prototype.setMatchId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 score = 4;
 * @return {number}
 */
proto.tournaments.SubmitScoreRequest.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.SubmitScoreRequest} returns this
 */
proto.tournaments.SubmitScoreRequest.prototype.setScore = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 rank = 5;
 * @return {number}
 */
proto.tournaments.SubmitScoreRequest.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.SubmitScoreRequest} returns this
 */
proto.tournaments.SubmitScoreRequest.prototype.setRank = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * map<string, string> metadata = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.tournaments.SubmitScoreRequest.prototype.getMetadataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.tournaments.SubmitScoreRequest} returns this
 */
proto.tournaments.SubmitScoreRequest.prototype.clearMetadataMap = function() {
  this.getMetadataMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.SubmitScoreResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.SubmitScoreResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.SubmitScoreResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.SubmitScoreResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    score: jspb.Message.getFieldWithDefault(msg, 1, 0),
    rank: jspb.Message.getFieldWithDefault(msg, 2, 0),
    metadataMap: (f = msg.getMetadataMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.SubmitScoreResponse}
 */
proto.tournaments.SubmitScoreResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.SubmitScoreResponse;
  return proto.tournaments.SubmitScoreResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.SubmitScoreResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.SubmitScoreResponse}
 */
proto.tournaments.SubmitScoreResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScore(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRank(value);
      break;
    case 3:
      var value = msg.getMetadataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.SubmitScoreResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.SubmitScoreResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.SubmitScoreResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.SubmitScoreResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScore();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRank();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMetadataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional int64 score = 1;
 * @return {number}
 */
proto.tournaments.SubmitScoreResponse.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.SubmitScoreResponse} returns this
 */
proto.tournaments.SubmitScoreResponse.prototype.setScore = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 rank = 2;
 * @return {number}
 */
proto.tournaments.SubmitScoreResponse.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.SubmitScoreResponse} returns this
 */
proto.tournaments.SubmitScoreResponse.prototype.setRank = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * map<string, string> metadata = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.tournaments.SubmitScoreResponse.prototype.getMetadataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.tournaments.SubmitScoreResponse} returns this
 */
proto.tournaments.SubmitScoreResponse.prototype.clearMetadataMap = function() {
  this.getMetadataMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.GetTournamentResultSummaryByOwnerIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.GetTournamentResultSummaryByOwnerIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.GetTournamentResultSummaryByOwnerIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.GetTournamentResultSummaryByOwnerIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tournamentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.GetTournamentResultSummaryByOwnerIdRequest}
 */
proto.tournaments.GetTournamentResultSummaryByOwnerIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.GetTournamentResultSummaryByOwnerIdRequest;
  return proto.tournaments.GetTournamentResultSummaryByOwnerIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.GetTournamentResultSummaryByOwnerIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.GetTournamentResultSummaryByOwnerIdRequest}
 */
proto.tournaments.GetTournamentResultSummaryByOwnerIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTournamentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.GetTournamentResultSummaryByOwnerIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.GetTournamentResultSummaryByOwnerIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.GetTournamentResultSummaryByOwnerIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.GetTournamentResultSummaryByOwnerIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTournamentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tournament_id = 1;
 * @return {string}
 */
proto.tournaments.GetTournamentResultSummaryByOwnerIdRequest.prototype.getTournamentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.GetTournamentResultSummaryByOwnerIdRequest} returns this
 */
proto.tournaments.GetTournamentResultSummaryByOwnerIdRequest.prototype.setTournamentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string owner_id = 2;
 * @return {string}
 */
proto.tournaments.GetTournamentResultSummaryByOwnerIdRequest.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.GetTournamentResultSummaryByOwnerIdRequest} returns this
 */
proto.tournaments.GetTournamentResultSummaryByOwnerIdRequest.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.TournamentResultSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.TournamentResultSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.TournamentResultSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.TournamentResultSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    rank: jspb.Message.getFieldWithDefault(msg, 1, 0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    points: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    totalRanks: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.TournamentResultSummary}
 */
proto.tournaments.TournamentResultSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.TournamentResultSummary;
  return proto.tournaments.TournamentResultSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.TournamentResultSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.TournamentResultSummary}
 */
proto.tournaments.TournamentResultSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRank(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPoints(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalRanks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.TournamentResultSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.TournamentResultSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.TournamentResultSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.TournamentResultSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRank();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getPoints();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getTotalRanks();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 rank = 1;
 * @return {number}
 */
proto.tournaments.TournamentResultSummary.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.TournamentResultSummary} returns this
 */
proto.tournaments.TournamentResultSummary.prototype.setRank = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float score = 2;
 * @return {number}
 */
proto.tournaments.TournamentResultSummary.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.TournamentResultSummary} returns this
 */
proto.tournaments.TournamentResultSummary.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float points = 3;
 * @return {number}
 */
proto.tournaments.TournamentResultSummary.prototype.getPoints = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.TournamentResultSummary} returns this
 */
proto.tournaments.TournamentResultSummary.prototype.setPoints = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 total_ranks = 4;
 * @return {number}
 */
proto.tournaments.TournamentResultSummary.prototype.getTotalRanks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.TournamentResultSummary} returns this
 */
proto.tournaments.TournamentResultSummary.prototype.setTotalRanks = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.RoundResultSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.RoundResultSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.RoundResultSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.RoundResultSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    roundId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rank: jspb.Message.getFieldWithDefault(msg, 2, 0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    points: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    totalRanks: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.RoundResultSummary}
 */
proto.tournaments.RoundResultSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.RoundResultSummary;
  return proto.tournaments.RoundResultSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.RoundResultSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.RoundResultSummary}
 */
proto.tournaments.RoundResultSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoundId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRank(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPoints(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalRanks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.RoundResultSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.RoundResultSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.RoundResultSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.RoundResultSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoundId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRank();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getPoints();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getTotalRanks();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string round_id = 1;
 * @return {string}
 */
proto.tournaments.RoundResultSummary.prototype.getRoundId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.RoundResultSummary} returns this
 */
proto.tournaments.RoundResultSummary.prototype.setRoundId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 rank = 2;
 * @return {number}
 */
proto.tournaments.RoundResultSummary.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.RoundResultSummary} returns this
 */
proto.tournaments.RoundResultSummary.prototype.setRank = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float score = 3;
 * @return {number}
 */
proto.tournaments.RoundResultSummary.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.RoundResultSummary} returns this
 */
proto.tournaments.RoundResultSummary.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float points = 4;
 * @return {number}
 */
proto.tournaments.RoundResultSummary.prototype.getPoints = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.RoundResultSummary} returns this
 */
proto.tournaments.RoundResultSummary.prototype.setPoints = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int32 total_ranks = 5;
 * @return {number}
 */
proto.tournaments.RoundResultSummary.prototype.getTotalRanks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.RoundResultSummary} returns this
 */
proto.tournaments.RoundResultSummary.prototype.setTotalRanks = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tournamentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tournamentSummary: (f = msg.getTournamentSummary()) && proto.tournaments.TournamentResultSummary.toObject(includeInstance, f),
    roundSummaryList: jspb.Message.toObjectList(msg.getRoundSummaryList(),
    proto.tournaments.RoundResultSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse}
 */
proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse;
  return proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse}
 */
proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTournamentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 3:
      var value = new proto.tournaments.TournamentResultSummary;
      reader.readMessage(value,proto.tournaments.TournamentResultSummary.deserializeBinaryFromReader);
      msg.setTournamentSummary(value);
      break;
    case 4:
      var value = new proto.tournaments.RoundResultSummary;
      reader.readMessage(value,proto.tournaments.RoundResultSummary.deserializeBinaryFromReader);
      msg.addRoundSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTournamentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTournamentSummary();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.tournaments.TournamentResultSummary.serializeBinaryToWriter
    );
  }
  f = message.getRoundSummaryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.tournaments.RoundResultSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tournament_id = 1;
 * @return {string}
 */
proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse.prototype.getTournamentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse} returns this
 */
proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse.prototype.setTournamentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string owner_id = 2;
 * @return {string}
 */
proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse} returns this
 */
proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional TournamentResultSummary tournament_summary = 3;
 * @return {?proto.tournaments.TournamentResultSummary}
 */
proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse.prototype.getTournamentSummary = function() {
  return /** @type{?proto.tournaments.TournamentResultSummary} */ (
    jspb.Message.getWrapperField(this, proto.tournaments.TournamentResultSummary, 3));
};


/**
 * @param {?proto.tournaments.TournamentResultSummary|undefined} value
 * @return {!proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse} returns this
*/
proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse.prototype.setTournamentSummary = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse} returns this
 */
proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse.prototype.clearTournamentSummary = function() {
  return this.setTournamentSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse.prototype.hasTournamentSummary = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated RoundResultSummary round_summary = 4;
 * @return {!Array<!proto.tournaments.RoundResultSummary>}
 */
proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse.prototype.getRoundSummaryList = function() {
  return /** @type{!Array<!proto.tournaments.RoundResultSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tournaments.RoundResultSummary, 4));
};


/**
 * @param {!Array<!proto.tournaments.RoundResultSummary>} value
 * @return {!proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse} returns this
*/
proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse.prototype.setRoundSummaryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.tournaments.RoundResultSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tournaments.RoundResultSummary}
 */
proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse.prototype.addRoundSummary = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.tournaments.RoundResultSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse} returns this
 */
proto.tournaments.GetTournamentResultSummaryByOwnerIdResponse.prototype.clearRoundSummaryList = function() {
  return this.setRoundSummaryList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.GetTournamentLeaderboardsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.GetTournamentLeaderboardsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.GetTournamentLeaderboardsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.GetTournamentLeaderboardsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tournamentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.GetTournamentLeaderboardsRequest}
 */
proto.tournaments.GetTournamentLeaderboardsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.GetTournamentLeaderboardsRequest;
  return proto.tournaments.GetTournamentLeaderboardsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.GetTournamentLeaderboardsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.GetTournamentLeaderboardsRequest}
 */
proto.tournaments.GetTournamentLeaderboardsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTournamentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.GetTournamentLeaderboardsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.GetTournamentLeaderboardsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.GetTournamentLeaderboardsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.GetTournamentLeaderboardsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTournamentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tournament_id = 1;
 * @return {string}
 */
proto.tournaments.GetTournamentLeaderboardsRequest.prototype.getTournamentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.GetTournamentLeaderboardsRequest} returns this
 */
proto.tournaments.GetTournamentLeaderboardsRequest.prototype.setTournamentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.LeaderboardResultRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.LeaderboardResultRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.LeaderboardResultRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.LeaderboardResultRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rank: jspb.Message.getFieldWithDefault(msg, 2, 0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    points: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    totalRanks: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.LeaderboardResultRecord}
 */
proto.tournaments.LeaderboardResultRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.LeaderboardResultRecord;
  return proto.tournaments.LeaderboardResultRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.LeaderboardResultRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.LeaderboardResultRecord}
 */
proto.tournaments.LeaderboardResultRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRank(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPoints(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalRanks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.LeaderboardResultRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.LeaderboardResultRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.LeaderboardResultRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.LeaderboardResultRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRank();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getPoints();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getTotalRanks();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string owner_id = 1;
 * @return {string}
 */
proto.tournaments.LeaderboardResultRecord.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.LeaderboardResultRecord} returns this
 */
proto.tournaments.LeaderboardResultRecord.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 rank = 2;
 * @return {number}
 */
proto.tournaments.LeaderboardResultRecord.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.LeaderboardResultRecord} returns this
 */
proto.tournaments.LeaderboardResultRecord.prototype.setRank = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float score = 3;
 * @return {number}
 */
proto.tournaments.LeaderboardResultRecord.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.LeaderboardResultRecord} returns this
 */
proto.tournaments.LeaderboardResultRecord.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float points = 4;
 * @return {number}
 */
proto.tournaments.LeaderboardResultRecord.prototype.getPoints = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.LeaderboardResultRecord} returns this
 */
proto.tournaments.LeaderboardResultRecord.prototype.setPoints = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int32 total_ranks = 5;
 * @return {number}
 */
proto.tournaments.LeaderboardResultRecord.prototype.getTotalRanks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.LeaderboardResultRecord} returns this
 */
proto.tournaments.LeaderboardResultRecord.prototype.setTotalRanks = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tournaments.LeaderboardResult.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.LeaderboardResult.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.LeaderboardResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.LeaderboardResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.LeaderboardResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    leaderboardName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    leaderboardRecordsList: jspb.Message.toObjectList(msg.getLeaderboardRecordsList(),
    proto.tournaments.LeaderboardResultRecord.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.LeaderboardResult}
 */
proto.tournaments.LeaderboardResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.LeaderboardResult;
  return proto.tournaments.LeaderboardResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.LeaderboardResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.LeaderboardResult}
 */
proto.tournaments.LeaderboardResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeaderboardName(value);
      break;
    case 2:
      var value = new proto.tournaments.LeaderboardResultRecord;
      reader.readMessage(value,proto.tournaments.LeaderboardResultRecord.deserializeBinaryFromReader);
      msg.addLeaderboardRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.LeaderboardResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.LeaderboardResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.LeaderboardResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.LeaderboardResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLeaderboardName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLeaderboardRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.tournaments.LeaderboardResultRecord.serializeBinaryToWriter
    );
  }
};


/**
 * optional string leaderboard_name = 1;
 * @return {string}
 */
proto.tournaments.LeaderboardResult.prototype.getLeaderboardName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.LeaderboardResult} returns this
 */
proto.tournaments.LeaderboardResult.prototype.setLeaderboardName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated LeaderboardResultRecord leaderboard_records = 2;
 * @return {!Array<!proto.tournaments.LeaderboardResultRecord>}
 */
proto.tournaments.LeaderboardResult.prototype.getLeaderboardRecordsList = function() {
  return /** @type{!Array<!proto.tournaments.LeaderboardResultRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tournaments.LeaderboardResultRecord, 2));
};


/**
 * @param {!Array<!proto.tournaments.LeaderboardResultRecord>} value
 * @return {!proto.tournaments.LeaderboardResult} returns this
*/
proto.tournaments.LeaderboardResult.prototype.setLeaderboardRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.tournaments.LeaderboardResultRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tournaments.LeaderboardResultRecord}
 */
proto.tournaments.LeaderboardResult.prototype.addLeaderboardRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.tournaments.LeaderboardResultRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tournaments.LeaderboardResult} returns this
 */
proto.tournaments.LeaderboardResult.prototype.clearLeaderboardRecordsList = function() {
  return this.setLeaderboardRecordsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tournaments.GetTournamentLeaderboardsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.GetTournamentLeaderboardsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.GetTournamentLeaderboardsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.GetTournamentLeaderboardsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.GetTournamentLeaderboardsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tournamentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    leaderboardsList: jspb.Message.toObjectList(msg.getLeaderboardsList(),
    proto.tournaments.LeaderboardResult.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.GetTournamentLeaderboardsResponse}
 */
proto.tournaments.GetTournamentLeaderboardsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.GetTournamentLeaderboardsResponse;
  return proto.tournaments.GetTournamentLeaderboardsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.GetTournamentLeaderboardsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.GetTournamentLeaderboardsResponse}
 */
proto.tournaments.GetTournamentLeaderboardsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTournamentId(value);
      break;
    case 2:
      var value = new proto.tournaments.LeaderboardResult;
      reader.readMessage(value,proto.tournaments.LeaderboardResult.deserializeBinaryFromReader);
      msg.addLeaderboards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.GetTournamentLeaderboardsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.GetTournamentLeaderboardsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.GetTournamentLeaderboardsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.GetTournamentLeaderboardsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTournamentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLeaderboardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.tournaments.LeaderboardResult.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tournament_id = 1;
 * @return {string}
 */
proto.tournaments.GetTournamentLeaderboardsResponse.prototype.getTournamentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.GetTournamentLeaderboardsResponse} returns this
 */
proto.tournaments.GetTournamentLeaderboardsResponse.prototype.setTournamentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated LeaderboardResult leaderboards = 2;
 * @return {!Array<!proto.tournaments.LeaderboardResult>}
 */
proto.tournaments.GetTournamentLeaderboardsResponse.prototype.getLeaderboardsList = function() {
  return /** @type{!Array<!proto.tournaments.LeaderboardResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tournaments.LeaderboardResult, 2));
};


/**
 * @param {!Array<!proto.tournaments.LeaderboardResult>} value
 * @return {!proto.tournaments.GetTournamentLeaderboardsResponse} returns this
*/
proto.tournaments.GetTournamentLeaderboardsResponse.prototype.setLeaderboardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.tournaments.LeaderboardResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tournaments.LeaderboardResult}
 */
proto.tournaments.GetTournamentLeaderboardsResponse.prototype.addLeaderboards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.tournaments.LeaderboardResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tournaments.GetTournamentLeaderboardsResponse} returns this
 */
proto.tournaments.GetTournamentLeaderboardsResponse.prototype.clearLeaderboardsList = function() {
  return this.setLeaderboardsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.StartTournamentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.StartTournamentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.StartTournamentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.StartTournamentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tournamentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.StartTournamentRequest}
 */
proto.tournaments.StartTournamentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.StartTournamentRequest;
  return proto.tournaments.StartTournamentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.StartTournamentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.StartTournamentRequest}
 */
proto.tournaments.StartTournamentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTournamentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.StartTournamentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.StartTournamentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.StartTournamentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.StartTournamentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTournamentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tournament_id = 1;
 * @return {string}
 */
proto.tournaments.StartTournamentRequest.prototype.getTournamentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.StartTournamentRequest} returns this
 */
proto.tournaments.StartTournamentRequest.prototype.setTournamentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.StartTournamentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.StartTournamentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.StartTournamentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.StartTournamentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.StartTournamentResponse}
 */
proto.tournaments.StartTournamentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.StartTournamentResponse;
  return proto.tournaments.StartTournamentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.StartTournamentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.StartTournamentResponse}
 */
proto.tournaments.StartTournamentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.StartTournamentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.StartTournamentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.StartTournamentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.StartTournamentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.EndTournamentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.EndTournamentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.EndTournamentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.EndTournamentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tournamentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.EndTournamentRequest}
 */
proto.tournaments.EndTournamentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.EndTournamentRequest;
  return proto.tournaments.EndTournamentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.EndTournamentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.EndTournamentRequest}
 */
proto.tournaments.EndTournamentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTournamentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.EndTournamentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.EndTournamentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.EndTournamentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.EndTournamentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTournamentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tournament_id = 1;
 * @return {string}
 */
proto.tournaments.EndTournamentRequest.prototype.getTournamentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.EndTournamentRequest} returns this
 */
proto.tournaments.EndTournamentRequest.prototype.setTournamentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.EndTournamentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.EndTournamentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.EndTournamentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.EndTournamentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.EndTournamentResponse}
 */
proto.tournaments.EndTournamentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.EndTournamentResponse;
  return proto.tournaments.EndTournamentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.EndTournamentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.EndTournamentResponse}
 */
proto.tournaments.EndTournamentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.EndTournamentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.EndTournamentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.EndTournamentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.EndTournamentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.EndTournamentRoundRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.EndTournamentRoundRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.EndTournamentRoundRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.EndTournamentRoundRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tournamentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roundId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.EndTournamentRoundRequest}
 */
proto.tournaments.EndTournamentRoundRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.EndTournamentRoundRequest;
  return proto.tournaments.EndTournamentRoundRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.EndTournamentRoundRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.EndTournamentRoundRequest}
 */
proto.tournaments.EndTournamentRoundRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTournamentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoundId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.EndTournamentRoundRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.EndTournamentRoundRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.EndTournamentRoundRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.EndTournamentRoundRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTournamentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoundId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tournament_id = 1;
 * @return {string}
 */
proto.tournaments.EndTournamentRoundRequest.prototype.getTournamentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.EndTournamentRoundRequest} returns this
 */
proto.tournaments.EndTournamentRoundRequest.prototype.setTournamentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string round_id = 2;
 * @return {string}
 */
proto.tournaments.EndTournamentRoundRequest.prototype.getRoundId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.EndTournamentRoundRequest} returns this
 */
proto.tournaments.EndTournamentRoundRequest.prototype.setRoundId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.EndTournamentRoundResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.EndTournamentRoundResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.EndTournamentRoundResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.EndTournamentRoundResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.EndTournamentRoundResponse}
 */
proto.tournaments.EndTournamentRoundResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.EndTournamentRoundResponse;
  return proto.tournaments.EndTournamentRoundResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.EndTournamentRoundResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.EndTournamentRoundResponse}
 */
proto.tournaments.EndTournamentRoundResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.EndTournamentRoundResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.EndTournamentRoundResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.EndTournamentRoundResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.EndTournamentRoundResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.ReopenTournamentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.ReopenTournamentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.ReopenTournamentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.ReopenTournamentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tournamentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.ReopenTournamentRequest}
 */
proto.tournaments.ReopenTournamentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.ReopenTournamentRequest;
  return proto.tournaments.ReopenTournamentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.ReopenTournamentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.ReopenTournamentRequest}
 */
proto.tournaments.ReopenTournamentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTournamentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.ReopenTournamentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.ReopenTournamentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.ReopenTournamentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.ReopenTournamentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTournamentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tournament_id = 1;
 * @return {string}
 */
proto.tournaments.ReopenTournamentRequest.prototype.getTournamentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.ReopenTournamentRequest} returns this
 */
proto.tournaments.ReopenTournamentRequest.prototype.setTournamentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.ReopenTournamentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.ReopenTournamentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.ReopenTournamentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.ReopenTournamentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.ReopenTournamentResponse}
 */
proto.tournaments.ReopenTournamentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.ReopenTournamentResponse;
  return proto.tournaments.ReopenTournamentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.ReopenTournamentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.ReopenTournamentResponse}
 */
proto.tournaments.ReopenTournamentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.ReopenTournamentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.ReopenTournamentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.ReopenTournamentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.ReopenTournamentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.InternalGetTournamentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.InternalGetTournamentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.InternalGetTournamentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.InternalGetTournamentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.InternalGetTournamentRequest}
 */
proto.tournaments.InternalGetTournamentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.InternalGetTournamentRequest;
  return proto.tournaments.InternalGetTournamentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.InternalGetTournamentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.InternalGetTournamentRequest}
 */
proto.tournaments.InternalGetTournamentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.InternalGetTournamentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.InternalGetTournamentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.InternalGetTournamentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.InternalGetTournamentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.tournaments.InternalGetTournamentRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.InternalGetTournamentRequest} returns this
 */
proto.tournaments.InternalGetTournamentRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tournaments.InternalGetTournamentResponse.repeatedFields_ = [6,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tournaments.InternalGetTournamentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.tournaments.InternalGetTournamentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tournaments.InternalGetTournamentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.InternalGetTournamentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    leaderboardsList: jspb.Message.toObjectList(msg.getLeaderboardsList(),
    proto.tournaments.LeaderboardRecord.toObject, includeInstance),
    mode: jspb.Message.getFieldWithDefault(msg, 7, 0),
    gating: (f = msg.getGating()) && proto.tournaments.Gating.toObject(includeInstance, f),
    roundsList: jspb.Message.toObjectList(msg.getRoundsList(),
    proto.tournaments.Round.toObject, includeInstance),
    configuration: (f = msg.getConfiguration()) && proto.tournaments.TournamentConfiguration.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 11, 0),
    source: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tournaments.InternalGetTournamentResponse}
 */
proto.tournaments.InternalGetTournamentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tournaments.InternalGetTournamentResponse;
  return proto.tournaments.InternalGetTournamentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tournaments.InternalGetTournamentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tournaments.InternalGetTournamentResponse}
 */
proto.tournaments.InternalGetTournamentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = new proto.tournaments.LeaderboardRecord;
      reader.readMessage(value,proto.tournaments.LeaderboardRecord.deserializeBinaryFromReader);
      msg.addLeaderboards(value);
      break;
    case 7:
      var value = /** @type {!proto.tournaments.TournamentMode} */ (reader.readEnum());
      msg.setMode(value);
      break;
    case 8:
      var value = new proto.tournaments.Gating;
      reader.readMessage(value,proto.tournaments.Gating.deserializeBinaryFromReader);
      msg.setGating(value);
      break;
    case 9:
      var value = new proto.tournaments.Round;
      reader.readMessage(value,proto.tournaments.Round.deserializeBinaryFromReader);
      msg.addRounds(value);
      break;
    case 10:
      var value = new proto.tournaments.TournamentConfiguration;
      reader.readMessage(value,proto.tournaments.TournamentConfiguration.deserializeBinaryFromReader);
      msg.setConfiguration(value);
      break;
    case 11:
      var value = /** @type {!proto.tournaments.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tournaments.InternalGetTournamentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tournaments.InternalGetTournamentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tournaments.InternalGetTournamentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tournaments.InternalGetTournamentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEndAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLeaderboardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.tournaments.LeaderboardRecord.serializeBinaryToWriter
    );
  }
  f = message.getMode();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getGating();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.tournaments.Gating.serializeBinaryToWriter
    );
  }
  f = message.getRoundsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.tournaments.Round.serializeBinaryToWriter
    );
  }
  f = message.getConfiguration();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.tournaments.TournamentConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.tournaments.InternalGetTournamentResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.InternalGetTournamentResponse} returns this
 */
proto.tournaments.InternalGetTournamentResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 start_at = 2;
 * @return {number}
 */
proto.tournaments.InternalGetTournamentResponse.prototype.getStartAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.InternalGetTournamentResponse} returns this
 */
proto.tournaments.InternalGetTournamentResponse.prototype.setStartAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 end_at = 3;
 * @return {number}
 */
proto.tournaments.InternalGetTournamentResponse.prototype.getEndAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.tournaments.InternalGetTournamentResponse} returns this
 */
proto.tournaments.InternalGetTournamentResponse.prototype.setEndAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.tournaments.InternalGetTournamentResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.InternalGetTournamentResponse} returns this
 */
proto.tournaments.InternalGetTournamentResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.tournaments.InternalGetTournamentResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.InternalGetTournamentResponse} returns this
 */
proto.tournaments.InternalGetTournamentResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated LeaderboardRecord leaderboards = 6;
 * @return {!Array<!proto.tournaments.LeaderboardRecord>}
 */
proto.tournaments.InternalGetTournamentResponse.prototype.getLeaderboardsList = function() {
  return /** @type{!Array<!proto.tournaments.LeaderboardRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tournaments.LeaderboardRecord, 6));
};


/**
 * @param {!Array<!proto.tournaments.LeaderboardRecord>} value
 * @return {!proto.tournaments.InternalGetTournamentResponse} returns this
*/
proto.tournaments.InternalGetTournamentResponse.prototype.setLeaderboardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.tournaments.LeaderboardRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tournaments.LeaderboardRecord}
 */
proto.tournaments.InternalGetTournamentResponse.prototype.addLeaderboards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.tournaments.LeaderboardRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tournaments.InternalGetTournamentResponse} returns this
 */
proto.tournaments.InternalGetTournamentResponse.prototype.clearLeaderboardsList = function() {
  return this.setLeaderboardsList([]);
};


/**
 * optional TournamentMode mode = 7;
 * @return {!proto.tournaments.TournamentMode}
 */
proto.tournaments.InternalGetTournamentResponse.prototype.getMode = function() {
  return /** @type {!proto.tournaments.TournamentMode} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.tournaments.TournamentMode} value
 * @return {!proto.tournaments.InternalGetTournamentResponse} returns this
 */
proto.tournaments.InternalGetTournamentResponse.prototype.setMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional Gating gating = 8;
 * @return {?proto.tournaments.Gating}
 */
proto.tournaments.InternalGetTournamentResponse.prototype.getGating = function() {
  return /** @type{?proto.tournaments.Gating} */ (
    jspb.Message.getWrapperField(this, proto.tournaments.Gating, 8));
};


/**
 * @param {?proto.tournaments.Gating|undefined} value
 * @return {!proto.tournaments.InternalGetTournamentResponse} returns this
*/
proto.tournaments.InternalGetTournamentResponse.prototype.setGating = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tournaments.InternalGetTournamentResponse} returns this
 */
proto.tournaments.InternalGetTournamentResponse.prototype.clearGating = function() {
  return this.setGating(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tournaments.InternalGetTournamentResponse.prototype.hasGating = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated Round rounds = 9;
 * @return {!Array<!proto.tournaments.Round>}
 */
proto.tournaments.InternalGetTournamentResponse.prototype.getRoundsList = function() {
  return /** @type{!Array<!proto.tournaments.Round>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tournaments.Round, 9));
};


/**
 * @param {!Array<!proto.tournaments.Round>} value
 * @return {!proto.tournaments.InternalGetTournamentResponse} returns this
*/
proto.tournaments.InternalGetTournamentResponse.prototype.setRoundsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.tournaments.Round=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tournaments.Round}
 */
proto.tournaments.InternalGetTournamentResponse.prototype.addRounds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.tournaments.Round, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tournaments.InternalGetTournamentResponse} returns this
 */
proto.tournaments.InternalGetTournamentResponse.prototype.clearRoundsList = function() {
  return this.setRoundsList([]);
};


/**
 * optional TournamentConfiguration configuration = 10;
 * @return {?proto.tournaments.TournamentConfiguration}
 */
proto.tournaments.InternalGetTournamentResponse.prototype.getConfiguration = function() {
  return /** @type{?proto.tournaments.TournamentConfiguration} */ (
    jspb.Message.getWrapperField(this, proto.tournaments.TournamentConfiguration, 10));
};


/**
 * @param {?proto.tournaments.TournamentConfiguration|undefined} value
 * @return {!proto.tournaments.InternalGetTournamentResponse} returns this
*/
proto.tournaments.InternalGetTournamentResponse.prototype.setConfiguration = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tournaments.InternalGetTournamentResponse} returns this
 */
proto.tournaments.InternalGetTournamentResponse.prototype.clearConfiguration = function() {
  return this.setConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tournaments.InternalGetTournamentResponse.prototype.hasConfiguration = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Status status = 11;
 * @return {!proto.tournaments.Status}
 */
proto.tournaments.InternalGetTournamentResponse.prototype.getStatus = function() {
  return /** @type {!proto.tournaments.Status} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.tournaments.Status} value
 * @return {!proto.tournaments.InternalGetTournamentResponse} returns this
 */
proto.tournaments.InternalGetTournamentResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional string source = 12;
 * @return {string}
 */
proto.tournaments.InternalGetTournamentResponse.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.tournaments.InternalGetTournamentResponse} returns this
 */
proto.tournaments.InternalGetTournamentResponse.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * @enum {number}
 */
proto.tournaments.TournamentsJobType = {
  UNKNOWN: 0,
  REBUILD_TOURNAMENT_LEADERBOARD: 1,
  END_TOURNAMENT_ROUND: 2
};

/**
 * @enum {number}
 */
proto.tournaments.TournamentScoringType = {
  UNKNOWN_TOURNAMENT_SCORE_TYPE: 0,
  POOL_TOURNAMENT_SCORE_TYPE: 1,
  HIGHEST_TOURNAMENT_SCORE_TYPE: 2,
  INCR_TOURNAMENT_SCORE_TYPE: 3
};

/**
 * @enum {number}
 */
proto.tournaments.RoundScoringType = {
  UNKNOWN_ROUND_SCORING_TYPE: 0,
  HIGHEST_ROUND_SCORING_TYPE: 1,
  LOWEST_ROUND_SCORING_TYPE: 2,
  INCR_ROUND_SCORING_TYPE: 3,
  DECR_ROUND_SCORING_TYPE: 4
};

/**
 * @enum {number}
 */
proto.tournaments.Status = {
  UNKNOWN_STATUS: 0,
  UPCOMING_STATUS: 1,
  PENDING_STATUS: 2,
  ACTIVE_STATUS: 3,
  ENDED_STATUS: 4,
  LOCKED_STATUS: 5
};

/**
 * @enum {number}
 */
proto.tournaments.LeaderboardRecordType = {
  UNKNOWN_LEADERBOARD_TYPE: 0,
  GLOBAL: 1,
  PRIZE: 2,
  ROUND: 3
};

/**
 * @enum {number}
 */
proto.tournaments.TournamentMode = {
  UNKNOWN_TOURNAMENT_MODE: 0,
  ASYNC: 1,
  SYNC: 2
};

/**
 * @enum {number}
 */
proto.tournaments.GatingType = {
  UNKNOWN_GATING_TYPE: 0,
  OPEN: 1,
  ALLOWLIST: 2,
  NFT: 3,
  COMMUNITY: 4
};

/**
 * @enum {number}
 */
proto.tournaments.TournamentEventTypes = {
  UNKNOWN_TOURNAMENT_EVENT_TYPE: 0,
  TOURNAMENT_CONFIG_UPDATE: 1,
  TOURNAMENT_SCORES_UPDATE: 2
};

goog.object.extend(exports, proto.tournaments);
