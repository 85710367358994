// source: src/modules/access_management/access_management.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var validate_validate_pb = require('../../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var google_api_annotations_pb = require('../../../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var src_modules_team_selection_events_pb = require('../../../src/modules/team_selection/events_pb.js');
goog.object.extend(proto, src_modules_team_selection_events_pb);
var src_modules_tournaments_tournaments_pb = require('../../../src/modules/tournaments/tournaments_pb.js');
goog.object.extend(proto, src_modules_tournaments_tournaments_pb);
goog.exportSymbol('proto.access_management.AccessManagementJobType', null, global);
goog.exportSymbol('proto.access_management.AddCommunityAdminRequest', null, global);
goog.exportSymbol('proto.access_management.AddCommunityAdminResponse', null, global);
goog.exportSymbol('proto.access_management.AddUserToCommunityRequest', null, global);
goog.exportSymbol('proto.access_management.AddUserToCommunityResponse', null, global);
goog.exportSymbol('proto.access_management.BillingInfo', null, global);
goog.exportSymbol('proto.access_management.BillingPortalSession', null, global);
goog.exportSymbol('proto.access_management.BillingSession', null, global);
goog.exportSymbol('proto.access_management.BillingSession.BillingSessionCase', null, global);
goog.exportSymbol('proto.access_management.BillingSubscriptionCheckoutSession', null, global);
goog.exportSymbol('proto.access_management.CheckCommunityMAUsRequest', null, global);
goog.exportSymbol('proto.access_management.CheckCommunityMAUsResponse', null, global);
goog.exportSymbol('proto.access_management.CheckIfUsernameIsAvailableRequest', null, global);
goog.exportSymbol('proto.access_management.CheckIfUsernameIsAvailableResponse', null, global);
goog.exportSymbol('proto.access_management.ClientRoomEvent', null, global);
goog.exportSymbol('proto.access_management.ClientRoomEvent.EventCase', null, global);
goog.exportSymbol('proto.access_management.Community', null, global);
goog.exportSymbol('proto.access_management.CommunityCancelInviteRequest', null, global);
goog.exportSymbol('proto.access_management.CommunityCancelInviteResponse', null, global);
goog.exportSymbol('proto.access_management.CommunityGate', null, global);
goog.exportSymbol('proto.access_management.CommunityGate.GateCase', null, global);
goog.exportSymbol('proto.access_management.CommunityGating', null, global);
goog.exportSymbol('proto.access_management.CommunityMetricUpdateEvent', null, global);
goog.exportSymbol('proto.access_management.CommunityRoomConfigurationUpdateRequest', null, global);
goog.exportSymbol('proto.access_management.CommunityRoomConfigurationUpdateResponse', null, global);
goog.exportSymbol('proto.access_management.CommunityRoomStatusUpdateRequest', null, global);
goog.exportSymbol('proto.access_management.CommunityRoomStatusUpdateResponse', null, global);
goog.exportSymbol('proto.access_management.CommunitySendInviteRequest', null, global);
goog.exportSymbol('proto.access_management.CommunitySendInviteResponse', null, global);
goog.exportSymbol('proto.access_management.CommunityStatus', null, global);
goog.exportSymbol('proto.access_management.CommunitySubscriptionEvent', null, global);
goog.exportSymbol('proto.access_management.CommunitySubscriptionEventType', null, global);
goog.exportSymbol('proto.access_management.CommunityType', null, global);
goog.exportSymbol('proto.access_management.CommunityUser', null, global);
goog.exportSymbol('proto.access_management.ConfigUpdateEvent', null, global);
goog.exportSymbol('proto.access_management.ConnectUserRequest', null, global);
goog.exportSymbol('proto.access_management.Connected', null, global);
goog.exportSymbol('proto.access_management.ConnectedUserEvent', null, global);
goog.exportSymbol('proto.access_management.ConnectedUserEvent.EventCase', null, global);
goog.exportSymbol('proto.access_management.CreateCommunityRequest', null, global);
goog.exportSymbol('proto.access_management.CreateCommunityResponse', null, global);
goog.exportSymbol('proto.access_management.CreateCommunityRoomRequest', null, global);
goog.exportSymbol('proto.access_management.CreateCommunityRoomResponse', null, global);
goog.exportSymbol('proto.access_management.CreateGamenightTimestampRequest', null, global);
goog.exportSymbol('proto.access_management.CreateGamenightTimestampResponse', null, global);
goog.exportSymbol('proto.access_management.CreateUserRequest', null, global);
goog.exportSymbol('proto.access_management.CreateUserResponse', null, global);
goog.exportSymbol('proto.access_management.CustomerType', null, global);
goog.exportSymbol('proto.access_management.DeleteCommunityRequest', null, global);
goog.exportSymbol('proto.access_management.DeleteCommunityResponse', null, global);
goog.exportSymbol('proto.access_management.DeleteCommunityRoomRequest', null, global);
goog.exportSymbol('proto.access_management.DeleteCommunityRoomResponse', null, global);
goog.exportSymbol('proto.access_management.DeleteUserRequest', null, global);
goog.exportSymbol('proto.access_management.DeleteUserResponse', null, global);
goog.exportSymbol('proto.access_management.DomainGate', null, global);
goog.exportSymbol('proto.access_management.DoneDownloadingGameRequest', null, global);
goog.exportSymbol('proto.access_management.DoneDownloadingGameResponse', null, global);
goog.exportSymbol('proto.access_management.EndStreamEvent', null, global);
goog.exportSymbol('proto.access_management.FailedMatchConnectionEvent', null, global);
goog.exportSymbol('proto.access_management.Frequency', null, global);
goog.exportSymbol('proto.access_management.GameFailedToStartEvent', null, global);
goog.exportSymbol('proto.access_management.GameInProgressEvent', null, global);
goog.exportSymbol('proto.access_management.GameNight', null, global);
goog.exportSymbol('proto.access_management.GamePendingEvent', null, global);
goog.exportSymbol('proto.access_management.GameStartedEvent', null, global);
goog.exportSymbol('proto.access_management.GetBillingInfoRequest', null, global);
goog.exportSymbol('proto.access_management.GetBillingInfoResponse', null, global);
goog.exportSymbol('proto.access_management.GetCommunitiesRequest', null, global);
goog.exportSymbol('proto.access_management.GetCommunitiesResponse', null, global);
goog.exportSymbol('proto.access_management.GetCommunityAvailablePlansRequest', null, global);
goog.exportSymbol('proto.access_management.GetCommunityAvailablePlansResponse', null, global);
goog.exportSymbol('proto.access_management.GetCommunityByIdRequest', null, global);
goog.exportSymbol('proto.access_management.GetCommunityBySlugRequest', null, global);
goog.exportSymbol('proto.access_management.GetCommunityMetricsRequest', null, global);
goog.exportSymbol('proto.access_management.GetCommunityMetricsResponse', null, global);
goog.exportSymbol('proto.access_management.GetCommunityProfileBySlugRequest', null, global);
goog.exportSymbol('proto.access_management.GetCommunityProfileBySlugResponse', null, global);
goog.exportSymbol('proto.access_management.GetCommunityResponse', null, global);
goog.exportSymbol('proto.access_management.GetCommunityRoomByIdRequest', null, global);
goog.exportSymbol('proto.access_management.GetCommunityRoomBySlugRequest', null, global);
goog.exportSymbol('proto.access_management.GetCommunityRoomResponse', null, global);
goog.exportSymbol('proto.access_management.GetCommunityRoomUsersDownloadingRequest', null, global);
goog.exportSymbol('proto.access_management.GetCommunityRoomUsersDownloadingResponse', null, global);
goog.exportSymbol('proto.access_management.GetCommunityRoomUsersNotDownloadingRequest', null, global);
goog.exportSymbol('proto.access_management.GetCommunityRoomUsersNotDownloadingResponse', null, global);
goog.exportSymbol('proto.access_management.GetCommunityRoomUsersRequest', null, global);
goog.exportSymbol('proto.access_management.GetCommunityRoomUsersResponse', null, global);
goog.exportSymbol('proto.access_management.GetInitialRoomStateBySlugRequest', null, global);
goog.exportSymbol('proto.access_management.GetInitialRoomStateRequest', null, global);
goog.exportSymbol('proto.access_management.GetInitialRoomStateResponse', null, global);
goog.exportSymbol('proto.access_management.GetProSubscriptionPlanRequest', null, global);
goog.exportSymbol('proto.access_management.GetProSubscriptionPlanResponse', null, global);
goog.exportSymbol('proto.access_management.GetSubscriptionPlansRequest', null, global);
goog.exportSymbol('proto.access_management.GetSubscriptionPlansResponse', null, global);
goog.exportSymbol('proto.access_management.GetUserByExternalAuthIdRequest', null, global);
goog.exportSymbol('proto.access_management.GetUserByIdRequest', null, global);
goog.exportSymbol('proto.access_management.GetUserResponse', null, global);
goog.exportSymbol('proto.access_management.GetUserStatesByIdRequest', null, global);
goog.exportSymbol('proto.access_management.GetUserStatesByIdResponse', null, global);
goog.exportSymbol('proto.access_management.GetUsersByIdRequest', null, global);
goog.exportSymbol('proto.access_management.GetUsersByIdResponse', null, global);
goog.exportSymbol('proto.access_management.HostEndMatchEvent', null, global);
goog.exportSymbol('proto.access_management.Identifier', null, global);
goog.exportSymbol('proto.access_management.InternalDisconnectionCleanupRequest', null, global);
goog.exportSymbol('proto.access_management.InternalDisconnectionCleanupResponse', null, global);
goog.exportSymbol('proto.access_management.InternalDoneDownloadingGameRequest', null, global);
goog.exportSymbol('proto.access_management.InternalDoneDownloadingGameResponse', null, global);
goog.exportSymbol('proto.access_management.InternalGetGameDownloadingCancelTimeRequest', null, global);
goog.exportSymbol('proto.access_management.InternalGetGameDownloadingCancelTimeResponse', null, global);
goog.exportSymbol('proto.access_management.InternalGetIpsRequest', null, global);
goog.exportSymbol('proto.access_management.InternalGetIpsResponse', null, global);
goog.exportSymbol('proto.access_management.InternalReportUserStateRequest', null, global);
goog.exportSymbol('proto.access_management.InternalReportUserStateResponse', null, global);
goog.exportSymbol('proto.access_management.InternalReportUserStatesRequest', null, global);
goog.exportSymbol('proto.access_management.InternalReportUserStatesResponse', null, global);
goog.exportSymbol('proto.access_management.InternalUserState', null, global);
goog.exportSymbol('proto.access_management.InternalUserState.StateCase', null, global);
goog.exportSymbol('proto.access_management.InviteGate', null, global);
goog.exportSymbol('proto.access_management.InvitedCommunityUser', null, global);
goog.exportSymbol('proto.access_management.JoinCommunityRequest', null, global);
goog.exportSymbol('proto.access_management.JoinCommunityResponse', null, global);
goog.exportSymbol('proto.access_management.JoinedMatch', null, global);
goog.exportSymbol('proto.access_management.JoinedRoom', null, global);
goog.exportSymbol('proto.access_management.KeepAlive', null, global);
goog.exportSymbol('proto.access_management.LeaveCommunityRequest', null, global);
goog.exportSymbol('proto.access_management.LeaveCommunityResponse', null, global);
goog.exportSymbol('proto.access_management.LeftMatch', null, global);
goog.exportSymbol('proto.access_management.LeftRoom', null, global);
goog.exportSymbol('proto.access_management.ListCommunityRoomsFromSlugRequest', null, global);
goog.exportSymbol('proto.access_management.ListCommunityRoomsRequest', null, global);
goog.exportSymbol('proto.access_management.ListCommunityRoomsResponse', null, global);
goog.exportSymbol('proto.access_management.ListCommunityUsersRequest', null, global);
goog.exportSymbol('proto.access_management.ListCommunityUsersResponse', null, global);
goog.exportSymbol('proto.access_management.ListUserCommunitiesRequest', null, global);
goog.exportSymbol('proto.access_management.ListUserCommunitiesResponse', null, global);
goog.exportSymbol('proto.access_management.MAUInfo', null, global);
goog.exportSymbol('proto.access_management.MatchDistributionEvent', null, global);
goog.exportSymbol('proto.access_management.MatchEndedEvent', null, global);
goog.exportSymbol('proto.access_management.MatchGroupEndedEvent', null, global);
goog.exportSymbol('proto.access_management.Muted', null, global);
goog.exportSymbol('proto.access_management.NewHostEvent', null, global);
goog.exportSymbol('proto.access_management.OpenGate', null, global);
goog.exportSymbol('proto.access_management.PlayersDownloadingEvent', null, global);
goog.exportSymbol('proto.access_management.PublishConnectedUserEventRequest', null, global);
goog.exportSymbol('proto.access_management.PublishConnectedUserEventResponse', null, global);
goog.exportSymbol('proto.access_management.PublishRoomEventRequest', null, global);
goog.exportSymbol('proto.access_management.PublishRoomEventResponse', null, global);
goog.exportSymbol('proto.access_management.RemoveCommunityAdminRequest', null, global);
goog.exportSymbol('proto.access_management.RemoveCommunityAdminResponse', null, global);
goog.exportSymbol('proto.access_management.RemoveUserFromCommunityRequest', null, global);
goog.exportSymbol('proto.access_management.RemoveUserFromCommunityResponse', null, global);
goog.exportSymbol('proto.access_management.ReportUserStateRequest', null, global);
goog.exportSymbol('proto.access_management.ReportUserStateResponse', null, global);
goog.exportSymbol('proto.access_management.RequestBillingSessionLinkRequest', null, global);
goog.exportSymbol('proto.access_management.RequestBillingSessionLinkResponse', null, global);
goog.exportSymbol('proto.access_management.Room', null, global);
goog.exportSymbol('proto.access_management.RoomCreatedEvent', null, global);
goog.exportSymbol('proto.access_management.RoomDeletedEvent', null, global);
goog.exportSymbol('proto.access_management.RoomDisconnect', null, global);
goog.exportSymbol('proto.access_management.RoomDisplayUser', null, global);
goog.exportSymbol('proto.access_management.RoomEvent', null, global);
goog.exportSymbol('proto.access_management.RoomEvent.EventCase', null, global);
goog.exportSymbol('proto.access_management.RoomStatus', null, global);
goog.exportSymbol('proto.access_management.RoomType', null, global);
goog.exportSymbol('proto.access_management.RoomUpdatedEvent', null, global);
goog.exportSymbol('proto.access_management.RoomUser', null, global);
goog.exportSymbol('proto.access_management.SlowInternetConnection', null, global);
goog.exportSymbol('proto.access_management.StartCreateGamenightTimestampRequest', null, global);
goog.exportSymbol('proto.access_management.StartCreateGamenightTimestampResponse', null, global);
goog.exportSymbol('proto.access_management.StartOrContinueGameDownloadRequest', null, global);
goog.exportSymbol('proto.access_management.StartOrContinueGameDownloadResponse', null, global);
goog.exportSymbol('proto.access_management.StartSyncCommunityAdminsRequest', null, global);
goog.exportSymbol('proto.access_management.StartSyncCommunityAdminsResponse', null, global);
goog.exportSymbol('proto.access_management.SubscribeToRoomRequest', null, global);
goog.exportSymbol('proto.access_management.SubscriptionPlan', null, global);
goog.exportSymbol('proto.access_management.SyncCommunityAdminsRequest', null, global);
goog.exportSymbol('proto.access_management.SyncCommunityAdminsResponse', null, global);
goog.exportSymbol('proto.access_management.TournamentEvent', null, global);
goog.exportSymbol('proto.access_management.UpdateAvailablePlansRequest', null, global);
goog.exportSymbol('proto.access_management.UpdateAvailablePlansResponse', null, global);
goog.exportSymbol('proto.access_management.UpdateCommunityBillingInfoRequest', null, global);
goog.exportSymbol('proto.access_management.UpdateCommunityBillingInfoResponse', null, global);
goog.exportSymbol('proto.access_management.UpdateCommunityRequest', null, global);
goog.exportSymbol('proto.access_management.UpdateCommunityResponse', null, global);
goog.exportSymbol('proto.access_management.UpdateCommunityRoomRequest', null, global);
goog.exportSymbol('proto.access_management.UpdateCommunityRoomResponse', null, global);
goog.exportSymbol('proto.access_management.UpdateRoomHostRequest', null, global);
goog.exportSymbol('proto.access_management.UpdateRoomHostResponse', null, global);
goog.exportSymbol('proto.access_management.UpdateUserRequest', null, global);
goog.exportSymbol('proto.access_management.UpdateUserResponse', null, global);
goog.exportSymbol('proto.access_management.UserElement', null, global);
goog.exportSymbol('proto.access_management.UserJoinedEvent', null, global);
goog.exportSymbol('proto.access_management.UserJoinedRoomEvent', null, global);
goog.exportSymbol('proto.access_management.UserLeftEvent', null, global);
goog.exportSymbol('proto.access_management.UserLeftRoomEvent', null, global);
goog.exportSymbol('proto.access_management.UserState', null, global);
goog.exportSymbol('proto.access_management.UserState.StatusCase', null, global);
goog.exportSymbol('proto.access_management.UserStateMap', null, global);
goog.exportSymbol('proto.access_management.UserStateReport', null, global);
goog.exportSymbol('proto.access_management.UserStateUpdateEvent', null, global);
goog.exportSymbol('proto.access_management.VoiceChatOptOut', null, global);
goog.exportSymbol('proto.access_management.Weekday', null, global);
goog.exportSymbol('proto.access_management.WhitelistDataValue', null, global);
goog.exportSymbol('proto.access_management.WhitelistDataValue.ValueCase', null, global);
goog.exportSymbol('proto.access_management.WhitelistEntryCreateRequest', null, global);
goog.exportSymbol('proto.access_management.WhitelistEntryCreateResponse', null, global);
goog.exportSymbol('proto.access_management.WhitelistEntryGetRequest', null, global);
goog.exportSymbol('proto.access_management.WhitelistEntryGetResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.Room = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.Room.repeatedFields_, null);
};
goog.inherits(proto.access_management.Room, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.Room.displayName = 'proto.access_management.Room';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GameNight = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.GameNight, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GameNight.displayName = 'proto.access_management.GameNight';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.KeepAlive = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.KeepAlive, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.KeepAlive.displayName = 'proto.access_management.KeepAlive';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.Connected = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.Connected, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.Connected.displayName = 'proto.access_management.Connected';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.WhitelistDataValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.access_management.WhitelistDataValue.oneofGroups_);
};
goog.inherits(proto.access_management.WhitelistDataValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.WhitelistDataValue.displayName = 'proto.access_management.WhitelistDataValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.SubscriptionPlan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.SubscriptionPlan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.SubscriptionPlan.displayName = 'proto.access_management.SubscriptionPlan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.CreateUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.CreateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.CreateUserRequest.displayName = 'proto.access_management.CreateUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.CreateUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.CreateUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.CreateUserResponse.displayName = 'proto.access_management.CreateUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.CheckIfUsernameIsAvailableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.CheckIfUsernameIsAvailableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.CheckIfUsernameIsAvailableRequest.displayName = 'proto.access_management.CheckIfUsernameIsAvailableRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.CheckIfUsernameIsAvailableResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.CheckIfUsernameIsAvailableResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.CheckIfUsernameIsAvailableResponse.displayName = 'proto.access_management.CheckIfUsernameIsAvailableResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetUserByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.GetUserByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetUserByIdRequest.displayName = 'proto.access_management.GetUserByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetUserByExternalAuthIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.GetUserByExternalAuthIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetUserByExternalAuthIdRequest.displayName = 'proto.access_management.GetUserByExternalAuthIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.GetUserResponse.repeatedFields_, null);
};
goog.inherits(proto.access_management.GetUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetUserResponse.displayName = 'proto.access_management.GetUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetUsersByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.GetUsersByIdRequest.repeatedFields_, null);
};
goog.inherits(proto.access_management.GetUsersByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetUsersByIdRequest.displayName = 'proto.access_management.GetUsersByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.UserElement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.UserElement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.UserElement.displayName = 'proto.access_management.UserElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetUsersByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.GetUsersByIdResponse.repeatedFields_, null);
};
goog.inherits(proto.access_management.GetUsersByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetUsersByIdResponse.displayName = 'proto.access_management.GetUsersByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.AddUserToCommunityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.AddUserToCommunityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.AddUserToCommunityRequest.displayName = 'proto.access_management.AddUserToCommunityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.AddUserToCommunityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.AddUserToCommunityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.AddUserToCommunityResponse.displayName = 'proto.access_management.AddUserToCommunityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.RemoveUserFromCommunityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.RemoveUserFromCommunityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.RemoveUserFromCommunityRequest.displayName = 'proto.access_management.RemoveUserFromCommunityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.RemoveUserFromCommunityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.RemoveUserFromCommunityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.RemoveUserFromCommunityResponse.displayName = 'proto.access_management.RemoveUserFromCommunityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.UpdateUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.UpdateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.UpdateUserRequest.displayName = 'proto.access_management.UpdateUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.UpdateUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.UpdateUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.UpdateUserResponse.displayName = 'proto.access_management.UpdateUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.DeleteUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.DeleteUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.DeleteUserRequest.displayName = 'proto.access_management.DeleteUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.DeleteUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.DeleteUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.DeleteUserResponse.displayName = 'proto.access_management.DeleteUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.ListUserCommunitiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.ListUserCommunitiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.ListUserCommunitiesRequest.displayName = 'proto.access_management.ListUserCommunitiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.Community = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.Community, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.Community.displayName = 'proto.access_management.Community';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.ListUserCommunitiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.ListUserCommunitiesResponse.repeatedFields_, null);
};
goog.inherits(proto.access_management.ListUserCommunitiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.ListUserCommunitiesResponse.displayName = 'proto.access_management.ListUserCommunitiesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.JoinCommunityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.JoinCommunityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.JoinCommunityRequest.displayName = 'proto.access_management.JoinCommunityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.JoinCommunityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.JoinCommunityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.JoinCommunityResponse.displayName = 'proto.access_management.JoinCommunityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.LeaveCommunityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.LeaveCommunityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.LeaveCommunityRequest.displayName = 'proto.access_management.LeaveCommunityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.LeaveCommunityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.LeaveCommunityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.LeaveCommunityResponse.displayName = 'proto.access_management.LeaveCommunityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.UserState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.access_management.UserState.oneofGroups_);
};
goog.inherits(proto.access_management.UserState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.UserState.displayName = 'proto.access_management.UserState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.SlowInternetConnection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.SlowInternetConnection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.SlowInternetConnection.displayName = 'proto.access_management.SlowInternetConnection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.Muted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.Muted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.Muted.displayName = 'proto.access_management.Muted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.VoiceChatOptOut = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.VoiceChatOptOut, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.VoiceChatOptOut.displayName = 'proto.access_management.VoiceChatOptOut';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.ReportUserStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.ReportUserStateRequest.repeatedFields_, null);
};
goog.inherits(proto.access_management.ReportUserStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.ReportUserStateRequest.displayName = 'proto.access_management.ReportUserStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.ReportUserStateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.ReportUserStateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.ReportUserStateResponse.displayName = 'proto.access_management.ReportUserStateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.InternalUserState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.access_management.InternalUserState.oneofGroups_);
};
goog.inherits(proto.access_management.InternalUserState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.InternalUserState.displayName = 'proto.access_management.InternalUserState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.JoinedMatch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.JoinedMatch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.JoinedMatch.displayName = 'proto.access_management.JoinedMatch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.LeftMatch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.LeftMatch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.LeftMatch.displayName = 'proto.access_management.LeftMatch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.JoinedRoom = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.JoinedRoom, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.JoinedRoom.displayName = 'proto.access_management.JoinedRoom';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.LeftRoom = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.LeftRoom, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.LeftRoom.displayName = 'proto.access_management.LeftRoom';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.UserStateReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.UserStateReport.repeatedFields_, null);
};
goog.inherits(proto.access_management.UserStateReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.UserStateReport.displayName = 'proto.access_management.UserStateReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.InternalReportUserStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.InternalReportUserStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.InternalReportUserStateRequest.displayName = 'proto.access_management.InternalReportUserStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.InternalReportUserStateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.InternalReportUserStateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.InternalReportUserStateResponse.displayName = 'proto.access_management.InternalReportUserStateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.InternalReportUserStatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.InternalReportUserStatesRequest.repeatedFields_, null);
};
goog.inherits(proto.access_management.InternalReportUserStatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.InternalReportUserStatesRequest.displayName = 'proto.access_management.InternalReportUserStatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.InternalReportUserStatesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.InternalReportUserStatesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.InternalReportUserStatesResponse.displayName = 'proto.access_management.InternalReportUserStatesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.InternalGetIpsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.InternalGetIpsRequest.repeatedFields_, null);
};
goog.inherits(proto.access_management.InternalGetIpsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.InternalGetIpsRequest.displayName = 'proto.access_management.InternalGetIpsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.InternalGetIpsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.InternalGetIpsResponse.repeatedFields_, null);
};
goog.inherits(proto.access_management.InternalGetIpsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.InternalGetIpsResponse.displayName = 'proto.access_management.InternalGetIpsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetUserStatesByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.GetUserStatesByIdRequest.repeatedFields_, null);
};
goog.inherits(proto.access_management.GetUserStatesByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetUserStatesByIdRequest.displayName = 'proto.access_management.GetUserStatesByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.UserStateMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.UserStateMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.UserStateMap.displayName = 'proto.access_management.UserStateMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetUserStatesByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.GetUserStatesByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetUserStatesByIdResponse.displayName = 'proto.access_management.GetUserStatesByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.DomainGate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.DomainGate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.DomainGate.displayName = 'proto.access_management.DomainGate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.InviteGate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.InviteGate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.InviteGate.displayName = 'proto.access_management.InviteGate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.OpenGate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.OpenGate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.OpenGate.displayName = 'proto.access_management.OpenGate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.CommunityGate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.access_management.CommunityGate.oneofGroups_);
};
goog.inherits(proto.access_management.CommunityGate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.CommunityGate.displayName = 'proto.access_management.CommunityGate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.CreateCommunityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.CreateCommunityRequest.repeatedFields_, null);
};
goog.inherits(proto.access_management.CreateCommunityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.CreateCommunityRequest.displayName = 'proto.access_management.CreateCommunityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.CreateCommunityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.CreateCommunityResponse.repeatedFields_, null);
};
goog.inherits(proto.access_management.CreateCommunityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.CreateCommunityResponse.displayName = 'proto.access_management.CreateCommunityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetCommunityByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.GetCommunityByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetCommunityByIdRequest.displayName = 'proto.access_management.GetCommunityByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetCommunityBySlugRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.GetCommunityBySlugRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetCommunityBySlugRequest.displayName = 'proto.access_management.GetCommunityBySlugRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetCommunityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.GetCommunityResponse.repeatedFields_, null);
};
goog.inherits(proto.access_management.GetCommunityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetCommunityResponse.displayName = 'proto.access_management.GetCommunityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetCommunityProfileBySlugRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.GetCommunityProfileBySlugRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetCommunityProfileBySlugRequest.displayName = 'proto.access_management.GetCommunityProfileBySlugRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetCommunityProfileBySlugResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.GetCommunityProfileBySlugResponse.repeatedFields_, null);
};
goog.inherits(proto.access_management.GetCommunityProfileBySlugResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetCommunityProfileBySlugResponse.displayName = 'proto.access_management.GetCommunityProfileBySlugResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.UpdateCommunityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.UpdateCommunityRequest.repeatedFields_, null);
};
goog.inherits(proto.access_management.UpdateCommunityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.UpdateCommunityRequest.displayName = 'proto.access_management.UpdateCommunityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.UpdateCommunityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.UpdateCommunityResponse.repeatedFields_, null);
};
goog.inherits(proto.access_management.UpdateCommunityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.UpdateCommunityResponse.displayName = 'proto.access_management.UpdateCommunityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.AddCommunityAdminRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.AddCommunityAdminRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.AddCommunityAdminRequest.displayName = 'proto.access_management.AddCommunityAdminRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.AddCommunityAdminResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.AddCommunityAdminResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.AddCommunityAdminResponse.displayName = 'proto.access_management.AddCommunityAdminResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.RemoveCommunityAdminRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.RemoveCommunityAdminRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.RemoveCommunityAdminRequest.displayName = 'proto.access_management.RemoveCommunityAdminRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.RemoveCommunityAdminResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.RemoveCommunityAdminResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.RemoveCommunityAdminResponse.displayName = 'proto.access_management.RemoveCommunityAdminResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.UpdateCommunityBillingInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.UpdateCommunityBillingInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.UpdateCommunityBillingInfoRequest.displayName = 'proto.access_management.UpdateCommunityBillingInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.UpdateCommunityBillingInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.UpdateCommunityBillingInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.UpdateCommunityBillingInfoResponse.displayName = 'proto.access_management.UpdateCommunityBillingInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.DeleteCommunityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.DeleteCommunityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.DeleteCommunityRequest.displayName = 'proto.access_management.DeleteCommunityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.DeleteCommunityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.DeleteCommunityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.DeleteCommunityResponse.displayName = 'proto.access_management.DeleteCommunityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetCommunityMetricsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.GetCommunityMetricsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetCommunityMetricsRequest.displayName = 'proto.access_management.GetCommunityMetricsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetCommunityMetricsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.GetCommunityMetricsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetCommunityMetricsResponse.displayName = 'proto.access_management.GetCommunityMetricsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.ListCommunityUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.ListCommunityUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.ListCommunityUsersRequest.displayName = 'proto.access_management.ListCommunityUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.CommunityUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.CommunityUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.CommunityUser.displayName = 'proto.access_management.CommunityUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.InvitedCommunityUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.InvitedCommunityUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.InvitedCommunityUser.displayName = 'proto.access_management.InvitedCommunityUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.ListCommunityUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.ListCommunityUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.access_management.ListCommunityUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.ListCommunityUsersResponse.displayName = 'proto.access_management.ListCommunityUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.CommunitySendInviteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.CommunitySendInviteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.CommunitySendInviteRequest.displayName = 'proto.access_management.CommunitySendInviteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.CommunitySendInviteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.CommunitySendInviteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.CommunitySendInviteResponse.displayName = 'proto.access_management.CommunitySendInviteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.CommunityCancelInviteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.CommunityCancelInviteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.CommunityCancelInviteRequest.displayName = 'proto.access_management.CommunityCancelInviteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.CommunityCancelInviteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.CommunityCancelInviteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.CommunityCancelInviteResponse.displayName = 'proto.access_management.CommunityCancelInviteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.CreateCommunityRoomRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.CreateCommunityRoomRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.CreateCommunityRoomRequest.displayName = 'proto.access_management.CreateCommunityRoomRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.CreateCommunityRoomResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.CreateCommunityRoomResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.CreateCommunityRoomResponse.displayName = 'proto.access_management.CreateCommunityRoomResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetCommunityRoomByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.GetCommunityRoomByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetCommunityRoomByIdRequest.displayName = 'proto.access_management.GetCommunityRoomByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetCommunityRoomBySlugRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.GetCommunityRoomBySlugRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetCommunityRoomBySlugRequest.displayName = 'proto.access_management.GetCommunityRoomBySlugRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetCommunityRoomResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.GetCommunityRoomResponse.repeatedFields_, null);
};
goog.inherits(proto.access_management.GetCommunityRoomResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetCommunityRoomResponse.displayName = 'proto.access_management.GetCommunityRoomResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.UpdateCommunityRoomRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.UpdateCommunityRoomRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.UpdateCommunityRoomRequest.displayName = 'proto.access_management.UpdateCommunityRoomRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.UpdateCommunityRoomResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.UpdateCommunityRoomResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.UpdateCommunityRoomResponse.displayName = 'proto.access_management.UpdateCommunityRoomResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.CommunityRoomConfigurationUpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.CommunityRoomConfigurationUpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.CommunityRoomConfigurationUpdateRequest.displayName = 'proto.access_management.CommunityRoomConfigurationUpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.CommunityRoomConfigurationUpdateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.CommunityRoomConfigurationUpdateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.CommunityRoomConfigurationUpdateResponse.displayName = 'proto.access_management.CommunityRoomConfigurationUpdateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.DeleteCommunityRoomRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.DeleteCommunityRoomRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.DeleteCommunityRoomRequest.displayName = 'proto.access_management.DeleteCommunityRoomRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.DeleteCommunityRoomResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.DeleteCommunityRoomResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.DeleteCommunityRoomResponse.displayName = 'proto.access_management.DeleteCommunityRoomResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.ListCommunityRoomsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.ListCommunityRoomsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.ListCommunityRoomsRequest.displayName = 'proto.access_management.ListCommunityRoomsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.ListCommunityRoomsFromSlugRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.ListCommunityRoomsFromSlugRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.ListCommunityRoomsFromSlugRequest.displayName = 'proto.access_management.ListCommunityRoomsFromSlugRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.RoomDisplayUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.RoomDisplayUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.RoomDisplayUser.displayName = 'proto.access_management.RoomDisplayUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.ListCommunityRoomsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.ListCommunityRoomsResponse.repeatedFields_, null);
};
goog.inherits(proto.access_management.ListCommunityRoomsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.ListCommunityRoomsResponse.displayName = 'proto.access_management.ListCommunityRoomsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetInitialRoomStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.GetInitialRoomStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetInitialRoomStateRequest.displayName = 'proto.access_management.GetInitialRoomStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetInitialRoomStateBySlugRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.GetInitialRoomStateBySlugRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetInitialRoomStateBySlugRequest.displayName = 'proto.access_management.GetInitialRoomStateBySlugRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.RoomUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.RoomUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.RoomUser.displayName = 'proto.access_management.RoomUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetInitialRoomStateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.GetInitialRoomStateResponse.repeatedFields_, null);
};
goog.inherits(proto.access_management.GetInitialRoomStateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetInitialRoomStateResponse.displayName = 'proto.access_management.GetInitialRoomStateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetCommunityRoomUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.GetCommunityRoomUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetCommunityRoomUsersRequest.displayName = 'proto.access_management.GetCommunityRoomUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetCommunityRoomUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.GetCommunityRoomUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.access_management.GetCommunityRoomUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetCommunityRoomUsersResponse.displayName = 'proto.access_management.GetCommunityRoomUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.UpdateRoomHostRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.UpdateRoomHostRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.UpdateRoomHostRequest.displayName = 'proto.access_management.UpdateRoomHostRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.UpdateRoomHostResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.UpdateRoomHostResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.UpdateRoomHostResponse.displayName = 'proto.access_management.UpdateRoomHostResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.StartOrContinueGameDownloadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.StartOrContinueGameDownloadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.StartOrContinueGameDownloadRequest.displayName = 'proto.access_management.StartOrContinueGameDownloadRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.StartOrContinueGameDownloadResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.StartOrContinueGameDownloadResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.StartOrContinueGameDownloadResponse.displayName = 'proto.access_management.StartOrContinueGameDownloadResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.DoneDownloadingGameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.DoneDownloadingGameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.DoneDownloadingGameRequest.displayName = 'proto.access_management.DoneDownloadingGameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.DoneDownloadingGameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.DoneDownloadingGameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.DoneDownloadingGameResponse.displayName = 'proto.access_management.DoneDownloadingGameResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetCommunityRoomUsersDownloadingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.GetCommunityRoomUsersDownloadingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetCommunityRoomUsersDownloadingRequest.displayName = 'proto.access_management.GetCommunityRoomUsersDownloadingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetCommunityRoomUsersDownloadingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.GetCommunityRoomUsersDownloadingResponse.repeatedFields_, null);
};
goog.inherits(proto.access_management.GetCommunityRoomUsersDownloadingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetCommunityRoomUsersDownloadingResponse.displayName = 'proto.access_management.GetCommunityRoomUsersDownloadingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetCommunityRoomUsersNotDownloadingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.GetCommunityRoomUsersNotDownloadingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetCommunityRoomUsersNotDownloadingRequest.displayName = 'proto.access_management.GetCommunityRoomUsersNotDownloadingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetCommunityRoomUsersNotDownloadingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.GetCommunityRoomUsersNotDownloadingResponse.repeatedFields_, null);
};
goog.inherits(proto.access_management.GetCommunityRoomUsersNotDownloadingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetCommunityRoomUsersNotDownloadingResponse.displayName = 'proto.access_management.GetCommunityRoomUsersNotDownloadingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.SubscribeToRoomRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.SubscribeToRoomRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.SubscribeToRoomRequest.displayName = 'proto.access_management.SubscribeToRoomRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.EndStreamEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.EndStreamEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.EndStreamEvent.displayName = 'proto.access_management.EndStreamEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.ClientRoomEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.access_management.ClientRoomEvent.oneofGroups_);
};
goog.inherits(proto.access_management.ClientRoomEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.ClientRoomEvent.displayName = 'proto.access_management.ClientRoomEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.RoomEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.access_management.RoomEvent.oneofGroups_);
};
goog.inherits(proto.access_management.RoomEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.RoomEvent.displayName = 'proto.access_management.RoomEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.UserJoinedEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.UserJoinedEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.UserJoinedEvent.displayName = 'proto.access_management.UserJoinedEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.UserLeftEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.UserLeftEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.UserLeftEvent.displayName = 'proto.access_management.UserLeftEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.ConfigUpdateEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.ConfigUpdateEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.ConfigUpdateEvent.displayName = 'proto.access_management.ConfigUpdateEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GamePendingEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.GamePendingEvent.repeatedFields_, null);
};
goog.inherits(proto.access_management.GamePendingEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GamePendingEvent.displayName = 'proto.access_management.GamePendingEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GameInProgressEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.GameInProgressEvent.repeatedFields_, null);
};
goog.inherits(proto.access_management.GameInProgressEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GameInProgressEvent.displayName = 'proto.access_management.GameInProgressEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GameFailedToStartEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.GameFailedToStartEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GameFailedToStartEvent.displayName = 'proto.access_management.GameFailedToStartEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.MatchDistributionEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.MatchDistributionEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.MatchDistributionEvent.displayName = 'proto.access_management.MatchDistributionEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.HostEndMatchEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.HostEndMatchEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.HostEndMatchEvent.displayName = 'proto.access_management.HostEndMatchEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.MatchGroupEndedEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.MatchGroupEndedEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.MatchGroupEndedEvent.displayName = 'proto.access_management.MatchGroupEndedEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.FailedMatchConnectionEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.FailedMatchConnectionEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.FailedMatchConnectionEvent.displayName = 'proto.access_management.FailedMatchConnectionEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GameStartedEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.GameStartedEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GameStartedEvent.displayName = 'proto.access_management.GameStartedEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.PlayersDownloadingEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.PlayersDownloadingEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.PlayersDownloadingEvent.displayName = 'proto.access_management.PlayersDownloadingEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.NewHostEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.NewHostEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.NewHostEvent.displayName = 'proto.access_management.NewHostEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.MatchEndedEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.MatchEndedEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.MatchEndedEvent.displayName = 'proto.access_management.MatchEndedEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.UserStateUpdateEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.UserStateUpdateEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.UserStateUpdateEvent.displayName = 'proto.access_management.UserStateUpdateEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.TournamentEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.TournamentEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.TournamentEvent.displayName = 'proto.access_management.TournamentEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.ConnectUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.ConnectUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.ConnectUserRequest.displayName = 'proto.access_management.ConnectUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.ConnectedUserEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.access_management.ConnectedUserEvent.oneofGroups_);
};
goog.inherits(proto.access_management.ConnectedUserEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.ConnectedUserEvent.displayName = 'proto.access_management.ConnectedUserEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.CommunityMetricUpdateEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.CommunityMetricUpdateEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.CommunityMetricUpdateEvent.displayName = 'proto.access_management.CommunityMetricUpdateEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.RoomCreatedEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.RoomCreatedEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.RoomCreatedEvent.displayName = 'proto.access_management.RoomCreatedEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.RoomDeletedEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.RoomDeletedEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.RoomDeletedEvent.displayName = 'proto.access_management.RoomDeletedEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.UserJoinedRoomEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.UserJoinedRoomEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.UserJoinedRoomEvent.displayName = 'proto.access_management.UserJoinedRoomEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.UserLeftRoomEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.UserLeftRoomEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.UserLeftRoomEvent.displayName = 'proto.access_management.UserLeftRoomEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.RoomUpdatedEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.RoomUpdatedEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.RoomUpdatedEvent.displayName = 'proto.access_management.RoomUpdatedEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.CommunitySubscriptionEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.CommunitySubscriptionEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.CommunitySubscriptionEvent.displayName = 'proto.access_management.CommunitySubscriptionEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.PublishRoomEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.PublishRoomEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.PublishRoomEventRequest.displayName = 'proto.access_management.PublishRoomEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.PublishRoomEventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.PublishRoomEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.PublishRoomEventResponse.displayName = 'proto.access_management.PublishRoomEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.InternalDisconnectionCleanupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.InternalDisconnectionCleanupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.InternalDisconnectionCleanupRequest.displayName = 'proto.access_management.InternalDisconnectionCleanupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.InternalDisconnectionCleanupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.InternalDisconnectionCleanupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.InternalDisconnectionCleanupResponse.displayName = 'proto.access_management.InternalDisconnectionCleanupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.PublishConnectedUserEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.PublishConnectedUserEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.PublishConnectedUserEventRequest.displayName = 'proto.access_management.PublishConnectedUserEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.PublishConnectedUserEventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.PublishConnectedUserEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.PublishConnectedUserEventResponse.displayName = 'proto.access_management.PublishConnectedUserEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.CommunityRoomStatusUpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.CommunityRoomStatusUpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.CommunityRoomStatusUpdateRequest.displayName = 'proto.access_management.CommunityRoomStatusUpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.CommunityRoomStatusUpdateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.CommunityRoomStatusUpdateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.CommunityRoomStatusUpdateResponse.displayName = 'proto.access_management.CommunityRoomStatusUpdateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.Identifier = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.Identifier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.Identifier.displayName = 'proto.access_management.Identifier';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.WhitelistEntryCreateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.WhitelistEntryCreateRequest.repeatedFields_, null);
};
goog.inherits(proto.access_management.WhitelistEntryCreateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.WhitelistEntryCreateRequest.displayName = 'proto.access_management.WhitelistEntryCreateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.WhitelistEntryCreateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.WhitelistEntryCreateResponse.repeatedFields_, null);
};
goog.inherits(proto.access_management.WhitelistEntryCreateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.WhitelistEntryCreateResponse.displayName = 'proto.access_management.WhitelistEntryCreateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.WhitelistEntryGetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.WhitelistEntryGetRequest.repeatedFields_, null);
};
goog.inherits(proto.access_management.WhitelistEntryGetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.WhitelistEntryGetRequest.displayName = 'proto.access_management.WhitelistEntryGetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.WhitelistEntryGetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.WhitelistEntryGetResponse.repeatedFields_, null);
};
goog.inherits(proto.access_management.WhitelistEntryGetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.WhitelistEntryGetResponse.displayName = 'proto.access_management.WhitelistEntryGetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.InternalDoneDownloadingGameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.InternalDoneDownloadingGameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.InternalDoneDownloadingGameRequest.displayName = 'proto.access_management.InternalDoneDownloadingGameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.InternalDoneDownloadingGameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.InternalDoneDownloadingGameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.InternalDoneDownloadingGameResponse.displayName = 'proto.access_management.InternalDoneDownloadingGameResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.InternalGetGameDownloadingCancelTimeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.InternalGetGameDownloadingCancelTimeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.InternalGetGameDownloadingCancelTimeRequest.displayName = 'proto.access_management.InternalGetGameDownloadingCancelTimeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.InternalGetGameDownloadingCancelTimeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.InternalGetGameDownloadingCancelTimeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.InternalGetGameDownloadingCancelTimeResponse.displayName = 'proto.access_management.InternalGetGameDownloadingCancelTimeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetCommunitiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.GetCommunitiesRequest.repeatedFields_, null);
};
goog.inherits(proto.access_management.GetCommunitiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetCommunitiesRequest.displayName = 'proto.access_management.GetCommunitiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetCommunitiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.GetCommunitiesResponse.repeatedFields_, null);
};
goog.inherits(proto.access_management.GetCommunitiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetCommunitiesResponse.displayName = 'proto.access_management.GetCommunitiesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.StartSyncCommunityAdminsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.StartSyncCommunityAdminsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.StartSyncCommunityAdminsRequest.displayName = 'proto.access_management.StartSyncCommunityAdminsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.StartSyncCommunityAdminsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.StartSyncCommunityAdminsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.StartSyncCommunityAdminsResponse.displayName = 'proto.access_management.StartSyncCommunityAdminsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.StartCreateGamenightTimestampRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.StartCreateGamenightTimestampRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.StartCreateGamenightTimestampRequest.displayName = 'proto.access_management.StartCreateGamenightTimestampRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.StartCreateGamenightTimestampResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.StartCreateGamenightTimestampResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.StartCreateGamenightTimestampResponse.displayName = 'proto.access_management.StartCreateGamenightTimestampResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.SyncCommunityAdminsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.SyncCommunityAdminsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.SyncCommunityAdminsRequest.displayName = 'proto.access_management.SyncCommunityAdminsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.SyncCommunityAdminsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.SyncCommunityAdminsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.SyncCommunityAdminsResponse.displayName = 'proto.access_management.SyncCommunityAdminsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.CreateGamenightTimestampRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.CreateGamenightTimestampRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.CreateGamenightTimestampRequest.displayName = 'proto.access_management.CreateGamenightTimestampRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.CreateGamenightTimestampResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.CreateGamenightTimestampResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.CreateGamenightTimestampResponse.displayName = 'proto.access_management.CreateGamenightTimestampResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetBillingInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.GetBillingInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetBillingInfoRequest.displayName = 'proto.access_management.GetBillingInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.BillingInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.BillingInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.BillingInfo.displayName = 'proto.access_management.BillingInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetBillingInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.GetBillingInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetBillingInfoResponse.displayName = 'proto.access_management.GetBillingInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.BillingPortalSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.BillingPortalSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.BillingPortalSession.displayName = 'proto.access_management.BillingPortalSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.BillingSubscriptionCheckoutSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.BillingSubscriptionCheckoutSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.BillingSubscriptionCheckoutSession.displayName = 'proto.access_management.BillingSubscriptionCheckoutSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.BillingSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.access_management.BillingSession.oneofGroups_);
};
goog.inherits(proto.access_management.BillingSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.BillingSession.displayName = 'proto.access_management.BillingSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.RequestBillingSessionLinkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.RequestBillingSessionLinkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.RequestBillingSessionLinkRequest.displayName = 'proto.access_management.RequestBillingSessionLinkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.RequestBillingSessionLinkResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.RequestBillingSessionLinkResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.RequestBillingSessionLinkResponse.displayName = 'proto.access_management.RequestBillingSessionLinkResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.CheckCommunityMAUsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.CheckCommunityMAUsRequest.repeatedFields_, null);
};
goog.inherits(proto.access_management.CheckCommunityMAUsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.CheckCommunityMAUsRequest.displayName = 'proto.access_management.CheckCommunityMAUsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.MAUInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.MAUInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.MAUInfo.displayName = 'proto.access_management.MAUInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.CheckCommunityMAUsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.CheckCommunityMAUsResponse.repeatedFields_, null);
};
goog.inherits(proto.access_management.CheckCommunityMAUsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.CheckCommunityMAUsResponse.displayName = 'proto.access_management.CheckCommunityMAUsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetProSubscriptionPlanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.GetProSubscriptionPlanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetProSubscriptionPlanRequest.displayName = 'proto.access_management.GetProSubscriptionPlanRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetProSubscriptionPlanResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.GetProSubscriptionPlanResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetProSubscriptionPlanResponse.displayName = 'proto.access_management.GetProSubscriptionPlanResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.UpdateAvailablePlansRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.UpdateAvailablePlansRequest.repeatedFields_, null);
};
goog.inherits(proto.access_management.UpdateAvailablePlansRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.UpdateAvailablePlansRequest.displayName = 'proto.access_management.UpdateAvailablePlansRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.UpdateAvailablePlansResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.UpdateAvailablePlansResponse.repeatedFields_, null);
};
goog.inherits(proto.access_management.UpdateAvailablePlansResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.UpdateAvailablePlansResponse.displayName = 'proto.access_management.UpdateAvailablePlansResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetSubscriptionPlansRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.GetSubscriptionPlansRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetSubscriptionPlansRequest.displayName = 'proto.access_management.GetSubscriptionPlansRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetSubscriptionPlansResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.GetSubscriptionPlansResponse.repeatedFields_, null);
};
goog.inherits(proto.access_management.GetSubscriptionPlansResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetSubscriptionPlansResponse.displayName = 'proto.access_management.GetSubscriptionPlansResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetCommunityAvailablePlansRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.access_management.GetCommunityAvailablePlansRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetCommunityAvailablePlansRequest.displayName = 'proto.access_management.GetCommunityAvailablePlansRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.access_management.GetCommunityAvailablePlansResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.access_management.GetCommunityAvailablePlansResponse.repeatedFields_, null);
};
goog.inherits(proto.access_management.GetCommunityAvailablePlansResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.access_management.GetCommunityAvailablePlansResponse.displayName = 'proto.access_management.GetCommunityAvailablePlansResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.Room.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.Room.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.Room.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.Room} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.Room.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    slug: jspb.Message.getFieldWithDefault(msg, 3, ""),
    displayUsersList: jspb.Message.toObjectList(msg.getDisplayUsersList(),
    proto.access_management.RoomDisplayUser.toObject, includeInstance),
    userCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    host: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pinned: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    pb_private: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    roomType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    createdBy: (f = msg.getCreatedBy()) && proto.access_management.RoomDisplayUser.toObject(includeInstance, f),
    currentgame: jspb.Message.getFieldWithDefault(msg, 11, ""),
    tournamentId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    tournamentStatus: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.Room}
 */
proto.access_management.Room.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.Room;
  return proto.access_management.Room.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.Room} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.Room}
 */
proto.access_management.Room.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    case 4:
      var value = new proto.access_management.RoomDisplayUser;
      reader.readMessage(value,proto.access_management.RoomDisplayUser.deserializeBinaryFromReader);
      msg.addDisplayUsers(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserCount(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setHost(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPinned(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivate(value);
      break;
    case 9:
      var value = /** @type {!proto.access_management.RoomType} */ (reader.readEnum());
      msg.setRoomType(value);
      break;
    case 10:
      var value = new proto.access_management.RoomDisplayUser;
      reader.readMessage(value,proto.access_management.RoomDisplayUser.deserializeBinaryFromReader);
      msg.setCreatedBy(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentgame(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setTournamentId(value);
      break;
    case 13:
      var value = /** @type {!proto.tournaments.Status} */ (reader.readEnum());
      msg.setTournamentStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.Room.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.Room.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.Room} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.Room.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDisplayUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.access_management.RoomDisplayUser.serializeBinaryToWriter
    );
  }
  f = message.getUserCount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getHost();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPinned();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getPrivate();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getRoomType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getCreatedBy();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.access_management.RoomDisplayUser.serializeBinaryToWriter
    );
  }
  f = message.getCurrentgame();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTournamentId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTournamentStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.Room.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.Room} returns this
 */
proto.access_management.Room.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.access_management.Room.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.Room} returns this
 */
proto.access_management.Room.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string slug = 3;
 * @return {string}
 */
proto.access_management.Room.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.Room} returns this
 */
proto.access_management.Room.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated RoomDisplayUser display_users = 4;
 * @return {!Array<!proto.access_management.RoomDisplayUser>}
 */
proto.access_management.Room.prototype.getDisplayUsersList = function() {
  return /** @type{!Array<!proto.access_management.RoomDisplayUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.access_management.RoomDisplayUser, 4));
};


/**
 * @param {!Array<!proto.access_management.RoomDisplayUser>} value
 * @return {!proto.access_management.Room} returns this
*/
proto.access_management.Room.prototype.setDisplayUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.access_management.RoomDisplayUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.access_management.RoomDisplayUser}
 */
proto.access_management.Room.prototype.addDisplayUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.access_management.RoomDisplayUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.Room} returns this
 */
proto.access_management.Room.prototype.clearDisplayUsersList = function() {
  return this.setDisplayUsersList([]);
};


/**
 * optional int64 user_count = 5;
 * @return {number}
 */
proto.access_management.Room.prototype.getUserCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.Room} returns this
 */
proto.access_management.Room.prototype.setUserCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string host = 6;
 * @return {string}
 */
proto.access_management.Room.prototype.getHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.Room} returns this
 */
proto.access_management.Room.prototype.setHost = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool pinned = 7;
 * @return {boolean}
 */
proto.access_management.Room.prototype.getPinned = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.access_management.Room} returns this
 */
proto.access_management.Room.prototype.setPinned = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool private = 8;
 * @return {boolean}
 */
proto.access_management.Room.prototype.getPrivate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.access_management.Room} returns this
 */
proto.access_management.Room.prototype.setPrivate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional RoomType room_type = 9;
 * @return {!proto.access_management.RoomType}
 */
proto.access_management.Room.prototype.getRoomType = function() {
  return /** @type {!proto.access_management.RoomType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.access_management.RoomType} value
 * @return {!proto.access_management.Room} returns this
 */
proto.access_management.Room.prototype.setRoomType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional RoomDisplayUser created_by = 10;
 * @return {?proto.access_management.RoomDisplayUser}
 */
proto.access_management.Room.prototype.getCreatedBy = function() {
  return /** @type{?proto.access_management.RoomDisplayUser} */ (
    jspb.Message.getWrapperField(this, proto.access_management.RoomDisplayUser, 10));
};


/**
 * @param {?proto.access_management.RoomDisplayUser|undefined} value
 * @return {!proto.access_management.Room} returns this
*/
proto.access_management.Room.prototype.setCreatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.Room} returns this
 */
proto.access_management.Room.prototype.clearCreatedBy = function() {
  return this.setCreatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.Room.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string currentGame = 11;
 * @return {string}
 */
proto.access_management.Room.prototype.getCurrentgame = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.Room} returns this
 */
proto.access_management.Room.prototype.setCurrentgame = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string tournament_id = 12;
 * @return {string}
 */
proto.access_management.Room.prototype.getTournamentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.Room} returns this
 */
proto.access_management.Room.prototype.setTournamentId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional tournaments.Status tournament_status = 13;
 * @return {!proto.tournaments.Status}
 */
proto.access_management.Room.prototype.getTournamentStatus = function() {
  return /** @type {!proto.tournaments.Status} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.tournaments.Status} value
 * @return {!proto.access_management.Room} returns this
 */
proto.access_management.Room.prototype.setTournamentStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GameNight.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GameNight.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GameNight} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GameNight.toObject = function(includeInstance, msg) {
  var f, obj = {
    frequency: jspb.Message.getFieldWithDefault(msg, 1, 0),
    weekday: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hour: jspb.Message.getFieldWithDefault(msg, 3, 0),
    minute: jspb.Message.getFieldWithDefault(msg, 4, 0),
    timestamp: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GameNight}
 */
proto.access_management.GameNight.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GameNight;
  return proto.access_management.GameNight.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GameNight} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GameNight}
 */
proto.access_management.GameNight.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.access_management.Frequency} */ (reader.readEnum());
      msg.setFrequency(value);
      break;
    case 2:
      var value = /** @type {!proto.access_management.Weekday} */ (reader.readEnum());
      msg.setWeekday(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHour(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinute(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GameNight.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GameNight.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GameNight} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GameNight.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrequency();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getWeekday();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getHour();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getMinute();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
};


/**
 * optional Frequency frequency = 1;
 * @return {!proto.access_management.Frequency}
 */
proto.access_management.GameNight.prototype.getFrequency = function() {
  return /** @type {!proto.access_management.Frequency} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.access_management.Frequency} value
 * @return {!proto.access_management.GameNight} returns this
 */
proto.access_management.GameNight.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Weekday weekday = 2;
 * @return {!proto.access_management.Weekday}
 */
proto.access_management.GameNight.prototype.getWeekday = function() {
  return /** @type {!proto.access_management.Weekday} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.access_management.Weekday} value
 * @return {!proto.access_management.GameNight} returns this
 */
proto.access_management.GameNight.prototype.setWeekday = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint32 hour = 3;
 * @return {number}
 */
proto.access_management.GameNight.prototype.getHour = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.GameNight} returns this
 */
proto.access_management.GameNight.prototype.setHour = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 minute = 4;
 * @return {number}
 */
proto.access_management.GameNight.prototype.getMinute = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.GameNight} returns this
 */
proto.access_management.GameNight.prototype.setMinute = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 timestamp = 5;
 * @return {number}
 */
proto.access_management.GameNight.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.GameNight} returns this
 */
proto.access_management.GameNight.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.KeepAlive.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.KeepAlive.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.KeepAlive} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.KeepAlive.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.KeepAlive}
 */
proto.access_management.KeepAlive.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.KeepAlive;
  return proto.access_management.KeepAlive.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.KeepAlive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.KeepAlive}
 */
proto.access_management.KeepAlive.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.KeepAlive.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.KeepAlive.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.KeepAlive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.KeepAlive.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.Connected.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.Connected.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.Connected} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.Connected.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.Connected}
 */
proto.access_management.Connected.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.Connected;
  return proto.access_management.Connected.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.Connected} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.Connected}
 */
proto.access_management.Connected.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.Connected.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.Connected.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.Connected} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.Connected.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.access_management.WhitelistDataValue.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.access_management.WhitelistDataValue.ValueCase = {
  VALUE_NOT_SET: 0,
  BOOL_VALUE: 1
};

/**
 * @return {proto.access_management.WhitelistDataValue.ValueCase}
 */
proto.access_management.WhitelistDataValue.prototype.getValueCase = function() {
  return /** @type {proto.access_management.WhitelistDataValue.ValueCase} */(jspb.Message.computeOneofCase(this, proto.access_management.WhitelistDataValue.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.WhitelistDataValue.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.WhitelistDataValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.WhitelistDataValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.WhitelistDataValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    boolValue: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.WhitelistDataValue}
 */
proto.access_management.WhitelistDataValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.WhitelistDataValue;
  return proto.access_management.WhitelistDataValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.WhitelistDataValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.WhitelistDataValue}
 */
proto.access_management.WhitelistDataValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBoolValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.WhitelistDataValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.WhitelistDataValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.WhitelistDataValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.WhitelistDataValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool bool_value = 1;
 * @return {boolean}
 */
proto.access_management.WhitelistDataValue.prototype.getBoolValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.access_management.WhitelistDataValue} returns this
 */
proto.access_management.WhitelistDataValue.prototype.setBoolValue = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.access_management.WhitelistDataValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.access_management.WhitelistDataValue} returns this
 */
proto.access_management.WhitelistDataValue.prototype.clearBoolValue = function() {
  return jspb.Message.setOneofField(this, 1, proto.access_management.WhitelistDataValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.WhitelistDataValue.prototype.hasBoolValue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.SubscriptionPlan.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.SubscriptionPlan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.SubscriptionPlan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.SubscriptionPlan.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    planId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 6, ""),
    billingCycle: jspb.Message.getFieldWithDefault(msg, 7, ""),
    trialPeriod: jspb.Message.getFieldWithDefault(msg, 8, 0),
    mau: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.SubscriptionPlan}
 */
proto.access_management.SubscriptionPlan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.SubscriptionPlan;
  return proto.access_management.SubscriptionPlan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.SubscriptionPlan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.SubscriptionPlan}
 */
proto.access_management.SubscriptionPlan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillingCycle(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTrialPeriod(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMau(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.SubscriptionPlan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.SubscriptionPlan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.SubscriptionPlan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.SubscriptionPlan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPlanId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBillingCycle();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTrialPeriod();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getMau();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.SubscriptionPlan.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.SubscriptionPlan} returns this
 */
proto.access_management.SubscriptionPlan.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.access_management.SubscriptionPlan.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.SubscriptionPlan} returns this
 */
proto.access_management.SubscriptionPlan.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.access_management.SubscriptionPlan.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.SubscriptionPlan} returns this
 */
proto.access_management.SubscriptionPlan.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string plan_id = 4;
 * @return {string}
 */
proto.access_management.SubscriptionPlan.prototype.getPlanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.SubscriptionPlan} returns this
 */
proto.access_management.SubscriptionPlan.prototype.setPlanId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double price = 5;
 * @return {number}
 */
proto.access_management.SubscriptionPlan.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.SubscriptionPlan} returns this
 */
proto.access_management.SubscriptionPlan.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string currency = 6;
 * @return {string}
 */
proto.access_management.SubscriptionPlan.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.SubscriptionPlan} returns this
 */
proto.access_management.SubscriptionPlan.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string billing_cycle = 7;
 * @return {string}
 */
proto.access_management.SubscriptionPlan.prototype.getBillingCycle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.SubscriptionPlan} returns this
 */
proto.access_management.SubscriptionPlan.prototype.setBillingCycle = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 trial_period = 8;
 * @return {number}
 */
proto.access_management.SubscriptionPlan.prototype.getTrialPeriod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.SubscriptionPlan} returns this
 */
proto.access_management.SubscriptionPlan.prototype.setTrialPeriod = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool mau = 9;
 * @return {boolean}
 */
proto.access_management.SubscriptionPlan.prototype.getMau = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.access_management.SubscriptionPlan} returns this
 */
proto.access_management.SubscriptionPlan.prototype.setMau = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.CreateUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.CreateUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.CreateUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CreateUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalAuthId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    username: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bestRegion: jspb.Message.getFieldWithDefault(msg, 4, ""),
    displayName: (f = msg.getDisplayName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.CreateUserRequest}
 */
proto.access_management.CreateUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.CreateUserRequest;
  return proto.access_management.CreateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.CreateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.CreateUserRequest}
 */
proto.access_management.CreateUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalAuthId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBestRegion(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.CreateUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.CreateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.CreateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CreateUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalAuthId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBestRegion();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDisplayName();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string external_auth_id = 1;
 * @return {string}
 */
proto.access_management.CreateUserRequest.prototype.getExternalAuthId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateUserRequest} returns this
 */
proto.access_management.CreateUserRequest.prototype.setExternalAuthId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.access_management.CreateUserRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateUserRequest} returns this
 */
proto.access_management.CreateUserRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string username = 3;
 * @return {string}
 */
proto.access_management.CreateUserRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateUserRequest} returns this
 */
proto.access_management.CreateUserRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string best_region = 4;
 * @return {string}
 */
proto.access_management.CreateUserRequest.prototype.getBestRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateUserRequest} returns this
 */
proto.access_management.CreateUserRequest.prototype.setBestRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.StringValue display_name = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.access_management.CreateUserRequest.prototype.getDisplayName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.access_management.CreateUserRequest} returns this
*/
proto.access_management.CreateUserRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.CreateUserRequest} returns this
 */
proto.access_management.CreateUserRequest.prototype.clearDisplayName = function() {
  return this.setDisplayName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.CreateUserRequest.prototype.hasDisplayName = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.CreateUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.CreateUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.CreateUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CreateUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    externalAuthId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    username: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bestRegion: jspb.Message.getFieldWithDefault(msg, 5, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    avatarUrl: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.CreateUserResponse}
 */
proto.access_management.CreateUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.CreateUserResponse;
  return proto.access_management.CreateUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.CreateUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.CreateUserResponse}
 */
proto.access_management.CreateUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalAuthId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBestRegion(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatarUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.CreateUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.CreateUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.CreateUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CreateUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExternalAuthId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBestRegion();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAvatarUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.CreateUserResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateUserResponse} returns this
 */
proto.access_management.CreateUserResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string external_auth_id = 2;
 * @return {string}
 */
proto.access_management.CreateUserResponse.prototype.getExternalAuthId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateUserResponse} returns this
 */
proto.access_management.CreateUserResponse.prototype.setExternalAuthId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.access_management.CreateUserResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateUserResponse} returns this
 */
proto.access_management.CreateUserResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string username = 4;
 * @return {string}
 */
proto.access_management.CreateUserResponse.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateUserResponse} returns this
 */
proto.access_management.CreateUserResponse.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string best_region = 5;
 * @return {string}
 */
proto.access_management.CreateUserResponse.prototype.getBestRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateUserResponse} returns this
 */
proto.access_management.CreateUserResponse.prototype.setBestRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string display_name = 6;
 * @return {string}
 */
proto.access_management.CreateUserResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateUserResponse} returns this
 */
proto.access_management.CreateUserResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string avatar_url = 7;
 * @return {string}
 */
proto.access_management.CreateUserResponse.prototype.getAvatarUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateUserResponse} returns this
 */
proto.access_management.CreateUserResponse.prototype.setAvatarUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.CheckIfUsernameIsAvailableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.CheckIfUsernameIsAvailableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.CheckIfUsernameIsAvailableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CheckIfUsernameIsAvailableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.CheckIfUsernameIsAvailableRequest}
 */
proto.access_management.CheckIfUsernameIsAvailableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.CheckIfUsernameIsAvailableRequest;
  return proto.access_management.CheckIfUsernameIsAvailableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.CheckIfUsernameIsAvailableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.CheckIfUsernameIsAvailableRequest}
 */
proto.access_management.CheckIfUsernameIsAvailableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.CheckIfUsernameIsAvailableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.CheckIfUsernameIsAvailableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.CheckIfUsernameIsAvailableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CheckIfUsernameIsAvailableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.access_management.CheckIfUsernameIsAvailableRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CheckIfUsernameIsAvailableRequest} returns this
 */
proto.access_management.CheckIfUsernameIsAvailableRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.CheckIfUsernameIsAvailableResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.CheckIfUsernameIsAvailableResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.CheckIfUsernameIsAvailableResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CheckIfUsernameIsAvailableResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    available: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.CheckIfUsernameIsAvailableResponse}
 */
proto.access_management.CheckIfUsernameIsAvailableResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.CheckIfUsernameIsAvailableResponse;
  return proto.access_management.CheckIfUsernameIsAvailableResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.CheckIfUsernameIsAvailableResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.CheckIfUsernameIsAvailableResponse}
 */
proto.access_management.CheckIfUsernameIsAvailableResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAvailable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.CheckIfUsernameIsAvailableResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.CheckIfUsernameIsAvailableResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.CheckIfUsernameIsAvailableResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CheckIfUsernameIsAvailableResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAvailable();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool available = 1;
 * @return {boolean}
 */
proto.access_management.CheckIfUsernameIsAvailableResponse.prototype.getAvailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.access_management.CheckIfUsernameIsAvailableResponse} returns this
 */
proto.access_management.CheckIfUsernameIsAvailableResponse.prototype.setAvailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetUserByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetUserByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetUserByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetUserByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetUserByIdRequest}
 */
proto.access_management.GetUserByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetUserByIdRequest;
  return proto.access_management.GetUserByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetUserByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetUserByIdRequest}
 */
proto.access_management.GetUserByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetUserByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetUserByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetUserByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetUserByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.GetUserByIdRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetUserByIdRequest} returns this
 */
proto.access_management.GetUserByIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetUserByExternalAuthIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetUserByExternalAuthIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetUserByExternalAuthIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetUserByExternalAuthIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetUserByExternalAuthIdRequest}
 */
proto.access_management.GetUserByExternalAuthIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetUserByExternalAuthIdRequest;
  return proto.access_management.GetUserByExternalAuthIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetUserByExternalAuthIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetUserByExternalAuthIdRequest}
 */
proto.access_management.GetUserByExternalAuthIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetUserByExternalAuthIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetUserByExternalAuthIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetUserByExternalAuthIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetUserByExternalAuthIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.GetUserByExternalAuthIdRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetUserByExternalAuthIdRequest} returns this
 */
proto.access_management.GetUserByExternalAuthIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.GetUserResponse.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    externalAuthId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    username: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bestRegion: jspb.Message.getFieldWithDefault(msg, 5, ""),
    communityIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    avatarUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetUserResponse}
 */
proto.access_management.GetUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetUserResponse;
  return proto.access_management.GetUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetUserResponse}
 */
proto.access_management.GetUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalAuthId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBestRegion(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addCommunityIds(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatarUrl(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExternalAuthId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBestRegion();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCommunityIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getAvatarUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.GetUserResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetUserResponse} returns this
 */
proto.access_management.GetUserResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string external_auth_id = 2;
 * @return {string}
 */
proto.access_management.GetUserResponse.prototype.getExternalAuthId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetUserResponse} returns this
 */
proto.access_management.GetUserResponse.prototype.setExternalAuthId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.access_management.GetUserResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetUserResponse} returns this
 */
proto.access_management.GetUserResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string username = 4;
 * @return {string}
 */
proto.access_management.GetUserResponse.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetUserResponse} returns this
 */
proto.access_management.GetUserResponse.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string best_region = 5;
 * @return {string}
 */
proto.access_management.GetUserResponse.prototype.getBestRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetUserResponse} returns this
 */
proto.access_management.GetUserResponse.prototype.setBestRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string community_ids = 6;
 * @return {!Array<string>}
 */
proto.access_management.GetUserResponse.prototype.getCommunityIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.access_management.GetUserResponse} returns this
 */
proto.access_management.GetUserResponse.prototype.setCommunityIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.access_management.GetUserResponse} returns this
 */
proto.access_management.GetUserResponse.prototype.addCommunityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.GetUserResponse} returns this
 */
proto.access_management.GetUserResponse.prototype.clearCommunityIdsList = function() {
  return this.setCommunityIdsList([]);
};


/**
 * optional string avatar_url = 7;
 * @return {string}
 */
proto.access_management.GetUserResponse.prototype.getAvatarUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetUserResponse} returns this
 */
proto.access_management.GetUserResponse.prototype.setAvatarUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string display_name = 8;
 * @return {string}
 */
proto.access_management.GetUserResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetUserResponse} returns this
 */
proto.access_management.GetUserResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.GetUsersByIdRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetUsersByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetUsersByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetUsersByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetUsersByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetUsersByIdRequest}
 */
proto.access_management.GetUsersByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetUsersByIdRequest;
  return proto.access_management.GetUsersByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetUsersByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetUsersByIdRequest}
 */
proto.access_management.GetUsersByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetUsersByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetUsersByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetUsersByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetUsersByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.access_management.GetUsersByIdRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.access_management.GetUsersByIdRequest} returns this
 */
proto.access_management.GetUsersByIdRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.access_management.GetUsersByIdRequest} returns this
 */
proto.access_management.GetUsersByIdRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.GetUsersByIdRequest} returns this
 */
proto.access_management.GetUsersByIdRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.UserElement.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.UserElement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.UserElement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UserElement.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    avatarUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.UserElement}
 */
proto.access_management.UserElement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.UserElement;
  return proto.access_management.UserElement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.UserElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.UserElement}
 */
proto.access_management.UserElement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatarUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.UserElement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.UserElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.UserElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UserElement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAvatarUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.UserElement.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UserElement} returns this
 */
proto.access_management.UserElement.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.access_management.UserElement.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UserElement} returns this
 */
proto.access_management.UserElement.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string avatar_url = 3;
 * @return {string}
 */
proto.access_management.UserElement.prototype.getAvatarUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UserElement} returns this
 */
proto.access_management.UserElement.prototype.setAvatarUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string display_name = 4;
 * @return {string}
 */
proto.access_management.UserElement.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UserElement} returns this
 */
proto.access_management.UserElement.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.GetUsersByIdResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetUsersByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetUsersByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetUsersByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetUsersByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.access_management.UserElement.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetUsersByIdResponse}
 */
proto.access_management.GetUsersByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetUsersByIdResponse;
  return proto.access_management.GetUsersByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetUsersByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetUsersByIdResponse}
 */
proto.access_management.GetUsersByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.access_management.UserElement;
      reader.readMessage(value,proto.access_management.UserElement.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetUsersByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetUsersByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetUsersByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetUsersByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.access_management.UserElement.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserElement users = 1;
 * @return {!Array<!proto.access_management.UserElement>}
 */
proto.access_management.GetUsersByIdResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.access_management.UserElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.access_management.UserElement, 1));
};


/**
 * @param {!Array<!proto.access_management.UserElement>} value
 * @return {!proto.access_management.GetUsersByIdResponse} returns this
*/
proto.access_management.GetUsersByIdResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.access_management.UserElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.access_management.UserElement}
 */
proto.access_management.GetUsersByIdResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.access_management.UserElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.GetUsersByIdResponse} returns this
 */
proto.access_management.GetUsersByIdResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.AddUserToCommunityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.AddUserToCommunityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.AddUserToCommunityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.AddUserToCommunityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    communityId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.AddUserToCommunityRequest}
 */
proto.access_management.AddUserToCommunityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.AddUserToCommunityRequest;
  return proto.access_management.AddUserToCommunityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.AddUserToCommunityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.AddUserToCommunityRequest}
 */
proto.access_management.AddUserToCommunityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommunityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.AddUserToCommunityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.AddUserToCommunityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.AddUserToCommunityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.AddUserToCommunityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCommunityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.access_management.AddUserToCommunityRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.AddUserToCommunityRequest} returns this
 */
proto.access_management.AddUserToCommunityRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string community_id = 2;
 * @return {string}
 */
proto.access_management.AddUserToCommunityRequest.prototype.getCommunityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.AddUserToCommunityRequest} returns this
 */
proto.access_management.AddUserToCommunityRequest.prototype.setCommunityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.AddUserToCommunityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.AddUserToCommunityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.AddUserToCommunityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.AddUserToCommunityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.AddUserToCommunityResponse}
 */
proto.access_management.AddUserToCommunityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.AddUserToCommunityResponse;
  return proto.access_management.AddUserToCommunityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.AddUserToCommunityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.AddUserToCommunityResponse}
 */
proto.access_management.AddUserToCommunityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.AddUserToCommunityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.AddUserToCommunityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.AddUserToCommunityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.AddUserToCommunityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.RemoveUserFromCommunityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.RemoveUserFromCommunityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.RemoveUserFromCommunityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.RemoveUserFromCommunityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    communityId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.RemoveUserFromCommunityRequest}
 */
proto.access_management.RemoveUserFromCommunityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.RemoveUserFromCommunityRequest;
  return proto.access_management.RemoveUserFromCommunityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.RemoveUserFromCommunityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.RemoveUserFromCommunityRequest}
 */
proto.access_management.RemoveUserFromCommunityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommunityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.RemoveUserFromCommunityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.RemoveUserFromCommunityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.RemoveUserFromCommunityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.RemoveUserFromCommunityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCommunityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.access_management.RemoveUserFromCommunityRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.RemoveUserFromCommunityRequest} returns this
 */
proto.access_management.RemoveUserFromCommunityRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string community_id = 2;
 * @return {string}
 */
proto.access_management.RemoveUserFromCommunityRequest.prototype.getCommunityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.RemoveUserFromCommunityRequest} returns this
 */
proto.access_management.RemoveUserFromCommunityRequest.prototype.setCommunityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.RemoveUserFromCommunityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.RemoveUserFromCommunityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.RemoveUserFromCommunityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.RemoveUserFromCommunityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.RemoveUserFromCommunityResponse}
 */
proto.access_management.RemoveUserFromCommunityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.RemoveUserFromCommunityResponse;
  return proto.access_management.RemoveUserFromCommunityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.RemoveUserFromCommunityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.RemoveUserFromCommunityResponse}
 */
proto.access_management.RemoveUserFromCommunityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.RemoveUserFromCommunityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.RemoveUserFromCommunityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.RemoveUserFromCommunityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.RemoveUserFromCommunityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.UpdateUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.UpdateUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.UpdateUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UpdateUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    username: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bestRegion: jspb.Message.getFieldWithDefault(msg, 4, ""),
    avatarVersion: jspb.Message.getFieldWithDefault(msg, 5, ""),
    displayName: (f = msg.getDisplayName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.UpdateUserRequest}
 */
proto.access_management.UpdateUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.UpdateUserRequest;
  return proto.access_management.UpdateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.UpdateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.UpdateUserRequest}
 */
proto.access_management.UpdateUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBestRegion(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatarVersion(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.UpdateUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.UpdateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.UpdateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UpdateUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBestRegion();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAvatarVersion();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDisplayName();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.UpdateUserRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateUserRequest} returns this
 */
proto.access_management.UpdateUserRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.access_management.UpdateUserRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateUserRequest} returns this
 */
proto.access_management.UpdateUserRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string username = 3;
 * @return {string}
 */
proto.access_management.UpdateUserRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateUserRequest} returns this
 */
proto.access_management.UpdateUserRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string best_region = 4;
 * @return {string}
 */
proto.access_management.UpdateUserRequest.prototype.getBestRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateUserRequest} returns this
 */
proto.access_management.UpdateUserRequest.prototype.setBestRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string avatar_version = 5;
 * @return {string}
 */
proto.access_management.UpdateUserRequest.prototype.getAvatarVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateUserRequest} returns this
 */
proto.access_management.UpdateUserRequest.prototype.setAvatarVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.StringValue display_name = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.access_management.UpdateUserRequest.prototype.getDisplayName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.access_management.UpdateUserRequest} returns this
*/
proto.access_management.UpdateUserRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.UpdateUserRequest} returns this
 */
proto.access_management.UpdateUserRequest.prototype.clearDisplayName = function() {
  return this.setDisplayName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.UpdateUserRequest.prototype.hasDisplayName = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.UpdateUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.UpdateUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.UpdateUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UpdateUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    username: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bestRegion: jspb.Message.getFieldWithDefault(msg, 4, ""),
    avatarUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.UpdateUserResponse}
 */
proto.access_management.UpdateUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.UpdateUserResponse;
  return proto.access_management.UpdateUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.UpdateUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.UpdateUserResponse}
 */
proto.access_management.UpdateUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBestRegion(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatarUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.UpdateUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.UpdateUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.UpdateUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UpdateUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBestRegion();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAvatarUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.UpdateUserResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateUserResponse} returns this
 */
proto.access_management.UpdateUserResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.access_management.UpdateUserResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateUserResponse} returns this
 */
proto.access_management.UpdateUserResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string username = 3;
 * @return {string}
 */
proto.access_management.UpdateUserResponse.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateUserResponse} returns this
 */
proto.access_management.UpdateUserResponse.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string best_region = 4;
 * @return {string}
 */
proto.access_management.UpdateUserResponse.prototype.getBestRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateUserResponse} returns this
 */
proto.access_management.UpdateUserResponse.prototype.setBestRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string avatar_url = 5;
 * @return {string}
 */
proto.access_management.UpdateUserResponse.prototype.getAvatarUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateUserResponse} returns this
 */
proto.access_management.UpdateUserResponse.prototype.setAvatarUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string display_name = 6;
 * @return {string}
 */
proto.access_management.UpdateUserResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateUserResponse} returns this
 */
proto.access_management.UpdateUserResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.DeleteUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.DeleteUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.DeleteUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.DeleteUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.DeleteUserRequest}
 */
proto.access_management.DeleteUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.DeleteUserRequest;
  return proto.access_management.DeleteUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.DeleteUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.DeleteUserRequest}
 */
proto.access_management.DeleteUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.DeleteUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.DeleteUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.DeleteUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.DeleteUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.DeleteUserRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.DeleteUserRequest} returns this
 */
proto.access_management.DeleteUserRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.DeleteUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.DeleteUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.DeleteUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.DeleteUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.DeleteUserResponse}
 */
proto.access_management.DeleteUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.DeleteUserResponse;
  return proto.access_management.DeleteUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.DeleteUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.DeleteUserResponse}
 */
proto.access_management.DeleteUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.DeleteUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.DeleteUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.DeleteUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.DeleteUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.ListUserCommunitiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.ListUserCommunitiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.ListUserCommunitiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.ListUserCommunitiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.ListUserCommunitiesRequest}
 */
proto.access_management.ListUserCommunitiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.ListUserCommunitiesRequest;
  return proto.access_management.ListUserCommunitiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.ListUserCommunitiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.ListUserCommunitiesRequest}
 */
proto.access_management.ListUserCommunitiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.ListUserCommunitiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.ListUserCommunitiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.ListUserCommunitiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.ListUserCommunitiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.ListUserCommunitiesRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.ListUserCommunitiesRequest} returns this
 */
proto.access_management.ListUserCommunitiesRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.Community.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.Community.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.Community} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.Community.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    slug: jspb.Message.getFieldWithDefault(msg, 3, ""),
    onlineMembers: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalMembers: jspb.Message.getFieldWithDefault(msg, 5, 0),
    roomCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    admin: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    logoUrl: jspb.Message.getFieldWithDefault(msg, 8, ""),
    billingInfoMap: (f = msg.getBillingInfoMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.Community}
 */
proto.access_management.Community.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.Community;
  return proto.access_management.Community.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.Community} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.Community}
 */
proto.access_management.Community.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOnlineMembers(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalMembers(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomCount(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAdmin(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoUrl(value);
      break;
    case 9:
      var value = msg.getBillingInfoMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.Community.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.Community.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.Community} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.Community.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOnlineMembers();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getTotalMembers();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getRoomCount();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getAdmin();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getLogoUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getBillingInfoMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(9, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.Community.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.Community} returns this
 */
proto.access_management.Community.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.access_management.Community.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.Community} returns this
 */
proto.access_management.Community.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string slug = 3;
 * @return {string}
 */
proto.access_management.Community.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.Community} returns this
 */
proto.access_management.Community.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 online_members = 4;
 * @return {number}
 */
proto.access_management.Community.prototype.getOnlineMembers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.Community} returns this
 */
proto.access_management.Community.prototype.setOnlineMembers = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 total_members = 5;
 * @return {number}
 */
proto.access_management.Community.prototype.getTotalMembers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.Community} returns this
 */
proto.access_management.Community.prototype.setTotalMembers = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 room_count = 6;
 * @return {number}
 */
proto.access_management.Community.prototype.getRoomCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.Community} returns this
 */
proto.access_management.Community.prototype.setRoomCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool admin = 7;
 * @return {boolean}
 */
proto.access_management.Community.prototype.getAdmin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.access_management.Community} returns this
 */
proto.access_management.Community.prototype.setAdmin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string logo_url = 8;
 * @return {string}
 */
proto.access_management.Community.prototype.getLogoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.Community} returns this
 */
proto.access_management.Community.prototype.setLogoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * map<string, string> billing_info = 9;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.access_management.Community.prototype.getBillingInfoMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 9, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.access_management.Community} returns this
 */
proto.access_management.Community.prototype.clearBillingInfoMap = function() {
  this.getBillingInfoMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.ListUserCommunitiesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.ListUserCommunitiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.ListUserCommunitiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.ListUserCommunitiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.ListUserCommunitiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    communitiesList: jspb.Message.toObjectList(msg.getCommunitiesList(),
    proto.access_management.Community.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.ListUserCommunitiesResponse}
 */
proto.access_management.ListUserCommunitiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.ListUserCommunitiesResponse;
  return proto.access_management.ListUserCommunitiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.ListUserCommunitiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.ListUserCommunitiesResponse}
 */
proto.access_management.ListUserCommunitiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.access_management.Community;
      reader.readMessage(value,proto.access_management.Community.deserializeBinaryFromReader);
      msg.addCommunities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.ListUserCommunitiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.ListUserCommunitiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.ListUserCommunitiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.ListUserCommunitiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommunitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.access_management.Community.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Community communities = 1;
 * @return {!Array<!proto.access_management.Community>}
 */
proto.access_management.ListUserCommunitiesResponse.prototype.getCommunitiesList = function() {
  return /** @type{!Array<!proto.access_management.Community>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.access_management.Community, 1));
};


/**
 * @param {!Array<!proto.access_management.Community>} value
 * @return {!proto.access_management.ListUserCommunitiesResponse} returns this
*/
proto.access_management.ListUserCommunitiesResponse.prototype.setCommunitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.access_management.Community=} opt_value
 * @param {number=} opt_index
 * @return {!proto.access_management.Community}
 */
proto.access_management.ListUserCommunitiesResponse.prototype.addCommunities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.access_management.Community, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.ListUserCommunitiesResponse} returns this
 */
proto.access_management.ListUserCommunitiesResponse.prototype.clearCommunitiesList = function() {
  return this.setCommunitiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.JoinCommunityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.JoinCommunityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.JoinCommunityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.JoinCommunityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    communityId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.JoinCommunityRequest}
 */
proto.access_management.JoinCommunityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.JoinCommunityRequest;
  return proto.access_management.JoinCommunityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.JoinCommunityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.JoinCommunityRequest}
 */
proto.access_management.JoinCommunityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommunityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.JoinCommunityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.JoinCommunityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.JoinCommunityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.JoinCommunityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommunityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string community_id = 1;
 * @return {string}
 */
proto.access_management.JoinCommunityRequest.prototype.getCommunityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.JoinCommunityRequest} returns this
 */
proto.access_management.JoinCommunityRequest.prototype.setCommunityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.JoinCommunityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.JoinCommunityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.JoinCommunityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.JoinCommunityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.JoinCommunityResponse}
 */
proto.access_management.JoinCommunityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.JoinCommunityResponse;
  return proto.access_management.JoinCommunityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.JoinCommunityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.JoinCommunityResponse}
 */
proto.access_management.JoinCommunityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.JoinCommunityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.JoinCommunityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.JoinCommunityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.JoinCommunityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.LeaveCommunityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.LeaveCommunityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.LeaveCommunityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.LeaveCommunityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    communityId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.LeaveCommunityRequest}
 */
proto.access_management.LeaveCommunityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.LeaveCommunityRequest;
  return proto.access_management.LeaveCommunityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.LeaveCommunityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.LeaveCommunityRequest}
 */
proto.access_management.LeaveCommunityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommunityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.LeaveCommunityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.LeaveCommunityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.LeaveCommunityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.LeaveCommunityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommunityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string community_id = 1;
 * @return {string}
 */
proto.access_management.LeaveCommunityRequest.prototype.getCommunityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.LeaveCommunityRequest} returns this
 */
proto.access_management.LeaveCommunityRequest.prototype.setCommunityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.LeaveCommunityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.LeaveCommunityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.LeaveCommunityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.LeaveCommunityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.LeaveCommunityResponse}
 */
proto.access_management.LeaveCommunityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.LeaveCommunityResponse;
  return proto.access_management.LeaveCommunityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.LeaveCommunityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.LeaveCommunityResponse}
 */
proto.access_management.LeaveCommunityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.LeaveCommunityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.LeaveCommunityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.LeaveCommunityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.LeaveCommunityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.access_management.UserState.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.access_management.UserState.StatusCase = {
  STATUS_NOT_SET: 0,
  SLOW_INTERNET_CONNECTION: 1,
  MUTED: 2,
  VOICE_CHAT_OPTOUT: 3
};

/**
 * @return {proto.access_management.UserState.StatusCase}
 */
proto.access_management.UserState.prototype.getStatusCase = function() {
  return /** @type {proto.access_management.UserState.StatusCase} */(jspb.Message.computeOneofCase(this, proto.access_management.UserState.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.UserState.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.UserState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.UserState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UserState.toObject = function(includeInstance, msg) {
  var f, obj = {
    slowInternetConnection: (f = msg.getSlowInternetConnection()) && proto.access_management.SlowInternetConnection.toObject(includeInstance, f),
    muted: (f = msg.getMuted()) && proto.access_management.Muted.toObject(includeInstance, f),
    voiceChatOptout: (f = msg.getVoiceChatOptout()) && proto.access_management.VoiceChatOptOut.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.UserState}
 */
proto.access_management.UserState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.UserState;
  return proto.access_management.UserState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.UserState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.UserState}
 */
proto.access_management.UserState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.access_management.SlowInternetConnection;
      reader.readMessage(value,proto.access_management.SlowInternetConnection.deserializeBinaryFromReader);
      msg.setSlowInternetConnection(value);
      break;
    case 2:
      var value = new proto.access_management.Muted;
      reader.readMessage(value,proto.access_management.Muted.deserializeBinaryFromReader);
      msg.setMuted(value);
      break;
    case 3:
      var value = new proto.access_management.VoiceChatOptOut;
      reader.readMessage(value,proto.access_management.VoiceChatOptOut.deserializeBinaryFromReader);
      msg.setVoiceChatOptout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.UserState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.UserState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.UserState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UserState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlowInternetConnection();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.access_management.SlowInternetConnection.serializeBinaryToWriter
    );
  }
  f = message.getMuted();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.access_management.Muted.serializeBinaryToWriter
    );
  }
  f = message.getVoiceChatOptout();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.access_management.VoiceChatOptOut.serializeBinaryToWriter
    );
  }
};


/**
 * optional SlowInternetConnection slow_internet_connection = 1;
 * @return {?proto.access_management.SlowInternetConnection}
 */
proto.access_management.UserState.prototype.getSlowInternetConnection = function() {
  return /** @type{?proto.access_management.SlowInternetConnection} */ (
    jspb.Message.getWrapperField(this, proto.access_management.SlowInternetConnection, 1));
};


/**
 * @param {?proto.access_management.SlowInternetConnection|undefined} value
 * @return {!proto.access_management.UserState} returns this
*/
proto.access_management.UserState.prototype.setSlowInternetConnection = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.access_management.UserState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.UserState} returns this
 */
proto.access_management.UserState.prototype.clearSlowInternetConnection = function() {
  return this.setSlowInternetConnection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.UserState.prototype.hasSlowInternetConnection = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Muted muted = 2;
 * @return {?proto.access_management.Muted}
 */
proto.access_management.UserState.prototype.getMuted = function() {
  return /** @type{?proto.access_management.Muted} */ (
    jspb.Message.getWrapperField(this, proto.access_management.Muted, 2));
};


/**
 * @param {?proto.access_management.Muted|undefined} value
 * @return {!proto.access_management.UserState} returns this
*/
proto.access_management.UserState.prototype.setMuted = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.access_management.UserState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.UserState} returns this
 */
proto.access_management.UserState.prototype.clearMuted = function() {
  return this.setMuted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.UserState.prototype.hasMuted = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional VoiceChatOptOut voice_chat_optout = 3;
 * @return {?proto.access_management.VoiceChatOptOut}
 */
proto.access_management.UserState.prototype.getVoiceChatOptout = function() {
  return /** @type{?proto.access_management.VoiceChatOptOut} */ (
    jspb.Message.getWrapperField(this, proto.access_management.VoiceChatOptOut, 3));
};


/**
 * @param {?proto.access_management.VoiceChatOptOut|undefined} value
 * @return {!proto.access_management.UserState} returns this
*/
proto.access_management.UserState.prototype.setVoiceChatOptout = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.access_management.UserState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.UserState} returns this
 */
proto.access_management.UserState.prototype.clearVoiceChatOptout = function() {
  return this.setVoiceChatOptout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.UserState.prototype.hasVoiceChatOptout = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.SlowInternetConnection.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.SlowInternetConnection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.SlowInternetConnection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.SlowInternetConnection.toObject = function(includeInstance, msg) {
  var f, obj = {
    slow: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.SlowInternetConnection}
 */
proto.access_management.SlowInternetConnection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.SlowInternetConnection;
  return proto.access_management.SlowInternetConnection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.SlowInternetConnection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.SlowInternetConnection}
 */
proto.access_management.SlowInternetConnection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSlow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.SlowInternetConnection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.SlowInternetConnection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.SlowInternetConnection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.SlowInternetConnection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlow();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool slow = 1;
 * @return {boolean}
 */
proto.access_management.SlowInternetConnection.prototype.getSlow = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.access_management.SlowInternetConnection} returns this
 */
proto.access_management.SlowInternetConnection.prototype.setSlow = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.Muted.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.Muted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.Muted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.Muted.toObject = function(includeInstance, msg) {
  var f, obj = {
    muted: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.Muted}
 */
proto.access_management.Muted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.Muted;
  return proto.access_management.Muted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.Muted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.Muted}
 */
proto.access_management.Muted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMuted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.Muted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.Muted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.Muted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.Muted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMuted();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool muted = 1;
 * @return {boolean}
 */
proto.access_management.Muted.prototype.getMuted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.access_management.Muted} returns this
 */
proto.access_management.Muted.prototype.setMuted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.VoiceChatOptOut.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.VoiceChatOptOut.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.VoiceChatOptOut} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.VoiceChatOptOut.toObject = function(includeInstance, msg) {
  var f, obj = {
    voiceChatOptOut: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.VoiceChatOptOut}
 */
proto.access_management.VoiceChatOptOut.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.VoiceChatOptOut;
  return proto.access_management.VoiceChatOptOut.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.VoiceChatOptOut} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.VoiceChatOptOut}
 */
proto.access_management.VoiceChatOptOut.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVoiceChatOptOut(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.VoiceChatOptOut.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.VoiceChatOptOut.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.VoiceChatOptOut} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.VoiceChatOptOut.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVoiceChatOptOut();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool voice_chat_opt_out = 1;
 * @return {boolean}
 */
proto.access_management.VoiceChatOptOut.prototype.getVoiceChatOptOut = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.access_management.VoiceChatOptOut} returns this
 */
proto.access_management.VoiceChatOptOut.prototype.setVoiceChatOptOut = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.ReportUserStateRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.ReportUserStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.ReportUserStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.ReportUserStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.ReportUserStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userStateUpdatesList: jspb.Message.toObjectList(msg.getUserStateUpdatesList(),
    proto.access_management.UserState.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.ReportUserStateRequest}
 */
proto.access_management.ReportUserStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.ReportUserStateRequest;
  return proto.access_management.ReportUserStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.ReportUserStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.ReportUserStateRequest}
 */
proto.access_management.ReportUserStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.access_management.UserState;
      reader.readMessage(value,proto.access_management.UserState.deserializeBinaryFromReader);
      msg.addUserStateUpdates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.ReportUserStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.ReportUserStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.ReportUserStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.ReportUserStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserStateUpdatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.access_management.UserState.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserState user_state_updates = 1;
 * @return {!Array<!proto.access_management.UserState>}
 */
proto.access_management.ReportUserStateRequest.prototype.getUserStateUpdatesList = function() {
  return /** @type{!Array<!proto.access_management.UserState>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.access_management.UserState, 1));
};


/**
 * @param {!Array<!proto.access_management.UserState>} value
 * @return {!proto.access_management.ReportUserStateRequest} returns this
*/
proto.access_management.ReportUserStateRequest.prototype.setUserStateUpdatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.access_management.UserState=} opt_value
 * @param {number=} opt_index
 * @return {!proto.access_management.UserState}
 */
proto.access_management.ReportUserStateRequest.prototype.addUserStateUpdates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.access_management.UserState, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.ReportUserStateRequest} returns this
 */
proto.access_management.ReportUserStateRequest.prototype.clearUserStateUpdatesList = function() {
  return this.setUserStateUpdatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.ReportUserStateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.ReportUserStateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.ReportUserStateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.ReportUserStateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentUserStateMap: (f = msg.getCurrentUserStateMap()) ? f.toObject(includeInstance, undefined) : [],
    userId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.ReportUserStateResponse}
 */
proto.access_management.ReportUserStateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.ReportUserStateResponse;
  return proto.access_management.ReportUserStateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.ReportUserStateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.ReportUserStateResponse}
 */
proto.access_management.ReportUserStateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getCurrentUserStateMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.ReportUserStateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.ReportUserStateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.ReportUserStateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.ReportUserStateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentUserStateMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * map<string, string> current_user_state = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.access_management.ReportUserStateResponse.prototype.getCurrentUserStateMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.access_management.ReportUserStateResponse} returns this
 */
proto.access_management.ReportUserStateResponse.prototype.clearCurrentUserStateMap = function() {
  this.getCurrentUserStateMap().clear();
  return this;};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.access_management.ReportUserStateResponse.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.ReportUserStateResponse} returns this
 */
proto.access_management.ReportUserStateResponse.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.access_management.InternalUserState.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.access_management.InternalUserState.StateCase = {
  STATE_NOT_SET: 0,
  JOINED_MATCH: 1,
  LEFT_MATCH: 2,
  JOINED_ROOM: 3,
  LEFT_ROOM: 4
};

/**
 * @return {proto.access_management.InternalUserState.StateCase}
 */
proto.access_management.InternalUserState.prototype.getStateCase = function() {
  return /** @type {proto.access_management.InternalUserState.StateCase} */(jspb.Message.computeOneofCase(this, proto.access_management.InternalUserState.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.InternalUserState.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.InternalUserState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.InternalUserState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InternalUserState.toObject = function(includeInstance, msg) {
  var f, obj = {
    joinedMatch: (f = msg.getJoinedMatch()) && proto.access_management.JoinedMatch.toObject(includeInstance, f),
    leftMatch: (f = msg.getLeftMatch()) && proto.access_management.LeftMatch.toObject(includeInstance, f),
    joinedRoom: (f = msg.getJoinedRoom()) && proto.access_management.JoinedRoom.toObject(includeInstance, f),
    leftRoom: (f = msg.getLeftRoom()) && proto.access_management.LeftRoom.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.InternalUserState}
 */
proto.access_management.InternalUserState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.InternalUserState;
  return proto.access_management.InternalUserState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.InternalUserState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.InternalUserState}
 */
proto.access_management.InternalUserState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.access_management.JoinedMatch;
      reader.readMessage(value,proto.access_management.JoinedMatch.deserializeBinaryFromReader);
      msg.setJoinedMatch(value);
      break;
    case 2:
      var value = new proto.access_management.LeftMatch;
      reader.readMessage(value,proto.access_management.LeftMatch.deserializeBinaryFromReader);
      msg.setLeftMatch(value);
      break;
    case 3:
      var value = new proto.access_management.JoinedRoom;
      reader.readMessage(value,proto.access_management.JoinedRoom.deserializeBinaryFromReader);
      msg.setJoinedRoom(value);
      break;
    case 4:
      var value = new proto.access_management.LeftRoom;
      reader.readMessage(value,proto.access_management.LeftRoom.deserializeBinaryFromReader);
      msg.setLeftRoom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.InternalUserState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.InternalUserState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.InternalUserState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InternalUserState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJoinedMatch();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.access_management.JoinedMatch.serializeBinaryToWriter
    );
  }
  f = message.getLeftMatch();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.access_management.LeftMatch.serializeBinaryToWriter
    );
  }
  f = message.getJoinedRoom();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.access_management.JoinedRoom.serializeBinaryToWriter
    );
  }
  f = message.getLeftRoom();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.access_management.LeftRoom.serializeBinaryToWriter
    );
  }
};


/**
 * optional JoinedMatch joined_match = 1;
 * @return {?proto.access_management.JoinedMatch}
 */
proto.access_management.InternalUserState.prototype.getJoinedMatch = function() {
  return /** @type{?proto.access_management.JoinedMatch} */ (
    jspb.Message.getWrapperField(this, proto.access_management.JoinedMatch, 1));
};


/**
 * @param {?proto.access_management.JoinedMatch|undefined} value
 * @return {!proto.access_management.InternalUserState} returns this
*/
proto.access_management.InternalUserState.prototype.setJoinedMatch = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.access_management.InternalUserState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.InternalUserState} returns this
 */
proto.access_management.InternalUserState.prototype.clearJoinedMatch = function() {
  return this.setJoinedMatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.InternalUserState.prototype.hasJoinedMatch = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LeftMatch left_match = 2;
 * @return {?proto.access_management.LeftMatch}
 */
proto.access_management.InternalUserState.prototype.getLeftMatch = function() {
  return /** @type{?proto.access_management.LeftMatch} */ (
    jspb.Message.getWrapperField(this, proto.access_management.LeftMatch, 2));
};


/**
 * @param {?proto.access_management.LeftMatch|undefined} value
 * @return {!proto.access_management.InternalUserState} returns this
*/
proto.access_management.InternalUserState.prototype.setLeftMatch = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.access_management.InternalUserState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.InternalUserState} returns this
 */
proto.access_management.InternalUserState.prototype.clearLeftMatch = function() {
  return this.setLeftMatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.InternalUserState.prototype.hasLeftMatch = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional JoinedRoom joined_room = 3;
 * @return {?proto.access_management.JoinedRoom}
 */
proto.access_management.InternalUserState.prototype.getJoinedRoom = function() {
  return /** @type{?proto.access_management.JoinedRoom} */ (
    jspb.Message.getWrapperField(this, proto.access_management.JoinedRoom, 3));
};


/**
 * @param {?proto.access_management.JoinedRoom|undefined} value
 * @return {!proto.access_management.InternalUserState} returns this
*/
proto.access_management.InternalUserState.prototype.setJoinedRoom = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.access_management.InternalUserState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.InternalUserState} returns this
 */
proto.access_management.InternalUserState.prototype.clearJoinedRoom = function() {
  return this.setJoinedRoom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.InternalUserState.prototype.hasJoinedRoom = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional LeftRoom left_room = 4;
 * @return {?proto.access_management.LeftRoom}
 */
proto.access_management.InternalUserState.prototype.getLeftRoom = function() {
  return /** @type{?proto.access_management.LeftRoom} */ (
    jspb.Message.getWrapperField(this, proto.access_management.LeftRoom, 4));
};


/**
 * @param {?proto.access_management.LeftRoom|undefined} value
 * @return {!proto.access_management.InternalUserState} returns this
*/
proto.access_management.InternalUserState.prototype.setLeftRoom = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.access_management.InternalUserState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.InternalUserState} returns this
 */
proto.access_management.InternalUserState.prototype.clearLeftRoom = function() {
  return this.setLeftRoom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.InternalUserState.prototype.hasLeftRoom = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.JoinedMatch.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.JoinedMatch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.JoinedMatch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.JoinedMatch.toObject = function(includeInstance, msg) {
  var f, obj = {
    matchId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.JoinedMatch}
 */
proto.access_management.JoinedMatch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.JoinedMatch;
  return proto.access_management.JoinedMatch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.JoinedMatch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.JoinedMatch}
 */
proto.access_management.JoinedMatch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatchId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.JoinedMatch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.JoinedMatch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.JoinedMatch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.JoinedMatch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMatchId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string match_id = 1;
 * @return {string}
 */
proto.access_management.JoinedMatch.prototype.getMatchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.JoinedMatch} returns this
 */
proto.access_management.JoinedMatch.prototype.setMatchId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.LeftMatch.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.LeftMatch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.LeftMatch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.LeftMatch.toObject = function(includeInstance, msg) {
  var f, obj = {
    matchId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.LeftMatch}
 */
proto.access_management.LeftMatch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.LeftMatch;
  return proto.access_management.LeftMatch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.LeftMatch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.LeftMatch}
 */
proto.access_management.LeftMatch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatchId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.LeftMatch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.LeftMatch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.LeftMatch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.LeftMatch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMatchId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string match_id = 1;
 * @return {string}
 */
proto.access_management.LeftMatch.prototype.getMatchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.LeftMatch} returns this
 */
proto.access_management.LeftMatch.prototype.setMatchId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.JoinedRoom.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.JoinedRoom.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.JoinedRoom} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.JoinedRoom.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.JoinedRoom}
 */
proto.access_management.JoinedRoom.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.JoinedRoom;
  return proto.access_management.JoinedRoom.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.JoinedRoom} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.JoinedRoom}
 */
proto.access_management.JoinedRoom.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.JoinedRoom.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.JoinedRoom.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.JoinedRoom} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.JoinedRoom.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.access_management.JoinedRoom.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.JoinedRoom} returns this
 */
proto.access_management.JoinedRoom.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.LeftRoom.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.LeftRoom.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.LeftRoom} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.LeftRoom.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.LeftRoom}
 */
proto.access_management.LeftRoom.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.LeftRoom;
  return proto.access_management.LeftRoom.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.LeftRoom} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.LeftRoom}
 */
proto.access_management.LeftRoom.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.LeftRoom.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.LeftRoom.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.LeftRoom} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.LeftRoom.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.access_management.LeftRoom.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.LeftRoom} returns this
 */
proto.access_management.LeftRoom.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.UserStateReport.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.UserStateReport.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.UserStateReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.UserStateReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UserStateReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userStateUpdatesList: jspb.Message.toObjectList(msg.getUserStateUpdatesList(),
    proto.access_management.InternalUserState.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.UserStateReport}
 */
proto.access_management.UserStateReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.UserStateReport;
  return proto.access_management.UserStateReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.UserStateReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.UserStateReport}
 */
proto.access_management.UserStateReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = new proto.access_management.InternalUserState;
      reader.readMessage(value,proto.access_management.InternalUserState.deserializeBinaryFromReader);
      msg.addUserStateUpdates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.UserStateReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.UserStateReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.UserStateReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UserStateReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserStateUpdatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.access_management.InternalUserState.serializeBinaryToWriter
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.access_management.UserStateReport.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UserStateReport} returns this
 */
proto.access_management.UserStateReport.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated InternalUserState user_state_updates = 2;
 * @return {!Array<!proto.access_management.InternalUserState>}
 */
proto.access_management.UserStateReport.prototype.getUserStateUpdatesList = function() {
  return /** @type{!Array<!proto.access_management.InternalUserState>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.access_management.InternalUserState, 2));
};


/**
 * @param {!Array<!proto.access_management.InternalUserState>} value
 * @return {!proto.access_management.UserStateReport} returns this
*/
proto.access_management.UserStateReport.prototype.setUserStateUpdatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.access_management.InternalUserState=} opt_value
 * @param {number=} opt_index
 * @return {!proto.access_management.InternalUserState}
 */
proto.access_management.UserStateReport.prototype.addUserStateUpdates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.access_management.InternalUserState, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.UserStateReport} returns this
 */
proto.access_management.UserStateReport.prototype.clearUserStateUpdatesList = function() {
  return this.setUserStateUpdatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.InternalReportUserStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.InternalReportUserStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.InternalReportUserStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InternalReportUserStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userStateReport: (f = msg.getUserStateReport()) && proto.access_management.UserStateReport.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.InternalReportUserStateRequest}
 */
proto.access_management.InternalReportUserStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.InternalReportUserStateRequest;
  return proto.access_management.InternalReportUserStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.InternalReportUserStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.InternalReportUserStateRequest}
 */
proto.access_management.InternalReportUserStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.access_management.UserStateReport;
      reader.readMessage(value,proto.access_management.UserStateReport.deserializeBinaryFromReader);
      msg.setUserStateReport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.InternalReportUserStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.InternalReportUserStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.InternalReportUserStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InternalReportUserStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserStateReport();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.access_management.UserStateReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserStateReport user_state_report = 1;
 * @return {?proto.access_management.UserStateReport}
 */
proto.access_management.InternalReportUserStateRequest.prototype.getUserStateReport = function() {
  return /** @type{?proto.access_management.UserStateReport} */ (
    jspb.Message.getWrapperField(this, proto.access_management.UserStateReport, 1));
};


/**
 * @param {?proto.access_management.UserStateReport|undefined} value
 * @return {!proto.access_management.InternalReportUserStateRequest} returns this
*/
proto.access_management.InternalReportUserStateRequest.prototype.setUserStateReport = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.InternalReportUserStateRequest} returns this
 */
proto.access_management.InternalReportUserStateRequest.prototype.clearUserStateReport = function() {
  return this.setUserStateReport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.InternalReportUserStateRequest.prototype.hasUserStateReport = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.InternalReportUserStateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.InternalReportUserStateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.InternalReportUserStateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InternalReportUserStateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentUserStateMap: (f = msg.getCurrentUserStateMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.InternalReportUserStateResponse}
 */
proto.access_management.InternalReportUserStateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.InternalReportUserStateResponse;
  return proto.access_management.InternalReportUserStateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.InternalReportUserStateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.InternalReportUserStateResponse}
 */
proto.access_management.InternalReportUserStateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getCurrentUserStateMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.InternalReportUserStateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.InternalReportUserStateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.InternalReportUserStateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InternalReportUserStateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentUserStateMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * map<string, string> current_user_state = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.access_management.InternalReportUserStateResponse.prototype.getCurrentUserStateMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.access_management.InternalReportUserStateResponse} returns this
 */
proto.access_management.InternalReportUserStateResponse.prototype.clearCurrentUserStateMap = function() {
  this.getCurrentUserStateMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.InternalReportUserStatesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.InternalReportUserStatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.InternalReportUserStatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.InternalReportUserStatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InternalReportUserStatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userStateReportsList: jspb.Message.toObjectList(msg.getUserStateReportsList(),
    proto.access_management.UserStateReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.InternalReportUserStatesRequest}
 */
proto.access_management.InternalReportUserStatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.InternalReportUserStatesRequest;
  return proto.access_management.InternalReportUserStatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.InternalReportUserStatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.InternalReportUserStatesRequest}
 */
proto.access_management.InternalReportUserStatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.access_management.UserStateReport;
      reader.readMessage(value,proto.access_management.UserStateReport.deserializeBinaryFromReader);
      msg.addUserStateReports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.InternalReportUserStatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.InternalReportUserStatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.InternalReportUserStatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InternalReportUserStatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserStateReportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.access_management.UserStateReport.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserStateReport user_state_reports = 1;
 * @return {!Array<!proto.access_management.UserStateReport>}
 */
proto.access_management.InternalReportUserStatesRequest.prototype.getUserStateReportsList = function() {
  return /** @type{!Array<!proto.access_management.UserStateReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.access_management.UserStateReport, 1));
};


/**
 * @param {!Array<!proto.access_management.UserStateReport>} value
 * @return {!proto.access_management.InternalReportUserStatesRequest} returns this
*/
proto.access_management.InternalReportUserStatesRequest.prototype.setUserStateReportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.access_management.UserStateReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.access_management.UserStateReport}
 */
proto.access_management.InternalReportUserStatesRequest.prototype.addUserStateReports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.access_management.UserStateReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.InternalReportUserStatesRequest} returns this
 */
proto.access_management.InternalReportUserStatesRequest.prototype.clearUserStateReportsList = function() {
  return this.setUserStateReportsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.InternalReportUserStatesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.InternalReportUserStatesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.InternalReportUserStatesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InternalReportUserStatesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentUserStatesMap: (f = msg.getCurrentUserStatesMap()) ? f.toObject(includeInstance, proto.access_management.UserStateMap.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.InternalReportUserStatesResponse}
 */
proto.access_management.InternalReportUserStatesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.InternalReportUserStatesResponse;
  return proto.access_management.InternalReportUserStatesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.InternalReportUserStatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.InternalReportUserStatesResponse}
 */
proto.access_management.InternalReportUserStatesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getCurrentUserStatesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.access_management.UserStateMap.deserializeBinaryFromReader, "", new proto.access_management.UserStateMap());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.InternalReportUserStatesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.InternalReportUserStatesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.InternalReportUserStatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InternalReportUserStatesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentUserStatesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.access_management.UserStateMap.serializeBinaryToWriter);
  }
};


/**
 * map<string, UserStateMap> current_user_states = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.access_management.UserStateMap>}
 */
proto.access_management.InternalReportUserStatesResponse.prototype.getCurrentUserStatesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.access_management.UserStateMap>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.access_management.UserStateMap));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.access_management.InternalReportUserStatesResponse} returns this
 */
proto.access_management.InternalReportUserStatesResponse.prototype.clearCurrentUserStatesMap = function() {
  this.getCurrentUserStatesMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.InternalGetIpsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.InternalGetIpsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.InternalGetIpsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.InternalGetIpsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InternalGetIpsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.InternalGetIpsRequest}
 */
proto.access_management.InternalGetIpsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.InternalGetIpsRequest;
  return proto.access_management.InternalGetIpsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.InternalGetIpsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.InternalGetIpsRequest}
 */
proto.access_management.InternalGetIpsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.InternalGetIpsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.InternalGetIpsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.InternalGetIpsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InternalGetIpsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string user_ids = 1;
 * @return {!Array<string>}
 */
proto.access_management.InternalGetIpsRequest.prototype.getUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.access_management.InternalGetIpsRequest} returns this
 */
proto.access_management.InternalGetIpsRequest.prototype.setUserIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.access_management.InternalGetIpsRequest} returns this
 */
proto.access_management.InternalGetIpsRequest.prototype.addUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.InternalGetIpsRequest} returns this
 */
proto.access_management.InternalGetIpsRequest.prototype.clearUserIdsList = function() {
  return this.setUserIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.InternalGetIpsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.InternalGetIpsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.InternalGetIpsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.InternalGetIpsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InternalGetIpsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ipsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.InternalGetIpsResponse}
 */
proto.access_management.InternalGetIpsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.InternalGetIpsResponse;
  return proto.access_management.InternalGetIpsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.InternalGetIpsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.InternalGetIpsResponse}
 */
proto.access_management.InternalGetIpsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIps(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.InternalGetIpsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.InternalGetIpsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.InternalGetIpsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InternalGetIpsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIpsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ips = 1;
 * @return {!Array<string>}
 */
proto.access_management.InternalGetIpsResponse.prototype.getIpsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.access_management.InternalGetIpsResponse} returns this
 */
proto.access_management.InternalGetIpsResponse.prototype.setIpsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.access_management.InternalGetIpsResponse} returns this
 */
proto.access_management.InternalGetIpsResponse.prototype.addIps = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.InternalGetIpsResponse} returns this
 */
proto.access_management.InternalGetIpsResponse.prototype.clearIpsList = function() {
  return this.setIpsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.GetUserStatesByIdRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetUserStatesByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetUserStatesByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetUserStatesByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetUserStatesByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetUserStatesByIdRequest}
 */
proto.access_management.GetUserStatesByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetUserStatesByIdRequest;
  return proto.access_management.GetUserStatesByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetUserStatesByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetUserStatesByIdRequest}
 */
proto.access_management.GetUserStatesByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetUserStatesByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetUserStatesByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetUserStatesByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetUserStatesByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string user_ids = 1;
 * @return {!Array<string>}
 */
proto.access_management.GetUserStatesByIdRequest.prototype.getUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.access_management.GetUserStatesByIdRequest} returns this
 */
proto.access_management.GetUserStatesByIdRequest.prototype.setUserIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.access_management.GetUserStatesByIdRequest} returns this
 */
proto.access_management.GetUserStatesByIdRequest.prototype.addUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.GetUserStatesByIdRequest} returns this
 */
proto.access_management.GetUserStatesByIdRequest.prototype.clearUserIdsList = function() {
  return this.setUserIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.UserStateMap.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.UserStateMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.UserStateMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UserStateMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    stateMap: (f = msg.getStateMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.UserStateMap}
 */
proto.access_management.UserStateMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.UserStateMap;
  return proto.access_management.UserStateMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.UserStateMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.UserStateMap}
 */
proto.access_management.UserStateMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getStateMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.UserStateMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.UserStateMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.UserStateMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UserStateMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStateMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * map<string, string> state = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.access_management.UserStateMap.prototype.getStateMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.access_management.UserStateMap} returns this
 */
proto.access_management.UserStateMap.prototype.clearStateMap = function() {
  this.getStateMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetUserStatesByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetUserStatesByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetUserStatesByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetUserStatesByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userStatesMap: (f = msg.getUserStatesMap()) ? f.toObject(includeInstance, proto.access_management.UserStateMap.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetUserStatesByIdResponse}
 */
proto.access_management.GetUserStatesByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetUserStatesByIdResponse;
  return proto.access_management.GetUserStatesByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetUserStatesByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetUserStatesByIdResponse}
 */
proto.access_management.GetUserStatesByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getUserStatesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.access_management.UserStateMap.deserializeBinaryFromReader, "", new proto.access_management.UserStateMap());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetUserStatesByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetUserStatesByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetUserStatesByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetUserStatesByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserStatesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.access_management.UserStateMap.serializeBinaryToWriter);
  }
};


/**
 * map<string, UserStateMap> user_states = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.access_management.UserStateMap>}
 */
proto.access_management.GetUserStatesByIdResponse.prototype.getUserStatesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.access_management.UserStateMap>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.access_management.UserStateMap));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.access_management.GetUserStatesByIdResponse} returns this
 */
proto.access_management.GetUserStatesByIdResponse.prototype.clearUserStatesMap = function() {
  this.getUserStatesMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.DomainGate.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.DomainGate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.DomainGate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.DomainGate.toObject = function(includeInstance, msg) {
  var f, obj = {
    domain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    group: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.DomainGate}
 */
proto.access_management.DomainGate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.DomainGate;
  return proto.access_management.DomainGate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.DomainGate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.DomainGate}
 */
proto.access_management.DomainGate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.DomainGate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.DomainGate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.DomainGate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.DomainGate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGroup();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string domain = 1;
 * @return {string}
 */
proto.access_management.DomainGate.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.DomainGate} returns this
 */
proto.access_management.DomainGate.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string group = 2;
 * @return {string}
 */
proto.access_management.DomainGate.prototype.getGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.DomainGate} returns this
 */
proto.access_management.DomainGate.prototype.setGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.InviteGate.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.InviteGate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.InviteGate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InviteGate.toObject = function(includeInstance, msg) {
  var f, obj = {
    group: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.InviteGate}
 */
proto.access_management.InviteGate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.InviteGate;
  return proto.access_management.InviteGate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.InviteGate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.InviteGate}
 */
proto.access_management.InviteGate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.InviteGate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.InviteGate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.InviteGate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InviteGate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroup();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string group = 1;
 * @return {string}
 */
proto.access_management.InviteGate.prototype.getGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.InviteGate} returns this
 */
proto.access_management.InviteGate.prototype.setGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.OpenGate.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.OpenGate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.OpenGate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.OpenGate.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.OpenGate}
 */
proto.access_management.OpenGate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.OpenGate;
  return proto.access_management.OpenGate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.OpenGate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.OpenGate}
 */
proto.access_management.OpenGate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.OpenGate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.OpenGate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.OpenGate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.OpenGate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.access_management.CommunityGate.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.access_management.CommunityGate.GateCase = {
  GATE_NOT_SET: 0,
  DOMAIN_GATE: 1,
  INVITE_GATE: 2,
  OPEN_GATE: 3
};

/**
 * @return {proto.access_management.CommunityGate.GateCase}
 */
proto.access_management.CommunityGate.prototype.getGateCase = function() {
  return /** @type {proto.access_management.CommunityGate.GateCase} */(jspb.Message.computeOneofCase(this, proto.access_management.CommunityGate.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.CommunityGate.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.CommunityGate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.CommunityGate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CommunityGate.toObject = function(includeInstance, msg) {
  var f, obj = {
    domainGate: (f = msg.getDomainGate()) && proto.access_management.DomainGate.toObject(includeInstance, f),
    inviteGate: (f = msg.getInviteGate()) && proto.access_management.InviteGate.toObject(includeInstance, f),
    openGate: (f = msg.getOpenGate()) && proto.access_management.OpenGate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.CommunityGate}
 */
proto.access_management.CommunityGate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.CommunityGate;
  return proto.access_management.CommunityGate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.CommunityGate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.CommunityGate}
 */
proto.access_management.CommunityGate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.access_management.DomainGate;
      reader.readMessage(value,proto.access_management.DomainGate.deserializeBinaryFromReader);
      msg.setDomainGate(value);
      break;
    case 2:
      var value = new proto.access_management.InviteGate;
      reader.readMessage(value,proto.access_management.InviteGate.deserializeBinaryFromReader);
      msg.setInviteGate(value);
      break;
    case 3:
      var value = new proto.access_management.OpenGate;
      reader.readMessage(value,proto.access_management.OpenGate.deserializeBinaryFromReader);
      msg.setOpenGate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.CommunityGate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.CommunityGate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.CommunityGate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CommunityGate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDomainGate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.access_management.DomainGate.serializeBinaryToWriter
    );
  }
  f = message.getInviteGate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.access_management.InviteGate.serializeBinaryToWriter
    );
  }
  f = message.getOpenGate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.access_management.OpenGate.serializeBinaryToWriter
    );
  }
};


/**
 * optional DomainGate domain_gate = 1;
 * @return {?proto.access_management.DomainGate}
 */
proto.access_management.CommunityGate.prototype.getDomainGate = function() {
  return /** @type{?proto.access_management.DomainGate} */ (
    jspb.Message.getWrapperField(this, proto.access_management.DomainGate, 1));
};


/**
 * @param {?proto.access_management.DomainGate|undefined} value
 * @return {!proto.access_management.CommunityGate} returns this
*/
proto.access_management.CommunityGate.prototype.setDomainGate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.access_management.CommunityGate.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.CommunityGate} returns this
 */
proto.access_management.CommunityGate.prototype.clearDomainGate = function() {
  return this.setDomainGate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.CommunityGate.prototype.hasDomainGate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional InviteGate invite_gate = 2;
 * @return {?proto.access_management.InviteGate}
 */
proto.access_management.CommunityGate.prototype.getInviteGate = function() {
  return /** @type{?proto.access_management.InviteGate} */ (
    jspb.Message.getWrapperField(this, proto.access_management.InviteGate, 2));
};


/**
 * @param {?proto.access_management.InviteGate|undefined} value
 * @return {!proto.access_management.CommunityGate} returns this
*/
proto.access_management.CommunityGate.prototype.setInviteGate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.access_management.CommunityGate.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.CommunityGate} returns this
 */
proto.access_management.CommunityGate.prototype.clearInviteGate = function() {
  return this.setInviteGate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.CommunityGate.prototype.hasInviteGate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional OpenGate open_gate = 3;
 * @return {?proto.access_management.OpenGate}
 */
proto.access_management.CommunityGate.prototype.getOpenGate = function() {
  return /** @type{?proto.access_management.OpenGate} */ (
    jspb.Message.getWrapperField(this, proto.access_management.OpenGate, 3));
};


/**
 * @param {?proto.access_management.OpenGate|undefined} value
 * @return {!proto.access_management.CommunityGate} returns this
*/
proto.access_management.CommunityGate.prototype.setOpenGate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.access_management.CommunityGate.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.CommunityGate} returns this
 */
proto.access_management.CommunityGate.prototype.clearOpenGate = function() {
  return this.setOpenGate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.CommunityGate.prototype.hasOpenGate = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.CreateCommunityRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.CreateCommunityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.CreateCommunityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.CreateCommunityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CreateCommunityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    gating: jspb.Message.getFieldWithDefault(msg, 4, 0),
    billingInfoMap: (f = msg.getBillingInfoMap()) ? f.toObject(includeInstance, undefined) : [],
    gatesList: jspb.Message.toObjectList(msg.getGatesList(),
    proto.access_management.CommunityGate.toObject, includeInstance),
    logoVersion: jspb.Message.getFieldWithDefault(msg, 7, ""),
    bannerVersion: jspb.Message.getFieldWithDefault(msg, 8, ""),
    gameNight: (f = msg.getGameNight()) && proto.access_management.GameNight.toObject(includeInstance, f),
    gameNightEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    communityType: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.CreateCommunityRequest}
 */
proto.access_management.CreateCommunityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.CreateCommunityRequest;
  return proto.access_management.CreateCommunityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.CreateCommunityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.CreateCommunityRequest}
 */
proto.access_management.CreateCommunityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {!proto.access_management.CommunityGating} */ (reader.readEnum());
      msg.setGating(value);
      break;
    case 5:
      var value = msg.getBillingInfoMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 6:
      var value = new proto.access_management.CommunityGate;
      reader.readMessage(value,proto.access_management.CommunityGate.deserializeBinaryFromReader);
      msg.addGates(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoVersion(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBannerVersion(value);
      break;
    case 9:
      var value = new proto.access_management.GameNight;
      reader.readMessage(value,proto.access_management.GameNight.deserializeBinaryFromReader);
      msg.setGameNight(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGameNightEnabled(value);
      break;
    case 11:
      var value = /** @type {!proto.access_management.CommunityType} */ (reader.readEnum());
      msg.setCommunityType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.CreateCommunityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.CreateCommunityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.CreateCommunityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CreateCommunityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGating();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getBillingInfoMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getGatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.access_management.CommunityGate.serializeBinaryToWriter
    );
  }
  f = message.getLogoVersion();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBannerVersion();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getGameNight();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.access_management.GameNight.serializeBinaryToWriter
    );
  }
  f = message.getGameNightEnabled();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getCommunityType();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.access_management.CreateCommunityRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateCommunityRequest} returns this
 */
proto.access_management.CreateCommunityRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.access_management.CreateCommunityRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateCommunityRequest} returns this
 */
proto.access_management.CreateCommunityRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CommunityGating gating = 4;
 * @return {!proto.access_management.CommunityGating}
 */
proto.access_management.CreateCommunityRequest.prototype.getGating = function() {
  return /** @type {!proto.access_management.CommunityGating} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.access_management.CommunityGating} value
 * @return {!proto.access_management.CreateCommunityRequest} returns this
 */
proto.access_management.CreateCommunityRequest.prototype.setGating = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * map<string, string> billing_info = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.access_management.CreateCommunityRequest.prototype.getBillingInfoMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.access_management.CreateCommunityRequest} returns this
 */
proto.access_management.CreateCommunityRequest.prototype.clearBillingInfoMap = function() {
  this.getBillingInfoMap().clear();
  return this;};


/**
 * repeated CommunityGate gates = 6;
 * @return {!Array<!proto.access_management.CommunityGate>}
 */
proto.access_management.CreateCommunityRequest.prototype.getGatesList = function() {
  return /** @type{!Array<!proto.access_management.CommunityGate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.access_management.CommunityGate, 6));
};


/**
 * @param {!Array<!proto.access_management.CommunityGate>} value
 * @return {!proto.access_management.CreateCommunityRequest} returns this
*/
proto.access_management.CreateCommunityRequest.prototype.setGatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.access_management.CommunityGate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.access_management.CommunityGate}
 */
proto.access_management.CreateCommunityRequest.prototype.addGates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.access_management.CommunityGate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.CreateCommunityRequest} returns this
 */
proto.access_management.CreateCommunityRequest.prototype.clearGatesList = function() {
  return this.setGatesList([]);
};


/**
 * optional string logo_version = 7;
 * @return {string}
 */
proto.access_management.CreateCommunityRequest.prototype.getLogoVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateCommunityRequest} returns this
 */
proto.access_management.CreateCommunityRequest.prototype.setLogoVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string banner_version = 8;
 * @return {string}
 */
proto.access_management.CreateCommunityRequest.prototype.getBannerVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateCommunityRequest} returns this
 */
proto.access_management.CreateCommunityRequest.prototype.setBannerVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional GameNight game_night = 9;
 * @return {?proto.access_management.GameNight}
 */
proto.access_management.CreateCommunityRequest.prototype.getGameNight = function() {
  return /** @type{?proto.access_management.GameNight} */ (
    jspb.Message.getWrapperField(this, proto.access_management.GameNight, 9));
};


/**
 * @param {?proto.access_management.GameNight|undefined} value
 * @return {!proto.access_management.CreateCommunityRequest} returns this
*/
proto.access_management.CreateCommunityRequest.prototype.setGameNight = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.CreateCommunityRequest} returns this
 */
proto.access_management.CreateCommunityRequest.prototype.clearGameNight = function() {
  return this.setGameNight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.CreateCommunityRequest.prototype.hasGameNight = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool game_night_enabled = 10;
 * @return {boolean}
 */
proto.access_management.CreateCommunityRequest.prototype.getGameNightEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.access_management.CreateCommunityRequest} returns this
 */
proto.access_management.CreateCommunityRequest.prototype.setGameNightEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional CommunityType community_type = 11;
 * @return {!proto.access_management.CommunityType}
 */
proto.access_management.CreateCommunityRequest.prototype.getCommunityType = function() {
  return /** @type {!proto.access_management.CommunityType} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.access_management.CommunityType} value
 * @return {!proto.access_management.CreateCommunityRequest} returns this
 */
proto.access_management.CreateCommunityRequest.prototype.setCommunityType = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.CreateCommunityResponse.repeatedFields_ = [5,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.CreateCommunityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.CreateCommunityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.CreateCommunityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CreateCommunityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    slug: jspb.Message.getFieldWithDefault(msg, 4, ""),
    adminsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    gating: jspb.Message.getFieldWithDefault(msg, 6, 0),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    billingInfoMap: (f = msg.getBillingInfoMap()) ? f.toObject(includeInstance, undefined) : [],
    gatesList: jspb.Message.toObjectList(msg.getGatesList(),
    proto.access_management.CommunityGate.toObject, includeInstance),
    logoUrl: jspb.Message.getFieldWithDefault(msg, 10, ""),
    bannerUrl: jspb.Message.getFieldWithDefault(msg, 11, ""),
    gameNight: (f = msg.getGameNight()) && proto.access_management.GameNight.toObject(includeInstance, f),
    gameNightEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    communityType: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.CreateCommunityResponse}
 */
proto.access_management.CreateCommunityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.CreateCommunityResponse;
  return proto.access_management.CreateCommunityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.CreateCommunityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.CreateCommunityResponse}
 */
proto.access_management.CreateCommunityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addAdmins(value);
      break;
    case 6:
      var value = /** @type {!proto.access_management.CommunityGating} */ (reader.readEnum());
      msg.setGating(value);
      break;
    case 7:
      var value = /** @type {!proto.access_management.CommunityStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 8:
      var value = msg.getBillingInfoMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 9:
      var value = new proto.access_management.CommunityGate;
      reader.readMessage(value,proto.access_management.CommunityGate.deserializeBinaryFromReader);
      msg.addGates(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoUrl(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setBannerUrl(value);
      break;
    case 12:
      var value = new proto.access_management.GameNight;
      reader.readMessage(value,proto.access_management.GameNight.deserializeBinaryFromReader);
      msg.setGameNight(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGameNightEnabled(value);
      break;
    case 14:
      var value = /** @type {!proto.access_management.CommunityType} */ (reader.readEnum());
      msg.setCommunityType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.CreateCommunityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.CreateCommunityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.CreateCommunityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CreateCommunityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAdminsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getGating();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getBillingInfoMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(8, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getGatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.access_management.CommunityGate.serializeBinaryToWriter
    );
  }
  f = message.getLogoUrl();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBannerUrl();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getGameNight();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.access_management.GameNight.serializeBinaryToWriter
    );
  }
  f = message.getGameNightEnabled();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getCommunityType();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.CreateCommunityResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateCommunityResponse} returns this
 */
proto.access_management.CreateCommunityResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.access_management.CreateCommunityResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateCommunityResponse} returns this
 */
proto.access_management.CreateCommunityResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.access_management.CreateCommunityResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateCommunityResponse} returns this
 */
proto.access_management.CreateCommunityResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string slug = 4;
 * @return {string}
 */
proto.access_management.CreateCommunityResponse.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateCommunityResponse} returns this
 */
proto.access_management.CreateCommunityResponse.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string admins = 5;
 * @return {!Array<string>}
 */
proto.access_management.CreateCommunityResponse.prototype.getAdminsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.access_management.CreateCommunityResponse} returns this
 */
proto.access_management.CreateCommunityResponse.prototype.setAdminsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.access_management.CreateCommunityResponse} returns this
 */
proto.access_management.CreateCommunityResponse.prototype.addAdmins = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.CreateCommunityResponse} returns this
 */
proto.access_management.CreateCommunityResponse.prototype.clearAdminsList = function() {
  return this.setAdminsList([]);
};


/**
 * optional CommunityGating gating = 6;
 * @return {!proto.access_management.CommunityGating}
 */
proto.access_management.CreateCommunityResponse.prototype.getGating = function() {
  return /** @type {!proto.access_management.CommunityGating} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.access_management.CommunityGating} value
 * @return {!proto.access_management.CreateCommunityResponse} returns this
 */
proto.access_management.CreateCommunityResponse.prototype.setGating = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional CommunityStatus status = 7;
 * @return {!proto.access_management.CommunityStatus}
 */
proto.access_management.CreateCommunityResponse.prototype.getStatus = function() {
  return /** @type {!proto.access_management.CommunityStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.access_management.CommunityStatus} value
 * @return {!proto.access_management.CreateCommunityResponse} returns this
 */
proto.access_management.CreateCommunityResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * map<string, string> billing_info = 8;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.access_management.CreateCommunityResponse.prototype.getBillingInfoMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 8, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.access_management.CreateCommunityResponse} returns this
 */
proto.access_management.CreateCommunityResponse.prototype.clearBillingInfoMap = function() {
  this.getBillingInfoMap().clear();
  return this;};


/**
 * repeated CommunityGate gates = 9;
 * @return {!Array<!proto.access_management.CommunityGate>}
 */
proto.access_management.CreateCommunityResponse.prototype.getGatesList = function() {
  return /** @type{!Array<!proto.access_management.CommunityGate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.access_management.CommunityGate, 9));
};


/**
 * @param {!Array<!proto.access_management.CommunityGate>} value
 * @return {!proto.access_management.CreateCommunityResponse} returns this
*/
proto.access_management.CreateCommunityResponse.prototype.setGatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.access_management.CommunityGate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.access_management.CommunityGate}
 */
proto.access_management.CreateCommunityResponse.prototype.addGates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.access_management.CommunityGate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.CreateCommunityResponse} returns this
 */
proto.access_management.CreateCommunityResponse.prototype.clearGatesList = function() {
  return this.setGatesList([]);
};


/**
 * optional string logo_url = 10;
 * @return {string}
 */
proto.access_management.CreateCommunityResponse.prototype.getLogoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateCommunityResponse} returns this
 */
proto.access_management.CreateCommunityResponse.prototype.setLogoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string banner_url = 11;
 * @return {string}
 */
proto.access_management.CreateCommunityResponse.prototype.getBannerUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateCommunityResponse} returns this
 */
proto.access_management.CreateCommunityResponse.prototype.setBannerUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional GameNight game_night = 12;
 * @return {?proto.access_management.GameNight}
 */
proto.access_management.CreateCommunityResponse.prototype.getGameNight = function() {
  return /** @type{?proto.access_management.GameNight} */ (
    jspb.Message.getWrapperField(this, proto.access_management.GameNight, 12));
};


/**
 * @param {?proto.access_management.GameNight|undefined} value
 * @return {!proto.access_management.CreateCommunityResponse} returns this
*/
proto.access_management.CreateCommunityResponse.prototype.setGameNight = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.CreateCommunityResponse} returns this
 */
proto.access_management.CreateCommunityResponse.prototype.clearGameNight = function() {
  return this.setGameNight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.CreateCommunityResponse.prototype.hasGameNight = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool game_night_enabled = 13;
 * @return {boolean}
 */
proto.access_management.CreateCommunityResponse.prototype.getGameNightEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.access_management.CreateCommunityResponse} returns this
 */
proto.access_management.CreateCommunityResponse.prototype.setGameNightEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional CommunityType community_type = 14;
 * @return {!proto.access_management.CommunityType}
 */
proto.access_management.CreateCommunityResponse.prototype.getCommunityType = function() {
  return /** @type {!proto.access_management.CommunityType} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.access_management.CommunityType} value
 * @return {!proto.access_management.CreateCommunityResponse} returns this
 */
proto.access_management.CreateCommunityResponse.prototype.setCommunityType = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetCommunityByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetCommunityByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetCommunityByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetCommunityByIdRequest}
 */
proto.access_management.GetCommunityByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetCommunityByIdRequest;
  return proto.access_management.GetCommunityByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetCommunityByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetCommunityByIdRequest}
 */
proto.access_management.GetCommunityByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetCommunityByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetCommunityByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetCommunityByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.GetCommunityByIdRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityByIdRequest} returns this
 */
proto.access_management.GetCommunityByIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetCommunityBySlugRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetCommunityBySlugRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetCommunityBySlugRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityBySlugRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    slug: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetCommunityBySlugRequest}
 */
proto.access_management.GetCommunityBySlugRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetCommunityBySlugRequest;
  return proto.access_management.GetCommunityBySlugRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetCommunityBySlugRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetCommunityBySlugRequest}
 */
proto.access_management.GetCommunityBySlugRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetCommunityBySlugRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetCommunityBySlugRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetCommunityBySlugRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityBySlugRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string slug = 1;
 * @return {string}
 */
proto.access_management.GetCommunityBySlugRequest.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityBySlugRequest} returns this
 */
proto.access_management.GetCommunityBySlugRequest.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.GetCommunityResponse.repeatedFields_ = [5,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetCommunityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetCommunityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetCommunityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    slug: jspb.Message.getFieldWithDefault(msg, 4, ""),
    adminsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    gating: jspb.Message.getFieldWithDefault(msg, 6, 0),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    billingInfoMap: (f = msg.getBillingInfoMap()) ? f.toObject(includeInstance, undefined) : [],
    gatesList: jspb.Message.toObjectList(msg.getGatesList(),
    proto.access_management.CommunityGate.toObject, includeInstance),
    logoUrl: jspb.Message.getFieldWithDefault(msg, 10, ""),
    bannerUrl: jspb.Message.getFieldWithDefault(msg, 11, ""),
    gameNight: (f = msg.getGameNight()) && proto.access_management.GameNight.toObject(includeInstance, f),
    gameNightEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    version: jspb.Message.getFieldWithDefault(msg, 14, 0),
    communityType: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetCommunityResponse}
 */
proto.access_management.GetCommunityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetCommunityResponse;
  return proto.access_management.GetCommunityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetCommunityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetCommunityResponse}
 */
proto.access_management.GetCommunityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addAdmins(value);
      break;
    case 6:
      var value = /** @type {!proto.access_management.CommunityGating} */ (reader.readEnum());
      msg.setGating(value);
      break;
    case 7:
      var value = /** @type {!proto.access_management.CommunityStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 8:
      var value = msg.getBillingInfoMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 9:
      var value = new proto.access_management.CommunityGate;
      reader.readMessage(value,proto.access_management.CommunityGate.deserializeBinaryFromReader);
      msg.addGates(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoUrl(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setBannerUrl(value);
      break;
    case 12:
      var value = new proto.access_management.GameNight;
      reader.readMessage(value,proto.access_management.GameNight.deserializeBinaryFromReader);
      msg.setGameNight(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGameNightEnabled(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVersion(value);
      break;
    case 15:
      var value = /** @type {!proto.access_management.CommunityType} */ (reader.readEnum());
      msg.setCommunityType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetCommunityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetCommunityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetCommunityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAdminsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getGating();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getBillingInfoMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(8, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getGatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.access_management.CommunityGate.serializeBinaryToWriter
    );
  }
  f = message.getLogoUrl();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBannerUrl();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getGameNight();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.access_management.GameNight.serializeBinaryToWriter
    );
  }
  f = message.getGameNightEnabled();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint64(
      14,
      f
    );
  }
  f = message.getCommunityType();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.GetCommunityResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityResponse} returns this
 */
proto.access_management.GetCommunityResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.access_management.GetCommunityResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityResponse} returns this
 */
proto.access_management.GetCommunityResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.access_management.GetCommunityResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityResponse} returns this
 */
proto.access_management.GetCommunityResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string slug = 4;
 * @return {string}
 */
proto.access_management.GetCommunityResponse.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityResponse} returns this
 */
proto.access_management.GetCommunityResponse.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string admins = 5;
 * @return {!Array<string>}
 */
proto.access_management.GetCommunityResponse.prototype.getAdminsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.access_management.GetCommunityResponse} returns this
 */
proto.access_management.GetCommunityResponse.prototype.setAdminsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.access_management.GetCommunityResponse} returns this
 */
proto.access_management.GetCommunityResponse.prototype.addAdmins = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.GetCommunityResponse} returns this
 */
proto.access_management.GetCommunityResponse.prototype.clearAdminsList = function() {
  return this.setAdminsList([]);
};


/**
 * optional CommunityGating gating = 6;
 * @return {!proto.access_management.CommunityGating}
 */
proto.access_management.GetCommunityResponse.prototype.getGating = function() {
  return /** @type {!proto.access_management.CommunityGating} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.access_management.CommunityGating} value
 * @return {!proto.access_management.GetCommunityResponse} returns this
 */
proto.access_management.GetCommunityResponse.prototype.setGating = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional CommunityStatus status = 7;
 * @return {!proto.access_management.CommunityStatus}
 */
proto.access_management.GetCommunityResponse.prototype.getStatus = function() {
  return /** @type {!proto.access_management.CommunityStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.access_management.CommunityStatus} value
 * @return {!proto.access_management.GetCommunityResponse} returns this
 */
proto.access_management.GetCommunityResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * map<string, string> billing_info = 8;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.access_management.GetCommunityResponse.prototype.getBillingInfoMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 8, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.access_management.GetCommunityResponse} returns this
 */
proto.access_management.GetCommunityResponse.prototype.clearBillingInfoMap = function() {
  this.getBillingInfoMap().clear();
  return this;};


/**
 * repeated CommunityGate gates = 9;
 * @return {!Array<!proto.access_management.CommunityGate>}
 */
proto.access_management.GetCommunityResponse.prototype.getGatesList = function() {
  return /** @type{!Array<!proto.access_management.CommunityGate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.access_management.CommunityGate, 9));
};


/**
 * @param {!Array<!proto.access_management.CommunityGate>} value
 * @return {!proto.access_management.GetCommunityResponse} returns this
*/
proto.access_management.GetCommunityResponse.prototype.setGatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.access_management.CommunityGate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.access_management.CommunityGate}
 */
proto.access_management.GetCommunityResponse.prototype.addGates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.access_management.CommunityGate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.GetCommunityResponse} returns this
 */
proto.access_management.GetCommunityResponse.prototype.clearGatesList = function() {
  return this.setGatesList([]);
};


/**
 * optional string logo_url = 10;
 * @return {string}
 */
proto.access_management.GetCommunityResponse.prototype.getLogoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityResponse} returns this
 */
proto.access_management.GetCommunityResponse.prototype.setLogoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string banner_url = 11;
 * @return {string}
 */
proto.access_management.GetCommunityResponse.prototype.getBannerUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityResponse} returns this
 */
proto.access_management.GetCommunityResponse.prototype.setBannerUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional GameNight game_night = 12;
 * @return {?proto.access_management.GameNight}
 */
proto.access_management.GetCommunityResponse.prototype.getGameNight = function() {
  return /** @type{?proto.access_management.GameNight} */ (
    jspb.Message.getWrapperField(this, proto.access_management.GameNight, 12));
};


/**
 * @param {?proto.access_management.GameNight|undefined} value
 * @return {!proto.access_management.GetCommunityResponse} returns this
*/
proto.access_management.GetCommunityResponse.prototype.setGameNight = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.GetCommunityResponse} returns this
 */
proto.access_management.GetCommunityResponse.prototype.clearGameNight = function() {
  return this.setGameNight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.GetCommunityResponse.prototype.hasGameNight = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool game_night_enabled = 13;
 * @return {boolean}
 */
proto.access_management.GetCommunityResponse.prototype.getGameNightEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.access_management.GetCommunityResponse} returns this
 */
proto.access_management.GetCommunityResponse.prototype.setGameNightEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional uint64 version = 14;
 * @return {number}
 */
proto.access_management.GetCommunityResponse.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.GetCommunityResponse} returns this
 */
proto.access_management.GetCommunityResponse.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional CommunityType community_type = 15;
 * @return {!proto.access_management.CommunityType}
 */
proto.access_management.GetCommunityResponse.prototype.getCommunityType = function() {
  return /** @type {!proto.access_management.CommunityType} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.access_management.CommunityType} value
 * @return {!proto.access_management.GetCommunityResponse} returns this
 */
proto.access_management.GetCommunityResponse.prototype.setCommunityType = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetCommunityProfileBySlugRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetCommunityProfileBySlugRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetCommunityProfileBySlugRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityProfileBySlugRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    slug: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetCommunityProfileBySlugRequest}
 */
proto.access_management.GetCommunityProfileBySlugRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetCommunityProfileBySlugRequest;
  return proto.access_management.GetCommunityProfileBySlugRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetCommunityProfileBySlugRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetCommunityProfileBySlugRequest}
 */
proto.access_management.GetCommunityProfileBySlugRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetCommunityProfileBySlugRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetCommunityProfileBySlugRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetCommunityProfileBySlugRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityProfileBySlugRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string slug = 1;
 * @return {string}
 */
proto.access_management.GetCommunityProfileBySlugRequest.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityProfileBySlugRequest} returns this
 */
proto.access_management.GetCommunityProfileBySlugRequest.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.GetCommunityProfileBySlugResponse.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetCommunityProfileBySlugResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetCommunityProfileBySlugResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetCommunityProfileBySlugResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityProfileBySlugResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    slug: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    onlineMembers: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalMembers: jspb.Message.getFieldWithDefault(msg, 5, 0),
    roomCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    gatesList: jspb.Message.toObjectList(msg.getGatesList(),
    proto.access_management.CommunityGate.toObject, includeInstance),
    logoUrl: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetCommunityProfileBySlugResponse}
 */
proto.access_management.GetCommunityProfileBySlugResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetCommunityProfileBySlugResponse;
  return proto.access_management.GetCommunityProfileBySlugResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetCommunityProfileBySlugResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetCommunityProfileBySlugResponse}
 */
proto.access_management.GetCommunityProfileBySlugResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOnlineMembers(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalMembers(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomCount(value);
      break;
    case 7:
      var value = new proto.access_management.CommunityGate;
      reader.readMessage(value,proto.access_management.CommunityGate.deserializeBinaryFromReader);
      msg.addGates(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetCommunityProfileBySlugResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetCommunityProfileBySlugResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetCommunityProfileBySlugResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityProfileBySlugResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOnlineMembers();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getTotalMembers();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getRoomCount();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getGatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.access_management.CommunityGate.serializeBinaryToWriter
    );
  }
  f = message.getLogoUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.GetCommunityProfileBySlugResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityProfileBySlugResponse} returns this
 */
proto.access_management.GetCommunityProfileBySlugResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string slug = 2;
 * @return {string}
 */
proto.access_management.GetCommunityProfileBySlugResponse.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityProfileBySlugResponse} returns this
 */
proto.access_management.GetCommunityProfileBySlugResponse.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.access_management.GetCommunityProfileBySlugResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityProfileBySlugResponse} returns this
 */
proto.access_management.GetCommunityProfileBySlugResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 online_members = 4;
 * @return {number}
 */
proto.access_management.GetCommunityProfileBySlugResponse.prototype.getOnlineMembers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.GetCommunityProfileBySlugResponse} returns this
 */
proto.access_management.GetCommunityProfileBySlugResponse.prototype.setOnlineMembers = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 total_members = 5;
 * @return {number}
 */
proto.access_management.GetCommunityProfileBySlugResponse.prototype.getTotalMembers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.GetCommunityProfileBySlugResponse} returns this
 */
proto.access_management.GetCommunityProfileBySlugResponse.prototype.setTotalMembers = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 room_count = 6;
 * @return {number}
 */
proto.access_management.GetCommunityProfileBySlugResponse.prototype.getRoomCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.GetCommunityProfileBySlugResponse} returns this
 */
proto.access_management.GetCommunityProfileBySlugResponse.prototype.setRoomCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated CommunityGate gates = 7;
 * @return {!Array<!proto.access_management.CommunityGate>}
 */
proto.access_management.GetCommunityProfileBySlugResponse.prototype.getGatesList = function() {
  return /** @type{!Array<!proto.access_management.CommunityGate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.access_management.CommunityGate, 7));
};


/**
 * @param {!Array<!proto.access_management.CommunityGate>} value
 * @return {!proto.access_management.GetCommunityProfileBySlugResponse} returns this
*/
proto.access_management.GetCommunityProfileBySlugResponse.prototype.setGatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.access_management.CommunityGate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.access_management.CommunityGate}
 */
proto.access_management.GetCommunityProfileBySlugResponse.prototype.addGates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.access_management.CommunityGate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.GetCommunityProfileBySlugResponse} returns this
 */
proto.access_management.GetCommunityProfileBySlugResponse.prototype.clearGatesList = function() {
  return this.setGatesList([]);
};


/**
 * optional string logo_url = 8;
 * @return {string}
 */
proto.access_management.GetCommunityProfileBySlugResponse.prototype.getLogoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityProfileBySlugResponse} returns this
 */
proto.access_management.GetCommunityProfileBySlugResponse.prototype.setLogoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.UpdateCommunityRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.UpdateCommunityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.UpdateCommunityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.UpdateCommunityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UpdateCommunityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    gatesList: jspb.Message.toObjectList(msg.getGatesList(),
    proto.access_management.CommunityGate.toObject, includeInstance),
    logoVersion: jspb.Message.getFieldWithDefault(msg, 5, ""),
    bannerVersion: jspb.Message.getFieldWithDefault(msg, 6, ""),
    gameNight: (f = msg.getGameNight()) && proto.access_management.GameNight.toObject(includeInstance, f),
    gameNightEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    version: jspb.Message.getFieldWithDefault(msg, 9, 0),
    communityType: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.UpdateCommunityRequest}
 */
proto.access_management.UpdateCommunityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.UpdateCommunityRequest;
  return proto.access_management.UpdateCommunityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.UpdateCommunityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.UpdateCommunityRequest}
 */
proto.access_management.UpdateCommunityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new proto.access_management.CommunityGate;
      reader.readMessage(value,proto.access_management.CommunityGate.deserializeBinaryFromReader);
      msg.addGates(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoVersion(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBannerVersion(value);
      break;
    case 7:
      var value = new proto.access_management.GameNight;
      reader.readMessage(value,proto.access_management.GameNight.deserializeBinaryFromReader);
      msg.setGameNight(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGameNightEnabled(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVersion(value);
      break;
    case 10:
      var value = /** @type {!proto.access_management.CommunityType} */ (reader.readEnum());
      msg.setCommunityType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.UpdateCommunityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.UpdateCommunityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.UpdateCommunityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UpdateCommunityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.access_management.CommunityGate.serializeBinaryToWriter
    );
  }
  f = message.getLogoVersion();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBannerVersion();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getGameNight();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.access_management.GameNight.serializeBinaryToWriter
    );
  }
  f = message.getGameNightEnabled();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint64(
      9,
      f
    );
  }
  f = message.getCommunityType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.UpdateCommunityRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateCommunityRequest} returns this
 */
proto.access_management.UpdateCommunityRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.access_management.UpdateCommunityRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateCommunityRequest} returns this
 */
proto.access_management.UpdateCommunityRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.access_management.UpdateCommunityRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateCommunityRequest} returns this
 */
proto.access_management.UpdateCommunityRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated CommunityGate gates = 4;
 * @return {!Array<!proto.access_management.CommunityGate>}
 */
proto.access_management.UpdateCommunityRequest.prototype.getGatesList = function() {
  return /** @type{!Array<!proto.access_management.CommunityGate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.access_management.CommunityGate, 4));
};


/**
 * @param {!Array<!proto.access_management.CommunityGate>} value
 * @return {!proto.access_management.UpdateCommunityRequest} returns this
*/
proto.access_management.UpdateCommunityRequest.prototype.setGatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.access_management.CommunityGate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.access_management.CommunityGate}
 */
proto.access_management.UpdateCommunityRequest.prototype.addGates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.access_management.CommunityGate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.UpdateCommunityRequest} returns this
 */
proto.access_management.UpdateCommunityRequest.prototype.clearGatesList = function() {
  return this.setGatesList([]);
};


/**
 * optional string logo_version = 5;
 * @return {string}
 */
proto.access_management.UpdateCommunityRequest.prototype.getLogoVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateCommunityRequest} returns this
 */
proto.access_management.UpdateCommunityRequest.prototype.setLogoVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string banner_version = 6;
 * @return {string}
 */
proto.access_management.UpdateCommunityRequest.prototype.getBannerVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateCommunityRequest} returns this
 */
proto.access_management.UpdateCommunityRequest.prototype.setBannerVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional GameNight game_night = 7;
 * @return {?proto.access_management.GameNight}
 */
proto.access_management.UpdateCommunityRequest.prototype.getGameNight = function() {
  return /** @type{?proto.access_management.GameNight} */ (
    jspb.Message.getWrapperField(this, proto.access_management.GameNight, 7));
};


/**
 * @param {?proto.access_management.GameNight|undefined} value
 * @return {!proto.access_management.UpdateCommunityRequest} returns this
*/
proto.access_management.UpdateCommunityRequest.prototype.setGameNight = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.UpdateCommunityRequest} returns this
 */
proto.access_management.UpdateCommunityRequest.prototype.clearGameNight = function() {
  return this.setGameNight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.UpdateCommunityRequest.prototype.hasGameNight = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool game_night_enabled = 8;
 * @return {boolean}
 */
proto.access_management.UpdateCommunityRequest.prototype.getGameNightEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.access_management.UpdateCommunityRequest} returns this
 */
proto.access_management.UpdateCommunityRequest.prototype.setGameNightEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional uint64 version = 9;
 * @return {number}
 */
proto.access_management.UpdateCommunityRequest.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.UpdateCommunityRequest} returns this
 */
proto.access_management.UpdateCommunityRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional CommunityType community_type = 10;
 * @return {!proto.access_management.CommunityType}
 */
proto.access_management.UpdateCommunityRequest.prototype.getCommunityType = function() {
  return /** @type {!proto.access_management.CommunityType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.access_management.CommunityType} value
 * @return {!proto.access_management.UpdateCommunityRequest} returns this
 */
proto.access_management.UpdateCommunityRequest.prototype.setCommunityType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.UpdateCommunityResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.UpdateCommunityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.UpdateCommunityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.UpdateCommunityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UpdateCommunityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    slug: jspb.Message.getFieldWithDefault(msg, 4, ""),
    gatesList: jspb.Message.toObjectList(msg.getGatesList(),
    proto.access_management.CommunityGate.toObject, includeInstance),
    logoUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    bannerUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    gameNight: (f = msg.getGameNight()) && proto.access_management.GameNight.toObject(includeInstance, f),
    gameNightEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    version: jspb.Message.getFieldWithDefault(msg, 10, 0),
    communityType: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.UpdateCommunityResponse}
 */
proto.access_management.UpdateCommunityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.UpdateCommunityResponse;
  return proto.access_management.UpdateCommunityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.UpdateCommunityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.UpdateCommunityResponse}
 */
proto.access_management.UpdateCommunityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    case 5:
      var value = new proto.access_management.CommunityGate;
      reader.readMessage(value,proto.access_management.CommunityGate.deserializeBinaryFromReader);
      msg.addGates(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoUrl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBannerUrl(value);
      break;
    case 8:
      var value = new proto.access_management.GameNight;
      reader.readMessage(value,proto.access_management.GameNight.deserializeBinaryFromReader);
      msg.setGameNight(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGameNightEnabled(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVersion(value);
      break;
    case 11:
      var value = /** @type {!proto.access_management.CommunityType} */ (reader.readEnum());
      msg.setCommunityType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.UpdateCommunityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.UpdateCommunityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.UpdateCommunityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UpdateCommunityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.access_management.CommunityGate.serializeBinaryToWriter
    );
  }
  f = message.getLogoUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBannerUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getGameNight();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.access_management.GameNight.serializeBinaryToWriter
    );
  }
  f = message.getGameNightEnabled();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint64(
      10,
      f
    );
  }
  f = message.getCommunityType();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.UpdateCommunityResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateCommunityResponse} returns this
 */
proto.access_management.UpdateCommunityResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.access_management.UpdateCommunityResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateCommunityResponse} returns this
 */
proto.access_management.UpdateCommunityResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.access_management.UpdateCommunityResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateCommunityResponse} returns this
 */
proto.access_management.UpdateCommunityResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string slug = 4;
 * @return {string}
 */
proto.access_management.UpdateCommunityResponse.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateCommunityResponse} returns this
 */
proto.access_management.UpdateCommunityResponse.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated CommunityGate gates = 5;
 * @return {!Array<!proto.access_management.CommunityGate>}
 */
proto.access_management.UpdateCommunityResponse.prototype.getGatesList = function() {
  return /** @type{!Array<!proto.access_management.CommunityGate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.access_management.CommunityGate, 5));
};


/**
 * @param {!Array<!proto.access_management.CommunityGate>} value
 * @return {!proto.access_management.UpdateCommunityResponse} returns this
*/
proto.access_management.UpdateCommunityResponse.prototype.setGatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.access_management.CommunityGate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.access_management.CommunityGate}
 */
proto.access_management.UpdateCommunityResponse.prototype.addGates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.access_management.CommunityGate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.UpdateCommunityResponse} returns this
 */
proto.access_management.UpdateCommunityResponse.prototype.clearGatesList = function() {
  return this.setGatesList([]);
};


/**
 * optional string logo_url = 6;
 * @return {string}
 */
proto.access_management.UpdateCommunityResponse.prototype.getLogoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateCommunityResponse} returns this
 */
proto.access_management.UpdateCommunityResponse.prototype.setLogoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string banner_url = 7;
 * @return {string}
 */
proto.access_management.UpdateCommunityResponse.prototype.getBannerUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateCommunityResponse} returns this
 */
proto.access_management.UpdateCommunityResponse.prototype.setBannerUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional GameNight game_night = 8;
 * @return {?proto.access_management.GameNight}
 */
proto.access_management.UpdateCommunityResponse.prototype.getGameNight = function() {
  return /** @type{?proto.access_management.GameNight} */ (
    jspb.Message.getWrapperField(this, proto.access_management.GameNight, 8));
};


/**
 * @param {?proto.access_management.GameNight|undefined} value
 * @return {!proto.access_management.UpdateCommunityResponse} returns this
*/
proto.access_management.UpdateCommunityResponse.prototype.setGameNight = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.UpdateCommunityResponse} returns this
 */
proto.access_management.UpdateCommunityResponse.prototype.clearGameNight = function() {
  return this.setGameNight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.UpdateCommunityResponse.prototype.hasGameNight = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool game_night_enabled = 9;
 * @return {boolean}
 */
proto.access_management.UpdateCommunityResponse.prototype.getGameNightEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.access_management.UpdateCommunityResponse} returns this
 */
proto.access_management.UpdateCommunityResponse.prototype.setGameNightEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional uint64 version = 10;
 * @return {number}
 */
proto.access_management.UpdateCommunityResponse.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.UpdateCommunityResponse} returns this
 */
proto.access_management.UpdateCommunityResponse.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional CommunityType community_type = 11;
 * @return {!proto.access_management.CommunityType}
 */
proto.access_management.UpdateCommunityResponse.prototype.getCommunityType = function() {
  return /** @type {!proto.access_management.CommunityType} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.access_management.CommunityType} value
 * @return {!proto.access_management.UpdateCommunityResponse} returns this
 */
proto.access_management.UpdateCommunityResponse.prototype.setCommunityType = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.AddCommunityAdminRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.AddCommunityAdminRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.AddCommunityAdminRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.AddCommunityAdminRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    communityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.AddCommunityAdminRequest}
 */
proto.access_management.AddCommunityAdminRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.AddCommunityAdminRequest;
  return proto.access_management.AddCommunityAdminRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.AddCommunityAdminRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.AddCommunityAdminRequest}
 */
proto.access_management.AddCommunityAdminRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommunityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.AddCommunityAdminRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.AddCommunityAdminRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.AddCommunityAdminRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.AddCommunityAdminRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommunityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string community_id = 1;
 * @return {string}
 */
proto.access_management.AddCommunityAdminRequest.prototype.getCommunityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.AddCommunityAdminRequest} returns this
 */
proto.access_management.AddCommunityAdminRequest.prototype.setCommunityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.access_management.AddCommunityAdminRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.AddCommunityAdminRequest} returns this
 */
proto.access_management.AddCommunityAdminRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.AddCommunityAdminResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.AddCommunityAdminResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.AddCommunityAdminResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.AddCommunityAdminResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.AddCommunityAdminResponse}
 */
proto.access_management.AddCommunityAdminResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.AddCommunityAdminResponse;
  return proto.access_management.AddCommunityAdminResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.AddCommunityAdminResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.AddCommunityAdminResponse}
 */
proto.access_management.AddCommunityAdminResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.AddCommunityAdminResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.AddCommunityAdminResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.AddCommunityAdminResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.AddCommunityAdminResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.RemoveCommunityAdminRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.RemoveCommunityAdminRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.RemoveCommunityAdminRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.RemoveCommunityAdminRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    communityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.RemoveCommunityAdminRequest}
 */
proto.access_management.RemoveCommunityAdminRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.RemoveCommunityAdminRequest;
  return proto.access_management.RemoveCommunityAdminRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.RemoveCommunityAdminRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.RemoveCommunityAdminRequest}
 */
proto.access_management.RemoveCommunityAdminRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommunityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.RemoveCommunityAdminRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.RemoveCommunityAdminRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.RemoveCommunityAdminRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.RemoveCommunityAdminRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommunityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string community_id = 1;
 * @return {string}
 */
proto.access_management.RemoveCommunityAdminRequest.prototype.getCommunityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.RemoveCommunityAdminRequest} returns this
 */
proto.access_management.RemoveCommunityAdminRequest.prototype.setCommunityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.access_management.RemoveCommunityAdminRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.RemoveCommunityAdminRequest} returns this
 */
proto.access_management.RemoveCommunityAdminRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.RemoveCommunityAdminResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.RemoveCommunityAdminResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.RemoveCommunityAdminResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.RemoveCommunityAdminResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.RemoveCommunityAdminResponse}
 */
proto.access_management.RemoveCommunityAdminResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.RemoveCommunityAdminResponse;
  return proto.access_management.RemoveCommunityAdminResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.RemoveCommunityAdminResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.RemoveCommunityAdminResponse}
 */
proto.access_management.RemoveCommunityAdminResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.RemoveCommunityAdminResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.RemoveCommunityAdminResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.RemoveCommunityAdminResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.RemoveCommunityAdminResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.UpdateCommunityBillingInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.UpdateCommunityBillingInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.UpdateCommunityBillingInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UpdateCommunityBillingInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    billingInfoMap: (f = msg.getBillingInfoMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.UpdateCommunityBillingInfoRequest}
 */
proto.access_management.UpdateCommunityBillingInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.UpdateCommunityBillingInfoRequest;
  return proto.access_management.UpdateCommunityBillingInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.UpdateCommunityBillingInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.UpdateCommunityBillingInfoRequest}
 */
proto.access_management.UpdateCommunityBillingInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = msg.getBillingInfoMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.UpdateCommunityBillingInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.UpdateCommunityBillingInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.UpdateCommunityBillingInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UpdateCommunityBillingInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBillingInfoMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.UpdateCommunityBillingInfoRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateCommunityBillingInfoRequest} returns this
 */
proto.access_management.UpdateCommunityBillingInfoRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<string, string> billing_info = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.access_management.UpdateCommunityBillingInfoRequest.prototype.getBillingInfoMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.access_management.UpdateCommunityBillingInfoRequest} returns this
 */
proto.access_management.UpdateCommunityBillingInfoRequest.prototype.clearBillingInfoMap = function() {
  this.getBillingInfoMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.UpdateCommunityBillingInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.UpdateCommunityBillingInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.UpdateCommunityBillingInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UpdateCommunityBillingInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    billingInfoMap: (f = msg.getBillingInfoMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.UpdateCommunityBillingInfoResponse}
 */
proto.access_management.UpdateCommunityBillingInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.UpdateCommunityBillingInfoResponse;
  return proto.access_management.UpdateCommunityBillingInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.UpdateCommunityBillingInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.UpdateCommunityBillingInfoResponse}
 */
proto.access_management.UpdateCommunityBillingInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = msg.getBillingInfoMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.UpdateCommunityBillingInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.UpdateCommunityBillingInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.UpdateCommunityBillingInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UpdateCommunityBillingInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBillingInfoMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.UpdateCommunityBillingInfoResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateCommunityBillingInfoResponse} returns this
 */
proto.access_management.UpdateCommunityBillingInfoResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<string, string> billing_info = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.access_management.UpdateCommunityBillingInfoResponse.prototype.getBillingInfoMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.access_management.UpdateCommunityBillingInfoResponse} returns this
 */
proto.access_management.UpdateCommunityBillingInfoResponse.prototype.clearBillingInfoMap = function() {
  this.getBillingInfoMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.DeleteCommunityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.DeleteCommunityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.DeleteCommunityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.DeleteCommunityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.DeleteCommunityRequest}
 */
proto.access_management.DeleteCommunityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.DeleteCommunityRequest;
  return proto.access_management.DeleteCommunityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.DeleteCommunityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.DeleteCommunityRequest}
 */
proto.access_management.DeleteCommunityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.DeleteCommunityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.DeleteCommunityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.DeleteCommunityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.DeleteCommunityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.DeleteCommunityRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.DeleteCommunityRequest} returns this
 */
proto.access_management.DeleteCommunityRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.DeleteCommunityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.DeleteCommunityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.DeleteCommunityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.DeleteCommunityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.DeleteCommunityResponse}
 */
proto.access_management.DeleteCommunityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.DeleteCommunityResponse;
  return proto.access_management.DeleteCommunityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.DeleteCommunityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.DeleteCommunityResponse}
 */
proto.access_management.DeleteCommunityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.DeleteCommunityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.DeleteCommunityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.DeleteCommunityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.DeleteCommunityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetCommunityMetricsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetCommunityMetricsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetCommunityMetricsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityMetricsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetCommunityMetricsRequest}
 */
proto.access_management.GetCommunityMetricsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetCommunityMetricsRequest;
  return proto.access_management.GetCommunityMetricsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetCommunityMetricsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetCommunityMetricsRequest}
 */
proto.access_management.GetCommunityMetricsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetCommunityMetricsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetCommunityMetricsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetCommunityMetricsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityMetricsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.GetCommunityMetricsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityMetricsRequest} returns this
 */
proto.access_management.GetCommunityMetricsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetCommunityMetricsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetCommunityMetricsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetCommunityMetricsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityMetricsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    onlineMembers: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalMembers: jspb.Message.getFieldWithDefault(msg, 3, 0),
    roomCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    mau: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetCommunityMetricsResponse}
 */
proto.access_management.GetCommunityMetricsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetCommunityMetricsResponse;
  return proto.access_management.GetCommunityMetricsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetCommunityMetricsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetCommunityMetricsResponse}
 */
proto.access_management.GetCommunityMetricsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOnlineMembers(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalMembers(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomCount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMau(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetCommunityMetricsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetCommunityMetricsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetCommunityMetricsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityMetricsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOnlineMembers();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTotalMembers();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRoomCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getMau();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.GetCommunityMetricsResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityMetricsResponse} returns this
 */
proto.access_management.GetCommunityMetricsResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 online_members = 2;
 * @return {number}
 */
proto.access_management.GetCommunityMetricsResponse.prototype.getOnlineMembers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.GetCommunityMetricsResponse} returns this
 */
proto.access_management.GetCommunityMetricsResponse.prototype.setOnlineMembers = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 total_members = 3;
 * @return {number}
 */
proto.access_management.GetCommunityMetricsResponse.prototype.getTotalMembers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.GetCommunityMetricsResponse} returns this
 */
proto.access_management.GetCommunityMetricsResponse.prototype.setTotalMembers = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 room_count = 4;
 * @return {number}
 */
proto.access_management.GetCommunityMetricsResponse.prototype.getRoomCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.GetCommunityMetricsResponse} returns this
 */
proto.access_management.GetCommunityMetricsResponse.prototype.setRoomCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 mau = 5;
 * @return {number}
 */
proto.access_management.GetCommunityMetricsResponse.prototype.getMau = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.GetCommunityMetricsResponse} returns this
 */
proto.access_management.GetCommunityMetricsResponse.prototype.setMau = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.ListCommunityUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.ListCommunityUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.ListCommunityUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.ListCommunityUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.ListCommunityUsersRequest}
 */
proto.access_management.ListCommunityUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.ListCommunityUsersRequest;
  return proto.access_management.ListCommunityUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.ListCommunityUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.ListCommunityUsersRequest}
 */
proto.access_management.ListCommunityUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.ListCommunityUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.ListCommunityUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.ListCommunityUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.ListCommunityUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.ListCommunityUsersRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.ListCommunityUsersRequest} returns this
 */
proto.access_management.ListCommunityUsersRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.CommunityUser.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.CommunityUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.CommunityUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CommunityUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    username: jspb.Message.getFieldWithDefault(msg, 3, ""),
    role: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lastActive: jspb.Message.getFieldWithDefault(msg, 5, 0),
    avatarUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.CommunityUser}
 */
proto.access_management.CommunityUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.CommunityUser;
  return proto.access_management.CommunityUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.CommunityUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.CommunityUser}
 */
proto.access_management.CommunityUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRole(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastActive(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatarUrl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.CommunityUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.CommunityUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.CommunityUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CommunityUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRole();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLastActive();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getAvatarUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.CommunityUser.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CommunityUser} returns this
 */
proto.access_management.CommunityUser.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.access_management.CommunityUser.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CommunityUser} returns this
 */
proto.access_management.CommunityUser.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string username = 3;
 * @return {string}
 */
proto.access_management.CommunityUser.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CommunityUser} returns this
 */
proto.access_management.CommunityUser.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string role = 4;
 * @return {string}
 */
proto.access_management.CommunityUser.prototype.getRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CommunityUser} returns this
 */
proto.access_management.CommunityUser.prototype.setRole = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 last_active = 5;
 * @return {number}
 */
proto.access_management.CommunityUser.prototype.getLastActive = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.CommunityUser} returns this
 */
proto.access_management.CommunityUser.prototype.setLastActive = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string avatar_url = 6;
 * @return {string}
 */
proto.access_management.CommunityUser.prototype.getAvatarUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CommunityUser} returns this
 */
proto.access_management.CommunityUser.prototype.setAvatarUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string display_name = 7;
 * @return {string}
 */
proto.access_management.CommunityUser.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CommunityUser} returns this
 */
proto.access_management.CommunityUser.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.InvitedCommunityUser.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.InvitedCommunityUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.InvitedCommunityUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InvitedCommunityUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    role: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.InvitedCommunityUser}
 */
proto.access_management.InvitedCommunityUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.InvitedCommunityUser;
  return proto.access_management.InvitedCommunityUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.InvitedCommunityUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.InvitedCommunityUser}
 */
proto.access_management.InvitedCommunityUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.InvitedCommunityUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.InvitedCommunityUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.InvitedCommunityUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InvitedCommunityUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRole();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.InvitedCommunityUser.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.InvitedCommunityUser} returns this
 */
proto.access_management.InvitedCommunityUser.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.access_management.InvitedCommunityUser.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.InvitedCommunityUser} returns this
 */
proto.access_management.InvitedCommunityUser.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string role = 3;
 * @return {string}
 */
proto.access_management.InvitedCommunityUser.prototype.getRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.InvitedCommunityUser} returns this
 */
proto.access_management.InvitedCommunityUser.prototype.setRole = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.ListCommunityUsersResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.ListCommunityUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.ListCommunityUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.ListCommunityUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.ListCommunityUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.access_management.CommunityUser.toObject, includeInstance),
    invitedUsersList: jspb.Message.toObjectList(msg.getInvitedUsersList(),
    proto.access_management.InvitedCommunityUser.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.ListCommunityUsersResponse}
 */
proto.access_management.ListCommunityUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.ListCommunityUsersResponse;
  return proto.access_management.ListCommunityUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.ListCommunityUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.ListCommunityUsersResponse}
 */
proto.access_management.ListCommunityUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.access_management.CommunityUser;
      reader.readMessage(value,proto.access_management.CommunityUser.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 2:
      var value = new proto.access_management.InvitedCommunityUser;
      reader.readMessage(value,proto.access_management.InvitedCommunityUser.deserializeBinaryFromReader);
      msg.addInvitedUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.ListCommunityUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.ListCommunityUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.ListCommunityUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.ListCommunityUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.access_management.CommunityUser.serializeBinaryToWriter
    );
  }
  f = message.getInvitedUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.access_management.InvitedCommunityUser.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CommunityUser users = 1;
 * @return {!Array<!proto.access_management.CommunityUser>}
 */
proto.access_management.ListCommunityUsersResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.access_management.CommunityUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.access_management.CommunityUser, 1));
};


/**
 * @param {!Array<!proto.access_management.CommunityUser>} value
 * @return {!proto.access_management.ListCommunityUsersResponse} returns this
*/
proto.access_management.ListCommunityUsersResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.access_management.CommunityUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.access_management.CommunityUser}
 */
proto.access_management.ListCommunityUsersResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.access_management.CommunityUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.ListCommunityUsersResponse} returns this
 */
proto.access_management.ListCommunityUsersResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * repeated InvitedCommunityUser invited_users = 2;
 * @return {!Array<!proto.access_management.InvitedCommunityUser>}
 */
proto.access_management.ListCommunityUsersResponse.prototype.getInvitedUsersList = function() {
  return /** @type{!Array<!proto.access_management.InvitedCommunityUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.access_management.InvitedCommunityUser, 2));
};


/**
 * @param {!Array<!proto.access_management.InvitedCommunityUser>} value
 * @return {!proto.access_management.ListCommunityUsersResponse} returns this
*/
proto.access_management.ListCommunityUsersResponse.prototype.setInvitedUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.access_management.InvitedCommunityUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.access_management.InvitedCommunityUser}
 */
proto.access_management.ListCommunityUsersResponse.prototype.addInvitedUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.access_management.InvitedCommunityUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.ListCommunityUsersResponse} returns this
 */
proto.access_management.ListCommunityUsersResponse.prototype.clearInvitedUsersList = function() {
  return this.setInvitedUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.CommunitySendInviteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.CommunitySendInviteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.CommunitySendInviteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CommunitySendInviteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    communityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isAdmin: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.CommunitySendInviteRequest}
 */
proto.access_management.CommunitySendInviteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.CommunitySendInviteRequest;
  return proto.access_management.CommunitySendInviteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.CommunitySendInviteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.CommunitySendInviteRequest}
 */
proto.access_management.CommunitySendInviteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommunityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAdmin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.CommunitySendInviteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.CommunitySendInviteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.CommunitySendInviteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CommunitySendInviteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommunityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsAdmin();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string community_id = 1;
 * @return {string}
 */
proto.access_management.CommunitySendInviteRequest.prototype.getCommunityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CommunitySendInviteRequest} returns this
 */
proto.access_management.CommunitySendInviteRequest.prototype.setCommunityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.access_management.CommunitySendInviteRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CommunitySendInviteRequest} returns this
 */
proto.access_management.CommunitySendInviteRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_admin = 3;
 * @return {boolean}
 */
proto.access_management.CommunitySendInviteRequest.prototype.getIsAdmin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.access_management.CommunitySendInviteRequest} returns this
 */
proto.access_management.CommunitySendInviteRequest.prototype.setIsAdmin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.CommunitySendInviteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.CommunitySendInviteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.CommunitySendInviteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CommunitySendInviteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.CommunitySendInviteResponse}
 */
proto.access_management.CommunitySendInviteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.CommunitySendInviteResponse;
  return proto.access_management.CommunitySendInviteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.CommunitySendInviteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.CommunitySendInviteResponse}
 */
proto.access_management.CommunitySendInviteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.CommunitySendInviteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.CommunitySendInviteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.CommunitySendInviteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CommunitySendInviteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.CommunityCancelInviteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.CommunityCancelInviteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.CommunityCancelInviteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CommunityCancelInviteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    communityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inviteId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.CommunityCancelInviteRequest}
 */
proto.access_management.CommunityCancelInviteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.CommunityCancelInviteRequest;
  return proto.access_management.CommunityCancelInviteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.CommunityCancelInviteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.CommunityCancelInviteRequest}
 */
proto.access_management.CommunityCancelInviteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommunityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInviteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.CommunityCancelInviteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.CommunityCancelInviteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.CommunityCancelInviteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CommunityCancelInviteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommunityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInviteId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string community_id = 1;
 * @return {string}
 */
proto.access_management.CommunityCancelInviteRequest.prototype.getCommunityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CommunityCancelInviteRequest} returns this
 */
proto.access_management.CommunityCancelInviteRequest.prototype.setCommunityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string invite_id = 2;
 * @return {string}
 */
proto.access_management.CommunityCancelInviteRequest.prototype.getInviteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CommunityCancelInviteRequest} returns this
 */
proto.access_management.CommunityCancelInviteRequest.prototype.setInviteId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.CommunityCancelInviteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.CommunityCancelInviteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.CommunityCancelInviteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CommunityCancelInviteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.CommunityCancelInviteResponse}
 */
proto.access_management.CommunityCancelInviteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.CommunityCancelInviteResponse;
  return proto.access_management.CommunityCancelInviteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.CommunityCancelInviteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.CommunityCancelInviteResponse}
 */
proto.access_management.CommunityCancelInviteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.CommunityCancelInviteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.CommunityCancelInviteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.CommunityCancelInviteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CommunityCancelInviteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.CreateCommunityRoomRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.CreateCommunityRoomRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.CreateCommunityRoomRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CreateCommunityRoomRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    communityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    host: jspb.Message.getFieldWithDefault(msg, 3, ""),
    configMap: (f = msg.getConfigMap()) ? f.toObject(includeInstance, undefined) : [],
    pinned: (f = msg.getPinned()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    pb_private: (f = msg.getPrivate()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    roomType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    isTournament: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.CreateCommunityRoomRequest}
 */
proto.access_management.CreateCommunityRoomRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.CreateCommunityRoomRequest;
  return proto.access_management.CreateCommunityRoomRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.CreateCommunityRoomRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.CreateCommunityRoomRequest}
 */
proto.access_management.CreateCommunityRoomRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommunityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHost(value);
      break;
    case 4:
      var value = msg.getConfigMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setPinned(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setPrivate(value);
      break;
    case 7:
      var value = /** @type {!proto.access_management.RoomType} */ (reader.readEnum());
      msg.setRoomType(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTournament(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.CreateCommunityRoomRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.CreateCommunityRoomRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.CreateCommunityRoomRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CreateCommunityRoomRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommunityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHost();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getConfigMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getPinned();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getPrivate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getRoomType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getIsTournament();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional string community_id = 1;
 * @return {string}
 */
proto.access_management.CreateCommunityRoomRequest.prototype.getCommunityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateCommunityRoomRequest} returns this
 */
proto.access_management.CreateCommunityRoomRequest.prototype.setCommunityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.access_management.CreateCommunityRoomRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateCommunityRoomRequest} returns this
 */
proto.access_management.CreateCommunityRoomRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string host = 3;
 * @return {string}
 */
proto.access_management.CreateCommunityRoomRequest.prototype.getHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateCommunityRoomRequest} returns this
 */
proto.access_management.CreateCommunityRoomRequest.prototype.setHost = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * map<string, string> config = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.access_management.CreateCommunityRoomRequest.prototype.getConfigMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.access_management.CreateCommunityRoomRequest} returns this
 */
proto.access_management.CreateCommunityRoomRequest.prototype.clearConfigMap = function() {
  this.getConfigMap().clear();
  return this;};


/**
 * optional google.protobuf.BoolValue pinned = 5;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.access_management.CreateCommunityRoomRequest.prototype.getPinned = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 5));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.access_management.CreateCommunityRoomRequest} returns this
*/
proto.access_management.CreateCommunityRoomRequest.prototype.setPinned = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.CreateCommunityRoomRequest} returns this
 */
proto.access_management.CreateCommunityRoomRequest.prototype.clearPinned = function() {
  return this.setPinned(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.CreateCommunityRoomRequest.prototype.hasPinned = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.BoolValue private = 6;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.access_management.CreateCommunityRoomRequest.prototype.getPrivate = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 6));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.access_management.CreateCommunityRoomRequest} returns this
*/
proto.access_management.CreateCommunityRoomRequest.prototype.setPrivate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.CreateCommunityRoomRequest} returns this
 */
proto.access_management.CreateCommunityRoomRequest.prototype.clearPrivate = function() {
  return this.setPrivate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.CreateCommunityRoomRequest.prototype.hasPrivate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional RoomType room_type = 7;
 * @return {!proto.access_management.RoomType}
 */
proto.access_management.CreateCommunityRoomRequest.prototype.getRoomType = function() {
  return /** @type {!proto.access_management.RoomType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.access_management.RoomType} value
 * @return {!proto.access_management.CreateCommunityRoomRequest} returns this
 */
proto.access_management.CreateCommunityRoomRequest.prototype.setRoomType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional bool is_tournament = 8;
 * @return {boolean}
 */
proto.access_management.CreateCommunityRoomRequest.prototype.getIsTournament = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.access_management.CreateCommunityRoomRequest} returns this
 */
proto.access_management.CreateCommunityRoomRequest.prototype.setIsTournament = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.CreateCommunityRoomResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.CreateCommunityRoomResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.CreateCommunityRoomResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CreateCommunityRoomResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    communityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    host: jspb.Message.getFieldWithDefault(msg, 4, ""),
    configMap: (f = msg.getConfigMap()) ? f.toObject(includeInstance, undefined) : [],
    slug: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pinned: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    pb_private: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    roomType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    createdBy: jspb.Message.getFieldWithDefault(msg, 10, ""),
    tournamentId: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.CreateCommunityRoomResponse}
 */
proto.access_management.CreateCommunityRoomResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.CreateCommunityRoomResponse;
  return proto.access_management.CreateCommunityRoomResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.CreateCommunityRoomResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.CreateCommunityRoomResponse}
 */
proto.access_management.CreateCommunityRoomResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommunityId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setHost(value);
      break;
    case 5:
      var value = msg.getConfigMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPinned(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivate(value);
      break;
    case 9:
      var value = /** @type {!proto.access_management.RoomType} */ (reader.readEnum());
      msg.setRoomType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTournamentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.CreateCommunityRoomResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.CreateCommunityRoomResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.CreateCommunityRoomResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CreateCommunityRoomResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCommunityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHost();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getConfigMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPinned();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getPrivate();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getRoomType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTournamentId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.CreateCommunityRoomResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateCommunityRoomResponse} returns this
 */
proto.access_management.CreateCommunityRoomResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string community_id = 2;
 * @return {string}
 */
proto.access_management.CreateCommunityRoomResponse.prototype.getCommunityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateCommunityRoomResponse} returns this
 */
proto.access_management.CreateCommunityRoomResponse.prototype.setCommunityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.access_management.CreateCommunityRoomResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateCommunityRoomResponse} returns this
 */
proto.access_management.CreateCommunityRoomResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string host = 4;
 * @return {string}
 */
proto.access_management.CreateCommunityRoomResponse.prototype.getHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateCommunityRoomResponse} returns this
 */
proto.access_management.CreateCommunityRoomResponse.prototype.setHost = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * map<string, string> config = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.access_management.CreateCommunityRoomResponse.prototype.getConfigMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.access_management.CreateCommunityRoomResponse} returns this
 */
proto.access_management.CreateCommunityRoomResponse.prototype.clearConfigMap = function() {
  this.getConfigMap().clear();
  return this;};


/**
 * optional string slug = 6;
 * @return {string}
 */
proto.access_management.CreateCommunityRoomResponse.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateCommunityRoomResponse} returns this
 */
proto.access_management.CreateCommunityRoomResponse.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool pinned = 7;
 * @return {boolean}
 */
proto.access_management.CreateCommunityRoomResponse.prototype.getPinned = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.access_management.CreateCommunityRoomResponse} returns this
 */
proto.access_management.CreateCommunityRoomResponse.prototype.setPinned = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool private = 8;
 * @return {boolean}
 */
proto.access_management.CreateCommunityRoomResponse.prototype.getPrivate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.access_management.CreateCommunityRoomResponse} returns this
 */
proto.access_management.CreateCommunityRoomResponse.prototype.setPrivate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional RoomType room_type = 9;
 * @return {!proto.access_management.RoomType}
 */
proto.access_management.CreateCommunityRoomResponse.prototype.getRoomType = function() {
  return /** @type {!proto.access_management.RoomType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.access_management.RoomType} value
 * @return {!proto.access_management.CreateCommunityRoomResponse} returns this
 */
proto.access_management.CreateCommunityRoomResponse.prototype.setRoomType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string created_by = 10;
 * @return {string}
 */
proto.access_management.CreateCommunityRoomResponse.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateCommunityRoomResponse} returns this
 */
proto.access_management.CreateCommunityRoomResponse.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string tournament_id = 11;
 * @return {string}
 */
proto.access_management.CreateCommunityRoomResponse.prototype.getTournamentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateCommunityRoomResponse} returns this
 */
proto.access_management.CreateCommunityRoomResponse.prototype.setTournamentId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetCommunityRoomByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetCommunityRoomByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetCommunityRoomByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityRoomByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetCommunityRoomByIdRequest}
 */
proto.access_management.GetCommunityRoomByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetCommunityRoomByIdRequest;
  return proto.access_management.GetCommunityRoomByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetCommunityRoomByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetCommunityRoomByIdRequest}
 */
proto.access_management.GetCommunityRoomByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetCommunityRoomByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetCommunityRoomByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetCommunityRoomByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityRoomByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.GetCommunityRoomByIdRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityRoomByIdRequest} returns this
 */
proto.access_management.GetCommunityRoomByIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetCommunityRoomBySlugRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetCommunityRoomBySlugRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetCommunityRoomBySlugRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityRoomBySlugRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    communityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    slug: jspb.Message.getFieldWithDefault(msg, 2, ""),
    communitySlug: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetCommunityRoomBySlugRequest}
 */
proto.access_management.GetCommunityRoomBySlugRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetCommunityRoomBySlugRequest;
  return proto.access_management.GetCommunityRoomBySlugRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetCommunityRoomBySlugRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetCommunityRoomBySlugRequest}
 */
proto.access_management.GetCommunityRoomBySlugRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommunityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommunitySlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetCommunityRoomBySlugRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetCommunityRoomBySlugRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetCommunityRoomBySlugRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityRoomBySlugRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommunityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCommunitySlug();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string community_id = 1;
 * @return {string}
 */
proto.access_management.GetCommunityRoomBySlugRequest.prototype.getCommunityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityRoomBySlugRequest} returns this
 */
proto.access_management.GetCommunityRoomBySlugRequest.prototype.setCommunityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string slug = 2;
 * @return {string}
 */
proto.access_management.GetCommunityRoomBySlugRequest.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityRoomBySlugRequest} returns this
 */
proto.access_management.GetCommunityRoomBySlugRequest.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string community_slug = 3;
 * @return {string}
 */
proto.access_management.GetCommunityRoomBySlugRequest.prototype.getCommunitySlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityRoomBySlugRequest} returns this
 */
proto.access_management.GetCommunityRoomBySlugRequest.prototype.setCommunitySlug = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.GetCommunityRoomResponse.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetCommunityRoomResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetCommunityRoomResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetCommunityRoomResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityRoomResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    communityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    host: jspb.Message.getFieldWithDefault(msg, 4, ""),
    configMap: (f = msg.getConfigMap()) ? f.toObject(includeInstance, undefined) : [],
    slug: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pinned: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    pb_private: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    roomType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    createdBy: (f = msg.getCreatedBy()) && proto.access_management.RoomDisplayUser.toObject(includeInstance, f),
    displayUsersList: jspb.Message.toObjectList(msg.getDisplayUsersList(),
    proto.access_management.RoomDisplayUser.toObject, includeInstance),
    userCount: jspb.Message.getFieldWithDefault(msg, 12, 0),
    currentgame: jspb.Message.getFieldWithDefault(msg, 13, ""),
    tournamentId: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetCommunityRoomResponse}
 */
proto.access_management.GetCommunityRoomResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetCommunityRoomResponse;
  return proto.access_management.GetCommunityRoomResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetCommunityRoomResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetCommunityRoomResponse}
 */
proto.access_management.GetCommunityRoomResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommunityId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setHost(value);
      break;
    case 5:
      var value = msg.getConfigMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPinned(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivate(value);
      break;
    case 9:
      var value = /** @type {!proto.access_management.RoomType} */ (reader.readEnum());
      msg.setRoomType(value);
      break;
    case 10:
      var value = new proto.access_management.RoomDisplayUser;
      reader.readMessage(value,proto.access_management.RoomDisplayUser.deserializeBinaryFromReader);
      msg.setCreatedBy(value);
      break;
    case 11:
      var value = new proto.access_management.RoomDisplayUser;
      reader.readMessage(value,proto.access_management.RoomDisplayUser.deserializeBinaryFromReader);
      msg.addDisplayUsers(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserCount(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentgame(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setTournamentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetCommunityRoomResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetCommunityRoomResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetCommunityRoomResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityRoomResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCommunityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHost();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getConfigMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPinned();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getPrivate();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getRoomType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getCreatedBy();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.access_management.RoomDisplayUser.serializeBinaryToWriter
    );
  }
  f = message.getDisplayUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.access_management.RoomDisplayUser.serializeBinaryToWriter
    );
  }
  f = message.getUserCount();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getCurrentgame();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getTournamentId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.GetCommunityRoomResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityRoomResponse} returns this
 */
proto.access_management.GetCommunityRoomResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string community_id = 2;
 * @return {string}
 */
proto.access_management.GetCommunityRoomResponse.prototype.getCommunityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityRoomResponse} returns this
 */
proto.access_management.GetCommunityRoomResponse.prototype.setCommunityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.access_management.GetCommunityRoomResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityRoomResponse} returns this
 */
proto.access_management.GetCommunityRoomResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string host = 4;
 * @return {string}
 */
proto.access_management.GetCommunityRoomResponse.prototype.getHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityRoomResponse} returns this
 */
proto.access_management.GetCommunityRoomResponse.prototype.setHost = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * map<string, string> config = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.access_management.GetCommunityRoomResponse.prototype.getConfigMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.access_management.GetCommunityRoomResponse} returns this
 */
proto.access_management.GetCommunityRoomResponse.prototype.clearConfigMap = function() {
  this.getConfigMap().clear();
  return this;};


/**
 * optional string slug = 6;
 * @return {string}
 */
proto.access_management.GetCommunityRoomResponse.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityRoomResponse} returns this
 */
proto.access_management.GetCommunityRoomResponse.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool pinned = 7;
 * @return {boolean}
 */
proto.access_management.GetCommunityRoomResponse.prototype.getPinned = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.access_management.GetCommunityRoomResponse} returns this
 */
proto.access_management.GetCommunityRoomResponse.prototype.setPinned = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool private = 8;
 * @return {boolean}
 */
proto.access_management.GetCommunityRoomResponse.prototype.getPrivate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.access_management.GetCommunityRoomResponse} returns this
 */
proto.access_management.GetCommunityRoomResponse.prototype.setPrivate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional RoomType room_type = 9;
 * @return {!proto.access_management.RoomType}
 */
proto.access_management.GetCommunityRoomResponse.prototype.getRoomType = function() {
  return /** @type {!proto.access_management.RoomType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.access_management.RoomType} value
 * @return {!proto.access_management.GetCommunityRoomResponse} returns this
 */
proto.access_management.GetCommunityRoomResponse.prototype.setRoomType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional RoomDisplayUser created_by = 10;
 * @return {?proto.access_management.RoomDisplayUser}
 */
proto.access_management.GetCommunityRoomResponse.prototype.getCreatedBy = function() {
  return /** @type{?proto.access_management.RoomDisplayUser} */ (
    jspb.Message.getWrapperField(this, proto.access_management.RoomDisplayUser, 10));
};


/**
 * @param {?proto.access_management.RoomDisplayUser|undefined} value
 * @return {!proto.access_management.GetCommunityRoomResponse} returns this
*/
proto.access_management.GetCommunityRoomResponse.prototype.setCreatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.GetCommunityRoomResponse} returns this
 */
proto.access_management.GetCommunityRoomResponse.prototype.clearCreatedBy = function() {
  return this.setCreatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.GetCommunityRoomResponse.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated RoomDisplayUser display_users = 11;
 * @return {!Array<!proto.access_management.RoomDisplayUser>}
 */
proto.access_management.GetCommunityRoomResponse.prototype.getDisplayUsersList = function() {
  return /** @type{!Array<!proto.access_management.RoomDisplayUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.access_management.RoomDisplayUser, 11));
};


/**
 * @param {!Array<!proto.access_management.RoomDisplayUser>} value
 * @return {!proto.access_management.GetCommunityRoomResponse} returns this
*/
proto.access_management.GetCommunityRoomResponse.prototype.setDisplayUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.access_management.RoomDisplayUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.access_management.RoomDisplayUser}
 */
proto.access_management.GetCommunityRoomResponse.prototype.addDisplayUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.access_management.RoomDisplayUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.GetCommunityRoomResponse} returns this
 */
proto.access_management.GetCommunityRoomResponse.prototype.clearDisplayUsersList = function() {
  return this.setDisplayUsersList([]);
};


/**
 * optional int64 user_count = 12;
 * @return {number}
 */
proto.access_management.GetCommunityRoomResponse.prototype.getUserCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.GetCommunityRoomResponse} returns this
 */
proto.access_management.GetCommunityRoomResponse.prototype.setUserCount = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string currentGame = 13;
 * @return {string}
 */
proto.access_management.GetCommunityRoomResponse.prototype.getCurrentgame = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityRoomResponse} returns this
 */
proto.access_management.GetCommunityRoomResponse.prototype.setCurrentgame = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string tournament_id = 14;
 * @return {string}
 */
proto.access_management.GetCommunityRoomResponse.prototype.getTournamentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityRoomResponse} returns this
 */
proto.access_management.GetCommunityRoomResponse.prototype.setTournamentId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.UpdateCommunityRoomRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.UpdateCommunityRoomRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.UpdateCommunityRoomRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UpdateCommunityRoomRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    host: jspb.Message.getFieldWithDefault(msg, 4, ""),
    pinned: (f = msg.getPinned()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    pb_private: (f = msg.getPrivate()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.UpdateCommunityRoomRequest}
 */
proto.access_management.UpdateCommunityRoomRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.UpdateCommunityRoomRequest;
  return proto.access_management.UpdateCommunityRoomRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.UpdateCommunityRoomRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.UpdateCommunityRoomRequest}
 */
proto.access_management.UpdateCommunityRoomRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setHost(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setPinned(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setPrivate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.UpdateCommunityRoomRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.UpdateCommunityRoomRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.UpdateCommunityRoomRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UpdateCommunityRoomRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPinned();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getPrivate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.UpdateCommunityRoomRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateCommunityRoomRequest} returns this
 */
proto.access_management.UpdateCommunityRoomRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.access_management.UpdateCommunityRoomRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateCommunityRoomRequest} returns this
 */
proto.access_management.UpdateCommunityRoomRequest.prototype.setName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.access_management.UpdateCommunityRoomRequest} returns this
 */
proto.access_management.UpdateCommunityRoomRequest.prototype.clearName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.UpdateCommunityRoomRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string host = 4;
 * @return {string}
 */
proto.access_management.UpdateCommunityRoomRequest.prototype.getHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateCommunityRoomRequest} returns this
 */
proto.access_management.UpdateCommunityRoomRequest.prototype.setHost = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.access_management.UpdateCommunityRoomRequest} returns this
 */
proto.access_management.UpdateCommunityRoomRequest.prototype.clearHost = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.UpdateCommunityRoomRequest.prototype.hasHost = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.BoolValue pinned = 5;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.access_management.UpdateCommunityRoomRequest.prototype.getPinned = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 5));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.access_management.UpdateCommunityRoomRequest} returns this
*/
proto.access_management.UpdateCommunityRoomRequest.prototype.setPinned = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.UpdateCommunityRoomRequest} returns this
 */
proto.access_management.UpdateCommunityRoomRequest.prototype.clearPinned = function() {
  return this.setPinned(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.UpdateCommunityRoomRequest.prototype.hasPinned = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.BoolValue private = 6;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.access_management.UpdateCommunityRoomRequest.prototype.getPrivate = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 6));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.access_management.UpdateCommunityRoomRequest} returns this
*/
proto.access_management.UpdateCommunityRoomRequest.prototype.setPrivate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.UpdateCommunityRoomRequest} returns this
 */
proto.access_management.UpdateCommunityRoomRequest.prototype.clearPrivate = function() {
  return this.setPrivate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.UpdateCommunityRoomRequest.prototype.hasPrivate = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.UpdateCommunityRoomResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.UpdateCommunityRoomResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.UpdateCommunityRoomResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UpdateCommunityRoomResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    communityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    host: jspb.Message.getFieldWithDefault(msg, 4, ""),
    configMap: (f = msg.getConfigMap()) ? f.toObject(includeInstance, undefined) : [],
    slug: jspb.Message.getFieldWithDefault(msg, 6, ""),
    roomStatus: jspb.Message.getFieldWithDefault(msg, 7, 0),
    pinned: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    pb_private: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    roomType: jspb.Message.getFieldWithDefault(msg, 10, 0),
    createdBy: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.UpdateCommunityRoomResponse}
 */
proto.access_management.UpdateCommunityRoomResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.UpdateCommunityRoomResponse;
  return proto.access_management.UpdateCommunityRoomResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.UpdateCommunityRoomResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.UpdateCommunityRoomResponse}
 */
proto.access_management.UpdateCommunityRoomResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommunityId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setHost(value);
      break;
    case 5:
      var value = msg.getConfigMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    case 7:
      var value = /** @type {!proto.access_management.RoomStatus} */ (reader.readEnum());
      msg.setRoomStatus(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPinned(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivate(value);
      break;
    case 10:
      var value = /** @type {!proto.access_management.RoomType} */ (reader.readEnum());
      msg.setRoomType(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.UpdateCommunityRoomResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.UpdateCommunityRoomResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.UpdateCommunityRoomResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UpdateCommunityRoomResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCommunityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHost();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getConfigMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRoomStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getPinned();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getPrivate();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getRoomType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.UpdateCommunityRoomResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateCommunityRoomResponse} returns this
 */
proto.access_management.UpdateCommunityRoomResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string community_id = 2;
 * @return {string}
 */
proto.access_management.UpdateCommunityRoomResponse.prototype.getCommunityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateCommunityRoomResponse} returns this
 */
proto.access_management.UpdateCommunityRoomResponse.prototype.setCommunityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.access_management.UpdateCommunityRoomResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateCommunityRoomResponse} returns this
 */
proto.access_management.UpdateCommunityRoomResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string host = 4;
 * @return {string}
 */
proto.access_management.UpdateCommunityRoomResponse.prototype.getHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateCommunityRoomResponse} returns this
 */
proto.access_management.UpdateCommunityRoomResponse.prototype.setHost = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * map<string, string> config = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.access_management.UpdateCommunityRoomResponse.prototype.getConfigMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.access_management.UpdateCommunityRoomResponse} returns this
 */
proto.access_management.UpdateCommunityRoomResponse.prototype.clearConfigMap = function() {
  this.getConfigMap().clear();
  return this;};


/**
 * optional string slug = 6;
 * @return {string}
 */
proto.access_management.UpdateCommunityRoomResponse.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateCommunityRoomResponse} returns this
 */
proto.access_management.UpdateCommunityRoomResponse.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional RoomStatus room_status = 7;
 * @return {!proto.access_management.RoomStatus}
 */
proto.access_management.UpdateCommunityRoomResponse.prototype.getRoomStatus = function() {
  return /** @type {!proto.access_management.RoomStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.access_management.RoomStatus} value
 * @return {!proto.access_management.UpdateCommunityRoomResponse} returns this
 */
proto.access_management.UpdateCommunityRoomResponse.prototype.setRoomStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional bool pinned = 8;
 * @return {boolean}
 */
proto.access_management.UpdateCommunityRoomResponse.prototype.getPinned = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.access_management.UpdateCommunityRoomResponse} returns this
 */
proto.access_management.UpdateCommunityRoomResponse.prototype.setPinned = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool private = 9;
 * @return {boolean}
 */
proto.access_management.UpdateCommunityRoomResponse.prototype.getPrivate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.access_management.UpdateCommunityRoomResponse} returns this
 */
proto.access_management.UpdateCommunityRoomResponse.prototype.setPrivate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional RoomType room_type = 10;
 * @return {!proto.access_management.RoomType}
 */
proto.access_management.UpdateCommunityRoomResponse.prototype.getRoomType = function() {
  return /** @type {!proto.access_management.RoomType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.access_management.RoomType} value
 * @return {!proto.access_management.UpdateCommunityRoomResponse} returns this
 */
proto.access_management.UpdateCommunityRoomResponse.prototype.setRoomType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string created_by = 11;
 * @return {string}
 */
proto.access_management.UpdateCommunityRoomResponse.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateCommunityRoomResponse} returns this
 */
proto.access_management.UpdateCommunityRoomResponse.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.CommunityRoomConfigurationUpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.CommunityRoomConfigurationUpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.CommunityRoomConfigurationUpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CommunityRoomConfigurationUpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    configMap: (f = msg.getConfigMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.CommunityRoomConfigurationUpdateRequest}
 */
proto.access_management.CommunityRoomConfigurationUpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.CommunityRoomConfigurationUpdateRequest;
  return proto.access_management.CommunityRoomConfigurationUpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.CommunityRoomConfigurationUpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.CommunityRoomConfigurationUpdateRequest}
 */
proto.access_management.CommunityRoomConfigurationUpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = msg.getConfigMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.CommunityRoomConfigurationUpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.CommunityRoomConfigurationUpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.CommunityRoomConfigurationUpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CommunityRoomConfigurationUpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConfigMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.CommunityRoomConfigurationUpdateRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CommunityRoomConfigurationUpdateRequest} returns this
 */
proto.access_management.CommunityRoomConfigurationUpdateRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<string, string> config = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.access_management.CommunityRoomConfigurationUpdateRequest.prototype.getConfigMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.access_management.CommunityRoomConfigurationUpdateRequest} returns this
 */
proto.access_management.CommunityRoomConfigurationUpdateRequest.prototype.clearConfigMap = function() {
  this.getConfigMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.CommunityRoomConfigurationUpdateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.CommunityRoomConfigurationUpdateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.CommunityRoomConfigurationUpdateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CommunityRoomConfigurationUpdateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    configMap: (f = msg.getConfigMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.CommunityRoomConfigurationUpdateResponse}
 */
proto.access_management.CommunityRoomConfigurationUpdateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.CommunityRoomConfigurationUpdateResponse;
  return proto.access_management.CommunityRoomConfigurationUpdateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.CommunityRoomConfigurationUpdateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.CommunityRoomConfigurationUpdateResponse}
 */
proto.access_management.CommunityRoomConfigurationUpdateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = msg.getConfigMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.CommunityRoomConfigurationUpdateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.CommunityRoomConfigurationUpdateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.CommunityRoomConfigurationUpdateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CommunityRoomConfigurationUpdateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConfigMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.CommunityRoomConfigurationUpdateResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CommunityRoomConfigurationUpdateResponse} returns this
 */
proto.access_management.CommunityRoomConfigurationUpdateResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<string, string> config = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.access_management.CommunityRoomConfigurationUpdateResponse.prototype.getConfigMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.access_management.CommunityRoomConfigurationUpdateResponse} returns this
 */
proto.access_management.CommunityRoomConfigurationUpdateResponse.prototype.clearConfigMap = function() {
  this.getConfigMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.DeleteCommunityRoomRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.DeleteCommunityRoomRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.DeleteCommunityRoomRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.DeleteCommunityRoomRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.DeleteCommunityRoomRequest}
 */
proto.access_management.DeleteCommunityRoomRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.DeleteCommunityRoomRequest;
  return proto.access_management.DeleteCommunityRoomRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.DeleteCommunityRoomRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.DeleteCommunityRoomRequest}
 */
proto.access_management.DeleteCommunityRoomRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.DeleteCommunityRoomRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.DeleteCommunityRoomRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.DeleteCommunityRoomRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.DeleteCommunityRoomRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.DeleteCommunityRoomRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.DeleteCommunityRoomRequest} returns this
 */
proto.access_management.DeleteCommunityRoomRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.DeleteCommunityRoomResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.DeleteCommunityRoomResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.DeleteCommunityRoomResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.DeleteCommunityRoomResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.DeleteCommunityRoomResponse}
 */
proto.access_management.DeleteCommunityRoomResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.DeleteCommunityRoomResponse;
  return proto.access_management.DeleteCommunityRoomResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.DeleteCommunityRoomResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.DeleteCommunityRoomResponse}
 */
proto.access_management.DeleteCommunityRoomResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.DeleteCommunityRoomResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.DeleteCommunityRoomResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.DeleteCommunityRoomResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.DeleteCommunityRoomResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.ListCommunityRoomsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.ListCommunityRoomsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.ListCommunityRoomsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.ListCommunityRoomsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    communityId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.ListCommunityRoomsRequest}
 */
proto.access_management.ListCommunityRoomsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.ListCommunityRoomsRequest;
  return proto.access_management.ListCommunityRoomsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.ListCommunityRoomsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.ListCommunityRoomsRequest}
 */
proto.access_management.ListCommunityRoomsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommunityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.ListCommunityRoomsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.ListCommunityRoomsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.ListCommunityRoomsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.ListCommunityRoomsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommunityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string community_id = 1;
 * @return {string}
 */
proto.access_management.ListCommunityRoomsRequest.prototype.getCommunityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.ListCommunityRoomsRequest} returns this
 */
proto.access_management.ListCommunityRoomsRequest.prototype.setCommunityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.ListCommunityRoomsFromSlugRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.ListCommunityRoomsFromSlugRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.ListCommunityRoomsFromSlugRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.ListCommunityRoomsFromSlugRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    slug: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.ListCommunityRoomsFromSlugRequest}
 */
proto.access_management.ListCommunityRoomsFromSlugRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.ListCommunityRoomsFromSlugRequest;
  return proto.access_management.ListCommunityRoomsFromSlugRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.ListCommunityRoomsFromSlugRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.ListCommunityRoomsFromSlugRequest}
 */
proto.access_management.ListCommunityRoomsFromSlugRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.ListCommunityRoomsFromSlugRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.ListCommunityRoomsFromSlugRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.ListCommunityRoomsFromSlugRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.ListCommunityRoomsFromSlugRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string slug = 1;
 * @return {string}
 */
proto.access_management.ListCommunityRoomsFromSlugRequest.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.ListCommunityRoomsFromSlugRequest} returns this
 */
proto.access_management.ListCommunityRoomsFromSlugRequest.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.RoomDisplayUser.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.RoomDisplayUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.RoomDisplayUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.RoomDisplayUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    avatarUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    username: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.RoomDisplayUser}
 */
proto.access_management.RoomDisplayUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.RoomDisplayUser;
  return proto.access_management.RoomDisplayUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.RoomDisplayUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.RoomDisplayUser}
 */
proto.access_management.RoomDisplayUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatarUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.RoomDisplayUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.RoomDisplayUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.RoomDisplayUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.RoomDisplayUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAvatarUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.RoomDisplayUser.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.RoomDisplayUser} returns this
 */
proto.access_management.RoomDisplayUser.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string avatar_url = 2;
 * @return {string}
 */
proto.access_management.RoomDisplayUser.prototype.getAvatarUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.RoomDisplayUser} returns this
 */
proto.access_management.RoomDisplayUser.prototype.setAvatarUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.access_management.RoomDisplayUser.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.RoomDisplayUser} returns this
 */
proto.access_management.RoomDisplayUser.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string username = 4;
 * @return {string}
 */
proto.access_management.RoomDisplayUser.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.RoomDisplayUser} returns this
 */
proto.access_management.RoomDisplayUser.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.ListCommunityRoomsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.ListCommunityRoomsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.ListCommunityRoomsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.ListCommunityRoomsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.ListCommunityRoomsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomsList: jspb.Message.toObjectList(msg.getRoomsList(),
    proto.access_management.Room.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.ListCommunityRoomsResponse}
 */
proto.access_management.ListCommunityRoomsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.ListCommunityRoomsResponse;
  return proto.access_management.ListCommunityRoomsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.ListCommunityRoomsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.ListCommunityRoomsResponse}
 */
proto.access_management.ListCommunityRoomsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.access_management.Room;
      reader.readMessage(value,proto.access_management.Room.deserializeBinaryFromReader);
      msg.addRooms(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.ListCommunityRoomsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.ListCommunityRoomsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.ListCommunityRoomsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.ListCommunityRoomsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.access_management.Room.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Room rooms = 1;
 * @return {!Array<!proto.access_management.Room>}
 */
proto.access_management.ListCommunityRoomsResponse.prototype.getRoomsList = function() {
  return /** @type{!Array<!proto.access_management.Room>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.access_management.Room, 1));
};


/**
 * @param {!Array<!proto.access_management.Room>} value
 * @return {!proto.access_management.ListCommunityRoomsResponse} returns this
*/
proto.access_management.ListCommunityRoomsResponse.prototype.setRoomsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.access_management.Room=} opt_value
 * @param {number=} opt_index
 * @return {!proto.access_management.Room}
 */
proto.access_management.ListCommunityRoomsResponse.prototype.addRooms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.access_management.Room, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.ListCommunityRoomsResponse} returns this
 */
proto.access_management.ListCommunityRoomsResponse.prototype.clearRoomsList = function() {
  return this.setRoomsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetInitialRoomStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetInitialRoomStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetInitialRoomStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetInitialRoomStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetInitialRoomStateRequest}
 */
proto.access_management.GetInitialRoomStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetInitialRoomStateRequest;
  return proto.access_management.GetInitialRoomStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetInitialRoomStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetInitialRoomStateRequest}
 */
proto.access_management.GetInitialRoomStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetInitialRoomStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetInitialRoomStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetInitialRoomStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetInitialRoomStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.access_management.GetInitialRoomStateRequest.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetInitialRoomStateRequest} returns this
 */
proto.access_management.GetInitialRoomStateRequest.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetInitialRoomStateBySlugRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetInitialRoomStateBySlugRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetInitialRoomStateBySlugRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetInitialRoomStateBySlugRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    communitySlug: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roomSlug: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetInitialRoomStateBySlugRequest}
 */
proto.access_management.GetInitialRoomStateBySlugRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetInitialRoomStateBySlugRequest;
  return proto.access_management.GetInitialRoomStateBySlugRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetInitialRoomStateBySlugRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetInitialRoomStateBySlugRequest}
 */
proto.access_management.GetInitialRoomStateBySlugRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommunitySlug(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomSlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetInitialRoomStateBySlugRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetInitialRoomStateBySlugRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetInitialRoomStateBySlugRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetInitialRoomStateBySlugRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommunitySlug();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoomSlug();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string community_slug = 1;
 * @return {string}
 */
proto.access_management.GetInitialRoomStateBySlugRequest.prototype.getCommunitySlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetInitialRoomStateBySlugRequest} returns this
 */
proto.access_management.GetInitialRoomStateBySlugRequest.prototype.setCommunitySlug = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string room_slug = 2;
 * @return {string}
 */
proto.access_management.GetInitialRoomStateBySlugRequest.prototype.getRoomSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetInitialRoomStateBySlugRequest} returns this
 */
proto.access_management.GetInitialRoomStateBySlugRequest.prototype.setRoomSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.RoomUser.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.RoomUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.RoomUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.RoomUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    username: jspb.Message.getFieldWithDefault(msg, 3, ""),
    avatarUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    stateMap: (f = msg.getStateMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.RoomUser}
 */
proto.access_management.RoomUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.RoomUser;
  return proto.access_management.RoomUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.RoomUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.RoomUser}
 */
proto.access_management.RoomUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatarUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 6:
      var value = msg.getStateMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.RoomUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.RoomUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.RoomUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.RoomUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAvatarUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStateMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.RoomUser.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.RoomUser} returns this
 */
proto.access_management.RoomUser.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.access_management.RoomUser.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.RoomUser} returns this
 */
proto.access_management.RoomUser.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string username = 3;
 * @return {string}
 */
proto.access_management.RoomUser.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.RoomUser} returns this
 */
proto.access_management.RoomUser.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string avatar_url = 4;
 * @return {string}
 */
proto.access_management.RoomUser.prototype.getAvatarUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.RoomUser} returns this
 */
proto.access_management.RoomUser.prototype.setAvatarUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string display_name = 5;
 * @return {string}
 */
proto.access_management.RoomUser.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.RoomUser} returns this
 */
proto.access_management.RoomUser.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * map<string, string> state = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.access_management.RoomUser.prototype.getStateMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.access_management.RoomUser} returns this
 */
proto.access_management.RoomUser.prototype.clearStateMap = function() {
  this.getStateMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.GetInitialRoomStateResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetInitialRoomStateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetInitialRoomStateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetInitialRoomStateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetInitialRoomStateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.access_management.RoomUser.toObject, includeInstance),
    configMap: (f = msg.getConfigMap()) ? f.toObject(includeInstance, undefined) : [],
    playersDownloading: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetInitialRoomStateResponse}
 */
proto.access_management.GetInitialRoomStateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetInitialRoomStateResponse;
  return proto.access_management.GetInitialRoomStateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetInitialRoomStateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetInitialRoomStateResponse}
 */
proto.access_management.GetInitialRoomStateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 2:
      var value = new proto.access_management.RoomUser;
      reader.readMessage(value,proto.access_management.RoomUser.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 3:
      var value = msg.getConfigMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPlayersDownloading(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetInitialRoomStateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetInitialRoomStateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetInitialRoomStateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetInitialRoomStateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.access_management.RoomUser.serializeBinaryToWriter
    );
  }
  f = message.getConfigMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getPlayersDownloading();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.access_management.GetInitialRoomStateResponse.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetInitialRoomStateResponse} returns this
 */
proto.access_management.GetInitialRoomStateResponse.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated RoomUser users = 2;
 * @return {!Array<!proto.access_management.RoomUser>}
 */
proto.access_management.GetInitialRoomStateResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.access_management.RoomUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.access_management.RoomUser, 2));
};


/**
 * @param {!Array<!proto.access_management.RoomUser>} value
 * @return {!proto.access_management.GetInitialRoomStateResponse} returns this
*/
proto.access_management.GetInitialRoomStateResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.access_management.RoomUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.access_management.RoomUser}
 */
proto.access_management.GetInitialRoomStateResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.access_management.RoomUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.GetInitialRoomStateResponse} returns this
 */
proto.access_management.GetInitialRoomStateResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * map<string, string> config = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.access_management.GetInitialRoomStateResponse.prototype.getConfigMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.access_management.GetInitialRoomStateResponse} returns this
 */
proto.access_management.GetInitialRoomStateResponse.prototype.clearConfigMap = function() {
  this.getConfigMap().clear();
  return this;};


/**
 * optional uint32 players_downloading = 4;
 * @return {number}
 */
proto.access_management.GetInitialRoomStateResponse.prototype.getPlayersDownloading = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.GetInitialRoomStateResponse} returns this
 */
proto.access_management.GetInitialRoomStateResponse.prototype.setPlayersDownloading = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetCommunityRoomUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetCommunityRoomUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetCommunityRoomUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityRoomUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetCommunityRoomUsersRequest}
 */
proto.access_management.GetCommunityRoomUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetCommunityRoomUsersRequest;
  return proto.access_management.GetCommunityRoomUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetCommunityRoomUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetCommunityRoomUsersRequest}
 */
proto.access_management.GetCommunityRoomUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetCommunityRoomUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetCommunityRoomUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetCommunityRoomUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityRoomUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.access_management.GetCommunityRoomUsersRequest.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityRoomUsersRequest} returns this
 */
proto.access_management.GetCommunityRoomUsersRequest.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.GetCommunityRoomUsersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetCommunityRoomUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetCommunityRoomUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetCommunityRoomUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityRoomUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetCommunityRoomUsersResponse}
 */
proto.access_management.GetCommunityRoomUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetCommunityRoomUsersResponse;
  return proto.access_management.GetCommunityRoomUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetCommunityRoomUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetCommunityRoomUsersResponse}
 */
proto.access_management.GetCommunityRoomUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetCommunityRoomUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetCommunityRoomUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetCommunityRoomUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityRoomUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string users = 1;
 * @return {!Array<string>}
 */
proto.access_management.GetCommunityRoomUsersResponse.prototype.getUsersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.access_management.GetCommunityRoomUsersResponse} returns this
 */
proto.access_management.GetCommunityRoomUsersResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.access_management.GetCommunityRoomUsersResponse} returns this
 */
proto.access_management.GetCommunityRoomUsersResponse.prototype.addUsers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.GetCommunityRoomUsersResponse} returns this
 */
proto.access_management.GetCommunityRoomUsersResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.UpdateRoomHostRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.UpdateRoomHostRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.UpdateRoomHostRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UpdateRoomHostRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.UpdateRoomHostRequest}
 */
proto.access_management.UpdateRoomHostRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.UpdateRoomHostRequest;
  return proto.access_management.UpdateRoomHostRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.UpdateRoomHostRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.UpdateRoomHostRequest}
 */
proto.access_management.UpdateRoomHostRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.UpdateRoomHostRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.UpdateRoomHostRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.UpdateRoomHostRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UpdateRoomHostRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.access_management.UpdateRoomHostRequest.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateRoomHostRequest} returns this
 */
proto.access_management.UpdateRoomHostRequest.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.access_management.UpdateRoomHostRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateRoomHostRequest} returns this
 */
proto.access_management.UpdateRoomHostRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.UpdateRoomHostResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.UpdateRoomHostResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.UpdateRoomHostResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UpdateRoomHostResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.UpdateRoomHostResponse}
 */
proto.access_management.UpdateRoomHostResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.UpdateRoomHostResponse;
  return proto.access_management.UpdateRoomHostResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.UpdateRoomHostResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.UpdateRoomHostResponse}
 */
proto.access_management.UpdateRoomHostResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.UpdateRoomHostResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.UpdateRoomHostResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.UpdateRoomHostResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UpdateRoomHostResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.StartOrContinueGameDownloadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.StartOrContinueGameDownloadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.StartOrContinueGameDownloadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.StartOrContinueGameDownloadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    game: jspb.Message.getFieldWithDefault(msg, 2, ""),
    version: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.StartOrContinueGameDownloadRequest}
 */
proto.access_management.StartOrContinueGameDownloadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.StartOrContinueGameDownloadRequest;
  return proto.access_management.StartOrContinueGameDownloadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.StartOrContinueGameDownloadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.StartOrContinueGameDownloadRequest}
 */
proto.access_management.StartOrContinueGameDownloadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGame(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.StartOrContinueGameDownloadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.StartOrContinueGameDownloadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.StartOrContinueGameDownloadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.StartOrContinueGameDownloadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGame();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.access_management.StartOrContinueGameDownloadRequest.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.StartOrContinueGameDownloadRequest} returns this
 */
proto.access_management.StartOrContinueGameDownloadRequest.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string game = 2;
 * @return {string}
 */
proto.access_management.StartOrContinueGameDownloadRequest.prototype.getGame = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.StartOrContinueGameDownloadRequest} returns this
 */
proto.access_management.StartOrContinueGameDownloadRequest.prototype.setGame = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string version = 3;
 * @return {string}
 */
proto.access_management.StartOrContinueGameDownloadRequest.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.StartOrContinueGameDownloadRequest} returns this
 */
proto.access_management.StartOrContinueGameDownloadRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.StartOrContinueGameDownloadResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.StartOrContinueGameDownloadResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.StartOrContinueGameDownloadResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.StartOrContinueGameDownloadResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.StartOrContinueGameDownloadResponse}
 */
proto.access_management.StartOrContinueGameDownloadResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.StartOrContinueGameDownloadResponse;
  return proto.access_management.StartOrContinueGameDownloadResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.StartOrContinueGameDownloadResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.StartOrContinueGameDownloadResponse}
 */
proto.access_management.StartOrContinueGameDownloadResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.StartOrContinueGameDownloadResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.StartOrContinueGameDownloadResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.StartOrContinueGameDownloadResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.StartOrContinueGameDownloadResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.DoneDownloadingGameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.DoneDownloadingGameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.DoneDownloadingGameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.DoneDownloadingGameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    game: jspb.Message.getFieldWithDefault(msg, 2, ""),
    version: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.DoneDownloadingGameRequest}
 */
proto.access_management.DoneDownloadingGameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.DoneDownloadingGameRequest;
  return proto.access_management.DoneDownloadingGameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.DoneDownloadingGameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.DoneDownloadingGameRequest}
 */
proto.access_management.DoneDownloadingGameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGame(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.DoneDownloadingGameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.DoneDownloadingGameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.DoneDownloadingGameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.DoneDownloadingGameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGame();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.access_management.DoneDownloadingGameRequest.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.DoneDownloadingGameRequest} returns this
 */
proto.access_management.DoneDownloadingGameRequest.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string game = 2;
 * @return {string}
 */
proto.access_management.DoneDownloadingGameRequest.prototype.getGame = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.DoneDownloadingGameRequest} returns this
 */
proto.access_management.DoneDownloadingGameRequest.prototype.setGame = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string version = 3;
 * @return {string}
 */
proto.access_management.DoneDownloadingGameRequest.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.DoneDownloadingGameRequest} returns this
 */
proto.access_management.DoneDownloadingGameRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.DoneDownloadingGameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.DoneDownloadingGameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.DoneDownloadingGameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.DoneDownloadingGameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.DoneDownloadingGameResponse}
 */
proto.access_management.DoneDownloadingGameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.DoneDownloadingGameResponse;
  return proto.access_management.DoneDownloadingGameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.DoneDownloadingGameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.DoneDownloadingGameResponse}
 */
proto.access_management.DoneDownloadingGameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.DoneDownloadingGameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.DoneDownloadingGameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.DoneDownloadingGameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.DoneDownloadingGameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetCommunityRoomUsersDownloadingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetCommunityRoomUsersDownloadingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetCommunityRoomUsersDownloadingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityRoomUsersDownloadingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetCommunityRoomUsersDownloadingRequest}
 */
proto.access_management.GetCommunityRoomUsersDownloadingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetCommunityRoomUsersDownloadingRequest;
  return proto.access_management.GetCommunityRoomUsersDownloadingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetCommunityRoomUsersDownloadingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetCommunityRoomUsersDownloadingRequest}
 */
proto.access_management.GetCommunityRoomUsersDownloadingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetCommunityRoomUsersDownloadingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetCommunityRoomUsersDownloadingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetCommunityRoomUsersDownloadingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityRoomUsersDownloadingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.access_management.GetCommunityRoomUsersDownloadingRequest.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityRoomUsersDownloadingRequest} returns this
 */
proto.access_management.GetCommunityRoomUsersDownloadingRequest.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.GetCommunityRoomUsersDownloadingResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetCommunityRoomUsersDownloadingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetCommunityRoomUsersDownloadingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetCommunityRoomUsersDownloadingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityRoomUsersDownloadingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    downloadingUsersList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetCommunityRoomUsersDownloadingResponse}
 */
proto.access_management.GetCommunityRoomUsersDownloadingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetCommunityRoomUsersDownloadingResponse;
  return proto.access_management.GetCommunityRoomUsersDownloadingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetCommunityRoomUsersDownloadingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetCommunityRoomUsersDownloadingResponse}
 */
proto.access_management.GetCommunityRoomUsersDownloadingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addDownloadingUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetCommunityRoomUsersDownloadingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetCommunityRoomUsersDownloadingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetCommunityRoomUsersDownloadingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityRoomUsersDownloadingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDownloadingUsersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string downloading_users = 1;
 * @return {!Array<string>}
 */
proto.access_management.GetCommunityRoomUsersDownloadingResponse.prototype.getDownloadingUsersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.access_management.GetCommunityRoomUsersDownloadingResponse} returns this
 */
proto.access_management.GetCommunityRoomUsersDownloadingResponse.prototype.setDownloadingUsersList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.access_management.GetCommunityRoomUsersDownloadingResponse} returns this
 */
proto.access_management.GetCommunityRoomUsersDownloadingResponse.prototype.addDownloadingUsers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.GetCommunityRoomUsersDownloadingResponse} returns this
 */
proto.access_management.GetCommunityRoomUsersDownloadingResponse.prototype.clearDownloadingUsersList = function() {
  return this.setDownloadingUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetCommunityRoomUsersNotDownloadingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetCommunityRoomUsersNotDownloadingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetCommunityRoomUsersNotDownloadingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityRoomUsersNotDownloadingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetCommunityRoomUsersNotDownloadingRequest}
 */
proto.access_management.GetCommunityRoomUsersNotDownloadingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetCommunityRoomUsersNotDownloadingRequest;
  return proto.access_management.GetCommunityRoomUsersNotDownloadingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetCommunityRoomUsersNotDownloadingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetCommunityRoomUsersNotDownloadingRequest}
 */
proto.access_management.GetCommunityRoomUsersNotDownloadingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetCommunityRoomUsersNotDownloadingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetCommunityRoomUsersNotDownloadingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetCommunityRoomUsersNotDownloadingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityRoomUsersNotDownloadingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.access_management.GetCommunityRoomUsersNotDownloadingRequest.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityRoomUsersNotDownloadingRequest} returns this
 */
proto.access_management.GetCommunityRoomUsersNotDownloadingRequest.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.GetCommunityRoomUsersNotDownloadingResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetCommunityRoomUsersNotDownloadingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetCommunityRoomUsersNotDownloadingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetCommunityRoomUsersNotDownloadingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityRoomUsersNotDownloadingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetCommunityRoomUsersNotDownloadingResponse}
 */
proto.access_management.GetCommunityRoomUsersNotDownloadingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetCommunityRoomUsersNotDownloadingResponse;
  return proto.access_management.GetCommunityRoomUsersNotDownloadingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetCommunityRoomUsersNotDownloadingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetCommunityRoomUsersNotDownloadingResponse}
 */
proto.access_management.GetCommunityRoomUsersNotDownloadingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetCommunityRoomUsersNotDownloadingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetCommunityRoomUsersNotDownloadingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetCommunityRoomUsersNotDownloadingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityRoomUsersNotDownloadingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string users = 1;
 * @return {!Array<string>}
 */
proto.access_management.GetCommunityRoomUsersNotDownloadingResponse.prototype.getUsersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.access_management.GetCommunityRoomUsersNotDownloadingResponse} returns this
 */
proto.access_management.GetCommunityRoomUsersNotDownloadingResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.access_management.GetCommunityRoomUsersNotDownloadingResponse} returns this
 */
proto.access_management.GetCommunityRoomUsersNotDownloadingResponse.prototype.addUsers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.GetCommunityRoomUsersNotDownloadingResponse} returns this
 */
proto.access_management.GetCommunityRoomUsersNotDownloadingResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.SubscribeToRoomRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.SubscribeToRoomRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.SubscribeToRoomRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.SubscribeToRoomRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    communitySlug: jspb.Message.getFieldWithDefault(msg, 3, ""),
    roomSlug: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.SubscribeToRoomRequest}
 */
proto.access_management.SubscribeToRoomRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.SubscribeToRoomRequest;
  return proto.access_management.SubscribeToRoomRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.SubscribeToRoomRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.SubscribeToRoomRequest}
 */
proto.access_management.SubscribeToRoomRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommunitySlug(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomSlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.SubscribeToRoomRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.SubscribeToRoomRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.SubscribeToRoomRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.SubscribeToRoomRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string room_id = 2;
 * @return {string}
 */
proto.access_management.SubscribeToRoomRequest.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.SubscribeToRoomRequest} returns this
 */
proto.access_management.SubscribeToRoomRequest.prototype.setRoomId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.access_management.SubscribeToRoomRequest} returns this
 */
proto.access_management.SubscribeToRoomRequest.prototype.clearRoomId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.SubscribeToRoomRequest.prototype.hasRoomId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string community_slug = 3;
 * @return {string}
 */
proto.access_management.SubscribeToRoomRequest.prototype.getCommunitySlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.SubscribeToRoomRequest} returns this
 */
proto.access_management.SubscribeToRoomRequest.prototype.setCommunitySlug = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.access_management.SubscribeToRoomRequest} returns this
 */
proto.access_management.SubscribeToRoomRequest.prototype.clearCommunitySlug = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.SubscribeToRoomRequest.prototype.hasCommunitySlug = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string room_slug = 4;
 * @return {string}
 */
proto.access_management.SubscribeToRoomRequest.prototype.getRoomSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.SubscribeToRoomRequest} returns this
 */
proto.access_management.SubscribeToRoomRequest.prototype.setRoomSlug = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.access_management.SubscribeToRoomRequest} returns this
 */
proto.access_management.SubscribeToRoomRequest.prototype.clearRoomSlug = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.SubscribeToRoomRequest.prototype.hasRoomSlug = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.EndStreamEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.EndStreamEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.EndStreamEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.EndStreamEvent.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.EndStreamEvent}
 */
proto.access_management.EndStreamEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.EndStreamEvent;
  return proto.access_management.EndStreamEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.EndStreamEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.EndStreamEvent}
 */
proto.access_management.EndStreamEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.EndStreamEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.EndStreamEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.EndStreamEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.EndStreamEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.access_management.ClientRoomEvent.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.access_management.ClientRoomEvent.EventCase = {
  EVENT_NOT_SET: 0,
  SUBSCRIBETOROOMREQUEST: 1,
  ENDSTREAM: 2
};

/**
 * @return {proto.access_management.ClientRoomEvent.EventCase}
 */
proto.access_management.ClientRoomEvent.prototype.getEventCase = function() {
  return /** @type {proto.access_management.ClientRoomEvent.EventCase} */(jspb.Message.computeOneofCase(this, proto.access_management.ClientRoomEvent.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.ClientRoomEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.ClientRoomEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.ClientRoomEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.ClientRoomEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscribetoroomrequest: (f = msg.getSubscribetoroomrequest()) && proto.access_management.SubscribeToRoomRequest.toObject(includeInstance, f),
    endstream: (f = msg.getEndstream()) && proto.access_management.EndStreamEvent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.ClientRoomEvent}
 */
proto.access_management.ClientRoomEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.ClientRoomEvent;
  return proto.access_management.ClientRoomEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.ClientRoomEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.ClientRoomEvent}
 */
proto.access_management.ClientRoomEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.access_management.SubscribeToRoomRequest;
      reader.readMessage(value,proto.access_management.SubscribeToRoomRequest.deserializeBinaryFromReader);
      msg.setSubscribetoroomrequest(value);
      break;
    case 2:
      var value = new proto.access_management.EndStreamEvent;
      reader.readMessage(value,proto.access_management.EndStreamEvent.deserializeBinaryFromReader);
      msg.setEndstream(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.ClientRoomEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.ClientRoomEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.ClientRoomEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.ClientRoomEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscribetoroomrequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.access_management.SubscribeToRoomRequest.serializeBinaryToWriter
    );
  }
  f = message.getEndstream();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.access_management.EndStreamEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional SubscribeToRoomRequest subscribeToRoomRequest = 1;
 * @return {?proto.access_management.SubscribeToRoomRequest}
 */
proto.access_management.ClientRoomEvent.prototype.getSubscribetoroomrequest = function() {
  return /** @type{?proto.access_management.SubscribeToRoomRequest} */ (
    jspb.Message.getWrapperField(this, proto.access_management.SubscribeToRoomRequest, 1));
};


/**
 * @param {?proto.access_management.SubscribeToRoomRequest|undefined} value
 * @return {!proto.access_management.ClientRoomEvent} returns this
*/
proto.access_management.ClientRoomEvent.prototype.setSubscribetoroomrequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.access_management.ClientRoomEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.ClientRoomEvent} returns this
 */
proto.access_management.ClientRoomEvent.prototype.clearSubscribetoroomrequest = function() {
  return this.setSubscribetoroomrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.ClientRoomEvent.prototype.hasSubscribetoroomrequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EndStreamEvent endStream = 2;
 * @return {?proto.access_management.EndStreamEvent}
 */
proto.access_management.ClientRoomEvent.prototype.getEndstream = function() {
  return /** @type{?proto.access_management.EndStreamEvent} */ (
    jspb.Message.getWrapperField(this, proto.access_management.EndStreamEvent, 2));
};


/**
 * @param {?proto.access_management.EndStreamEvent|undefined} value
 * @return {!proto.access_management.ClientRoomEvent} returns this
*/
proto.access_management.ClientRoomEvent.prototype.setEndstream = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.access_management.ClientRoomEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.ClientRoomEvent} returns this
 */
proto.access_management.ClientRoomEvent.prototype.clearEndstream = function() {
  return this.setEndstream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.ClientRoomEvent.prototype.hasEndstream = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.access_management.RoomEvent.oneofGroups_ = [[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19]];

/**
 * @enum {number}
 */
proto.access_management.RoomEvent.EventCase = {
  EVENT_NOT_SET: 0,
  USERJOINED: 1,
  USERLEFT: 2,
  CONFIGUPDATE: 3,
  GAMEINPROGRESS: 4,
  KEEPALIVE: 5,
  GAMEPENDING: 6,
  GAMEFAILEDTOSTART: 7,
  HOSTENDMATCH: 8,
  CONNECTED: 9,
  MATCHDISTRIBUTIONEVENT: 10,
  MATCHGROUPENDED: 11,
  FAILEDMATCHCONNECTION: 12,
  GAMESTARTED: 13,
  PLAYERSDOWNLOADING: 14,
  NEWHOST: 15,
  MATCHENDED: 16,
  TEAMCONFIG: 17,
  USERSTATEUPDATE: 18,
  TOURNAMENTEVENT: 19
};

/**
 * @return {proto.access_management.RoomEvent.EventCase}
 */
proto.access_management.RoomEvent.prototype.getEventCase = function() {
  return /** @type {proto.access_management.RoomEvent.EventCase} */(jspb.Message.computeOneofCase(this, proto.access_management.RoomEvent.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.RoomEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.RoomEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.RoomEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.RoomEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    userjoined: (f = msg.getUserjoined()) && proto.access_management.UserJoinedEvent.toObject(includeInstance, f),
    userleft: (f = msg.getUserleft()) && proto.access_management.UserLeftEvent.toObject(includeInstance, f),
    configupdate: (f = msg.getConfigupdate()) && proto.access_management.ConfigUpdateEvent.toObject(includeInstance, f),
    gameinprogress: (f = msg.getGameinprogress()) && proto.access_management.GameInProgressEvent.toObject(includeInstance, f),
    keepalive: (f = msg.getKeepalive()) && proto.access_management.KeepAlive.toObject(includeInstance, f),
    gamepending: (f = msg.getGamepending()) && proto.access_management.GamePendingEvent.toObject(includeInstance, f),
    gamefailedtostart: (f = msg.getGamefailedtostart()) && proto.access_management.GameFailedToStartEvent.toObject(includeInstance, f),
    hostendmatch: (f = msg.getHostendmatch()) && proto.access_management.HostEndMatchEvent.toObject(includeInstance, f),
    connected: (f = msg.getConnected()) && proto.access_management.Connected.toObject(includeInstance, f),
    matchdistributionevent: (f = msg.getMatchdistributionevent()) && proto.access_management.MatchDistributionEvent.toObject(includeInstance, f),
    matchgroupended: (f = msg.getMatchgroupended()) && proto.access_management.MatchGroupEndedEvent.toObject(includeInstance, f),
    failedmatchconnection: (f = msg.getFailedmatchconnection()) && proto.access_management.FailedMatchConnectionEvent.toObject(includeInstance, f),
    gamestarted: (f = msg.getGamestarted()) && proto.access_management.GameStartedEvent.toObject(includeInstance, f),
    playersdownloading: (f = msg.getPlayersdownloading()) && proto.access_management.PlayersDownloadingEvent.toObject(includeInstance, f),
    newhost: (f = msg.getNewhost()) && proto.access_management.NewHostEvent.toObject(includeInstance, f),
    matchended: (f = msg.getMatchended()) && proto.access_management.MatchEndedEvent.toObject(includeInstance, f),
    teamconfig: (f = msg.getTeamconfig()) && src_modules_team_selection_events_pb.TeamConfigEvent.toObject(includeInstance, f),
    userstateupdate: (f = msg.getUserstateupdate()) && proto.access_management.UserStateUpdateEvent.toObject(includeInstance, f),
    tournamentevent: (f = msg.getTournamentevent()) && proto.access_management.TournamentEvent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.RoomEvent}
 */
proto.access_management.RoomEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.RoomEvent;
  return proto.access_management.RoomEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.RoomEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.RoomEvent}
 */
proto.access_management.RoomEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.access_management.UserJoinedEvent;
      reader.readMessage(value,proto.access_management.UserJoinedEvent.deserializeBinaryFromReader);
      msg.setUserjoined(value);
      break;
    case 2:
      var value = new proto.access_management.UserLeftEvent;
      reader.readMessage(value,proto.access_management.UserLeftEvent.deserializeBinaryFromReader);
      msg.setUserleft(value);
      break;
    case 3:
      var value = new proto.access_management.ConfigUpdateEvent;
      reader.readMessage(value,proto.access_management.ConfigUpdateEvent.deserializeBinaryFromReader);
      msg.setConfigupdate(value);
      break;
    case 4:
      var value = new proto.access_management.GameInProgressEvent;
      reader.readMessage(value,proto.access_management.GameInProgressEvent.deserializeBinaryFromReader);
      msg.setGameinprogress(value);
      break;
    case 5:
      var value = new proto.access_management.KeepAlive;
      reader.readMessage(value,proto.access_management.KeepAlive.deserializeBinaryFromReader);
      msg.setKeepalive(value);
      break;
    case 6:
      var value = new proto.access_management.GamePendingEvent;
      reader.readMessage(value,proto.access_management.GamePendingEvent.deserializeBinaryFromReader);
      msg.setGamepending(value);
      break;
    case 7:
      var value = new proto.access_management.GameFailedToStartEvent;
      reader.readMessage(value,proto.access_management.GameFailedToStartEvent.deserializeBinaryFromReader);
      msg.setGamefailedtostart(value);
      break;
    case 8:
      var value = new proto.access_management.HostEndMatchEvent;
      reader.readMessage(value,proto.access_management.HostEndMatchEvent.deserializeBinaryFromReader);
      msg.setHostendmatch(value);
      break;
    case 9:
      var value = new proto.access_management.Connected;
      reader.readMessage(value,proto.access_management.Connected.deserializeBinaryFromReader);
      msg.setConnected(value);
      break;
    case 10:
      var value = new proto.access_management.MatchDistributionEvent;
      reader.readMessage(value,proto.access_management.MatchDistributionEvent.deserializeBinaryFromReader);
      msg.setMatchdistributionevent(value);
      break;
    case 11:
      var value = new proto.access_management.MatchGroupEndedEvent;
      reader.readMessage(value,proto.access_management.MatchGroupEndedEvent.deserializeBinaryFromReader);
      msg.setMatchgroupended(value);
      break;
    case 12:
      var value = new proto.access_management.FailedMatchConnectionEvent;
      reader.readMessage(value,proto.access_management.FailedMatchConnectionEvent.deserializeBinaryFromReader);
      msg.setFailedmatchconnection(value);
      break;
    case 13:
      var value = new proto.access_management.GameStartedEvent;
      reader.readMessage(value,proto.access_management.GameStartedEvent.deserializeBinaryFromReader);
      msg.setGamestarted(value);
      break;
    case 14:
      var value = new proto.access_management.PlayersDownloadingEvent;
      reader.readMessage(value,proto.access_management.PlayersDownloadingEvent.deserializeBinaryFromReader);
      msg.setPlayersdownloading(value);
      break;
    case 15:
      var value = new proto.access_management.NewHostEvent;
      reader.readMessage(value,proto.access_management.NewHostEvent.deserializeBinaryFromReader);
      msg.setNewhost(value);
      break;
    case 16:
      var value = new proto.access_management.MatchEndedEvent;
      reader.readMessage(value,proto.access_management.MatchEndedEvent.deserializeBinaryFromReader);
      msg.setMatchended(value);
      break;
    case 17:
      var value = new src_modules_team_selection_events_pb.TeamConfigEvent;
      reader.readMessage(value,src_modules_team_selection_events_pb.TeamConfigEvent.deserializeBinaryFromReader);
      msg.setTeamconfig(value);
      break;
    case 18:
      var value = new proto.access_management.UserStateUpdateEvent;
      reader.readMessage(value,proto.access_management.UserStateUpdateEvent.deserializeBinaryFromReader);
      msg.setUserstateupdate(value);
      break;
    case 19:
      var value = new proto.access_management.TournamentEvent;
      reader.readMessage(value,proto.access_management.TournamentEvent.deserializeBinaryFromReader);
      msg.setTournamentevent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.RoomEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.RoomEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.RoomEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.RoomEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserjoined();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.access_management.UserJoinedEvent.serializeBinaryToWriter
    );
  }
  f = message.getUserleft();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.access_management.UserLeftEvent.serializeBinaryToWriter
    );
  }
  f = message.getConfigupdate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.access_management.ConfigUpdateEvent.serializeBinaryToWriter
    );
  }
  f = message.getGameinprogress();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.access_management.GameInProgressEvent.serializeBinaryToWriter
    );
  }
  f = message.getKeepalive();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.access_management.KeepAlive.serializeBinaryToWriter
    );
  }
  f = message.getGamepending();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.access_management.GamePendingEvent.serializeBinaryToWriter
    );
  }
  f = message.getGamefailedtostart();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.access_management.GameFailedToStartEvent.serializeBinaryToWriter
    );
  }
  f = message.getHostendmatch();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.access_management.HostEndMatchEvent.serializeBinaryToWriter
    );
  }
  f = message.getConnected();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.access_management.Connected.serializeBinaryToWriter
    );
  }
  f = message.getMatchdistributionevent();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.access_management.MatchDistributionEvent.serializeBinaryToWriter
    );
  }
  f = message.getMatchgroupended();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.access_management.MatchGroupEndedEvent.serializeBinaryToWriter
    );
  }
  f = message.getFailedmatchconnection();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.access_management.FailedMatchConnectionEvent.serializeBinaryToWriter
    );
  }
  f = message.getGamestarted();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.access_management.GameStartedEvent.serializeBinaryToWriter
    );
  }
  f = message.getPlayersdownloading();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.access_management.PlayersDownloadingEvent.serializeBinaryToWriter
    );
  }
  f = message.getNewhost();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.access_management.NewHostEvent.serializeBinaryToWriter
    );
  }
  f = message.getMatchended();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.access_management.MatchEndedEvent.serializeBinaryToWriter
    );
  }
  f = message.getTeamconfig();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      src_modules_team_selection_events_pb.TeamConfigEvent.serializeBinaryToWriter
    );
  }
  f = message.getUserstateupdate();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.access_management.UserStateUpdateEvent.serializeBinaryToWriter
    );
  }
  f = message.getTournamentevent();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.access_management.TournamentEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserJoinedEvent userJoined = 1;
 * @return {?proto.access_management.UserJoinedEvent}
 */
proto.access_management.RoomEvent.prototype.getUserjoined = function() {
  return /** @type{?proto.access_management.UserJoinedEvent} */ (
    jspb.Message.getWrapperField(this, proto.access_management.UserJoinedEvent, 1));
};


/**
 * @param {?proto.access_management.UserJoinedEvent|undefined} value
 * @return {!proto.access_management.RoomEvent} returns this
*/
proto.access_management.RoomEvent.prototype.setUserjoined = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.access_management.RoomEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.RoomEvent} returns this
 */
proto.access_management.RoomEvent.prototype.clearUserjoined = function() {
  return this.setUserjoined(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.RoomEvent.prototype.hasUserjoined = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserLeftEvent userLeft = 2;
 * @return {?proto.access_management.UserLeftEvent}
 */
proto.access_management.RoomEvent.prototype.getUserleft = function() {
  return /** @type{?proto.access_management.UserLeftEvent} */ (
    jspb.Message.getWrapperField(this, proto.access_management.UserLeftEvent, 2));
};


/**
 * @param {?proto.access_management.UserLeftEvent|undefined} value
 * @return {!proto.access_management.RoomEvent} returns this
*/
proto.access_management.RoomEvent.prototype.setUserleft = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.access_management.RoomEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.RoomEvent} returns this
 */
proto.access_management.RoomEvent.prototype.clearUserleft = function() {
  return this.setUserleft(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.RoomEvent.prototype.hasUserleft = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ConfigUpdateEvent configUpdate = 3;
 * @return {?proto.access_management.ConfigUpdateEvent}
 */
proto.access_management.RoomEvent.prototype.getConfigupdate = function() {
  return /** @type{?proto.access_management.ConfigUpdateEvent} */ (
    jspb.Message.getWrapperField(this, proto.access_management.ConfigUpdateEvent, 3));
};


/**
 * @param {?proto.access_management.ConfigUpdateEvent|undefined} value
 * @return {!proto.access_management.RoomEvent} returns this
*/
proto.access_management.RoomEvent.prototype.setConfigupdate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.access_management.RoomEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.RoomEvent} returns this
 */
proto.access_management.RoomEvent.prototype.clearConfigupdate = function() {
  return this.setConfigupdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.RoomEvent.prototype.hasConfigupdate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional GameInProgressEvent gameInProgress = 4;
 * @return {?proto.access_management.GameInProgressEvent}
 */
proto.access_management.RoomEvent.prototype.getGameinprogress = function() {
  return /** @type{?proto.access_management.GameInProgressEvent} */ (
    jspb.Message.getWrapperField(this, proto.access_management.GameInProgressEvent, 4));
};


/**
 * @param {?proto.access_management.GameInProgressEvent|undefined} value
 * @return {!proto.access_management.RoomEvent} returns this
*/
proto.access_management.RoomEvent.prototype.setGameinprogress = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.access_management.RoomEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.RoomEvent} returns this
 */
proto.access_management.RoomEvent.prototype.clearGameinprogress = function() {
  return this.setGameinprogress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.RoomEvent.prototype.hasGameinprogress = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional KeepAlive keepAlive = 5;
 * @return {?proto.access_management.KeepAlive}
 */
proto.access_management.RoomEvent.prototype.getKeepalive = function() {
  return /** @type{?proto.access_management.KeepAlive} */ (
    jspb.Message.getWrapperField(this, proto.access_management.KeepAlive, 5));
};


/**
 * @param {?proto.access_management.KeepAlive|undefined} value
 * @return {!proto.access_management.RoomEvent} returns this
*/
proto.access_management.RoomEvent.prototype.setKeepalive = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.access_management.RoomEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.RoomEvent} returns this
 */
proto.access_management.RoomEvent.prototype.clearKeepalive = function() {
  return this.setKeepalive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.RoomEvent.prototype.hasKeepalive = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional GamePendingEvent gamePending = 6;
 * @return {?proto.access_management.GamePendingEvent}
 */
proto.access_management.RoomEvent.prototype.getGamepending = function() {
  return /** @type{?proto.access_management.GamePendingEvent} */ (
    jspb.Message.getWrapperField(this, proto.access_management.GamePendingEvent, 6));
};


/**
 * @param {?proto.access_management.GamePendingEvent|undefined} value
 * @return {!proto.access_management.RoomEvent} returns this
*/
proto.access_management.RoomEvent.prototype.setGamepending = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.access_management.RoomEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.RoomEvent} returns this
 */
proto.access_management.RoomEvent.prototype.clearGamepending = function() {
  return this.setGamepending(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.RoomEvent.prototype.hasGamepending = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional GameFailedToStartEvent gameFailedToStart = 7;
 * @return {?proto.access_management.GameFailedToStartEvent}
 */
proto.access_management.RoomEvent.prototype.getGamefailedtostart = function() {
  return /** @type{?proto.access_management.GameFailedToStartEvent} */ (
    jspb.Message.getWrapperField(this, proto.access_management.GameFailedToStartEvent, 7));
};


/**
 * @param {?proto.access_management.GameFailedToStartEvent|undefined} value
 * @return {!proto.access_management.RoomEvent} returns this
*/
proto.access_management.RoomEvent.prototype.setGamefailedtostart = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.access_management.RoomEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.RoomEvent} returns this
 */
proto.access_management.RoomEvent.prototype.clearGamefailedtostart = function() {
  return this.setGamefailedtostart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.RoomEvent.prototype.hasGamefailedtostart = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional HostEndMatchEvent hostEndMatch = 8;
 * @return {?proto.access_management.HostEndMatchEvent}
 */
proto.access_management.RoomEvent.prototype.getHostendmatch = function() {
  return /** @type{?proto.access_management.HostEndMatchEvent} */ (
    jspb.Message.getWrapperField(this, proto.access_management.HostEndMatchEvent, 8));
};


/**
 * @param {?proto.access_management.HostEndMatchEvent|undefined} value
 * @return {!proto.access_management.RoomEvent} returns this
*/
proto.access_management.RoomEvent.prototype.setHostendmatch = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.access_management.RoomEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.RoomEvent} returns this
 */
proto.access_management.RoomEvent.prototype.clearHostendmatch = function() {
  return this.setHostendmatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.RoomEvent.prototype.hasHostendmatch = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Connected connected = 9;
 * @return {?proto.access_management.Connected}
 */
proto.access_management.RoomEvent.prototype.getConnected = function() {
  return /** @type{?proto.access_management.Connected} */ (
    jspb.Message.getWrapperField(this, proto.access_management.Connected, 9));
};


/**
 * @param {?proto.access_management.Connected|undefined} value
 * @return {!proto.access_management.RoomEvent} returns this
*/
proto.access_management.RoomEvent.prototype.setConnected = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.access_management.RoomEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.RoomEvent} returns this
 */
proto.access_management.RoomEvent.prototype.clearConnected = function() {
  return this.setConnected(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.RoomEvent.prototype.hasConnected = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional MatchDistributionEvent matchDistributionEvent = 10;
 * @return {?proto.access_management.MatchDistributionEvent}
 */
proto.access_management.RoomEvent.prototype.getMatchdistributionevent = function() {
  return /** @type{?proto.access_management.MatchDistributionEvent} */ (
    jspb.Message.getWrapperField(this, proto.access_management.MatchDistributionEvent, 10));
};


/**
 * @param {?proto.access_management.MatchDistributionEvent|undefined} value
 * @return {!proto.access_management.RoomEvent} returns this
*/
proto.access_management.RoomEvent.prototype.setMatchdistributionevent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.access_management.RoomEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.RoomEvent} returns this
 */
proto.access_management.RoomEvent.prototype.clearMatchdistributionevent = function() {
  return this.setMatchdistributionevent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.RoomEvent.prototype.hasMatchdistributionevent = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional MatchGroupEndedEvent matchGroupEnded = 11;
 * @return {?proto.access_management.MatchGroupEndedEvent}
 */
proto.access_management.RoomEvent.prototype.getMatchgroupended = function() {
  return /** @type{?proto.access_management.MatchGroupEndedEvent} */ (
    jspb.Message.getWrapperField(this, proto.access_management.MatchGroupEndedEvent, 11));
};


/**
 * @param {?proto.access_management.MatchGroupEndedEvent|undefined} value
 * @return {!proto.access_management.RoomEvent} returns this
*/
proto.access_management.RoomEvent.prototype.setMatchgroupended = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.access_management.RoomEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.RoomEvent} returns this
 */
proto.access_management.RoomEvent.prototype.clearMatchgroupended = function() {
  return this.setMatchgroupended(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.RoomEvent.prototype.hasMatchgroupended = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional FailedMatchConnectionEvent failedMatchConnection = 12;
 * @return {?proto.access_management.FailedMatchConnectionEvent}
 */
proto.access_management.RoomEvent.prototype.getFailedmatchconnection = function() {
  return /** @type{?proto.access_management.FailedMatchConnectionEvent} */ (
    jspb.Message.getWrapperField(this, proto.access_management.FailedMatchConnectionEvent, 12));
};


/**
 * @param {?proto.access_management.FailedMatchConnectionEvent|undefined} value
 * @return {!proto.access_management.RoomEvent} returns this
*/
proto.access_management.RoomEvent.prototype.setFailedmatchconnection = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.access_management.RoomEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.RoomEvent} returns this
 */
proto.access_management.RoomEvent.prototype.clearFailedmatchconnection = function() {
  return this.setFailedmatchconnection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.RoomEvent.prototype.hasFailedmatchconnection = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional GameStartedEvent gameStarted = 13;
 * @return {?proto.access_management.GameStartedEvent}
 */
proto.access_management.RoomEvent.prototype.getGamestarted = function() {
  return /** @type{?proto.access_management.GameStartedEvent} */ (
    jspb.Message.getWrapperField(this, proto.access_management.GameStartedEvent, 13));
};


/**
 * @param {?proto.access_management.GameStartedEvent|undefined} value
 * @return {!proto.access_management.RoomEvent} returns this
*/
proto.access_management.RoomEvent.prototype.setGamestarted = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.access_management.RoomEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.RoomEvent} returns this
 */
proto.access_management.RoomEvent.prototype.clearGamestarted = function() {
  return this.setGamestarted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.RoomEvent.prototype.hasGamestarted = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional PlayersDownloadingEvent playersDownloading = 14;
 * @return {?proto.access_management.PlayersDownloadingEvent}
 */
proto.access_management.RoomEvent.prototype.getPlayersdownloading = function() {
  return /** @type{?proto.access_management.PlayersDownloadingEvent} */ (
    jspb.Message.getWrapperField(this, proto.access_management.PlayersDownloadingEvent, 14));
};


/**
 * @param {?proto.access_management.PlayersDownloadingEvent|undefined} value
 * @return {!proto.access_management.RoomEvent} returns this
*/
proto.access_management.RoomEvent.prototype.setPlayersdownloading = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.access_management.RoomEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.RoomEvent} returns this
 */
proto.access_management.RoomEvent.prototype.clearPlayersdownloading = function() {
  return this.setPlayersdownloading(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.RoomEvent.prototype.hasPlayersdownloading = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional NewHostEvent newHost = 15;
 * @return {?proto.access_management.NewHostEvent}
 */
proto.access_management.RoomEvent.prototype.getNewhost = function() {
  return /** @type{?proto.access_management.NewHostEvent} */ (
    jspb.Message.getWrapperField(this, proto.access_management.NewHostEvent, 15));
};


/**
 * @param {?proto.access_management.NewHostEvent|undefined} value
 * @return {!proto.access_management.RoomEvent} returns this
*/
proto.access_management.RoomEvent.prototype.setNewhost = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.access_management.RoomEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.RoomEvent} returns this
 */
proto.access_management.RoomEvent.prototype.clearNewhost = function() {
  return this.setNewhost(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.RoomEvent.prototype.hasNewhost = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional MatchEndedEvent matchEnded = 16;
 * @return {?proto.access_management.MatchEndedEvent}
 */
proto.access_management.RoomEvent.prototype.getMatchended = function() {
  return /** @type{?proto.access_management.MatchEndedEvent} */ (
    jspb.Message.getWrapperField(this, proto.access_management.MatchEndedEvent, 16));
};


/**
 * @param {?proto.access_management.MatchEndedEvent|undefined} value
 * @return {!proto.access_management.RoomEvent} returns this
*/
proto.access_management.RoomEvent.prototype.setMatchended = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.access_management.RoomEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.RoomEvent} returns this
 */
proto.access_management.RoomEvent.prototype.clearMatchended = function() {
  return this.setMatchended(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.RoomEvent.prototype.hasMatchended = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional team_selection.TeamConfigEvent teamConfig = 17;
 * @return {?proto.team_selection.TeamConfigEvent}
 */
proto.access_management.RoomEvent.prototype.getTeamconfig = function() {
  return /** @type{?proto.team_selection.TeamConfigEvent} */ (
    jspb.Message.getWrapperField(this, src_modules_team_selection_events_pb.TeamConfigEvent, 17));
};


/**
 * @param {?proto.team_selection.TeamConfigEvent|undefined} value
 * @return {!proto.access_management.RoomEvent} returns this
*/
proto.access_management.RoomEvent.prototype.setTeamconfig = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.access_management.RoomEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.RoomEvent} returns this
 */
proto.access_management.RoomEvent.prototype.clearTeamconfig = function() {
  return this.setTeamconfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.RoomEvent.prototype.hasTeamconfig = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional UserStateUpdateEvent userStateUpdate = 18;
 * @return {?proto.access_management.UserStateUpdateEvent}
 */
proto.access_management.RoomEvent.prototype.getUserstateupdate = function() {
  return /** @type{?proto.access_management.UserStateUpdateEvent} */ (
    jspb.Message.getWrapperField(this, proto.access_management.UserStateUpdateEvent, 18));
};


/**
 * @param {?proto.access_management.UserStateUpdateEvent|undefined} value
 * @return {!proto.access_management.RoomEvent} returns this
*/
proto.access_management.RoomEvent.prototype.setUserstateupdate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.access_management.RoomEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.RoomEvent} returns this
 */
proto.access_management.RoomEvent.prototype.clearUserstateupdate = function() {
  return this.setUserstateupdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.RoomEvent.prototype.hasUserstateupdate = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional TournamentEvent tournamentEvent = 19;
 * @return {?proto.access_management.TournamentEvent}
 */
proto.access_management.RoomEvent.prototype.getTournamentevent = function() {
  return /** @type{?proto.access_management.TournamentEvent} */ (
    jspb.Message.getWrapperField(this, proto.access_management.TournamentEvent, 19));
};


/**
 * @param {?proto.access_management.TournamentEvent|undefined} value
 * @return {!proto.access_management.RoomEvent} returns this
*/
proto.access_management.RoomEvent.prototype.setTournamentevent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.access_management.RoomEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.RoomEvent} returns this
 */
proto.access_management.RoomEvent.prototype.clearTournamentevent = function() {
  return this.setTournamentevent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.RoomEvent.prototype.hasTournamentevent = function() {
  return jspb.Message.getField(this, 19) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.UserJoinedEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.UserJoinedEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.UserJoinedEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UserJoinedEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.UserJoinedEvent}
 */
proto.access_management.UserJoinedEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.UserJoinedEvent;
  return proto.access_management.UserJoinedEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.UserJoinedEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.UserJoinedEvent}
 */
proto.access_management.UserJoinedEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.UserJoinedEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.UserJoinedEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.UserJoinedEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UserJoinedEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.access_management.UserJoinedEvent.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UserJoinedEvent} returns this
 */
proto.access_management.UserJoinedEvent.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.UserLeftEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.UserLeftEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.UserLeftEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UserLeftEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.UserLeftEvent}
 */
proto.access_management.UserLeftEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.UserLeftEvent;
  return proto.access_management.UserLeftEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.UserLeftEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.UserLeftEvent}
 */
proto.access_management.UserLeftEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.UserLeftEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.UserLeftEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.UserLeftEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UserLeftEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.access_management.UserLeftEvent.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UserLeftEvent} returns this
 */
proto.access_management.UserLeftEvent.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.ConfigUpdateEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.ConfigUpdateEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.ConfigUpdateEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.ConfigUpdateEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    configMap: (f = msg.getConfigMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.ConfigUpdateEvent}
 */
proto.access_management.ConfigUpdateEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.ConfigUpdateEvent;
  return proto.access_management.ConfigUpdateEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.ConfigUpdateEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.ConfigUpdateEvent}
 */
proto.access_management.ConfigUpdateEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getConfigMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.ConfigUpdateEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.ConfigUpdateEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.ConfigUpdateEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.ConfigUpdateEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfigMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * map<string, string> config = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.access_management.ConfigUpdateEvent.prototype.getConfigMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.access_management.ConfigUpdateEvent} returns this
 */
proto.access_management.ConfigUpdateEvent.prototype.clearConfigMap = function() {
  this.getConfigMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.GamePendingEvent.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GamePendingEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GamePendingEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GamePendingEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GamePendingEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    matchId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    playersList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GamePendingEvent}
 */
proto.access_management.GamePendingEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GamePendingEvent;
  return proto.access_management.GamePendingEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GamePendingEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GamePendingEvent}
 */
proto.access_management.GamePendingEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatchId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addPlayers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GamePendingEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GamePendingEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GamePendingEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GamePendingEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMatchId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlayersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string match_id = 1;
 * @return {string}
 */
proto.access_management.GamePendingEvent.prototype.getMatchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GamePendingEvent} returns this
 */
proto.access_management.GamePendingEvent.prototype.setMatchId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string players = 2;
 * @return {!Array<string>}
 */
proto.access_management.GamePendingEvent.prototype.getPlayersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.access_management.GamePendingEvent} returns this
 */
proto.access_management.GamePendingEvent.prototype.setPlayersList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.access_management.GamePendingEvent} returns this
 */
proto.access_management.GamePendingEvent.prototype.addPlayers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.GamePendingEvent} returns this
 */
proto.access_management.GamePendingEvent.prototype.clearPlayersList = function() {
  return this.setPlayersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.GameInProgressEvent.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GameInProgressEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GameInProgressEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GameInProgressEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GameInProgressEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    serverUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    port: jspb.Message.getFieldWithDefault(msg, 2, 0),
    matchId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    offline: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    playflowMatchId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    playersList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    provider: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GameInProgressEvent}
 */
proto.access_management.GameInProgressEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GameInProgressEvent;
  return proto.access_management.GameInProgressEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GameInProgressEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GameInProgressEvent}
 */
proto.access_management.GameInProgressEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServerUrl(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPort(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatchId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOffline(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayflowMatchId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addPlayers(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GameInProgressEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GameInProgressEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GameInProgressEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GameInProgressEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServerUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPort();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getMatchId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOffline();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getPlayflowMatchId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPlayersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getProvider();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string server_url = 1;
 * @return {string}
 */
proto.access_management.GameInProgressEvent.prototype.getServerUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GameInProgressEvent} returns this
 */
proto.access_management.GameInProgressEvent.prototype.setServerUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 port = 2;
 * @return {number}
 */
proto.access_management.GameInProgressEvent.prototype.getPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.GameInProgressEvent} returns this
 */
proto.access_management.GameInProgressEvent.prototype.setPort = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string match_id = 3;
 * @return {string}
 */
proto.access_management.GameInProgressEvent.prototype.getMatchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GameInProgressEvent} returns this
 */
proto.access_management.GameInProgressEvent.prototype.setMatchId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool offline = 4;
 * @return {boolean}
 */
proto.access_management.GameInProgressEvent.prototype.getOffline = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.access_management.GameInProgressEvent} returns this
 */
proto.access_management.GameInProgressEvent.prototype.setOffline = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string playflow_match_id = 5;
 * @return {string}
 */
proto.access_management.GameInProgressEvent.prototype.getPlayflowMatchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GameInProgressEvent} returns this
 */
proto.access_management.GameInProgressEvent.prototype.setPlayflowMatchId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string players = 6;
 * @return {!Array<string>}
 */
proto.access_management.GameInProgressEvent.prototype.getPlayersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.access_management.GameInProgressEvent} returns this
 */
proto.access_management.GameInProgressEvent.prototype.setPlayersList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.access_management.GameInProgressEvent} returns this
 */
proto.access_management.GameInProgressEvent.prototype.addPlayers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.GameInProgressEvent} returns this
 */
proto.access_management.GameInProgressEvent.prototype.clearPlayersList = function() {
  return this.setPlayersList([]);
};


/**
 * optional string provider = 7;
 * @return {string}
 */
proto.access_management.GameInProgressEvent.prototype.getProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GameInProgressEvent} returns this
 */
proto.access_management.GameInProgressEvent.prototype.setProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GameFailedToStartEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GameFailedToStartEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GameFailedToStartEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GameFailedToStartEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    reason: jspb.Message.getFieldWithDefault(msg, 1, ""),
    matchId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    playflowMatchId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GameFailedToStartEvent}
 */
proto.access_management.GameFailedToStartEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GameFailedToStartEvent;
  return proto.access_management.GameFailedToStartEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GameFailedToStartEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GameFailedToStartEvent}
 */
proto.access_management.GameFailedToStartEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatchId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayflowMatchId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GameFailedToStartEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GameFailedToStartEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GameFailedToStartEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GameFailedToStartEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMatchId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlayflowMatchId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string reason = 1;
 * @return {string}
 */
proto.access_management.GameFailedToStartEvent.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GameFailedToStartEvent} returns this
 */
proto.access_management.GameFailedToStartEvent.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string match_id = 2;
 * @return {string}
 */
proto.access_management.GameFailedToStartEvent.prototype.getMatchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GameFailedToStartEvent} returns this
 */
proto.access_management.GameFailedToStartEvent.prototype.setMatchId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string playflow_match_id = 3;
 * @return {string}
 */
proto.access_management.GameFailedToStartEvent.prototype.getPlayflowMatchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GameFailedToStartEvent} returns this
 */
proto.access_management.GameFailedToStartEvent.prototype.setPlayflowMatchId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.MatchDistributionEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.MatchDistributionEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.MatchDistributionEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.MatchDistributionEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    minPlayers: jspb.Message.getFieldWithDefault(msg, 1, 0),
    maxPlayers: jspb.Message.getFieldWithDefault(msg, 2, 0),
    numberOfMatches: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalPlayers: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.MatchDistributionEvent}
 */
proto.access_management.MatchDistributionEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.MatchDistributionEvent;
  return proto.access_management.MatchDistributionEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.MatchDistributionEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.MatchDistributionEvent}
 */
proto.access_management.MatchDistributionEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinPlayers(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxPlayers(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfMatches(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalPlayers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.MatchDistributionEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.MatchDistributionEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.MatchDistributionEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.MatchDistributionEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinPlayers();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMaxPlayers();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getNumberOfMatches();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTotalPlayers();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 min_players = 1;
 * @return {number}
 */
proto.access_management.MatchDistributionEvent.prototype.getMinPlayers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.MatchDistributionEvent} returns this
 */
proto.access_management.MatchDistributionEvent.prototype.setMinPlayers = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 max_players = 2;
 * @return {number}
 */
proto.access_management.MatchDistributionEvent.prototype.getMaxPlayers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.MatchDistributionEvent} returns this
 */
proto.access_management.MatchDistributionEvent.prototype.setMaxPlayers = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 number_of_matches = 3;
 * @return {number}
 */
proto.access_management.MatchDistributionEvent.prototype.getNumberOfMatches = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.MatchDistributionEvent} returns this
 */
proto.access_management.MatchDistributionEvent.prototype.setNumberOfMatches = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 total_players = 4;
 * @return {number}
 */
proto.access_management.MatchDistributionEvent.prototype.getTotalPlayers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.MatchDistributionEvent} returns this
 */
proto.access_management.MatchDistributionEvent.prototype.setTotalPlayers = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.HostEndMatchEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.HostEndMatchEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.HostEndMatchEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.HostEndMatchEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    matchId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.HostEndMatchEvent}
 */
proto.access_management.HostEndMatchEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.HostEndMatchEvent;
  return proto.access_management.HostEndMatchEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.HostEndMatchEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.HostEndMatchEvent}
 */
proto.access_management.HostEndMatchEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatchId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.HostEndMatchEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.HostEndMatchEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.HostEndMatchEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.HostEndMatchEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMatchId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string match_id = 1;
 * @return {string}
 */
proto.access_management.HostEndMatchEvent.prototype.getMatchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.HostEndMatchEvent} returns this
 */
proto.access_management.HostEndMatchEvent.prototype.setMatchId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.MatchGroupEndedEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.MatchGroupEndedEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.MatchGroupEndedEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.MatchGroupEndedEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    matchGroupId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.MatchGroupEndedEvent}
 */
proto.access_management.MatchGroupEndedEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.MatchGroupEndedEvent;
  return proto.access_management.MatchGroupEndedEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.MatchGroupEndedEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.MatchGroupEndedEvent}
 */
proto.access_management.MatchGroupEndedEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatchGroupId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.MatchGroupEndedEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.MatchGroupEndedEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.MatchGroupEndedEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.MatchGroupEndedEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMatchGroupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string match_group_id = 1;
 * @return {string}
 */
proto.access_management.MatchGroupEndedEvent.prototype.getMatchGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.MatchGroupEndedEvent} returns this
 */
proto.access_management.MatchGroupEndedEvent.prototype.setMatchGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.FailedMatchConnectionEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.FailedMatchConnectionEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.FailedMatchConnectionEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.FailedMatchConnectionEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    matchId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reason: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.FailedMatchConnectionEvent}
 */
proto.access_management.FailedMatchConnectionEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.FailedMatchConnectionEvent;
  return proto.access_management.FailedMatchConnectionEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.FailedMatchConnectionEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.FailedMatchConnectionEvent}
 */
proto.access_management.FailedMatchConnectionEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatchId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.FailedMatchConnectionEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.FailedMatchConnectionEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.FailedMatchConnectionEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.FailedMatchConnectionEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMatchId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string match_id = 1;
 * @return {string}
 */
proto.access_management.FailedMatchConnectionEvent.prototype.getMatchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.FailedMatchConnectionEvent} returns this
 */
proto.access_management.FailedMatchConnectionEvent.prototype.setMatchId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.access_management.FailedMatchConnectionEvent.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.FailedMatchConnectionEvent} returns this
 */
proto.access_management.FailedMatchConnectionEvent.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string reason = 3;
 * @return {string}
 */
proto.access_management.FailedMatchConnectionEvent.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.FailedMatchConnectionEvent} returns this
 */
proto.access_management.FailedMatchConnectionEvent.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GameStartedEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GameStartedEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GameStartedEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GameStartedEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    matchId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GameStartedEvent}
 */
proto.access_management.GameStartedEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GameStartedEvent;
  return proto.access_management.GameStartedEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GameStartedEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GameStartedEvent}
 */
proto.access_management.GameStartedEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatchId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GameStartedEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GameStartedEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GameStartedEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GameStartedEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMatchId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string match_id = 1;
 * @return {string}
 */
proto.access_management.GameStartedEvent.prototype.getMatchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GameStartedEvent} returns this
 */
proto.access_management.GameStartedEvent.prototype.setMatchId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.PlayersDownloadingEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.PlayersDownloadingEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.PlayersDownloadingEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.PlayersDownloadingEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.PlayersDownloadingEvent}
 */
proto.access_management.PlayersDownloadingEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.PlayersDownloadingEvent;
  return proto.access_management.PlayersDownloadingEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.PlayersDownloadingEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.PlayersDownloadingEvent}
 */
proto.access_management.PlayersDownloadingEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.PlayersDownloadingEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.PlayersDownloadingEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.PlayersDownloadingEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.PlayersDownloadingEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 count = 1;
 * @return {number}
 */
proto.access_management.PlayersDownloadingEvent.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.PlayersDownloadingEvent} returns this
 */
proto.access_management.PlayersDownloadingEvent.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.NewHostEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.NewHostEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.NewHostEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.NewHostEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.NewHostEvent}
 */
proto.access_management.NewHostEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.NewHostEvent;
  return proto.access_management.NewHostEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.NewHostEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.NewHostEvent}
 */
proto.access_management.NewHostEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.NewHostEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.NewHostEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.NewHostEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.NewHostEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.access_management.NewHostEvent.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.NewHostEvent} returns this
 */
proto.access_management.NewHostEvent.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.MatchEndedEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.MatchEndedEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.MatchEndedEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.MatchEndedEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    matchId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.MatchEndedEvent}
 */
proto.access_management.MatchEndedEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.MatchEndedEvent;
  return proto.access_management.MatchEndedEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.MatchEndedEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.MatchEndedEvent}
 */
proto.access_management.MatchEndedEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatchId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.MatchEndedEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.MatchEndedEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.MatchEndedEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.MatchEndedEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMatchId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string match_id = 1;
 * @return {string}
 */
proto.access_management.MatchEndedEvent.prototype.getMatchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.MatchEndedEvent} returns this
 */
proto.access_management.MatchEndedEvent.prototype.setMatchId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.UserStateUpdateEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.UserStateUpdateEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.UserStateUpdateEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UserStateUpdateEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stateMap: (f = msg.getStateMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.UserStateUpdateEvent}
 */
proto.access_management.UserStateUpdateEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.UserStateUpdateEvent;
  return proto.access_management.UserStateUpdateEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.UserStateUpdateEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.UserStateUpdateEvent}
 */
proto.access_management.UserStateUpdateEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = msg.getStateMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.UserStateUpdateEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.UserStateUpdateEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.UserStateUpdateEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UserStateUpdateEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStateMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.access_management.UserStateUpdateEvent.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UserStateUpdateEvent} returns this
 */
proto.access_management.UserStateUpdateEvent.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<string, string> state = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.access_management.UserStateUpdateEvent.prototype.getStateMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.access_management.UserStateUpdateEvent} returns this
 */
proto.access_management.UserStateUpdateEvent.prototype.clearStateMap = function() {
  this.getStateMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.TournamentEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.TournamentEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.TournamentEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.TournamentEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rawData: jspb.Message.getFieldWithDefault(msg, 2, ""),
    eventEnum: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.TournamentEvent}
 */
proto.access_management.TournamentEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.TournamentEvent;
  return proto.access_management.TournamentEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.TournamentEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.TournamentEvent}
 */
proto.access_management.TournamentEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRawData(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEventEnum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.TournamentEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.TournamentEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.TournamentEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.TournamentEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRawData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEventEnum();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string event_type = 1;
 * @return {string}
 */
proto.access_management.TournamentEvent.prototype.getEventType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.TournamentEvent} returns this
 */
proto.access_management.TournamentEvent.prototype.setEventType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string raw_data = 2;
 * @return {string}
 */
proto.access_management.TournamentEvent.prototype.getRawData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.TournamentEvent} returns this
 */
proto.access_management.TournamentEvent.prototype.setRawData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 event_enum = 3;
 * @return {number}
 */
proto.access_management.TournamentEvent.prototype.getEventEnum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.TournamentEvent} returns this
 */
proto.access_management.TournamentEvent.prototype.setEventEnum = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.ConnectUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.ConnectUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.ConnectUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.ConnectUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.ConnectUserRequest}
 */
proto.access_management.ConnectUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.ConnectUserRequest;
  return proto.access_management.ConnectUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.ConnectUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.ConnectUserRequest}
 */
proto.access_management.ConnectUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.ConnectUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.ConnectUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.ConnectUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.ConnectUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.access_management.ConnectedUserEvent.oneofGroups_ = [[1,2,4,5,6,7,8,9,10]];

/**
 * @enum {number}
 */
proto.access_management.ConnectedUserEvent.EventCase = {
  EVENT_NOT_SET: 0,
  COMMUNITYMETRICUPDATE: 1,
  ROOMCREATEDEVENT: 2,
  ROOMDELETEDEVENT: 4,
  KEEPALIVE: 5,
  USERJOINEDROOM: 6,
  USERLEFTROOM: 7,
  CONNECTED: 8,
  ROOMUPDATEDEVENT: 9,
  COMMUNITYSUBSCRIPTIONEVENT: 10
};

/**
 * @return {proto.access_management.ConnectedUserEvent.EventCase}
 */
proto.access_management.ConnectedUserEvent.prototype.getEventCase = function() {
  return /** @type {proto.access_management.ConnectedUserEvent.EventCase} */(jspb.Message.computeOneofCase(this, proto.access_management.ConnectedUserEvent.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.ConnectedUserEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.ConnectedUserEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.ConnectedUserEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.ConnectedUserEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    communitymetricupdate: (f = msg.getCommunitymetricupdate()) && proto.access_management.CommunityMetricUpdateEvent.toObject(includeInstance, f),
    roomcreatedevent: (f = msg.getRoomcreatedevent()) && proto.access_management.RoomCreatedEvent.toObject(includeInstance, f),
    roomdeletedevent: (f = msg.getRoomdeletedevent()) && proto.access_management.RoomDeletedEvent.toObject(includeInstance, f),
    keepalive: (f = msg.getKeepalive()) && proto.access_management.KeepAlive.toObject(includeInstance, f),
    userjoinedroom: (f = msg.getUserjoinedroom()) && proto.access_management.UserJoinedRoomEvent.toObject(includeInstance, f),
    userleftroom: (f = msg.getUserleftroom()) && proto.access_management.UserLeftRoomEvent.toObject(includeInstance, f),
    connected: (f = msg.getConnected()) && proto.access_management.Connected.toObject(includeInstance, f),
    roomupdatedevent: (f = msg.getRoomupdatedevent()) && proto.access_management.RoomUpdatedEvent.toObject(includeInstance, f),
    communitysubscriptionevent: (f = msg.getCommunitysubscriptionevent()) && proto.access_management.CommunitySubscriptionEvent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.ConnectedUserEvent}
 */
proto.access_management.ConnectedUserEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.ConnectedUserEvent;
  return proto.access_management.ConnectedUserEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.ConnectedUserEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.ConnectedUserEvent}
 */
proto.access_management.ConnectedUserEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.access_management.CommunityMetricUpdateEvent;
      reader.readMessage(value,proto.access_management.CommunityMetricUpdateEvent.deserializeBinaryFromReader);
      msg.setCommunitymetricupdate(value);
      break;
    case 2:
      var value = new proto.access_management.RoomCreatedEvent;
      reader.readMessage(value,proto.access_management.RoomCreatedEvent.deserializeBinaryFromReader);
      msg.setRoomcreatedevent(value);
      break;
    case 4:
      var value = new proto.access_management.RoomDeletedEvent;
      reader.readMessage(value,proto.access_management.RoomDeletedEvent.deserializeBinaryFromReader);
      msg.setRoomdeletedevent(value);
      break;
    case 5:
      var value = new proto.access_management.KeepAlive;
      reader.readMessage(value,proto.access_management.KeepAlive.deserializeBinaryFromReader);
      msg.setKeepalive(value);
      break;
    case 6:
      var value = new proto.access_management.UserJoinedRoomEvent;
      reader.readMessage(value,proto.access_management.UserJoinedRoomEvent.deserializeBinaryFromReader);
      msg.setUserjoinedroom(value);
      break;
    case 7:
      var value = new proto.access_management.UserLeftRoomEvent;
      reader.readMessage(value,proto.access_management.UserLeftRoomEvent.deserializeBinaryFromReader);
      msg.setUserleftroom(value);
      break;
    case 8:
      var value = new proto.access_management.Connected;
      reader.readMessage(value,proto.access_management.Connected.deserializeBinaryFromReader);
      msg.setConnected(value);
      break;
    case 9:
      var value = new proto.access_management.RoomUpdatedEvent;
      reader.readMessage(value,proto.access_management.RoomUpdatedEvent.deserializeBinaryFromReader);
      msg.setRoomupdatedevent(value);
      break;
    case 10:
      var value = new proto.access_management.CommunitySubscriptionEvent;
      reader.readMessage(value,proto.access_management.CommunitySubscriptionEvent.deserializeBinaryFromReader);
      msg.setCommunitysubscriptionevent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.ConnectedUserEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.ConnectedUserEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.ConnectedUserEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.ConnectedUserEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommunitymetricupdate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.access_management.CommunityMetricUpdateEvent.serializeBinaryToWriter
    );
  }
  f = message.getRoomcreatedevent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.access_management.RoomCreatedEvent.serializeBinaryToWriter
    );
  }
  f = message.getRoomdeletedevent();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.access_management.RoomDeletedEvent.serializeBinaryToWriter
    );
  }
  f = message.getKeepalive();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.access_management.KeepAlive.serializeBinaryToWriter
    );
  }
  f = message.getUserjoinedroom();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.access_management.UserJoinedRoomEvent.serializeBinaryToWriter
    );
  }
  f = message.getUserleftroom();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.access_management.UserLeftRoomEvent.serializeBinaryToWriter
    );
  }
  f = message.getConnected();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.access_management.Connected.serializeBinaryToWriter
    );
  }
  f = message.getRoomupdatedevent();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.access_management.RoomUpdatedEvent.serializeBinaryToWriter
    );
  }
  f = message.getCommunitysubscriptionevent();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.access_management.CommunitySubscriptionEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional CommunityMetricUpdateEvent communityMetricUpdate = 1;
 * @return {?proto.access_management.CommunityMetricUpdateEvent}
 */
proto.access_management.ConnectedUserEvent.prototype.getCommunitymetricupdate = function() {
  return /** @type{?proto.access_management.CommunityMetricUpdateEvent} */ (
    jspb.Message.getWrapperField(this, proto.access_management.CommunityMetricUpdateEvent, 1));
};


/**
 * @param {?proto.access_management.CommunityMetricUpdateEvent|undefined} value
 * @return {!proto.access_management.ConnectedUserEvent} returns this
*/
proto.access_management.ConnectedUserEvent.prototype.setCommunitymetricupdate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.access_management.ConnectedUserEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.ConnectedUserEvent} returns this
 */
proto.access_management.ConnectedUserEvent.prototype.clearCommunitymetricupdate = function() {
  return this.setCommunitymetricupdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.ConnectedUserEvent.prototype.hasCommunitymetricupdate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RoomCreatedEvent roomCreatedEvent = 2;
 * @return {?proto.access_management.RoomCreatedEvent}
 */
proto.access_management.ConnectedUserEvent.prototype.getRoomcreatedevent = function() {
  return /** @type{?proto.access_management.RoomCreatedEvent} */ (
    jspb.Message.getWrapperField(this, proto.access_management.RoomCreatedEvent, 2));
};


/**
 * @param {?proto.access_management.RoomCreatedEvent|undefined} value
 * @return {!proto.access_management.ConnectedUserEvent} returns this
*/
proto.access_management.ConnectedUserEvent.prototype.setRoomcreatedevent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.access_management.ConnectedUserEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.ConnectedUserEvent} returns this
 */
proto.access_management.ConnectedUserEvent.prototype.clearRoomcreatedevent = function() {
  return this.setRoomcreatedevent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.ConnectedUserEvent.prototype.hasRoomcreatedevent = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RoomDeletedEvent roomDeletedEvent = 4;
 * @return {?proto.access_management.RoomDeletedEvent}
 */
proto.access_management.ConnectedUserEvent.prototype.getRoomdeletedevent = function() {
  return /** @type{?proto.access_management.RoomDeletedEvent} */ (
    jspb.Message.getWrapperField(this, proto.access_management.RoomDeletedEvent, 4));
};


/**
 * @param {?proto.access_management.RoomDeletedEvent|undefined} value
 * @return {!proto.access_management.ConnectedUserEvent} returns this
*/
proto.access_management.ConnectedUserEvent.prototype.setRoomdeletedevent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.access_management.ConnectedUserEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.ConnectedUserEvent} returns this
 */
proto.access_management.ConnectedUserEvent.prototype.clearRoomdeletedevent = function() {
  return this.setRoomdeletedevent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.ConnectedUserEvent.prototype.hasRoomdeletedevent = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional KeepAlive keepAlive = 5;
 * @return {?proto.access_management.KeepAlive}
 */
proto.access_management.ConnectedUserEvent.prototype.getKeepalive = function() {
  return /** @type{?proto.access_management.KeepAlive} */ (
    jspb.Message.getWrapperField(this, proto.access_management.KeepAlive, 5));
};


/**
 * @param {?proto.access_management.KeepAlive|undefined} value
 * @return {!proto.access_management.ConnectedUserEvent} returns this
*/
proto.access_management.ConnectedUserEvent.prototype.setKeepalive = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.access_management.ConnectedUserEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.ConnectedUserEvent} returns this
 */
proto.access_management.ConnectedUserEvent.prototype.clearKeepalive = function() {
  return this.setKeepalive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.ConnectedUserEvent.prototype.hasKeepalive = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional UserJoinedRoomEvent userJoinedRoom = 6;
 * @return {?proto.access_management.UserJoinedRoomEvent}
 */
proto.access_management.ConnectedUserEvent.prototype.getUserjoinedroom = function() {
  return /** @type{?proto.access_management.UserJoinedRoomEvent} */ (
    jspb.Message.getWrapperField(this, proto.access_management.UserJoinedRoomEvent, 6));
};


/**
 * @param {?proto.access_management.UserJoinedRoomEvent|undefined} value
 * @return {!proto.access_management.ConnectedUserEvent} returns this
*/
proto.access_management.ConnectedUserEvent.prototype.setUserjoinedroom = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.access_management.ConnectedUserEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.ConnectedUserEvent} returns this
 */
proto.access_management.ConnectedUserEvent.prototype.clearUserjoinedroom = function() {
  return this.setUserjoinedroom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.ConnectedUserEvent.prototype.hasUserjoinedroom = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional UserLeftRoomEvent userLeftRoom = 7;
 * @return {?proto.access_management.UserLeftRoomEvent}
 */
proto.access_management.ConnectedUserEvent.prototype.getUserleftroom = function() {
  return /** @type{?proto.access_management.UserLeftRoomEvent} */ (
    jspb.Message.getWrapperField(this, proto.access_management.UserLeftRoomEvent, 7));
};


/**
 * @param {?proto.access_management.UserLeftRoomEvent|undefined} value
 * @return {!proto.access_management.ConnectedUserEvent} returns this
*/
proto.access_management.ConnectedUserEvent.prototype.setUserleftroom = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.access_management.ConnectedUserEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.ConnectedUserEvent} returns this
 */
proto.access_management.ConnectedUserEvent.prototype.clearUserleftroom = function() {
  return this.setUserleftroom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.ConnectedUserEvent.prototype.hasUserleftroom = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Connected connected = 8;
 * @return {?proto.access_management.Connected}
 */
proto.access_management.ConnectedUserEvent.prototype.getConnected = function() {
  return /** @type{?proto.access_management.Connected} */ (
    jspb.Message.getWrapperField(this, proto.access_management.Connected, 8));
};


/**
 * @param {?proto.access_management.Connected|undefined} value
 * @return {!proto.access_management.ConnectedUserEvent} returns this
*/
proto.access_management.ConnectedUserEvent.prototype.setConnected = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.access_management.ConnectedUserEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.ConnectedUserEvent} returns this
 */
proto.access_management.ConnectedUserEvent.prototype.clearConnected = function() {
  return this.setConnected(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.ConnectedUserEvent.prototype.hasConnected = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional RoomUpdatedEvent roomUpdatedEvent = 9;
 * @return {?proto.access_management.RoomUpdatedEvent}
 */
proto.access_management.ConnectedUserEvent.prototype.getRoomupdatedevent = function() {
  return /** @type{?proto.access_management.RoomUpdatedEvent} */ (
    jspb.Message.getWrapperField(this, proto.access_management.RoomUpdatedEvent, 9));
};


/**
 * @param {?proto.access_management.RoomUpdatedEvent|undefined} value
 * @return {!proto.access_management.ConnectedUserEvent} returns this
*/
proto.access_management.ConnectedUserEvent.prototype.setRoomupdatedevent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.access_management.ConnectedUserEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.ConnectedUserEvent} returns this
 */
proto.access_management.ConnectedUserEvent.prototype.clearRoomupdatedevent = function() {
  return this.setRoomupdatedevent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.ConnectedUserEvent.prototype.hasRoomupdatedevent = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional CommunitySubscriptionEvent communitySubscriptionEvent = 10;
 * @return {?proto.access_management.CommunitySubscriptionEvent}
 */
proto.access_management.ConnectedUserEvent.prototype.getCommunitysubscriptionevent = function() {
  return /** @type{?proto.access_management.CommunitySubscriptionEvent} */ (
    jspb.Message.getWrapperField(this, proto.access_management.CommunitySubscriptionEvent, 10));
};


/**
 * @param {?proto.access_management.CommunitySubscriptionEvent|undefined} value
 * @return {!proto.access_management.ConnectedUserEvent} returns this
*/
proto.access_management.ConnectedUserEvent.prototype.setCommunitysubscriptionevent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.access_management.ConnectedUserEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.ConnectedUserEvent} returns this
 */
proto.access_management.ConnectedUserEvent.prototype.clearCommunitysubscriptionevent = function() {
  return this.setCommunitysubscriptionevent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.ConnectedUserEvent.prototype.hasCommunitysubscriptionevent = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.CommunityMetricUpdateEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.CommunityMetricUpdateEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.CommunityMetricUpdateEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CommunityMetricUpdateEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    onlineMembers: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalMembers: jspb.Message.getFieldWithDefault(msg, 3, 0),
    roomCount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.CommunityMetricUpdateEvent}
 */
proto.access_management.CommunityMetricUpdateEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.CommunityMetricUpdateEvent;
  return proto.access_management.CommunityMetricUpdateEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.CommunityMetricUpdateEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.CommunityMetricUpdateEvent}
 */
proto.access_management.CommunityMetricUpdateEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOnlineMembers(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalMembers(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.CommunityMetricUpdateEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.CommunityMetricUpdateEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.CommunityMetricUpdateEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CommunityMetricUpdateEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOnlineMembers();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTotalMembers();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRoomCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.CommunityMetricUpdateEvent.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CommunityMetricUpdateEvent} returns this
 */
proto.access_management.CommunityMetricUpdateEvent.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 online_members = 2;
 * @return {number}
 */
proto.access_management.CommunityMetricUpdateEvent.prototype.getOnlineMembers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.CommunityMetricUpdateEvent} returns this
 */
proto.access_management.CommunityMetricUpdateEvent.prototype.setOnlineMembers = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 total_members = 3;
 * @return {number}
 */
proto.access_management.CommunityMetricUpdateEvent.prototype.getTotalMembers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.CommunityMetricUpdateEvent} returns this
 */
proto.access_management.CommunityMetricUpdateEvent.prototype.setTotalMembers = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 room_count = 4;
 * @return {number}
 */
proto.access_management.CommunityMetricUpdateEvent.prototype.getRoomCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.CommunityMetricUpdateEvent} returns this
 */
proto.access_management.CommunityMetricUpdateEvent.prototype.setRoomCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.RoomCreatedEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.RoomCreatedEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.RoomCreatedEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.RoomCreatedEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    communityId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.RoomCreatedEvent}
 */
proto.access_management.RoomCreatedEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.RoomCreatedEvent;
  return proto.access_management.RoomCreatedEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.RoomCreatedEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.RoomCreatedEvent}
 */
proto.access_management.RoomCreatedEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommunityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.RoomCreatedEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.RoomCreatedEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.RoomCreatedEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.RoomCreatedEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCommunityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.access_management.RoomCreatedEvent.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.RoomCreatedEvent} returns this
 */
proto.access_management.RoomCreatedEvent.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string community_id = 2;
 * @return {string}
 */
proto.access_management.RoomCreatedEvent.prototype.getCommunityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.RoomCreatedEvent} returns this
 */
proto.access_management.RoomCreatedEvent.prototype.setCommunityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.RoomDeletedEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.RoomDeletedEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.RoomDeletedEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.RoomDeletedEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    communityId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.RoomDeletedEvent}
 */
proto.access_management.RoomDeletedEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.RoomDeletedEvent;
  return proto.access_management.RoomDeletedEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.RoomDeletedEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.RoomDeletedEvent}
 */
proto.access_management.RoomDeletedEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommunityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.RoomDeletedEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.RoomDeletedEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.RoomDeletedEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.RoomDeletedEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCommunityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.access_management.RoomDeletedEvent.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.RoomDeletedEvent} returns this
 */
proto.access_management.RoomDeletedEvent.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string community_id = 2;
 * @return {string}
 */
proto.access_management.RoomDeletedEvent.prototype.getCommunityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.RoomDeletedEvent} returns this
 */
proto.access_management.RoomDeletedEvent.prototype.setCommunityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.UserJoinedRoomEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.UserJoinedRoomEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.UserJoinedRoomEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UserJoinedRoomEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roomId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    communityId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    roomMemberCount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.UserJoinedRoomEvent}
 */
proto.access_management.UserJoinedRoomEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.UserJoinedRoomEvent;
  return proto.access_management.UserJoinedRoomEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.UserJoinedRoomEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.UserJoinedRoomEvent}
 */
proto.access_management.UserJoinedRoomEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommunityId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRoomMemberCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.UserJoinedRoomEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.UserJoinedRoomEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.UserJoinedRoomEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UserJoinedRoomEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCommunityId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRoomMemberCount();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.access_management.UserJoinedRoomEvent.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UserJoinedRoomEvent} returns this
 */
proto.access_management.UserJoinedRoomEvent.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string room_id = 2;
 * @return {string}
 */
proto.access_management.UserJoinedRoomEvent.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UserJoinedRoomEvent} returns this
 */
proto.access_management.UserJoinedRoomEvent.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string community_id = 3;
 * @return {string}
 */
proto.access_management.UserJoinedRoomEvent.prototype.getCommunityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UserJoinedRoomEvent} returns this
 */
proto.access_management.UserJoinedRoomEvent.prototype.setCommunityId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint64 room_member_count = 4;
 * @return {number}
 */
proto.access_management.UserJoinedRoomEvent.prototype.getRoomMemberCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.UserJoinedRoomEvent} returns this
 */
proto.access_management.UserJoinedRoomEvent.prototype.setRoomMemberCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.UserLeftRoomEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.UserLeftRoomEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.UserLeftRoomEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UserLeftRoomEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roomId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    communityId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    roomMemberCount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.UserLeftRoomEvent}
 */
proto.access_management.UserLeftRoomEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.UserLeftRoomEvent;
  return proto.access_management.UserLeftRoomEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.UserLeftRoomEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.UserLeftRoomEvent}
 */
proto.access_management.UserLeftRoomEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommunityId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRoomMemberCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.UserLeftRoomEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.UserLeftRoomEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.UserLeftRoomEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UserLeftRoomEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCommunityId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRoomMemberCount();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.access_management.UserLeftRoomEvent.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UserLeftRoomEvent} returns this
 */
proto.access_management.UserLeftRoomEvent.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string room_id = 2;
 * @return {string}
 */
proto.access_management.UserLeftRoomEvent.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UserLeftRoomEvent} returns this
 */
proto.access_management.UserLeftRoomEvent.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string community_id = 3;
 * @return {string}
 */
proto.access_management.UserLeftRoomEvent.prototype.getCommunityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UserLeftRoomEvent} returns this
 */
proto.access_management.UserLeftRoomEvent.prototype.setCommunityId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint64 room_member_count = 4;
 * @return {number}
 */
proto.access_management.UserLeftRoomEvent.prototype.getRoomMemberCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.UserLeftRoomEvent} returns this
 */
proto.access_management.UserLeftRoomEvent.prototype.setRoomMemberCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.RoomUpdatedEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.RoomUpdatedEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.RoomUpdatedEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.RoomUpdatedEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    communityId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.RoomUpdatedEvent}
 */
proto.access_management.RoomUpdatedEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.RoomUpdatedEvent;
  return proto.access_management.RoomUpdatedEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.RoomUpdatedEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.RoomUpdatedEvent}
 */
proto.access_management.RoomUpdatedEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommunityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.RoomUpdatedEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.RoomUpdatedEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.RoomUpdatedEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.RoomUpdatedEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCommunityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.access_management.RoomUpdatedEvent.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.RoomUpdatedEvent} returns this
 */
proto.access_management.RoomUpdatedEvent.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string community_id = 2;
 * @return {string}
 */
proto.access_management.RoomUpdatedEvent.prototype.getCommunityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.RoomUpdatedEvent} returns this
 */
proto.access_management.RoomUpdatedEvent.prototype.setCommunityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.CommunitySubscriptionEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.CommunitySubscriptionEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.CommunitySubscriptionEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CommunitySubscriptionEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    communityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    stringDataMap: (f = msg.getStringDataMap()) ? f.toObject(includeInstance, undefined) : [],
    numberDataMap: (f = msg.getNumberDataMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.CommunitySubscriptionEvent}
 */
proto.access_management.CommunitySubscriptionEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.CommunitySubscriptionEvent;
  return proto.access_management.CommunitySubscriptionEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.CommunitySubscriptionEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.CommunitySubscriptionEvent}
 */
proto.access_management.CommunitySubscriptionEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommunityId(value);
      break;
    case 2:
      var value = /** @type {!proto.access_management.CommunitySubscriptionEventType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = msg.getStringDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 4:
      var value = msg.getNumberDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readFloat, null, "", 0.0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.CommunitySubscriptionEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.CommunitySubscriptionEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.CommunitySubscriptionEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CommunitySubscriptionEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommunityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getStringDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getNumberDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeFloat);
  }
};


/**
 * optional string community_id = 1;
 * @return {string}
 */
proto.access_management.CommunitySubscriptionEvent.prototype.getCommunityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CommunitySubscriptionEvent} returns this
 */
proto.access_management.CommunitySubscriptionEvent.prototype.setCommunityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CommunitySubscriptionEventType type = 2;
 * @return {!proto.access_management.CommunitySubscriptionEventType}
 */
proto.access_management.CommunitySubscriptionEvent.prototype.getType = function() {
  return /** @type {!proto.access_management.CommunitySubscriptionEventType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.access_management.CommunitySubscriptionEventType} value
 * @return {!proto.access_management.CommunitySubscriptionEvent} returns this
 */
proto.access_management.CommunitySubscriptionEvent.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * map<string, string> string_data = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.access_management.CommunitySubscriptionEvent.prototype.getStringDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.access_management.CommunitySubscriptionEvent} returns this
 */
proto.access_management.CommunitySubscriptionEvent.prototype.clearStringDataMap = function() {
  this.getStringDataMap().clear();
  return this;};


/**
 * map<string, float> number_data = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.access_management.CommunitySubscriptionEvent.prototype.getNumberDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.access_management.CommunitySubscriptionEvent} returns this
 */
proto.access_management.CommunitySubscriptionEvent.prototype.clearNumberDataMap = function() {
  this.getNumberDataMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.PublishRoomEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.PublishRoomEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.PublishRoomEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.PublishRoomEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roomEvent: (f = msg.getRoomEvent()) && proto.access_management.RoomEvent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.PublishRoomEventRequest}
 */
proto.access_management.PublishRoomEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.PublishRoomEventRequest;
  return proto.access_management.PublishRoomEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.PublishRoomEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.PublishRoomEventRequest}
 */
proto.access_management.PublishRoomEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 2:
      var value = new proto.access_management.RoomEvent;
      reader.readMessage(value,proto.access_management.RoomEvent.deserializeBinaryFromReader);
      msg.setRoomEvent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.PublishRoomEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.PublishRoomEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.PublishRoomEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.PublishRoomEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoomEvent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.access_management.RoomEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.access_management.PublishRoomEventRequest.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.PublishRoomEventRequest} returns this
 */
proto.access_management.PublishRoomEventRequest.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RoomEvent room_event = 2;
 * @return {?proto.access_management.RoomEvent}
 */
proto.access_management.PublishRoomEventRequest.prototype.getRoomEvent = function() {
  return /** @type{?proto.access_management.RoomEvent} */ (
    jspb.Message.getWrapperField(this, proto.access_management.RoomEvent, 2));
};


/**
 * @param {?proto.access_management.RoomEvent|undefined} value
 * @return {!proto.access_management.PublishRoomEventRequest} returns this
*/
proto.access_management.PublishRoomEventRequest.prototype.setRoomEvent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.PublishRoomEventRequest} returns this
 */
proto.access_management.PublishRoomEventRequest.prototype.clearRoomEvent = function() {
  return this.setRoomEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.PublishRoomEventRequest.prototype.hasRoomEvent = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.PublishRoomEventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.PublishRoomEventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.PublishRoomEventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.PublishRoomEventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.PublishRoomEventResponse}
 */
proto.access_management.PublishRoomEventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.PublishRoomEventResponse;
  return proto.access_management.PublishRoomEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.PublishRoomEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.PublishRoomEventResponse}
 */
proto.access_management.PublishRoomEventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.PublishRoomEventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.PublishRoomEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.PublishRoomEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.PublishRoomEventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.InternalDisconnectionCleanupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.InternalDisconnectionCleanupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.InternalDisconnectionCleanupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InternalDisconnectionCleanupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.InternalDisconnectionCleanupRequest}
 */
proto.access_management.InternalDisconnectionCleanupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.InternalDisconnectionCleanupRequest;
  return proto.access_management.InternalDisconnectionCleanupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.InternalDisconnectionCleanupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.InternalDisconnectionCleanupRequest}
 */
proto.access_management.InternalDisconnectionCleanupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.InternalDisconnectionCleanupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.InternalDisconnectionCleanupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.InternalDisconnectionCleanupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InternalDisconnectionCleanupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.access_management.InternalDisconnectionCleanupRequest.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.InternalDisconnectionCleanupRequest} returns this
 */
proto.access_management.InternalDisconnectionCleanupRequest.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.access_management.InternalDisconnectionCleanupRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.InternalDisconnectionCleanupRequest} returns this
 */
proto.access_management.InternalDisconnectionCleanupRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.InternalDisconnectionCleanupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.InternalDisconnectionCleanupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.InternalDisconnectionCleanupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InternalDisconnectionCleanupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.InternalDisconnectionCleanupResponse}
 */
proto.access_management.InternalDisconnectionCleanupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.InternalDisconnectionCleanupResponse;
  return proto.access_management.InternalDisconnectionCleanupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.InternalDisconnectionCleanupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.InternalDisconnectionCleanupResponse}
 */
proto.access_management.InternalDisconnectionCleanupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.InternalDisconnectionCleanupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.InternalDisconnectionCleanupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.InternalDisconnectionCleanupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InternalDisconnectionCleanupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.PublishConnectedUserEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.PublishConnectedUserEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.PublishConnectedUserEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.PublishConnectedUserEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    connectedUserEvent: (f = msg.getConnectedUserEvent()) && proto.access_management.ConnectedUserEvent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.PublishConnectedUserEventRequest}
 */
proto.access_management.PublishConnectedUserEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.PublishConnectedUserEventRequest;
  return proto.access_management.PublishConnectedUserEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.PublishConnectedUserEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.PublishConnectedUserEventRequest}
 */
proto.access_management.PublishConnectedUserEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.access_management.ConnectedUserEvent;
      reader.readMessage(value,proto.access_management.ConnectedUserEvent.deserializeBinaryFromReader);
      msg.setConnectedUserEvent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.PublishConnectedUserEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.PublishConnectedUserEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.PublishConnectedUserEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.PublishConnectedUserEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConnectedUserEvent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.access_management.ConnectedUserEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional ConnectedUserEvent connected_user_event = 1;
 * @return {?proto.access_management.ConnectedUserEvent}
 */
proto.access_management.PublishConnectedUserEventRequest.prototype.getConnectedUserEvent = function() {
  return /** @type{?proto.access_management.ConnectedUserEvent} */ (
    jspb.Message.getWrapperField(this, proto.access_management.ConnectedUserEvent, 1));
};


/**
 * @param {?proto.access_management.ConnectedUserEvent|undefined} value
 * @return {!proto.access_management.PublishConnectedUserEventRequest} returns this
*/
proto.access_management.PublishConnectedUserEventRequest.prototype.setConnectedUserEvent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.PublishConnectedUserEventRequest} returns this
 */
proto.access_management.PublishConnectedUserEventRequest.prototype.clearConnectedUserEvent = function() {
  return this.setConnectedUserEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.PublishConnectedUserEventRequest.prototype.hasConnectedUserEvent = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.PublishConnectedUserEventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.PublishConnectedUserEventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.PublishConnectedUserEventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.PublishConnectedUserEventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.PublishConnectedUserEventResponse}
 */
proto.access_management.PublishConnectedUserEventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.PublishConnectedUserEventResponse;
  return proto.access_management.PublishConnectedUserEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.PublishConnectedUserEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.PublishConnectedUserEventResponse}
 */
proto.access_management.PublishConnectedUserEventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.PublishConnectedUserEventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.PublishConnectedUserEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.PublishConnectedUserEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.PublishConnectedUserEventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.CommunityRoomStatusUpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.CommunityRoomStatusUpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.CommunityRoomStatusUpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CommunityRoomStatusUpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roomStatus: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.CommunityRoomStatusUpdateRequest}
 */
proto.access_management.CommunityRoomStatusUpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.CommunityRoomStatusUpdateRequest;
  return proto.access_management.CommunityRoomStatusUpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.CommunityRoomStatusUpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.CommunityRoomStatusUpdateRequest}
 */
proto.access_management.CommunityRoomStatusUpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 2:
      var value = /** @type {!proto.access_management.RoomStatus} */ (reader.readEnum());
      msg.setRoomStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.CommunityRoomStatusUpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.CommunityRoomStatusUpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.CommunityRoomStatusUpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CommunityRoomStatusUpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoomStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.access_management.CommunityRoomStatusUpdateRequest.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CommunityRoomStatusUpdateRequest} returns this
 */
proto.access_management.CommunityRoomStatusUpdateRequest.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RoomStatus room_status = 2;
 * @return {!proto.access_management.RoomStatus}
 */
proto.access_management.CommunityRoomStatusUpdateRequest.prototype.getRoomStatus = function() {
  return /** @type {!proto.access_management.RoomStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.access_management.RoomStatus} value
 * @return {!proto.access_management.CommunityRoomStatusUpdateRequest} returns this
 */
proto.access_management.CommunityRoomStatusUpdateRequest.prototype.setRoomStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.CommunityRoomStatusUpdateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.CommunityRoomStatusUpdateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.CommunityRoomStatusUpdateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CommunityRoomStatusUpdateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.CommunityRoomStatusUpdateResponse}
 */
proto.access_management.CommunityRoomStatusUpdateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.CommunityRoomStatusUpdateResponse;
  return proto.access_management.CommunityRoomStatusUpdateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.CommunityRoomStatusUpdateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.CommunityRoomStatusUpdateResponse}
 */
proto.access_management.CommunityRoomStatusUpdateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.CommunityRoomStatusUpdateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.CommunityRoomStatusUpdateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.CommunityRoomStatusUpdateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CommunityRoomStatusUpdateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.Identifier.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.Identifier.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.Identifier} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.Identifier.toObject = function(includeInstance, msg) {
  var f, obj = {
    identifierMapMap: (f = msg.getIdentifierMapMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.Identifier}
 */
proto.access_management.Identifier.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.Identifier;
  return proto.access_management.Identifier.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.Identifier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.Identifier}
 */
proto.access_management.Identifier.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getIdentifierMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.Identifier.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.Identifier.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.Identifier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.Identifier.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentifierMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * map<string, string> identifier_map = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.access_management.Identifier.prototype.getIdentifierMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.access_management.Identifier} returns this
 */
proto.access_management.Identifier.prototype.clearIdentifierMapMap = function() {
  this.getIdentifierMapMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.WhitelistEntryCreateRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.WhitelistEntryCreateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.WhitelistEntryCreateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.WhitelistEntryCreateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.WhitelistEntryCreateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    identifiersList: jspb.Message.toObjectList(msg.getIdentifiersList(),
    proto.access_management.Identifier.toObject, includeInstance),
    dataMap: (f = msg.getDataMap()) ? f.toObject(includeInstance, proto.access_management.WhitelistDataValue.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.WhitelistEntryCreateRequest}
 */
proto.access_management.WhitelistEntryCreateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.WhitelistEntryCreateRequest;
  return proto.access_management.WhitelistEntryCreateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.WhitelistEntryCreateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.WhitelistEntryCreateRequest}
 */
proto.access_management.WhitelistEntryCreateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 3:
      var value = new proto.access_management.Identifier;
      reader.readMessage(value,proto.access_management.Identifier.deserializeBinaryFromReader);
      msg.addIdentifiers(value);
      break;
    case 4:
      var value = msg.getDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.access_management.WhitelistDataValue.deserializeBinaryFromReader, "", new proto.access_management.WhitelistDataValue());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.WhitelistEntryCreateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.WhitelistEntryCreateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.WhitelistEntryCreateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.WhitelistEntryCreateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIdentifiersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.access_management.Identifier.serializeBinaryToWriter
    );
  }
  f = message.getDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.access_management.WhitelistDataValue.serializeBinaryToWriter);
  }
};


/**
 * optional string entity_type = 1;
 * @return {string}
 */
proto.access_management.WhitelistEntryCreateRequest.prototype.getEntityType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.WhitelistEntryCreateRequest} returns this
 */
proto.access_management.WhitelistEntryCreateRequest.prototype.setEntityType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string entity_id = 2;
 * @return {string}
 */
proto.access_management.WhitelistEntryCreateRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.WhitelistEntryCreateRequest} returns this
 */
proto.access_management.WhitelistEntryCreateRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Identifier identifiers = 3;
 * @return {!Array<!proto.access_management.Identifier>}
 */
proto.access_management.WhitelistEntryCreateRequest.prototype.getIdentifiersList = function() {
  return /** @type{!Array<!proto.access_management.Identifier>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.access_management.Identifier, 3));
};


/**
 * @param {!Array<!proto.access_management.Identifier>} value
 * @return {!proto.access_management.WhitelistEntryCreateRequest} returns this
*/
proto.access_management.WhitelistEntryCreateRequest.prototype.setIdentifiersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.access_management.Identifier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.access_management.Identifier}
 */
proto.access_management.WhitelistEntryCreateRequest.prototype.addIdentifiers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.access_management.Identifier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.WhitelistEntryCreateRequest} returns this
 */
proto.access_management.WhitelistEntryCreateRequest.prototype.clearIdentifiersList = function() {
  return this.setIdentifiersList([]);
};


/**
 * map<string, WhitelistDataValue> data = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.access_management.WhitelistDataValue>}
 */
proto.access_management.WhitelistEntryCreateRequest.prototype.getDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.access_management.WhitelistDataValue>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.access_management.WhitelistDataValue));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.access_management.WhitelistEntryCreateRequest} returns this
 */
proto.access_management.WhitelistEntryCreateRequest.prototype.clearDataMap = function() {
  this.getDataMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.WhitelistEntryCreateResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.WhitelistEntryCreateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.WhitelistEntryCreateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.WhitelistEntryCreateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.WhitelistEntryCreateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    identifiersList: jspb.Message.toObjectList(msg.getIdentifiersList(),
    proto.access_management.Identifier.toObject, includeInstance),
    dataMap: (f = msg.getDataMap()) ? f.toObject(includeInstance, proto.access_management.WhitelistDataValue.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.WhitelistEntryCreateResponse}
 */
proto.access_management.WhitelistEntryCreateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.WhitelistEntryCreateResponse;
  return proto.access_management.WhitelistEntryCreateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.WhitelistEntryCreateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.WhitelistEntryCreateResponse}
 */
proto.access_management.WhitelistEntryCreateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 4:
      var value = new proto.access_management.Identifier;
      reader.readMessage(value,proto.access_management.Identifier.deserializeBinaryFromReader);
      msg.addIdentifiers(value);
      break;
    case 5:
      var value = msg.getDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.access_management.WhitelistDataValue.deserializeBinaryFromReader, "", new proto.access_management.WhitelistDataValue());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.WhitelistEntryCreateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.WhitelistEntryCreateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.WhitelistEntryCreateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.WhitelistEntryCreateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIdentifiersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.access_management.Identifier.serializeBinaryToWriter
    );
  }
  f = message.getDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.access_management.WhitelistDataValue.serializeBinaryToWriter);
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.WhitelistEntryCreateResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.WhitelistEntryCreateResponse} returns this
 */
proto.access_management.WhitelistEntryCreateResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string entity_type = 2;
 * @return {string}
 */
proto.access_management.WhitelistEntryCreateResponse.prototype.getEntityType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.WhitelistEntryCreateResponse} returns this
 */
proto.access_management.WhitelistEntryCreateResponse.prototype.setEntityType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string entity_id = 3;
 * @return {string}
 */
proto.access_management.WhitelistEntryCreateResponse.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.WhitelistEntryCreateResponse} returns this
 */
proto.access_management.WhitelistEntryCreateResponse.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Identifier identifiers = 4;
 * @return {!Array<!proto.access_management.Identifier>}
 */
proto.access_management.WhitelistEntryCreateResponse.prototype.getIdentifiersList = function() {
  return /** @type{!Array<!proto.access_management.Identifier>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.access_management.Identifier, 4));
};


/**
 * @param {!Array<!proto.access_management.Identifier>} value
 * @return {!proto.access_management.WhitelistEntryCreateResponse} returns this
*/
proto.access_management.WhitelistEntryCreateResponse.prototype.setIdentifiersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.access_management.Identifier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.access_management.Identifier}
 */
proto.access_management.WhitelistEntryCreateResponse.prototype.addIdentifiers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.access_management.Identifier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.WhitelistEntryCreateResponse} returns this
 */
proto.access_management.WhitelistEntryCreateResponse.prototype.clearIdentifiersList = function() {
  return this.setIdentifiersList([]);
};


/**
 * map<string, WhitelistDataValue> data = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.access_management.WhitelistDataValue>}
 */
proto.access_management.WhitelistEntryCreateResponse.prototype.getDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.access_management.WhitelistDataValue>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      proto.access_management.WhitelistDataValue));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.access_management.WhitelistEntryCreateResponse} returns this
 */
proto.access_management.WhitelistEntryCreateResponse.prototype.clearDataMap = function() {
  this.getDataMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.WhitelistEntryGetRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.WhitelistEntryGetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.WhitelistEntryGetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.WhitelistEntryGetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.WhitelistEntryGetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    identifiersList: jspb.Message.toObjectList(msg.getIdentifiersList(),
    proto.access_management.Identifier.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.WhitelistEntryGetRequest}
 */
proto.access_management.WhitelistEntryGetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.WhitelistEntryGetRequest;
  return proto.access_management.WhitelistEntryGetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.WhitelistEntryGetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.WhitelistEntryGetRequest}
 */
proto.access_management.WhitelistEntryGetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 3:
      var value = new proto.access_management.Identifier;
      reader.readMessage(value,proto.access_management.Identifier.deserializeBinaryFromReader);
      msg.addIdentifiers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.WhitelistEntryGetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.WhitelistEntryGetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.WhitelistEntryGetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.WhitelistEntryGetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIdentifiersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.access_management.Identifier.serializeBinaryToWriter
    );
  }
};


/**
 * optional string entity_type = 1;
 * @return {string}
 */
proto.access_management.WhitelistEntryGetRequest.prototype.getEntityType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.WhitelistEntryGetRequest} returns this
 */
proto.access_management.WhitelistEntryGetRequest.prototype.setEntityType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string entity_id = 2;
 * @return {string}
 */
proto.access_management.WhitelistEntryGetRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.WhitelistEntryGetRequest} returns this
 */
proto.access_management.WhitelistEntryGetRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Identifier identifiers = 3;
 * @return {!Array<!proto.access_management.Identifier>}
 */
proto.access_management.WhitelistEntryGetRequest.prototype.getIdentifiersList = function() {
  return /** @type{!Array<!proto.access_management.Identifier>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.access_management.Identifier, 3));
};


/**
 * @param {!Array<!proto.access_management.Identifier>} value
 * @return {!proto.access_management.WhitelistEntryGetRequest} returns this
*/
proto.access_management.WhitelistEntryGetRequest.prototype.setIdentifiersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.access_management.Identifier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.access_management.Identifier}
 */
proto.access_management.WhitelistEntryGetRequest.prototype.addIdentifiers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.access_management.Identifier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.WhitelistEntryGetRequest} returns this
 */
proto.access_management.WhitelistEntryGetRequest.prototype.clearIdentifiersList = function() {
  return this.setIdentifiersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.WhitelistEntryGetResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.WhitelistEntryGetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.WhitelistEntryGetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.WhitelistEntryGetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.WhitelistEntryGetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    identifiersList: jspb.Message.toObjectList(msg.getIdentifiersList(),
    proto.access_management.Identifier.toObject, includeInstance),
    dataMap: (f = msg.getDataMap()) ? f.toObject(includeInstance, proto.access_management.WhitelistDataValue.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.WhitelistEntryGetResponse}
 */
proto.access_management.WhitelistEntryGetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.WhitelistEntryGetResponse;
  return proto.access_management.WhitelistEntryGetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.WhitelistEntryGetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.WhitelistEntryGetResponse}
 */
proto.access_management.WhitelistEntryGetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 4:
      var value = new proto.access_management.Identifier;
      reader.readMessage(value,proto.access_management.Identifier.deserializeBinaryFromReader);
      msg.addIdentifiers(value);
      break;
    case 5:
      var value = msg.getDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.access_management.WhitelistDataValue.deserializeBinaryFromReader, "", new proto.access_management.WhitelistDataValue());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.WhitelistEntryGetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.WhitelistEntryGetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.WhitelistEntryGetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.WhitelistEntryGetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIdentifiersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.access_management.Identifier.serializeBinaryToWriter
    );
  }
  f = message.getDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.access_management.WhitelistDataValue.serializeBinaryToWriter);
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.WhitelistEntryGetResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.WhitelistEntryGetResponse} returns this
 */
proto.access_management.WhitelistEntryGetResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string entity_type = 2;
 * @return {string}
 */
proto.access_management.WhitelistEntryGetResponse.prototype.getEntityType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.WhitelistEntryGetResponse} returns this
 */
proto.access_management.WhitelistEntryGetResponse.prototype.setEntityType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string entity_id = 3;
 * @return {string}
 */
proto.access_management.WhitelistEntryGetResponse.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.WhitelistEntryGetResponse} returns this
 */
proto.access_management.WhitelistEntryGetResponse.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Identifier identifiers = 4;
 * @return {!Array<!proto.access_management.Identifier>}
 */
proto.access_management.WhitelistEntryGetResponse.prototype.getIdentifiersList = function() {
  return /** @type{!Array<!proto.access_management.Identifier>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.access_management.Identifier, 4));
};


/**
 * @param {!Array<!proto.access_management.Identifier>} value
 * @return {!proto.access_management.WhitelistEntryGetResponse} returns this
*/
proto.access_management.WhitelistEntryGetResponse.prototype.setIdentifiersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.access_management.Identifier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.access_management.Identifier}
 */
proto.access_management.WhitelistEntryGetResponse.prototype.addIdentifiers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.access_management.Identifier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.WhitelistEntryGetResponse} returns this
 */
proto.access_management.WhitelistEntryGetResponse.prototype.clearIdentifiersList = function() {
  return this.setIdentifiersList([]);
};


/**
 * map<string, WhitelistDataValue> data = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.access_management.WhitelistDataValue>}
 */
proto.access_management.WhitelistEntryGetResponse.prototype.getDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.access_management.WhitelistDataValue>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      proto.access_management.WhitelistDataValue));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.access_management.WhitelistEntryGetResponse} returns this
 */
proto.access_management.WhitelistEntryGetResponse.prototype.clearDataMap = function() {
  this.getDataMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.InternalDoneDownloadingGameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.InternalDoneDownloadingGameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.InternalDoneDownloadingGameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InternalDoneDownloadingGameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    game: jspb.Message.getFieldWithDefault(msg, 3, ""),
    version: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.InternalDoneDownloadingGameRequest}
 */
proto.access_management.InternalDoneDownloadingGameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.InternalDoneDownloadingGameRequest;
  return proto.access_management.InternalDoneDownloadingGameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.InternalDoneDownloadingGameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.InternalDoneDownloadingGameRequest}
 */
proto.access_management.InternalDoneDownloadingGameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGame(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.InternalDoneDownloadingGameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.InternalDoneDownloadingGameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.InternalDoneDownloadingGameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InternalDoneDownloadingGameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGame();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.access_management.InternalDoneDownloadingGameRequest.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.InternalDoneDownloadingGameRequest} returns this
 */
proto.access_management.InternalDoneDownloadingGameRequest.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.access_management.InternalDoneDownloadingGameRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.InternalDoneDownloadingGameRequest} returns this
 */
proto.access_management.InternalDoneDownloadingGameRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string game = 3;
 * @return {string}
 */
proto.access_management.InternalDoneDownloadingGameRequest.prototype.getGame = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.InternalDoneDownloadingGameRequest} returns this
 */
proto.access_management.InternalDoneDownloadingGameRequest.prototype.setGame = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string version = 4;
 * @return {string}
 */
proto.access_management.InternalDoneDownloadingGameRequest.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.InternalDoneDownloadingGameRequest} returns this
 */
proto.access_management.InternalDoneDownloadingGameRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.InternalDoneDownloadingGameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.InternalDoneDownloadingGameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.InternalDoneDownloadingGameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InternalDoneDownloadingGameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.InternalDoneDownloadingGameResponse}
 */
proto.access_management.InternalDoneDownloadingGameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.InternalDoneDownloadingGameResponse;
  return proto.access_management.InternalDoneDownloadingGameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.InternalDoneDownloadingGameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.InternalDoneDownloadingGameResponse}
 */
proto.access_management.InternalDoneDownloadingGameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.InternalDoneDownloadingGameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.InternalDoneDownloadingGameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.InternalDoneDownloadingGameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InternalDoneDownloadingGameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.InternalGetGameDownloadingCancelTimeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.InternalGetGameDownloadingCancelTimeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.InternalGetGameDownloadingCancelTimeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InternalGetGameDownloadingCancelTimeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    game: jspb.Message.getFieldWithDefault(msg, 3, ""),
    version: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.InternalGetGameDownloadingCancelTimeRequest}
 */
proto.access_management.InternalGetGameDownloadingCancelTimeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.InternalGetGameDownloadingCancelTimeRequest;
  return proto.access_management.InternalGetGameDownloadingCancelTimeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.InternalGetGameDownloadingCancelTimeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.InternalGetGameDownloadingCancelTimeRequest}
 */
proto.access_management.InternalGetGameDownloadingCancelTimeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGame(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.InternalGetGameDownloadingCancelTimeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.InternalGetGameDownloadingCancelTimeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.InternalGetGameDownloadingCancelTimeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InternalGetGameDownloadingCancelTimeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGame();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.access_management.InternalGetGameDownloadingCancelTimeRequest.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.InternalGetGameDownloadingCancelTimeRequest} returns this
 */
proto.access_management.InternalGetGameDownloadingCancelTimeRequest.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.access_management.InternalGetGameDownloadingCancelTimeRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.InternalGetGameDownloadingCancelTimeRequest} returns this
 */
proto.access_management.InternalGetGameDownloadingCancelTimeRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string game = 3;
 * @return {string}
 */
proto.access_management.InternalGetGameDownloadingCancelTimeRequest.prototype.getGame = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.InternalGetGameDownloadingCancelTimeRequest} returns this
 */
proto.access_management.InternalGetGameDownloadingCancelTimeRequest.prototype.setGame = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string version = 4;
 * @return {string}
 */
proto.access_management.InternalGetGameDownloadingCancelTimeRequest.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.InternalGetGameDownloadingCancelTimeRequest} returns this
 */
proto.access_management.InternalGetGameDownloadingCancelTimeRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.InternalGetGameDownloadingCancelTimeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.InternalGetGameDownloadingCancelTimeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.InternalGetGameDownloadingCancelTimeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InternalGetGameDownloadingCancelTimeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.InternalGetGameDownloadingCancelTimeResponse}
 */
proto.access_management.InternalGetGameDownloadingCancelTimeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.InternalGetGameDownloadingCancelTimeResponse;
  return proto.access_management.InternalGetGameDownloadingCancelTimeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.InternalGetGameDownloadingCancelTimeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.InternalGetGameDownloadingCancelTimeResponse}
 */
proto.access_management.InternalGetGameDownloadingCancelTimeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.InternalGetGameDownloadingCancelTimeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.InternalGetGameDownloadingCancelTimeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.InternalGetGameDownloadingCancelTimeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.InternalGetGameDownloadingCancelTimeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 Timestamp = 1;
 * @return {number}
 */
proto.access_management.InternalGetGameDownloadingCancelTimeResponse.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.InternalGetGameDownloadingCancelTimeResponse} returns this
 */
proto.access_management.InternalGetGameDownloadingCancelTimeResponse.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.GetCommunitiesRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetCommunitiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetCommunitiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetCommunitiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunitiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fieldsToIncludeList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetCommunitiesRequest}
 */
proto.access_management.GetCommunitiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetCommunitiesRequest;
  return proto.access_management.GetCommunitiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetCommunitiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetCommunitiesRequest}
 */
proto.access_management.GetCommunitiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addFieldsToInclude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetCommunitiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetCommunitiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetCommunitiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunitiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getFieldsToIncludeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string last_id = 1;
 * @return {string}
 */
proto.access_management.GetCommunitiesRequest.prototype.getLastId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunitiesRequest} returns this
 */
proto.access_management.GetCommunitiesRequest.prototype.setLastId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 count = 2;
 * @return {number}
 */
proto.access_management.GetCommunitiesRequest.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.GetCommunitiesRequest} returns this
 */
proto.access_management.GetCommunitiesRequest.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated string fields_to_include = 3;
 * @return {!Array<string>}
 */
proto.access_management.GetCommunitiesRequest.prototype.getFieldsToIncludeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.access_management.GetCommunitiesRequest} returns this
 */
proto.access_management.GetCommunitiesRequest.prototype.setFieldsToIncludeList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.access_management.GetCommunitiesRequest} returns this
 */
proto.access_management.GetCommunitiesRequest.prototype.addFieldsToInclude = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.GetCommunitiesRequest} returns this
 */
proto.access_management.GetCommunitiesRequest.prototype.clearFieldsToIncludeList = function() {
  return this.setFieldsToIncludeList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.GetCommunitiesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetCommunitiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetCommunitiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetCommunitiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunitiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    communitiesList: jspb.Message.toObjectList(msg.getCommunitiesList(),
    proto.access_management.Community.toObject, includeInstance),
    nextId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetCommunitiesResponse}
 */
proto.access_management.GetCommunitiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetCommunitiesResponse;
  return proto.access_management.GetCommunitiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetCommunitiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetCommunitiesResponse}
 */
proto.access_management.GetCommunitiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.access_management.Community;
      reader.readMessage(value,proto.access_management.Community.deserializeBinaryFromReader);
      msg.addCommunities(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetCommunitiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetCommunitiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetCommunitiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunitiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommunitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.access_management.Community.serializeBinaryToWriter
    );
  }
  f = message.getNextId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated Community communities = 1;
 * @return {!Array<!proto.access_management.Community>}
 */
proto.access_management.GetCommunitiesResponse.prototype.getCommunitiesList = function() {
  return /** @type{!Array<!proto.access_management.Community>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.access_management.Community, 1));
};


/**
 * @param {!Array<!proto.access_management.Community>} value
 * @return {!proto.access_management.GetCommunitiesResponse} returns this
*/
proto.access_management.GetCommunitiesResponse.prototype.setCommunitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.access_management.Community=} opt_value
 * @param {number=} opt_index
 * @return {!proto.access_management.Community}
 */
proto.access_management.GetCommunitiesResponse.prototype.addCommunities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.access_management.Community, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.GetCommunitiesResponse} returns this
 */
proto.access_management.GetCommunitiesResponse.prototype.clearCommunitiesList = function() {
  return this.setCommunitiesList([]);
};


/**
 * optional string next_id = 2;
 * @return {string}
 */
proto.access_management.GetCommunitiesResponse.prototype.getNextId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunitiesResponse} returns this
 */
proto.access_management.GetCommunitiesResponse.prototype.setNextId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.StartSyncCommunityAdminsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.StartSyncCommunityAdminsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.StartSyncCommunityAdminsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.StartSyncCommunityAdminsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.StartSyncCommunityAdminsRequest}
 */
proto.access_management.StartSyncCommunityAdminsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.StartSyncCommunityAdminsRequest;
  return proto.access_management.StartSyncCommunityAdminsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.StartSyncCommunityAdminsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.StartSyncCommunityAdminsRequest}
 */
proto.access_management.StartSyncCommunityAdminsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.StartSyncCommunityAdminsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.StartSyncCommunityAdminsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.StartSyncCommunityAdminsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.StartSyncCommunityAdminsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.StartSyncCommunityAdminsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.StartSyncCommunityAdminsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.StartSyncCommunityAdminsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.StartSyncCommunityAdminsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.StartSyncCommunityAdminsResponse}
 */
proto.access_management.StartSyncCommunityAdminsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.StartSyncCommunityAdminsResponse;
  return proto.access_management.StartSyncCommunityAdminsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.StartSyncCommunityAdminsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.StartSyncCommunityAdminsResponse}
 */
proto.access_management.StartSyncCommunityAdminsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.StartSyncCommunityAdminsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.StartSyncCommunityAdminsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.StartSyncCommunityAdminsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.StartSyncCommunityAdminsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.StartCreateGamenightTimestampRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.StartCreateGamenightTimestampRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.StartCreateGamenightTimestampRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.StartCreateGamenightTimestampRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.StartCreateGamenightTimestampRequest}
 */
proto.access_management.StartCreateGamenightTimestampRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.StartCreateGamenightTimestampRequest;
  return proto.access_management.StartCreateGamenightTimestampRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.StartCreateGamenightTimestampRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.StartCreateGamenightTimestampRequest}
 */
proto.access_management.StartCreateGamenightTimestampRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.StartCreateGamenightTimestampRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.StartCreateGamenightTimestampRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.StartCreateGamenightTimestampRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.StartCreateGamenightTimestampRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.StartCreateGamenightTimestampResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.StartCreateGamenightTimestampResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.StartCreateGamenightTimestampResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.StartCreateGamenightTimestampResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.StartCreateGamenightTimestampResponse}
 */
proto.access_management.StartCreateGamenightTimestampResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.StartCreateGamenightTimestampResponse;
  return proto.access_management.StartCreateGamenightTimestampResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.StartCreateGamenightTimestampResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.StartCreateGamenightTimestampResponse}
 */
proto.access_management.StartCreateGamenightTimestampResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.StartCreateGamenightTimestampResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.StartCreateGamenightTimestampResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.StartCreateGamenightTimestampResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.StartCreateGamenightTimestampResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.SyncCommunityAdminsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.SyncCommunityAdminsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.SyncCommunityAdminsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.SyncCommunityAdminsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    communityId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.SyncCommunityAdminsRequest}
 */
proto.access_management.SyncCommunityAdminsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.SyncCommunityAdminsRequest;
  return proto.access_management.SyncCommunityAdminsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.SyncCommunityAdminsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.SyncCommunityAdminsRequest}
 */
proto.access_management.SyncCommunityAdminsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommunityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.SyncCommunityAdminsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.SyncCommunityAdminsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.SyncCommunityAdminsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.SyncCommunityAdminsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommunityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string community_id = 1;
 * @return {string}
 */
proto.access_management.SyncCommunityAdminsRequest.prototype.getCommunityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.SyncCommunityAdminsRequest} returns this
 */
proto.access_management.SyncCommunityAdminsRequest.prototype.setCommunityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.SyncCommunityAdminsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.SyncCommunityAdminsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.SyncCommunityAdminsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.SyncCommunityAdminsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.SyncCommunityAdminsResponse}
 */
proto.access_management.SyncCommunityAdminsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.SyncCommunityAdminsResponse;
  return proto.access_management.SyncCommunityAdminsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.SyncCommunityAdminsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.SyncCommunityAdminsResponse}
 */
proto.access_management.SyncCommunityAdminsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.SyncCommunityAdminsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.SyncCommunityAdminsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.SyncCommunityAdminsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.SyncCommunityAdminsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 count = 1;
 * @return {number}
 */
proto.access_management.SyncCommunityAdminsResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.SyncCommunityAdminsResponse} returns this
 */
proto.access_management.SyncCommunityAdminsResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.CreateGamenightTimestampRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.CreateGamenightTimestampRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.CreateGamenightTimestampRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CreateGamenightTimestampRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    communityId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.CreateGamenightTimestampRequest}
 */
proto.access_management.CreateGamenightTimestampRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.CreateGamenightTimestampRequest;
  return proto.access_management.CreateGamenightTimestampRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.CreateGamenightTimestampRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.CreateGamenightTimestampRequest}
 */
proto.access_management.CreateGamenightTimestampRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommunityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.CreateGamenightTimestampRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.CreateGamenightTimestampRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.CreateGamenightTimestampRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CreateGamenightTimestampRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommunityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string community_id = 1;
 * @return {string}
 */
proto.access_management.CreateGamenightTimestampRequest.prototype.getCommunityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CreateGamenightTimestampRequest} returns this
 */
proto.access_management.CreateGamenightTimestampRequest.prototype.setCommunityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.CreateGamenightTimestampResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.CreateGamenightTimestampResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.CreateGamenightTimestampResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CreateGamenightTimestampResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.CreateGamenightTimestampResponse}
 */
proto.access_management.CreateGamenightTimestampResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.CreateGamenightTimestampResponse;
  return proto.access_management.CreateGamenightTimestampResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.CreateGamenightTimestampResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.CreateGamenightTimestampResponse}
 */
proto.access_management.CreateGamenightTimestampResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.CreateGamenightTimestampResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.CreateGamenightTimestampResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.CreateGamenightTimestampResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CreateGamenightTimestampResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetBillingInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetBillingInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetBillingInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetBillingInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetBillingInfoRequest}
 */
proto.access_management.GetBillingInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetBillingInfoRequest;
  return proto.access_management.GetBillingInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetBillingInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetBillingInfoRequest}
 */
proto.access_management.GetBillingInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.access_management.CustomerType} */ (reader.readEnum());
      msg.setCustomerType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetBillingInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetBillingInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetBillingInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetBillingInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.access_management.GetBillingInfoRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetBillingInfoRequest} returns this
 */
proto.access_management.GetBillingInfoRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CustomerType customer_type = 2;
 * @return {!proto.access_management.CustomerType}
 */
proto.access_management.GetBillingInfoRequest.prototype.getCustomerType = function() {
  return /** @type {!proto.access_management.CustomerType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.access_management.CustomerType} value
 * @return {!proto.access_management.GetBillingInfoRequest} returns this
 */
proto.access_management.GetBillingInfoRequest.prototype.setCustomerType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.BillingInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.BillingInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.BillingInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.BillingInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentlySelectedPlan: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mau: jspb.Message.getFieldWithDefault(msg, 2, 0),
    onTrial: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    paymentTimestamp: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pricePerUser: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    billingCycleStart: jspb.Message.getFieldWithDefault(msg, 6, 0),
    billingCycleEnd: jspb.Message.getFieldWithDefault(msg, 7, 0),
    ccLastFour: jspb.Message.getFieldWithDefault(msg, 8, ""),
    cardType: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.BillingInfo}
 */
proto.access_management.BillingInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.BillingInfo;
  return proto.access_management.BillingInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.BillingInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.BillingInfo}
 */
proto.access_management.BillingInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentlySelectedPlan(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMau(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnTrial(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPaymentTimestamp(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPricePerUser(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBillingCycleStart(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBillingCycleEnd(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCcLastFour(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.BillingInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.BillingInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.BillingInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.BillingInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentlySelectedPlan();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMau();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOnTrial();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getPaymentTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPricePerUser();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getBillingCycleStart();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getBillingCycleEnd();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCcLastFour();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCardType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string currently_selected_plan = 1;
 * @return {string}
 */
proto.access_management.BillingInfo.prototype.getCurrentlySelectedPlan = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.BillingInfo} returns this
 */
proto.access_management.BillingInfo.prototype.setCurrentlySelectedPlan = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 mau = 2;
 * @return {number}
 */
proto.access_management.BillingInfo.prototype.getMau = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.BillingInfo} returns this
 */
proto.access_management.BillingInfo.prototype.setMau = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool on_trial = 3;
 * @return {boolean}
 */
proto.access_management.BillingInfo.prototype.getOnTrial = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.access_management.BillingInfo} returns this
 */
proto.access_management.BillingInfo.prototype.setOnTrial = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int64 payment_timestamp = 4;
 * @return {number}
 */
proto.access_management.BillingInfo.prototype.getPaymentTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.BillingInfo} returns this
 */
proto.access_management.BillingInfo.prototype.setPaymentTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double price_per_user = 5;
 * @return {number}
 */
proto.access_management.BillingInfo.prototype.getPricePerUser = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.BillingInfo} returns this
 */
proto.access_management.BillingInfo.prototype.setPricePerUser = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int64 billing_cycle_start = 6;
 * @return {number}
 */
proto.access_management.BillingInfo.prototype.getBillingCycleStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.BillingInfo} returns this
 */
proto.access_management.BillingInfo.prototype.setBillingCycleStart = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 billing_cycle_end = 7;
 * @return {number}
 */
proto.access_management.BillingInfo.prototype.getBillingCycleEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.access_management.BillingInfo} returns this
 */
proto.access_management.BillingInfo.prototype.setBillingCycleEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string cc_last_four = 8;
 * @return {string}
 */
proto.access_management.BillingInfo.prototype.getCcLastFour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.BillingInfo} returns this
 */
proto.access_management.BillingInfo.prototype.setCcLastFour = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string card_type = 9;
 * @return {string}
 */
proto.access_management.BillingInfo.prototype.getCardType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.BillingInfo} returns this
 */
proto.access_management.BillingInfo.prototype.setCardType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetBillingInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetBillingInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetBillingInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetBillingInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    billingInfo: (f = msg.getBillingInfo()) && proto.access_management.BillingInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetBillingInfoResponse}
 */
proto.access_management.GetBillingInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetBillingInfoResponse;
  return proto.access_management.GetBillingInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetBillingInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetBillingInfoResponse}
 */
proto.access_management.GetBillingInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {!proto.access_management.CustomerType} */ (reader.readEnum());
      msg.setCustomerType(value);
      break;
    case 3:
      var value = new proto.access_management.BillingInfo;
      reader.readMessage(value,proto.access_management.BillingInfo.deserializeBinaryFromReader);
      msg.setBillingInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetBillingInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetBillingInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetBillingInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetBillingInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getBillingInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.access_management.BillingInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.access_management.GetBillingInfoResponse.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetBillingInfoResponse} returns this
 */
proto.access_management.GetBillingInfoResponse.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CustomerType customer_type = 2;
 * @return {!proto.access_management.CustomerType}
 */
proto.access_management.GetBillingInfoResponse.prototype.getCustomerType = function() {
  return /** @type {!proto.access_management.CustomerType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.access_management.CustomerType} value
 * @return {!proto.access_management.GetBillingInfoResponse} returns this
 */
proto.access_management.GetBillingInfoResponse.prototype.setCustomerType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional BillingInfo billing_info = 3;
 * @return {?proto.access_management.BillingInfo}
 */
proto.access_management.GetBillingInfoResponse.prototype.getBillingInfo = function() {
  return /** @type{?proto.access_management.BillingInfo} */ (
    jspb.Message.getWrapperField(this, proto.access_management.BillingInfo, 3));
};


/**
 * @param {?proto.access_management.BillingInfo|undefined} value
 * @return {!proto.access_management.GetBillingInfoResponse} returns this
*/
proto.access_management.GetBillingInfoResponse.prototype.setBillingInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.GetBillingInfoResponse} returns this
 */
proto.access_management.GetBillingInfoResponse.prototype.clearBillingInfo = function() {
  return this.setBillingInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.GetBillingInfoResponse.prototype.hasBillingInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.BillingPortalSession.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.BillingPortalSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.BillingPortalSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.BillingPortalSession.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.BillingPortalSession}
 */
proto.access_management.BillingPortalSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.BillingPortalSession;
  return proto.access_management.BillingPortalSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.BillingPortalSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.BillingPortalSession}
 */
proto.access_management.BillingPortalSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.BillingPortalSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.BillingPortalSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.BillingPortalSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.BillingPortalSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.BillingSubscriptionCheckoutSession.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.BillingSubscriptionCheckoutSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.BillingSubscriptionCheckoutSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.BillingSubscriptionCheckoutSession.toObject = function(includeInstance, msg) {
  var f, obj = {
    productId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.BillingSubscriptionCheckoutSession}
 */
proto.access_management.BillingSubscriptionCheckoutSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.BillingSubscriptionCheckoutSession;
  return proto.access_management.BillingSubscriptionCheckoutSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.BillingSubscriptionCheckoutSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.BillingSubscriptionCheckoutSession}
 */
proto.access_management.BillingSubscriptionCheckoutSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.BillingSubscriptionCheckoutSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.BillingSubscriptionCheckoutSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.BillingSubscriptionCheckoutSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.BillingSubscriptionCheckoutSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string product_id = 1;
 * @return {string}
 */
proto.access_management.BillingSubscriptionCheckoutSession.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.BillingSubscriptionCheckoutSession} returns this
 */
proto.access_management.BillingSubscriptionCheckoutSession.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.access_management.BillingSession.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.access_management.BillingSession.BillingSessionCase = {
  BILLING_SESSION_NOT_SET: 0,
  PORTAL: 1,
  SUBSCRIPTION_CHECKOUT: 2
};

/**
 * @return {proto.access_management.BillingSession.BillingSessionCase}
 */
proto.access_management.BillingSession.prototype.getBillingSessionCase = function() {
  return /** @type {proto.access_management.BillingSession.BillingSessionCase} */(jspb.Message.computeOneofCase(this, proto.access_management.BillingSession.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.BillingSession.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.BillingSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.BillingSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.BillingSession.toObject = function(includeInstance, msg) {
  var f, obj = {
    portal: (f = msg.getPortal()) && proto.access_management.BillingPortalSession.toObject(includeInstance, f),
    subscriptionCheckout: (f = msg.getSubscriptionCheckout()) && proto.access_management.BillingSubscriptionCheckoutSession.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.BillingSession}
 */
proto.access_management.BillingSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.BillingSession;
  return proto.access_management.BillingSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.BillingSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.BillingSession}
 */
proto.access_management.BillingSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.access_management.BillingPortalSession;
      reader.readMessage(value,proto.access_management.BillingPortalSession.deserializeBinaryFromReader);
      msg.setPortal(value);
      break;
    case 2:
      var value = new proto.access_management.BillingSubscriptionCheckoutSession;
      reader.readMessage(value,proto.access_management.BillingSubscriptionCheckoutSession.deserializeBinaryFromReader);
      msg.setSubscriptionCheckout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.BillingSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.BillingSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.BillingSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.BillingSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPortal();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.access_management.BillingPortalSession.serializeBinaryToWriter
    );
  }
  f = message.getSubscriptionCheckout();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.access_management.BillingSubscriptionCheckoutSession.serializeBinaryToWriter
    );
  }
};


/**
 * optional BillingPortalSession portal = 1;
 * @return {?proto.access_management.BillingPortalSession}
 */
proto.access_management.BillingSession.prototype.getPortal = function() {
  return /** @type{?proto.access_management.BillingPortalSession} */ (
    jspb.Message.getWrapperField(this, proto.access_management.BillingPortalSession, 1));
};


/**
 * @param {?proto.access_management.BillingPortalSession|undefined} value
 * @return {!proto.access_management.BillingSession} returns this
*/
proto.access_management.BillingSession.prototype.setPortal = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.access_management.BillingSession.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.BillingSession} returns this
 */
proto.access_management.BillingSession.prototype.clearPortal = function() {
  return this.setPortal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.BillingSession.prototype.hasPortal = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BillingSubscriptionCheckoutSession subscription_checkout = 2;
 * @return {?proto.access_management.BillingSubscriptionCheckoutSession}
 */
proto.access_management.BillingSession.prototype.getSubscriptionCheckout = function() {
  return /** @type{?proto.access_management.BillingSubscriptionCheckoutSession} */ (
    jspb.Message.getWrapperField(this, proto.access_management.BillingSubscriptionCheckoutSession, 2));
};


/**
 * @param {?proto.access_management.BillingSubscriptionCheckoutSession|undefined} value
 * @return {!proto.access_management.BillingSession} returns this
*/
proto.access_management.BillingSession.prototype.setSubscriptionCheckout = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.access_management.BillingSession.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.BillingSession} returns this
 */
proto.access_management.BillingSession.prototype.clearSubscriptionCheckout = function() {
  return this.setSubscriptionCheckout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.BillingSession.prototype.hasSubscriptionCheckout = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.RequestBillingSessionLinkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.RequestBillingSessionLinkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.RequestBillingSessionLinkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.RequestBillingSessionLinkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    session: (f = msg.getSession()) && proto.access_management.BillingSession.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.RequestBillingSessionLinkRequest}
 */
proto.access_management.RequestBillingSessionLinkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.RequestBillingSessionLinkRequest;
  return proto.access_management.RequestBillingSessionLinkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.RequestBillingSessionLinkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.RequestBillingSessionLinkRequest}
 */
proto.access_management.RequestBillingSessionLinkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = new proto.access_management.BillingSession;
      reader.readMessage(value,proto.access_management.BillingSession.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.RequestBillingSessionLinkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.RequestBillingSessionLinkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.RequestBillingSessionLinkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.RequestBillingSessionLinkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.access_management.BillingSession.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.access_management.RequestBillingSessionLinkRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.RequestBillingSessionLinkRequest} returns this
 */
proto.access_management.RequestBillingSessionLinkRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional BillingSession session = 2;
 * @return {?proto.access_management.BillingSession}
 */
proto.access_management.RequestBillingSessionLinkRequest.prototype.getSession = function() {
  return /** @type{?proto.access_management.BillingSession} */ (
    jspb.Message.getWrapperField(this, proto.access_management.BillingSession, 2));
};


/**
 * @param {?proto.access_management.BillingSession|undefined} value
 * @return {!proto.access_management.RequestBillingSessionLinkRequest} returns this
*/
proto.access_management.RequestBillingSessionLinkRequest.prototype.setSession = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.access_management.RequestBillingSessionLinkRequest} returns this
 */
proto.access_management.RequestBillingSessionLinkRequest.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.access_management.RequestBillingSessionLinkRequest.prototype.hasSession = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.RequestBillingSessionLinkResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.RequestBillingSessionLinkResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.RequestBillingSessionLinkResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.RequestBillingSessionLinkResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    redirectUrl: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.RequestBillingSessionLinkResponse}
 */
proto.access_management.RequestBillingSessionLinkResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.RequestBillingSessionLinkResponse;
  return proto.access_management.RequestBillingSessionLinkResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.RequestBillingSessionLinkResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.RequestBillingSessionLinkResponse}
 */
proto.access_management.RequestBillingSessionLinkResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirectUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.RequestBillingSessionLinkResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.RequestBillingSessionLinkResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.RequestBillingSessionLinkResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.RequestBillingSessionLinkResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRedirectUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string redirect_url = 1;
 * @return {string}
 */
proto.access_management.RequestBillingSessionLinkResponse.prototype.getRedirectUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.RequestBillingSessionLinkResponse} returns this
 */
proto.access_management.RequestBillingSessionLinkResponse.prototype.setRedirectUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.CheckCommunityMAUsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.CheckCommunityMAUsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.CheckCommunityMAUsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.CheckCommunityMAUsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CheckCommunityMAUsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    communityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.CheckCommunityMAUsRequest}
 */
proto.access_management.CheckCommunityMAUsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.CheckCommunityMAUsRequest;
  return proto.access_management.CheckCommunityMAUsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.CheckCommunityMAUsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.CheckCommunityMAUsRequest}
 */
proto.access_management.CheckCommunityMAUsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommunityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.CheckCommunityMAUsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.CheckCommunityMAUsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.CheckCommunityMAUsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CheckCommunityMAUsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommunityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string community_id = 1;
 * @return {string}
 */
proto.access_management.CheckCommunityMAUsRequest.prototype.getCommunityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.CheckCommunityMAUsRequest} returns this
 */
proto.access_management.CheckCommunityMAUsRequest.prototype.setCommunityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string user_ids = 2;
 * @return {!Array<string>}
 */
proto.access_management.CheckCommunityMAUsRequest.prototype.getUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.access_management.CheckCommunityMAUsRequest} returns this
 */
proto.access_management.CheckCommunityMAUsRequest.prototype.setUserIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.access_management.CheckCommunityMAUsRequest} returns this
 */
proto.access_management.CheckCommunityMAUsRequest.prototype.addUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.CheckCommunityMAUsRequest} returns this
 */
proto.access_management.CheckCommunityMAUsRequest.prototype.clearUserIdsList = function() {
  return this.setUserIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.MAUInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.MAUInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.MAUInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.MAUInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.MAUInfo}
 */
proto.access_management.MAUInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.MAUInfo;
  return proto.access_management.MAUInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.MAUInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.MAUInfo}
 */
proto.access_management.MAUInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.MAUInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.MAUInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.MAUInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.MAUInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.access_management.MAUInfo.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.MAUInfo} returns this
 */
proto.access_management.MAUInfo.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.CheckCommunityMAUsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.CheckCommunityMAUsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.CheckCommunityMAUsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.CheckCommunityMAUsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CheckCommunityMAUsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    mausList: jspb.Message.toObjectList(msg.getMausList(),
    proto.access_management.MAUInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.CheckCommunityMAUsResponse}
 */
proto.access_management.CheckCommunityMAUsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.CheckCommunityMAUsResponse;
  return proto.access_management.CheckCommunityMAUsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.CheckCommunityMAUsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.CheckCommunityMAUsResponse}
 */
proto.access_management.CheckCommunityMAUsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.access_management.MAUInfo;
      reader.readMessage(value,proto.access_management.MAUInfo.deserializeBinaryFromReader);
      msg.addMaus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.CheckCommunityMAUsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.CheckCommunityMAUsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.CheckCommunityMAUsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.CheckCommunityMAUsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMausList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.access_management.MAUInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MAUInfo maus = 1;
 * @return {!Array<!proto.access_management.MAUInfo>}
 */
proto.access_management.CheckCommunityMAUsResponse.prototype.getMausList = function() {
  return /** @type{!Array<!proto.access_management.MAUInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.access_management.MAUInfo, 1));
};


/**
 * @param {!Array<!proto.access_management.MAUInfo>} value
 * @return {!proto.access_management.CheckCommunityMAUsResponse} returns this
*/
proto.access_management.CheckCommunityMAUsResponse.prototype.setMausList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.access_management.MAUInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.access_management.MAUInfo}
 */
proto.access_management.CheckCommunityMAUsResponse.prototype.addMaus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.access_management.MAUInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.CheckCommunityMAUsResponse} returns this
 */
proto.access_management.CheckCommunityMAUsResponse.prototype.clearMausList = function() {
  return this.setMausList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetProSubscriptionPlanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetProSubscriptionPlanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetProSubscriptionPlanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetProSubscriptionPlanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetProSubscriptionPlanRequest}
 */
proto.access_management.GetProSubscriptionPlanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetProSubscriptionPlanRequest;
  return proto.access_management.GetProSubscriptionPlanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetProSubscriptionPlanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetProSubscriptionPlanRequest}
 */
proto.access_management.GetProSubscriptionPlanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetProSubscriptionPlanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetProSubscriptionPlanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetProSubscriptionPlanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetProSubscriptionPlanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetProSubscriptionPlanResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetProSubscriptionPlanResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetProSubscriptionPlanResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetProSubscriptionPlanResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    planId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetProSubscriptionPlanResponse}
 */
proto.access_management.GetProSubscriptionPlanResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetProSubscriptionPlanResponse;
  return proto.access_management.GetProSubscriptionPlanResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetProSubscriptionPlanResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetProSubscriptionPlanResponse}
 */
proto.access_management.GetProSubscriptionPlanResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetProSubscriptionPlanResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetProSubscriptionPlanResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetProSubscriptionPlanResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetProSubscriptionPlanResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string plan_id = 1;
 * @return {string}
 */
proto.access_management.GetProSubscriptionPlanResponse.prototype.getPlanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetProSubscriptionPlanResponse} returns this
 */
proto.access_management.GetProSubscriptionPlanResponse.prototype.setPlanId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.UpdateAvailablePlansRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.UpdateAvailablePlansRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.UpdateAvailablePlansRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.UpdateAvailablePlansRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UpdateAvailablePlansRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    planIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    include: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.UpdateAvailablePlansRequest}
 */
proto.access_management.UpdateAvailablePlansRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.UpdateAvailablePlansRequest;
  return proto.access_management.UpdateAvailablePlansRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.UpdateAvailablePlansRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.UpdateAvailablePlansRequest}
 */
proto.access_management.UpdateAvailablePlansRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addPlanIds(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInclude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.UpdateAvailablePlansRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.UpdateAvailablePlansRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.UpdateAvailablePlansRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UpdateAvailablePlansRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlanIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getInclude();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.access_management.UpdateAvailablePlansRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.UpdateAvailablePlansRequest} returns this
 */
proto.access_management.UpdateAvailablePlansRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string plan_ids = 2;
 * @return {!Array<string>}
 */
proto.access_management.UpdateAvailablePlansRequest.prototype.getPlanIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.access_management.UpdateAvailablePlansRequest} returns this
 */
proto.access_management.UpdateAvailablePlansRequest.prototype.setPlanIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.access_management.UpdateAvailablePlansRequest} returns this
 */
proto.access_management.UpdateAvailablePlansRequest.prototype.addPlanIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.UpdateAvailablePlansRequest} returns this
 */
proto.access_management.UpdateAvailablePlansRequest.prototype.clearPlanIdsList = function() {
  return this.setPlanIdsList([]);
};


/**
 * optional bool include = 3;
 * @return {boolean}
 */
proto.access_management.UpdateAvailablePlansRequest.prototype.getInclude = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.access_management.UpdateAvailablePlansRequest} returns this
 */
proto.access_management.UpdateAvailablePlansRequest.prototype.setInclude = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.UpdateAvailablePlansResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.UpdateAvailablePlansResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.UpdateAvailablePlansResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.UpdateAvailablePlansResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UpdateAvailablePlansResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    plansList: jspb.Message.toObjectList(msg.getPlansList(),
    proto.access_management.SubscriptionPlan.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.UpdateAvailablePlansResponse}
 */
proto.access_management.UpdateAvailablePlansResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.UpdateAvailablePlansResponse;
  return proto.access_management.UpdateAvailablePlansResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.UpdateAvailablePlansResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.UpdateAvailablePlansResponse}
 */
proto.access_management.UpdateAvailablePlansResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.access_management.SubscriptionPlan;
      reader.readMessage(value,proto.access_management.SubscriptionPlan.deserializeBinaryFromReader);
      msg.addPlans(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.UpdateAvailablePlansResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.UpdateAvailablePlansResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.UpdateAvailablePlansResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.UpdateAvailablePlansResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.access_management.SubscriptionPlan.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SubscriptionPlan plans = 2;
 * @return {!Array<!proto.access_management.SubscriptionPlan>}
 */
proto.access_management.UpdateAvailablePlansResponse.prototype.getPlansList = function() {
  return /** @type{!Array<!proto.access_management.SubscriptionPlan>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.access_management.SubscriptionPlan, 2));
};


/**
 * @param {!Array<!proto.access_management.SubscriptionPlan>} value
 * @return {!proto.access_management.UpdateAvailablePlansResponse} returns this
*/
proto.access_management.UpdateAvailablePlansResponse.prototype.setPlansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.access_management.SubscriptionPlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.access_management.SubscriptionPlan}
 */
proto.access_management.UpdateAvailablePlansResponse.prototype.addPlans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.access_management.SubscriptionPlan, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.UpdateAvailablePlansResponse} returns this
 */
proto.access_management.UpdateAvailablePlansResponse.prototype.clearPlansList = function() {
  return this.setPlansList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetSubscriptionPlansRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetSubscriptionPlansRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetSubscriptionPlansRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetSubscriptionPlansRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetSubscriptionPlansRequest}
 */
proto.access_management.GetSubscriptionPlansRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetSubscriptionPlansRequest;
  return proto.access_management.GetSubscriptionPlansRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetSubscriptionPlansRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetSubscriptionPlansRequest}
 */
proto.access_management.GetSubscriptionPlansRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetSubscriptionPlansRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetSubscriptionPlansRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetSubscriptionPlansRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetSubscriptionPlansRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.GetSubscriptionPlansResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetSubscriptionPlansResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetSubscriptionPlansResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetSubscriptionPlansResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetSubscriptionPlansResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    plansList: jspb.Message.toObjectList(msg.getPlansList(),
    proto.access_management.SubscriptionPlan.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetSubscriptionPlansResponse}
 */
proto.access_management.GetSubscriptionPlansResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetSubscriptionPlansResponse;
  return proto.access_management.GetSubscriptionPlansResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetSubscriptionPlansResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetSubscriptionPlansResponse}
 */
proto.access_management.GetSubscriptionPlansResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.access_management.SubscriptionPlan;
      reader.readMessage(value,proto.access_management.SubscriptionPlan.deserializeBinaryFromReader);
      msg.addPlans(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetSubscriptionPlansResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetSubscriptionPlansResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetSubscriptionPlansResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetSubscriptionPlansResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.access_management.SubscriptionPlan.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SubscriptionPlan plans = 1;
 * @return {!Array<!proto.access_management.SubscriptionPlan>}
 */
proto.access_management.GetSubscriptionPlansResponse.prototype.getPlansList = function() {
  return /** @type{!Array<!proto.access_management.SubscriptionPlan>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.access_management.SubscriptionPlan, 1));
};


/**
 * @param {!Array<!proto.access_management.SubscriptionPlan>} value
 * @return {!proto.access_management.GetSubscriptionPlansResponse} returns this
*/
proto.access_management.GetSubscriptionPlansResponse.prototype.setPlansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.access_management.SubscriptionPlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.access_management.SubscriptionPlan}
 */
proto.access_management.GetSubscriptionPlansResponse.prototype.addPlans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.access_management.SubscriptionPlan, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.GetSubscriptionPlansResponse} returns this
 */
proto.access_management.GetSubscriptionPlansResponse.prototype.clearPlansList = function() {
  return this.setPlansList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetCommunityAvailablePlansRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetCommunityAvailablePlansRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetCommunityAvailablePlansRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityAvailablePlansRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    communityId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetCommunityAvailablePlansRequest}
 */
proto.access_management.GetCommunityAvailablePlansRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetCommunityAvailablePlansRequest;
  return proto.access_management.GetCommunityAvailablePlansRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetCommunityAvailablePlansRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetCommunityAvailablePlansRequest}
 */
proto.access_management.GetCommunityAvailablePlansRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommunityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetCommunityAvailablePlansRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetCommunityAvailablePlansRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetCommunityAvailablePlansRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityAvailablePlansRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommunityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string community_id = 1;
 * @return {string}
 */
proto.access_management.GetCommunityAvailablePlansRequest.prototype.getCommunityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.access_management.GetCommunityAvailablePlansRequest} returns this
 */
proto.access_management.GetCommunityAvailablePlansRequest.prototype.setCommunityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.access_management.GetCommunityAvailablePlansResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.access_management.GetCommunityAvailablePlansResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.access_management.GetCommunityAvailablePlansResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.access_management.GetCommunityAvailablePlansResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityAvailablePlansResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    plansList: jspb.Message.toObjectList(msg.getPlansList(),
    proto.access_management.SubscriptionPlan.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.access_management.GetCommunityAvailablePlansResponse}
 */
proto.access_management.GetCommunityAvailablePlansResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.access_management.GetCommunityAvailablePlansResponse;
  return proto.access_management.GetCommunityAvailablePlansResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.access_management.GetCommunityAvailablePlansResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.access_management.GetCommunityAvailablePlansResponse}
 */
proto.access_management.GetCommunityAvailablePlansResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.access_management.SubscriptionPlan;
      reader.readMessage(value,proto.access_management.SubscriptionPlan.deserializeBinaryFromReader);
      msg.addPlans(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.access_management.GetCommunityAvailablePlansResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.access_management.GetCommunityAvailablePlansResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.access_management.GetCommunityAvailablePlansResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.access_management.GetCommunityAvailablePlansResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.access_management.SubscriptionPlan.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SubscriptionPlan plans = 1;
 * @return {!Array<!proto.access_management.SubscriptionPlan>}
 */
proto.access_management.GetCommunityAvailablePlansResponse.prototype.getPlansList = function() {
  return /** @type{!Array<!proto.access_management.SubscriptionPlan>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.access_management.SubscriptionPlan, 1));
};


/**
 * @param {!Array<!proto.access_management.SubscriptionPlan>} value
 * @return {!proto.access_management.GetCommunityAvailablePlansResponse} returns this
*/
proto.access_management.GetCommunityAvailablePlansResponse.prototype.setPlansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.access_management.SubscriptionPlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.access_management.SubscriptionPlan}
 */
proto.access_management.GetCommunityAvailablePlansResponse.prototype.addPlans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.access_management.SubscriptionPlan, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.access_management.GetCommunityAvailablePlansResponse} returns this
 */
proto.access_management.GetCommunityAvailablePlansResponse.prototype.clearPlansList = function() {
  return this.setPlansList([]);
};


/**
 * @enum {number}
 */
proto.access_management.CommunityType = {
  COMMUNITY_TYPE_UNKNOWN: 0,
  COMMUNITY_TYPE_COMPANY: 1,
  COMMUNITY_TYPE_COMMUNITY: 2
};

/**
 * @enum {number}
 */
proto.access_management.CommunityGating = {
  COMMUNITY_GATING_TYPE_UNKNOWN: 0,
  COMMUNITY_GATING_TYPE_OPEN: 1,
  COMMUNITY_GATING_TYPE_NFT: 2,
  COMMUNITY_GATING_TYPE_DOMAIN: 3,
  COMMUNITY_GATING_TYPE_INVITE: 4
};

/**
 * @enum {number}
 */
proto.access_management.CommunityStatus = {
  COMMUNITY_STATUS_UNKNOWN: 0,
  COMMUNITY_STATUS_PENDING: 1,
  COMMUNITY_STATUS_READY: 2
};

/**
 * @enum {number}
 */
proto.access_management.RoomStatus = {
  ROOM_STATUS_UNKNOWN: 0,
  ROOM_STATUS_IN_PROGRESS: 1,
  ROOM_STATUS_PENDING: 2,
  ROOM_STATUS_IDLE: 3
};

/**
 * @enum {number}
 */
proto.access_management.RoomType = {
  ROOM_TYPE_UNKNOWN: 0,
  ROOM_TYPE_OFFICIAL: 1,
  ROOM_TYPE_PERSONAL: 2,
  ROOM_TYPE_GAME_NIGHT: 3
};

/**
 * @enum {number}
 */
proto.access_management.RoomDisconnect = {
  ROOM_DISCONNECT_UNKNOWN: 0,
  ROOM_DISCONNECT_CLOSED: 1,
  ROOM_DISCONNECT_KICKED: 2
};

/**
 * @enum {number}
 */
proto.access_management.Frequency = {
  WEEK: 0,
  FORTNIGHT: 1,
  MONTH: 2,
  ONCE: 3
};

/**
 * @enum {number}
 */
proto.access_management.Weekday = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6
};

/**
 * @enum {number}
 */
proto.access_management.AccessManagementJobType = {
  UNKNOWN: 0,
  CLEANUP_GAME_DOWNLOAD: 1,
  SYNC_COMMUNITY_ADMINS: 2,
  CREATE_GAMENIGHT_TIMESTAMP: 3,
  DISCONNECTION_CLEANUP: 4
};

/**
 * @enum {number}
 */
proto.access_management.CommunitySubscriptionEventType = {
  COMMUNITY_SUBSCRIPTION_EVENT_UNKNOWN: 0,
  COMMUNITY_SUBSCRIPTION_EVENT_ACTIVATED: 1,
  COMMUNITY_SUBSCRIPTION_EVENT_OVERDUE: 2,
  COMMUNITY_SUBSCRIPTION_EVENT_OVERCAPACITY: 3,
  COMMUNITY_SUBSCRIPTION_EVENT_UNDERCAPACITY: 4
};

/**
 * @enum {number}
 */
proto.access_management.CustomerType = {
  CUSTOMER_TYPE_UNKNOWN: 0,
  CUSTOMER_TYPE_COMMUNITY: 1,
  CUSTOMER_TYPE_USER: 2
};

goog.object.extend(exports, proto.access_management);
