import { getCookie } from 'cookies-next';
import { refreshAccessToken } from '~/utils/auth';

// implements both `UnaryInterceptor` and `StreamInterceptor` interface
export class AuthInterceptorUnary {
  async intercept(request: any, invoker: any) {
    const metadata = request.getMetadata();

    await refreshAccessToken();

    const token = getCookie('privy:token');
    metadata.Authorization = `bearer ${token}`;
    return invoker(request);
  }
}

// Note: There is a separate for StreamInterceptor because the `intercept` function cannot be async for streams
// refreshing the privy token happens on all unary calls
export class AuthInterceptorStream {
  intercept(request: any, invoker: any) {
    const metadata = request.getMetadata();

    const token = getCookie('privy:token');
    metadata.Authorization = `bearer ${token}`;

    return invoker(request);
  }
}
