/**
 * @fileoverview gRPC-Web generated client stub for access_management
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.19.1
// source: src/modules/access_management/access_management.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as src_modules_access_management_access_management_pb from '../../../src/modules/access_management/access_management_pb';


export class AccessManagementClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateUser = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/CreateUser',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.CreateUserRequest,
    src_modules_access_management_access_management_pb.CreateUserResponse,
    (request: src_modules_access_management_access_management_pb.CreateUserRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.CreateUserResponse.deserializeBinary
  );

  createUser(
    request: src_modules_access_management_access_management_pb.CreateUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.CreateUserResponse>;

  createUser(
    request: src_modules_access_management_access_management_pb.CreateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.CreateUserResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.CreateUserResponse>;

  createUser(
    request: src_modules_access_management_access_management_pb.CreateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.CreateUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/CreateUser',
        request,
        metadata || {},
        this.methodDescriptorCreateUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/CreateUser',
    request,
    metadata || {},
    this.methodDescriptorCreateUser);
  }

  methodDescriptorCheckIfUsernameIsAvailable = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/CheckIfUsernameIsAvailable',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.CheckIfUsernameIsAvailableRequest,
    src_modules_access_management_access_management_pb.CheckIfUsernameIsAvailableResponse,
    (request: src_modules_access_management_access_management_pb.CheckIfUsernameIsAvailableRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.CheckIfUsernameIsAvailableResponse.deserializeBinary
  );

  checkIfUsernameIsAvailable(
    request: src_modules_access_management_access_management_pb.CheckIfUsernameIsAvailableRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.CheckIfUsernameIsAvailableResponse>;

  checkIfUsernameIsAvailable(
    request: src_modules_access_management_access_management_pb.CheckIfUsernameIsAvailableRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.CheckIfUsernameIsAvailableResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.CheckIfUsernameIsAvailableResponse>;

  checkIfUsernameIsAvailable(
    request: src_modules_access_management_access_management_pb.CheckIfUsernameIsAvailableRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.CheckIfUsernameIsAvailableResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/CheckIfUsernameIsAvailable',
        request,
        metadata || {},
        this.methodDescriptorCheckIfUsernameIsAvailable,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/CheckIfUsernameIsAvailable',
    request,
    metadata || {},
    this.methodDescriptorCheckIfUsernameIsAvailable);
  }

  methodDescriptorGetUserById = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/GetUserById',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.GetUserByIdRequest,
    src_modules_access_management_access_management_pb.GetUserResponse,
    (request: src_modules_access_management_access_management_pb.GetUserByIdRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.GetUserResponse.deserializeBinary
  );

  getUserById(
    request: src_modules_access_management_access_management_pb.GetUserByIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.GetUserResponse>;

  getUserById(
    request: src_modules_access_management_access_management_pb.GetUserByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetUserResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.GetUserResponse>;

  getUserById(
    request: src_modules_access_management_access_management_pb.GetUserByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/GetUserById',
        request,
        metadata || {},
        this.methodDescriptorGetUserById,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/GetUserById',
    request,
    metadata || {},
    this.methodDescriptorGetUserById);
  }

  methodDescriptorGetUsersById = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/GetUsersById',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.GetUsersByIdRequest,
    src_modules_access_management_access_management_pb.GetUsersByIdResponse,
    (request: src_modules_access_management_access_management_pb.GetUsersByIdRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.GetUsersByIdResponse.deserializeBinary
  );

  getUsersById(
    request: src_modules_access_management_access_management_pb.GetUsersByIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.GetUsersByIdResponse>;

  getUsersById(
    request: src_modules_access_management_access_management_pb.GetUsersByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetUsersByIdResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.GetUsersByIdResponse>;

  getUsersById(
    request: src_modules_access_management_access_management_pb.GetUsersByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetUsersByIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/GetUsersById',
        request,
        metadata || {},
        this.methodDescriptorGetUsersById,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/GetUsersById',
    request,
    metadata || {},
    this.methodDescriptorGetUsersById);
  }

  methodDescriptorGetUserByExternalAuthId = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/GetUserByExternalAuthId',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.GetUserByExternalAuthIdRequest,
    src_modules_access_management_access_management_pb.GetUserResponse,
    (request: src_modules_access_management_access_management_pb.GetUserByExternalAuthIdRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.GetUserResponse.deserializeBinary
  );

  getUserByExternalAuthId(
    request: src_modules_access_management_access_management_pb.GetUserByExternalAuthIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.GetUserResponse>;

  getUserByExternalAuthId(
    request: src_modules_access_management_access_management_pb.GetUserByExternalAuthIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetUserResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.GetUserResponse>;

  getUserByExternalAuthId(
    request: src_modules_access_management_access_management_pb.GetUserByExternalAuthIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/GetUserByExternalAuthId',
        request,
        metadata || {},
        this.methodDescriptorGetUserByExternalAuthId,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/GetUserByExternalAuthId',
    request,
    metadata || {},
    this.methodDescriptorGetUserByExternalAuthId);
  }

  methodDescriptorAddUserToCommunity = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/AddUserToCommunity',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.AddUserToCommunityRequest,
    src_modules_access_management_access_management_pb.AddUserToCommunityResponse,
    (request: src_modules_access_management_access_management_pb.AddUserToCommunityRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.AddUserToCommunityResponse.deserializeBinary
  );

  addUserToCommunity(
    request: src_modules_access_management_access_management_pb.AddUserToCommunityRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.AddUserToCommunityResponse>;

  addUserToCommunity(
    request: src_modules_access_management_access_management_pb.AddUserToCommunityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.AddUserToCommunityResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.AddUserToCommunityResponse>;

  addUserToCommunity(
    request: src_modules_access_management_access_management_pb.AddUserToCommunityRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.AddUserToCommunityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/AddUserToCommunity',
        request,
        metadata || {},
        this.methodDescriptorAddUserToCommunity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/AddUserToCommunity',
    request,
    metadata || {},
    this.methodDescriptorAddUserToCommunity);
  }

  methodDescriptorRemoveUserFromCommunity = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/RemoveUserFromCommunity',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.RemoveUserFromCommunityRequest,
    src_modules_access_management_access_management_pb.RemoveUserFromCommunityResponse,
    (request: src_modules_access_management_access_management_pb.RemoveUserFromCommunityRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.RemoveUserFromCommunityResponse.deserializeBinary
  );

  removeUserFromCommunity(
    request: src_modules_access_management_access_management_pb.RemoveUserFromCommunityRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.RemoveUserFromCommunityResponse>;

  removeUserFromCommunity(
    request: src_modules_access_management_access_management_pb.RemoveUserFromCommunityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.RemoveUserFromCommunityResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.RemoveUserFromCommunityResponse>;

  removeUserFromCommunity(
    request: src_modules_access_management_access_management_pb.RemoveUserFromCommunityRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.RemoveUserFromCommunityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/RemoveUserFromCommunity',
        request,
        metadata || {},
        this.methodDescriptorRemoveUserFromCommunity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/RemoveUserFromCommunity',
    request,
    metadata || {},
    this.methodDescriptorRemoveUserFromCommunity);
  }

  methodDescriptorUpdateUser = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/UpdateUser',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.UpdateUserRequest,
    src_modules_access_management_access_management_pb.UpdateUserResponse,
    (request: src_modules_access_management_access_management_pb.UpdateUserRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.UpdateUserResponse.deserializeBinary
  );

  updateUser(
    request: src_modules_access_management_access_management_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.UpdateUserResponse>;

  updateUser(
    request: src_modules_access_management_access_management_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.UpdateUserResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.UpdateUserResponse>;

  updateUser(
    request: src_modules_access_management_access_management_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.UpdateUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/UpdateUser',
        request,
        metadata || {},
        this.methodDescriptorUpdateUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/UpdateUser',
    request,
    metadata || {},
    this.methodDescriptorUpdateUser);
  }

  methodDescriptorDeleteUser = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/DeleteUser',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.DeleteUserRequest,
    src_modules_access_management_access_management_pb.DeleteUserResponse,
    (request: src_modules_access_management_access_management_pb.DeleteUserRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.DeleteUserResponse.deserializeBinary
  );

  deleteUser(
    request: src_modules_access_management_access_management_pb.DeleteUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.DeleteUserResponse>;

  deleteUser(
    request: src_modules_access_management_access_management_pb.DeleteUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.DeleteUserResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.DeleteUserResponse>;

  deleteUser(
    request: src_modules_access_management_access_management_pb.DeleteUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.DeleteUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/DeleteUser',
        request,
        metadata || {},
        this.methodDescriptorDeleteUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/DeleteUser',
    request,
    metadata || {},
    this.methodDescriptorDeleteUser);
  }

  methodDescriptorListUserCommunities = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/ListUserCommunities',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.ListUserCommunitiesRequest,
    src_modules_access_management_access_management_pb.ListUserCommunitiesResponse,
    (request: src_modules_access_management_access_management_pb.ListUserCommunitiesRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.ListUserCommunitiesResponse.deserializeBinary
  );

  listUserCommunities(
    request: src_modules_access_management_access_management_pb.ListUserCommunitiesRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.ListUserCommunitiesResponse>;

  listUserCommunities(
    request: src_modules_access_management_access_management_pb.ListUserCommunitiesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.ListUserCommunitiesResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.ListUserCommunitiesResponse>;

  listUserCommunities(
    request: src_modules_access_management_access_management_pb.ListUserCommunitiesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.ListUserCommunitiesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/ListUserCommunities',
        request,
        metadata || {},
        this.methodDescriptorListUserCommunities,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/ListUserCommunities',
    request,
    metadata || {},
    this.methodDescriptorListUserCommunities);
  }

  methodDescriptorJoinCommunity = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/JoinCommunity',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.JoinCommunityRequest,
    src_modules_access_management_access_management_pb.JoinCommunityResponse,
    (request: src_modules_access_management_access_management_pb.JoinCommunityRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.JoinCommunityResponse.deserializeBinary
  );

  joinCommunity(
    request: src_modules_access_management_access_management_pb.JoinCommunityRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.JoinCommunityResponse>;

  joinCommunity(
    request: src_modules_access_management_access_management_pb.JoinCommunityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.JoinCommunityResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.JoinCommunityResponse>;

  joinCommunity(
    request: src_modules_access_management_access_management_pb.JoinCommunityRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.JoinCommunityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/JoinCommunity',
        request,
        metadata || {},
        this.methodDescriptorJoinCommunity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/JoinCommunity',
    request,
    metadata || {},
    this.methodDescriptorJoinCommunity);
  }

  methodDescriptorLeaveCommunity = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/LeaveCommunity',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.LeaveCommunityRequest,
    src_modules_access_management_access_management_pb.LeaveCommunityResponse,
    (request: src_modules_access_management_access_management_pb.LeaveCommunityRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.LeaveCommunityResponse.deserializeBinary
  );

  leaveCommunity(
    request: src_modules_access_management_access_management_pb.LeaveCommunityRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.LeaveCommunityResponse>;

  leaveCommunity(
    request: src_modules_access_management_access_management_pb.LeaveCommunityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.LeaveCommunityResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.LeaveCommunityResponse>;

  leaveCommunity(
    request: src_modules_access_management_access_management_pb.LeaveCommunityRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.LeaveCommunityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/LeaveCommunity',
        request,
        metadata || {},
        this.methodDescriptorLeaveCommunity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/LeaveCommunity',
    request,
    metadata || {},
    this.methodDescriptorLeaveCommunity);
  }

  methodDescriptorReportUserState = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/ReportUserState',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.ReportUserStateRequest,
    src_modules_access_management_access_management_pb.ReportUserStateResponse,
    (request: src_modules_access_management_access_management_pb.ReportUserStateRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.ReportUserStateResponse.deserializeBinary
  );

  reportUserState(
    request: src_modules_access_management_access_management_pb.ReportUserStateRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.ReportUserStateResponse>;

  reportUserState(
    request: src_modules_access_management_access_management_pb.ReportUserStateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.ReportUserStateResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.ReportUserStateResponse>;

  reportUserState(
    request: src_modules_access_management_access_management_pb.ReportUserStateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.ReportUserStateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/ReportUserState',
        request,
        metadata || {},
        this.methodDescriptorReportUserState,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/ReportUserState',
    request,
    metadata || {},
    this.methodDescriptorReportUserState);
  }

  methodDescriptorGetUserStatesById = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/GetUserStatesById',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.GetUserStatesByIdRequest,
    src_modules_access_management_access_management_pb.GetUserStatesByIdResponse,
    (request: src_modules_access_management_access_management_pb.GetUserStatesByIdRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.GetUserStatesByIdResponse.deserializeBinary
  );

  getUserStatesById(
    request: src_modules_access_management_access_management_pb.GetUserStatesByIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.GetUserStatesByIdResponse>;

  getUserStatesById(
    request: src_modules_access_management_access_management_pb.GetUserStatesByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetUserStatesByIdResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.GetUserStatesByIdResponse>;

  getUserStatesById(
    request: src_modules_access_management_access_management_pb.GetUserStatesByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetUserStatesByIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/GetUserStatesById',
        request,
        metadata || {},
        this.methodDescriptorGetUserStatesById,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/GetUserStatesById',
    request,
    metadata || {},
    this.methodDescriptorGetUserStatesById);
  }

  methodDescriptorConnectUser = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/ConnectUser',
    grpcWeb.MethodType.SERVER_STREAMING,
    src_modules_access_management_access_management_pb.ConnectUserRequest,
    src_modules_access_management_access_management_pb.ConnectedUserEvent,
    (request: src_modules_access_management_access_management_pb.ConnectUserRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.ConnectedUserEvent.deserializeBinary
  );

  connectUser(
    request: src_modules_access_management_access_management_pb.ConnectUserRequest,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.ConnectedUserEvent> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/access_management.AccessManagement/ConnectUser',
      request,
      metadata || {},
      this.methodDescriptorConnectUser);
  }

  methodDescriptorCreateCommunity = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/CreateCommunity',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.CreateCommunityRequest,
    src_modules_access_management_access_management_pb.CreateCommunityResponse,
    (request: src_modules_access_management_access_management_pb.CreateCommunityRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.CreateCommunityResponse.deserializeBinary
  );

  createCommunity(
    request: src_modules_access_management_access_management_pb.CreateCommunityRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.CreateCommunityResponse>;

  createCommunity(
    request: src_modules_access_management_access_management_pb.CreateCommunityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.CreateCommunityResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.CreateCommunityResponse>;

  createCommunity(
    request: src_modules_access_management_access_management_pb.CreateCommunityRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.CreateCommunityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/CreateCommunity',
        request,
        metadata || {},
        this.methodDescriptorCreateCommunity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/CreateCommunity',
    request,
    metadata || {},
    this.methodDescriptorCreateCommunity);
  }

  methodDescriptorGetCommunityById = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/GetCommunityById',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.GetCommunityByIdRequest,
    src_modules_access_management_access_management_pb.GetCommunityResponse,
    (request: src_modules_access_management_access_management_pb.GetCommunityByIdRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.GetCommunityResponse.deserializeBinary
  );

  getCommunityById(
    request: src_modules_access_management_access_management_pb.GetCommunityByIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.GetCommunityResponse>;

  getCommunityById(
    request: src_modules_access_management_access_management_pb.GetCommunityByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetCommunityResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.GetCommunityResponse>;

  getCommunityById(
    request: src_modules_access_management_access_management_pb.GetCommunityByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetCommunityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/GetCommunityById',
        request,
        metadata || {},
        this.methodDescriptorGetCommunityById,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/GetCommunityById',
    request,
    metadata || {},
    this.methodDescriptorGetCommunityById);
  }

  methodDescriptorGetCommunityBySlug = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/GetCommunityBySlug',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.GetCommunityBySlugRequest,
    src_modules_access_management_access_management_pb.GetCommunityResponse,
    (request: src_modules_access_management_access_management_pb.GetCommunityBySlugRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.GetCommunityResponse.deserializeBinary
  );

  getCommunityBySlug(
    request: src_modules_access_management_access_management_pb.GetCommunityBySlugRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.GetCommunityResponse>;

  getCommunityBySlug(
    request: src_modules_access_management_access_management_pb.GetCommunityBySlugRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetCommunityResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.GetCommunityResponse>;

  getCommunityBySlug(
    request: src_modules_access_management_access_management_pb.GetCommunityBySlugRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetCommunityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/GetCommunityBySlug',
        request,
        metadata || {},
        this.methodDescriptorGetCommunityBySlug,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/GetCommunityBySlug',
    request,
    metadata || {},
    this.methodDescriptorGetCommunityBySlug);
  }

  methodDescriptorGetCommunityProfileBySlug = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/GetCommunityProfileBySlug',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.GetCommunityProfileBySlugRequest,
    src_modules_access_management_access_management_pb.GetCommunityProfileBySlugResponse,
    (request: src_modules_access_management_access_management_pb.GetCommunityProfileBySlugRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.GetCommunityProfileBySlugResponse.deserializeBinary
  );

  getCommunityProfileBySlug(
    request: src_modules_access_management_access_management_pb.GetCommunityProfileBySlugRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.GetCommunityProfileBySlugResponse>;

  getCommunityProfileBySlug(
    request: src_modules_access_management_access_management_pb.GetCommunityProfileBySlugRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetCommunityProfileBySlugResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.GetCommunityProfileBySlugResponse>;

  getCommunityProfileBySlug(
    request: src_modules_access_management_access_management_pb.GetCommunityProfileBySlugRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetCommunityProfileBySlugResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/GetCommunityProfileBySlug',
        request,
        metadata || {},
        this.methodDescriptorGetCommunityProfileBySlug,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/GetCommunityProfileBySlug',
    request,
    metadata || {},
    this.methodDescriptorGetCommunityProfileBySlug);
  }

  methodDescriptorUpdateCommunity = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/UpdateCommunity',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.UpdateCommunityRequest,
    src_modules_access_management_access_management_pb.UpdateCommunityResponse,
    (request: src_modules_access_management_access_management_pb.UpdateCommunityRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.UpdateCommunityResponse.deserializeBinary
  );

  updateCommunity(
    request: src_modules_access_management_access_management_pb.UpdateCommunityRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.UpdateCommunityResponse>;

  updateCommunity(
    request: src_modules_access_management_access_management_pb.UpdateCommunityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.UpdateCommunityResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.UpdateCommunityResponse>;

  updateCommunity(
    request: src_modules_access_management_access_management_pb.UpdateCommunityRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.UpdateCommunityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/UpdateCommunity',
        request,
        metadata || {},
        this.methodDescriptorUpdateCommunity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/UpdateCommunity',
    request,
    metadata || {},
    this.methodDescriptorUpdateCommunity);
  }

  methodDescriptorUpdateCommunityBillingInfo = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/UpdateCommunityBillingInfo',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.UpdateCommunityBillingInfoRequest,
    src_modules_access_management_access_management_pb.UpdateCommunityBillingInfoResponse,
    (request: src_modules_access_management_access_management_pb.UpdateCommunityBillingInfoRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.UpdateCommunityBillingInfoResponse.deserializeBinary
  );

  updateCommunityBillingInfo(
    request: src_modules_access_management_access_management_pb.UpdateCommunityBillingInfoRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.UpdateCommunityBillingInfoResponse>;

  updateCommunityBillingInfo(
    request: src_modules_access_management_access_management_pb.UpdateCommunityBillingInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.UpdateCommunityBillingInfoResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.UpdateCommunityBillingInfoResponse>;

  updateCommunityBillingInfo(
    request: src_modules_access_management_access_management_pb.UpdateCommunityBillingInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.UpdateCommunityBillingInfoResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/UpdateCommunityBillingInfo',
        request,
        metadata || {},
        this.methodDescriptorUpdateCommunityBillingInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/UpdateCommunityBillingInfo',
    request,
    metadata || {},
    this.methodDescriptorUpdateCommunityBillingInfo);
  }

  methodDescriptorAddCommunityAdmin = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/AddCommunityAdmin',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.AddCommunityAdminRequest,
    src_modules_access_management_access_management_pb.AddCommunityAdminResponse,
    (request: src_modules_access_management_access_management_pb.AddCommunityAdminRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.AddCommunityAdminResponse.deserializeBinary
  );

  addCommunityAdmin(
    request: src_modules_access_management_access_management_pb.AddCommunityAdminRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.AddCommunityAdminResponse>;

  addCommunityAdmin(
    request: src_modules_access_management_access_management_pb.AddCommunityAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.AddCommunityAdminResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.AddCommunityAdminResponse>;

  addCommunityAdmin(
    request: src_modules_access_management_access_management_pb.AddCommunityAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.AddCommunityAdminResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/AddCommunityAdmin',
        request,
        metadata || {},
        this.methodDescriptorAddCommunityAdmin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/AddCommunityAdmin',
    request,
    metadata || {},
    this.methodDescriptorAddCommunityAdmin);
  }

  methodDescriptorRemoveCommunityAdmin = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/RemoveCommunityAdmin',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.RemoveCommunityAdminRequest,
    src_modules_access_management_access_management_pb.RemoveCommunityAdminResponse,
    (request: src_modules_access_management_access_management_pb.RemoveCommunityAdminRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.RemoveCommunityAdminResponse.deserializeBinary
  );

  removeCommunityAdmin(
    request: src_modules_access_management_access_management_pb.RemoveCommunityAdminRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.RemoveCommunityAdminResponse>;

  removeCommunityAdmin(
    request: src_modules_access_management_access_management_pb.RemoveCommunityAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.RemoveCommunityAdminResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.RemoveCommunityAdminResponse>;

  removeCommunityAdmin(
    request: src_modules_access_management_access_management_pb.RemoveCommunityAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.RemoveCommunityAdminResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/RemoveCommunityAdmin',
        request,
        metadata || {},
        this.methodDescriptorRemoveCommunityAdmin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/RemoveCommunityAdmin',
    request,
    metadata || {},
    this.methodDescriptorRemoveCommunityAdmin);
  }

  methodDescriptorDeleteCommunity = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/DeleteCommunity',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.DeleteCommunityRequest,
    src_modules_access_management_access_management_pb.DeleteCommunityResponse,
    (request: src_modules_access_management_access_management_pb.DeleteCommunityRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.DeleteCommunityResponse.deserializeBinary
  );

  deleteCommunity(
    request: src_modules_access_management_access_management_pb.DeleteCommunityRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.DeleteCommunityResponse>;

  deleteCommunity(
    request: src_modules_access_management_access_management_pb.DeleteCommunityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.DeleteCommunityResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.DeleteCommunityResponse>;

  deleteCommunity(
    request: src_modules_access_management_access_management_pb.DeleteCommunityRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.DeleteCommunityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/DeleteCommunity',
        request,
        metadata || {},
        this.methodDescriptorDeleteCommunity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/DeleteCommunity',
    request,
    metadata || {},
    this.methodDescriptorDeleteCommunity);
  }

  methodDescriptorListCommunityUsers = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/ListCommunityUsers',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.ListCommunityUsersRequest,
    src_modules_access_management_access_management_pb.ListCommunityUsersResponse,
    (request: src_modules_access_management_access_management_pb.ListCommunityUsersRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.ListCommunityUsersResponse.deserializeBinary
  );

  listCommunityUsers(
    request: src_modules_access_management_access_management_pb.ListCommunityUsersRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.ListCommunityUsersResponse>;

  listCommunityUsers(
    request: src_modules_access_management_access_management_pb.ListCommunityUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.ListCommunityUsersResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.ListCommunityUsersResponse>;

  listCommunityUsers(
    request: src_modules_access_management_access_management_pb.ListCommunityUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.ListCommunityUsersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/ListCommunityUsers',
        request,
        metadata || {},
        this.methodDescriptorListCommunityUsers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/ListCommunityUsers',
    request,
    metadata || {},
    this.methodDescriptorListCommunityUsers);
  }

  methodDescriptorCommunitySendInvite = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/CommunitySendInvite',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.CommunitySendInviteRequest,
    src_modules_access_management_access_management_pb.CommunitySendInviteResponse,
    (request: src_modules_access_management_access_management_pb.CommunitySendInviteRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.CommunitySendInviteResponse.deserializeBinary
  );

  communitySendInvite(
    request: src_modules_access_management_access_management_pb.CommunitySendInviteRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.CommunitySendInviteResponse>;

  communitySendInvite(
    request: src_modules_access_management_access_management_pb.CommunitySendInviteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.CommunitySendInviteResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.CommunitySendInviteResponse>;

  communitySendInvite(
    request: src_modules_access_management_access_management_pb.CommunitySendInviteRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.CommunitySendInviteResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/CommunitySendInvite',
        request,
        metadata || {},
        this.methodDescriptorCommunitySendInvite,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/CommunitySendInvite',
    request,
    metadata || {},
    this.methodDescriptorCommunitySendInvite);
  }

  methodDescriptorCommunityCancelInvite = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/CommunityCancelInvite',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.CommunityCancelInviteRequest,
    src_modules_access_management_access_management_pb.CommunityCancelInviteResponse,
    (request: src_modules_access_management_access_management_pb.CommunityCancelInviteRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.CommunityCancelInviteResponse.deserializeBinary
  );

  communityCancelInvite(
    request: src_modules_access_management_access_management_pb.CommunityCancelInviteRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.CommunityCancelInviteResponse>;

  communityCancelInvite(
    request: src_modules_access_management_access_management_pb.CommunityCancelInviteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.CommunityCancelInviteResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.CommunityCancelInviteResponse>;

  communityCancelInvite(
    request: src_modules_access_management_access_management_pb.CommunityCancelInviteRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.CommunityCancelInviteResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/CommunityCancelInvite',
        request,
        metadata || {},
        this.methodDescriptorCommunityCancelInvite,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/CommunityCancelInvite',
    request,
    metadata || {},
    this.methodDescriptorCommunityCancelInvite);
  }

  methodDescriptorWhitelistEntryCreate = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/WhitelistEntryCreate',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.WhitelistEntryCreateRequest,
    src_modules_access_management_access_management_pb.WhitelistEntryCreateResponse,
    (request: src_modules_access_management_access_management_pb.WhitelistEntryCreateRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.WhitelistEntryCreateResponse.deserializeBinary
  );

  whitelistEntryCreate(
    request: src_modules_access_management_access_management_pb.WhitelistEntryCreateRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.WhitelistEntryCreateResponse>;

  whitelistEntryCreate(
    request: src_modules_access_management_access_management_pb.WhitelistEntryCreateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.WhitelistEntryCreateResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.WhitelistEntryCreateResponse>;

  whitelistEntryCreate(
    request: src_modules_access_management_access_management_pb.WhitelistEntryCreateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.WhitelistEntryCreateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/WhitelistEntryCreate',
        request,
        metadata || {},
        this.methodDescriptorWhitelistEntryCreate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/WhitelistEntryCreate',
    request,
    metadata || {},
    this.methodDescriptorWhitelistEntryCreate);
  }

  methodDescriptorWhitelistEntryGet = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/WhitelistEntryGet',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.WhitelistEntryGetRequest,
    src_modules_access_management_access_management_pb.WhitelistEntryGetResponse,
    (request: src_modules_access_management_access_management_pb.WhitelistEntryGetRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.WhitelistEntryGetResponse.deserializeBinary
  );

  whitelistEntryGet(
    request: src_modules_access_management_access_management_pb.WhitelistEntryGetRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.WhitelistEntryGetResponse>;

  whitelistEntryGet(
    request: src_modules_access_management_access_management_pb.WhitelistEntryGetRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.WhitelistEntryGetResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.WhitelistEntryGetResponse>;

  whitelistEntryGet(
    request: src_modules_access_management_access_management_pb.WhitelistEntryGetRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.WhitelistEntryGetResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/WhitelistEntryGet',
        request,
        metadata || {},
        this.methodDescriptorWhitelistEntryGet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/WhitelistEntryGet',
    request,
    metadata || {},
    this.methodDescriptorWhitelistEntryGet);
  }

  methodDescriptorGetCommunityMetrics = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/GetCommunityMetrics',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.GetCommunityMetricsRequest,
    src_modules_access_management_access_management_pb.GetCommunityMetricsResponse,
    (request: src_modules_access_management_access_management_pb.GetCommunityMetricsRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.GetCommunityMetricsResponse.deserializeBinary
  );

  getCommunityMetrics(
    request: src_modules_access_management_access_management_pb.GetCommunityMetricsRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.GetCommunityMetricsResponse>;

  getCommunityMetrics(
    request: src_modules_access_management_access_management_pb.GetCommunityMetricsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetCommunityMetricsResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.GetCommunityMetricsResponse>;

  getCommunityMetrics(
    request: src_modules_access_management_access_management_pb.GetCommunityMetricsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetCommunityMetricsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/GetCommunityMetrics',
        request,
        metadata || {},
        this.methodDescriptorGetCommunityMetrics,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/GetCommunityMetrics',
    request,
    metadata || {},
    this.methodDescriptorGetCommunityMetrics);
  }

  methodDescriptorCreateCommunityRoom = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/CreateCommunityRoom',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.CreateCommunityRoomRequest,
    src_modules_access_management_access_management_pb.CreateCommunityRoomResponse,
    (request: src_modules_access_management_access_management_pb.CreateCommunityRoomRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.CreateCommunityRoomResponse.deserializeBinary
  );

  createCommunityRoom(
    request: src_modules_access_management_access_management_pb.CreateCommunityRoomRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.CreateCommunityRoomResponse>;

  createCommunityRoom(
    request: src_modules_access_management_access_management_pb.CreateCommunityRoomRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.CreateCommunityRoomResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.CreateCommunityRoomResponse>;

  createCommunityRoom(
    request: src_modules_access_management_access_management_pb.CreateCommunityRoomRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.CreateCommunityRoomResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/CreateCommunityRoom',
        request,
        metadata || {},
        this.methodDescriptorCreateCommunityRoom,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/CreateCommunityRoom',
    request,
    metadata || {},
    this.methodDescriptorCreateCommunityRoom);
  }

  methodDescriptorGetCommunityRoomById = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/GetCommunityRoomById',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.GetCommunityRoomByIdRequest,
    src_modules_access_management_access_management_pb.GetCommunityRoomResponse,
    (request: src_modules_access_management_access_management_pb.GetCommunityRoomByIdRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.GetCommunityRoomResponse.deserializeBinary
  );

  getCommunityRoomById(
    request: src_modules_access_management_access_management_pb.GetCommunityRoomByIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.GetCommunityRoomResponse>;

  getCommunityRoomById(
    request: src_modules_access_management_access_management_pb.GetCommunityRoomByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetCommunityRoomResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.GetCommunityRoomResponse>;

  getCommunityRoomById(
    request: src_modules_access_management_access_management_pb.GetCommunityRoomByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetCommunityRoomResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/GetCommunityRoomById',
        request,
        metadata || {},
        this.methodDescriptorGetCommunityRoomById,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/GetCommunityRoomById',
    request,
    metadata || {},
    this.methodDescriptorGetCommunityRoomById);
  }

  methodDescriptorGetCommunityRoomBySlug = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/GetCommunityRoomBySlug',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.GetCommunityRoomBySlugRequest,
    src_modules_access_management_access_management_pb.GetCommunityRoomResponse,
    (request: src_modules_access_management_access_management_pb.GetCommunityRoomBySlugRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.GetCommunityRoomResponse.deserializeBinary
  );

  getCommunityRoomBySlug(
    request: src_modules_access_management_access_management_pb.GetCommunityRoomBySlugRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.GetCommunityRoomResponse>;

  getCommunityRoomBySlug(
    request: src_modules_access_management_access_management_pb.GetCommunityRoomBySlugRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetCommunityRoomResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.GetCommunityRoomResponse>;

  getCommunityRoomBySlug(
    request: src_modules_access_management_access_management_pb.GetCommunityRoomBySlugRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetCommunityRoomResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/GetCommunityRoomBySlug',
        request,
        metadata || {},
        this.methodDescriptorGetCommunityRoomBySlug,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/GetCommunityRoomBySlug',
    request,
    metadata || {},
    this.methodDescriptorGetCommunityRoomBySlug);
  }

  methodDescriptorUpdateCommunityRoom = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/UpdateCommunityRoom',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.UpdateCommunityRoomRequest,
    src_modules_access_management_access_management_pb.UpdateCommunityRoomResponse,
    (request: src_modules_access_management_access_management_pb.UpdateCommunityRoomRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.UpdateCommunityRoomResponse.deserializeBinary
  );

  updateCommunityRoom(
    request: src_modules_access_management_access_management_pb.UpdateCommunityRoomRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.UpdateCommunityRoomResponse>;

  updateCommunityRoom(
    request: src_modules_access_management_access_management_pb.UpdateCommunityRoomRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.UpdateCommunityRoomResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.UpdateCommunityRoomResponse>;

  updateCommunityRoom(
    request: src_modules_access_management_access_management_pb.UpdateCommunityRoomRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.UpdateCommunityRoomResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/UpdateCommunityRoom',
        request,
        metadata || {},
        this.methodDescriptorUpdateCommunityRoom,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/UpdateCommunityRoom',
    request,
    metadata || {},
    this.methodDescriptorUpdateCommunityRoom);
  }

  methodDescriptorCommunityRoomConfigurationUpdate = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/CommunityRoomConfigurationUpdate',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.CommunityRoomConfigurationUpdateRequest,
    src_modules_access_management_access_management_pb.CommunityRoomConfigurationUpdateResponse,
    (request: src_modules_access_management_access_management_pb.CommunityRoomConfigurationUpdateRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.CommunityRoomConfigurationUpdateResponse.deserializeBinary
  );

  communityRoomConfigurationUpdate(
    request: src_modules_access_management_access_management_pb.CommunityRoomConfigurationUpdateRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.CommunityRoomConfigurationUpdateResponse>;

  communityRoomConfigurationUpdate(
    request: src_modules_access_management_access_management_pb.CommunityRoomConfigurationUpdateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.CommunityRoomConfigurationUpdateResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.CommunityRoomConfigurationUpdateResponse>;

  communityRoomConfigurationUpdate(
    request: src_modules_access_management_access_management_pb.CommunityRoomConfigurationUpdateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.CommunityRoomConfigurationUpdateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/CommunityRoomConfigurationUpdate',
        request,
        metadata || {},
        this.methodDescriptorCommunityRoomConfigurationUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/CommunityRoomConfigurationUpdate',
    request,
    metadata || {},
    this.methodDescriptorCommunityRoomConfigurationUpdate);
  }

  methodDescriptorDeleteCommunityRoom = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/DeleteCommunityRoom',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.DeleteCommunityRoomRequest,
    src_modules_access_management_access_management_pb.DeleteCommunityRoomResponse,
    (request: src_modules_access_management_access_management_pb.DeleteCommunityRoomRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.DeleteCommunityRoomResponse.deserializeBinary
  );

  deleteCommunityRoom(
    request: src_modules_access_management_access_management_pb.DeleteCommunityRoomRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.DeleteCommunityRoomResponse>;

  deleteCommunityRoom(
    request: src_modules_access_management_access_management_pb.DeleteCommunityRoomRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.DeleteCommunityRoomResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.DeleteCommunityRoomResponse>;

  deleteCommunityRoom(
    request: src_modules_access_management_access_management_pb.DeleteCommunityRoomRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.DeleteCommunityRoomResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/DeleteCommunityRoom',
        request,
        metadata || {},
        this.methodDescriptorDeleteCommunityRoom,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/DeleteCommunityRoom',
    request,
    metadata || {},
    this.methodDescriptorDeleteCommunityRoom);
  }

  methodDescriptorListCommunityRooms = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/ListCommunityRooms',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.ListCommunityRoomsRequest,
    src_modules_access_management_access_management_pb.ListCommunityRoomsResponse,
    (request: src_modules_access_management_access_management_pb.ListCommunityRoomsRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.ListCommunityRoomsResponse.deserializeBinary
  );

  listCommunityRooms(
    request: src_modules_access_management_access_management_pb.ListCommunityRoomsRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.ListCommunityRoomsResponse>;

  listCommunityRooms(
    request: src_modules_access_management_access_management_pb.ListCommunityRoomsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.ListCommunityRoomsResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.ListCommunityRoomsResponse>;

  listCommunityRooms(
    request: src_modules_access_management_access_management_pb.ListCommunityRoomsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.ListCommunityRoomsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/ListCommunityRooms',
        request,
        metadata || {},
        this.methodDescriptorListCommunityRooms,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/ListCommunityRooms',
    request,
    metadata || {},
    this.methodDescriptorListCommunityRooms);
  }

  methodDescriptorListCommunityRoomsFromSlug = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/ListCommunityRoomsFromSlug',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.ListCommunityRoomsFromSlugRequest,
    src_modules_access_management_access_management_pb.ListCommunityRoomsResponse,
    (request: src_modules_access_management_access_management_pb.ListCommunityRoomsFromSlugRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.ListCommunityRoomsResponse.deserializeBinary
  );

  listCommunityRoomsFromSlug(
    request: src_modules_access_management_access_management_pb.ListCommunityRoomsFromSlugRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.ListCommunityRoomsResponse>;

  listCommunityRoomsFromSlug(
    request: src_modules_access_management_access_management_pb.ListCommunityRoomsFromSlugRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.ListCommunityRoomsResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.ListCommunityRoomsResponse>;

  listCommunityRoomsFromSlug(
    request: src_modules_access_management_access_management_pb.ListCommunityRoomsFromSlugRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.ListCommunityRoomsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/ListCommunityRoomsFromSlug',
        request,
        metadata || {},
        this.methodDescriptorListCommunityRoomsFromSlug,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/ListCommunityRoomsFromSlug',
    request,
    metadata || {},
    this.methodDescriptorListCommunityRoomsFromSlug);
  }

  methodDescriptorGetInitialRoomState = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/GetInitialRoomState',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.GetInitialRoomStateRequest,
    src_modules_access_management_access_management_pb.GetInitialRoomStateResponse,
    (request: src_modules_access_management_access_management_pb.GetInitialRoomStateRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.GetInitialRoomStateResponse.deserializeBinary
  );

  getInitialRoomState(
    request: src_modules_access_management_access_management_pb.GetInitialRoomStateRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.GetInitialRoomStateResponse>;

  getInitialRoomState(
    request: src_modules_access_management_access_management_pb.GetInitialRoomStateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetInitialRoomStateResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.GetInitialRoomStateResponse>;

  getInitialRoomState(
    request: src_modules_access_management_access_management_pb.GetInitialRoomStateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetInitialRoomStateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/GetInitialRoomState',
        request,
        metadata || {},
        this.methodDescriptorGetInitialRoomState,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/GetInitialRoomState',
    request,
    metadata || {},
    this.methodDescriptorGetInitialRoomState);
  }

  methodDescriptorGetInitialRoomStateBySlug = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/GetInitialRoomStateBySlug',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.GetInitialRoomStateBySlugRequest,
    src_modules_access_management_access_management_pb.GetInitialRoomStateResponse,
    (request: src_modules_access_management_access_management_pb.GetInitialRoomStateBySlugRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.GetInitialRoomStateResponse.deserializeBinary
  );

  getInitialRoomStateBySlug(
    request: src_modules_access_management_access_management_pb.GetInitialRoomStateBySlugRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.GetInitialRoomStateResponse>;

  getInitialRoomStateBySlug(
    request: src_modules_access_management_access_management_pb.GetInitialRoomStateBySlugRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetInitialRoomStateResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.GetInitialRoomStateResponse>;

  getInitialRoomStateBySlug(
    request: src_modules_access_management_access_management_pb.GetInitialRoomStateBySlugRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetInitialRoomStateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/GetInitialRoomStateBySlug',
        request,
        metadata || {},
        this.methodDescriptorGetInitialRoomStateBySlug,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/GetInitialRoomStateBySlug',
    request,
    metadata || {},
    this.methodDescriptorGetInitialRoomStateBySlug);
  }

  methodDescriptorGetCommunityRoomUsers = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/GetCommunityRoomUsers',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.GetCommunityRoomUsersRequest,
    src_modules_access_management_access_management_pb.GetCommunityRoomUsersResponse,
    (request: src_modules_access_management_access_management_pb.GetCommunityRoomUsersRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.GetCommunityRoomUsersResponse.deserializeBinary
  );

  getCommunityRoomUsers(
    request: src_modules_access_management_access_management_pb.GetCommunityRoomUsersRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.GetCommunityRoomUsersResponse>;

  getCommunityRoomUsers(
    request: src_modules_access_management_access_management_pb.GetCommunityRoomUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetCommunityRoomUsersResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.GetCommunityRoomUsersResponse>;

  getCommunityRoomUsers(
    request: src_modules_access_management_access_management_pb.GetCommunityRoomUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetCommunityRoomUsersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/GetCommunityRoomUsers',
        request,
        metadata || {},
        this.methodDescriptorGetCommunityRoomUsers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/GetCommunityRoomUsers',
    request,
    metadata || {},
    this.methodDescriptorGetCommunityRoomUsers);
  }

  methodDescriptorUpdateRoomHost = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/UpdateRoomHost',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.UpdateRoomHostRequest,
    src_modules_access_management_access_management_pb.UpdateRoomHostResponse,
    (request: src_modules_access_management_access_management_pb.UpdateRoomHostRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.UpdateRoomHostResponse.deserializeBinary
  );

  updateRoomHost(
    request: src_modules_access_management_access_management_pb.UpdateRoomHostRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.UpdateRoomHostResponse>;

  updateRoomHost(
    request: src_modules_access_management_access_management_pb.UpdateRoomHostRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.UpdateRoomHostResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.UpdateRoomHostResponse>;

  updateRoomHost(
    request: src_modules_access_management_access_management_pb.UpdateRoomHostRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.UpdateRoomHostResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/UpdateRoomHost',
        request,
        metadata || {},
        this.methodDescriptorUpdateRoomHost,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/UpdateRoomHost',
    request,
    metadata || {},
    this.methodDescriptorUpdateRoomHost);
  }

  methodDescriptorStartOrContinueGameDownload = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/StartOrContinueGameDownload',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.StartOrContinueGameDownloadRequest,
    src_modules_access_management_access_management_pb.StartOrContinueGameDownloadResponse,
    (request: src_modules_access_management_access_management_pb.StartOrContinueGameDownloadRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.StartOrContinueGameDownloadResponse.deserializeBinary
  );

  startOrContinueGameDownload(
    request: src_modules_access_management_access_management_pb.StartOrContinueGameDownloadRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.StartOrContinueGameDownloadResponse>;

  startOrContinueGameDownload(
    request: src_modules_access_management_access_management_pb.StartOrContinueGameDownloadRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.StartOrContinueGameDownloadResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.StartOrContinueGameDownloadResponse>;

  startOrContinueGameDownload(
    request: src_modules_access_management_access_management_pb.StartOrContinueGameDownloadRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.StartOrContinueGameDownloadResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/StartOrContinueGameDownload',
        request,
        metadata || {},
        this.methodDescriptorStartOrContinueGameDownload,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/StartOrContinueGameDownload',
    request,
    metadata || {},
    this.methodDescriptorStartOrContinueGameDownload);
  }

  methodDescriptorDoneDownloadingGame = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/DoneDownloadingGame',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.DoneDownloadingGameRequest,
    src_modules_access_management_access_management_pb.DoneDownloadingGameResponse,
    (request: src_modules_access_management_access_management_pb.DoneDownloadingGameRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.DoneDownloadingGameResponse.deserializeBinary
  );

  doneDownloadingGame(
    request: src_modules_access_management_access_management_pb.DoneDownloadingGameRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.DoneDownloadingGameResponse>;

  doneDownloadingGame(
    request: src_modules_access_management_access_management_pb.DoneDownloadingGameRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.DoneDownloadingGameResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.DoneDownloadingGameResponse>;

  doneDownloadingGame(
    request: src_modules_access_management_access_management_pb.DoneDownloadingGameRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.DoneDownloadingGameResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/DoneDownloadingGame',
        request,
        metadata || {},
        this.methodDescriptorDoneDownloadingGame,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/DoneDownloadingGame',
    request,
    metadata || {},
    this.methodDescriptorDoneDownloadingGame);
  }

  methodDescriptorGetCommunityRoomUsersDownloading = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/GetCommunityRoomUsersDownloading',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.GetCommunityRoomUsersDownloadingRequest,
    src_modules_access_management_access_management_pb.GetCommunityRoomUsersDownloadingResponse,
    (request: src_modules_access_management_access_management_pb.GetCommunityRoomUsersDownloadingRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.GetCommunityRoomUsersDownloadingResponse.deserializeBinary
  );

  getCommunityRoomUsersDownloading(
    request: src_modules_access_management_access_management_pb.GetCommunityRoomUsersDownloadingRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.GetCommunityRoomUsersDownloadingResponse>;

  getCommunityRoomUsersDownloading(
    request: src_modules_access_management_access_management_pb.GetCommunityRoomUsersDownloadingRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetCommunityRoomUsersDownloadingResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.GetCommunityRoomUsersDownloadingResponse>;

  getCommunityRoomUsersDownloading(
    request: src_modules_access_management_access_management_pb.GetCommunityRoomUsersDownloadingRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetCommunityRoomUsersDownloadingResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/GetCommunityRoomUsersDownloading',
        request,
        metadata || {},
        this.methodDescriptorGetCommunityRoomUsersDownloading,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/GetCommunityRoomUsersDownloading',
    request,
    metadata || {},
    this.methodDescriptorGetCommunityRoomUsersDownloading);
  }

  methodDescriptorGetCommunityRoomUsersNotDownloading = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/GetCommunityRoomUsersNotDownloading',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.GetCommunityRoomUsersNotDownloadingRequest,
    src_modules_access_management_access_management_pb.GetCommunityRoomUsersNotDownloadingResponse,
    (request: src_modules_access_management_access_management_pb.GetCommunityRoomUsersNotDownloadingRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.GetCommunityRoomUsersNotDownloadingResponse.deserializeBinary
  );

  getCommunityRoomUsersNotDownloading(
    request: src_modules_access_management_access_management_pb.GetCommunityRoomUsersNotDownloadingRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.GetCommunityRoomUsersNotDownloadingResponse>;

  getCommunityRoomUsersNotDownloading(
    request: src_modules_access_management_access_management_pb.GetCommunityRoomUsersNotDownloadingRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetCommunityRoomUsersNotDownloadingResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.GetCommunityRoomUsersNotDownloadingResponse>;

  getCommunityRoomUsersNotDownloading(
    request: src_modules_access_management_access_management_pb.GetCommunityRoomUsersNotDownloadingRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetCommunityRoomUsersNotDownloadingResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/GetCommunityRoomUsersNotDownloading',
        request,
        metadata || {},
        this.methodDescriptorGetCommunityRoomUsersNotDownloading,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/GetCommunityRoomUsersNotDownloading',
    request,
    metadata || {},
    this.methodDescriptorGetCommunityRoomUsersNotDownloading);
  }

  methodDescriptorSubscribeToRoom = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/SubscribeToRoom',
    grpcWeb.MethodType.SERVER_STREAMING,
    src_modules_access_management_access_management_pb.SubscribeToRoomRequest,
    src_modules_access_management_access_management_pb.RoomEvent,
    (request: src_modules_access_management_access_management_pb.SubscribeToRoomRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.RoomEvent.deserializeBinary
  );

  subscribeToRoom(
    request: src_modules_access_management_access_management_pb.SubscribeToRoomRequest,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.RoomEvent> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/access_management.AccessManagement/SubscribeToRoom',
      request,
      metadata || {},
      this.methodDescriptorSubscribeToRoom);
  }

  methodDescriptorPublishRoomEvent = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/PublishRoomEvent',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.PublishRoomEventRequest,
    src_modules_access_management_access_management_pb.PublishRoomEventResponse,
    (request: src_modules_access_management_access_management_pb.PublishRoomEventRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.PublishRoomEventResponse.deserializeBinary
  );

  publishRoomEvent(
    request: src_modules_access_management_access_management_pb.PublishRoomEventRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.PublishRoomEventResponse>;

  publishRoomEvent(
    request: src_modules_access_management_access_management_pb.PublishRoomEventRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.PublishRoomEventResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.PublishRoomEventResponse>;

  publishRoomEvent(
    request: src_modules_access_management_access_management_pb.PublishRoomEventRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.PublishRoomEventResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/PublishRoomEvent',
        request,
        metadata || {},
        this.methodDescriptorPublishRoomEvent,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/PublishRoomEvent',
    request,
    metadata || {},
    this.methodDescriptorPublishRoomEvent);
  }

  methodDescriptorInternalDisconnectionCleanup = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/InternalDisconnectionCleanup',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.InternalDisconnectionCleanupRequest,
    src_modules_access_management_access_management_pb.InternalDisconnectionCleanupResponse,
    (request: src_modules_access_management_access_management_pb.InternalDisconnectionCleanupRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.InternalDisconnectionCleanupResponse.deserializeBinary
  );

  internalDisconnectionCleanup(
    request: src_modules_access_management_access_management_pb.InternalDisconnectionCleanupRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.InternalDisconnectionCleanupResponse>;

  internalDisconnectionCleanup(
    request: src_modules_access_management_access_management_pb.InternalDisconnectionCleanupRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.InternalDisconnectionCleanupResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.InternalDisconnectionCleanupResponse>;

  internalDisconnectionCleanup(
    request: src_modules_access_management_access_management_pb.InternalDisconnectionCleanupRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.InternalDisconnectionCleanupResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/InternalDisconnectionCleanup',
        request,
        metadata || {},
        this.methodDescriptorInternalDisconnectionCleanup,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/InternalDisconnectionCleanup',
    request,
    metadata || {},
    this.methodDescriptorInternalDisconnectionCleanup);
  }

  methodDescriptorPublishConnectedUserEvent = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/PublishConnectedUserEvent',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.PublishConnectedUserEventRequest,
    src_modules_access_management_access_management_pb.PublishConnectedUserEventResponse,
    (request: src_modules_access_management_access_management_pb.PublishConnectedUserEventRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.PublishConnectedUserEventResponse.deserializeBinary
  );

  publishConnectedUserEvent(
    request: src_modules_access_management_access_management_pb.PublishConnectedUserEventRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.PublishConnectedUserEventResponse>;

  publishConnectedUserEvent(
    request: src_modules_access_management_access_management_pb.PublishConnectedUserEventRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.PublishConnectedUserEventResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.PublishConnectedUserEventResponse>;

  publishConnectedUserEvent(
    request: src_modules_access_management_access_management_pb.PublishConnectedUserEventRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.PublishConnectedUserEventResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/PublishConnectedUserEvent',
        request,
        metadata || {},
        this.methodDescriptorPublishConnectedUserEvent,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/PublishConnectedUserEvent',
    request,
    metadata || {},
    this.methodDescriptorPublishConnectedUserEvent);
  }

  methodDescriptorGetBillingInfo = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/GetBillingInfo',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.GetBillingInfoRequest,
    src_modules_access_management_access_management_pb.GetBillingInfoResponse,
    (request: src_modules_access_management_access_management_pb.GetBillingInfoRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.GetBillingInfoResponse.deserializeBinary
  );

  getBillingInfo(
    request: src_modules_access_management_access_management_pb.GetBillingInfoRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.GetBillingInfoResponse>;

  getBillingInfo(
    request: src_modules_access_management_access_management_pb.GetBillingInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetBillingInfoResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.GetBillingInfoResponse>;

  getBillingInfo(
    request: src_modules_access_management_access_management_pb.GetBillingInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetBillingInfoResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/GetBillingInfo',
        request,
        metadata || {},
        this.methodDescriptorGetBillingInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/GetBillingInfo',
    request,
    metadata || {},
    this.methodDescriptorGetBillingInfo);
  }

  methodDescriptorRequestBillingSessionLink = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/RequestBillingSessionLink',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.RequestBillingSessionLinkRequest,
    src_modules_access_management_access_management_pb.RequestBillingSessionLinkResponse,
    (request: src_modules_access_management_access_management_pb.RequestBillingSessionLinkRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.RequestBillingSessionLinkResponse.deserializeBinary
  );

  requestBillingSessionLink(
    request: src_modules_access_management_access_management_pb.RequestBillingSessionLinkRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.RequestBillingSessionLinkResponse>;

  requestBillingSessionLink(
    request: src_modules_access_management_access_management_pb.RequestBillingSessionLinkRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.RequestBillingSessionLinkResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.RequestBillingSessionLinkResponse>;

  requestBillingSessionLink(
    request: src_modules_access_management_access_management_pb.RequestBillingSessionLinkRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.RequestBillingSessionLinkResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/RequestBillingSessionLink',
        request,
        metadata || {},
        this.methodDescriptorRequestBillingSessionLink,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/RequestBillingSessionLink',
    request,
    metadata || {},
    this.methodDescriptorRequestBillingSessionLink);
  }

  methodDescriptorCheckCommunityMAUs = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/CheckCommunityMAUs',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.CheckCommunityMAUsRequest,
    src_modules_access_management_access_management_pb.CheckCommunityMAUsResponse,
    (request: src_modules_access_management_access_management_pb.CheckCommunityMAUsRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.CheckCommunityMAUsResponse.deserializeBinary
  );

  checkCommunityMAUs(
    request: src_modules_access_management_access_management_pb.CheckCommunityMAUsRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.CheckCommunityMAUsResponse>;

  checkCommunityMAUs(
    request: src_modules_access_management_access_management_pb.CheckCommunityMAUsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.CheckCommunityMAUsResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.CheckCommunityMAUsResponse>;

  checkCommunityMAUs(
    request: src_modules_access_management_access_management_pb.CheckCommunityMAUsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.CheckCommunityMAUsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/CheckCommunityMAUs',
        request,
        metadata || {},
        this.methodDescriptorCheckCommunityMAUs,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/CheckCommunityMAUs',
    request,
    metadata || {},
    this.methodDescriptorCheckCommunityMAUs);
  }

  methodDescriptorGetProSubscriptionPlan = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/GetProSubscriptionPlan',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.GetProSubscriptionPlanRequest,
    src_modules_access_management_access_management_pb.GetProSubscriptionPlanResponse,
    (request: src_modules_access_management_access_management_pb.GetProSubscriptionPlanRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.GetProSubscriptionPlanResponse.deserializeBinary
  );

  getProSubscriptionPlan(
    request: src_modules_access_management_access_management_pb.GetProSubscriptionPlanRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.GetProSubscriptionPlanResponse>;

  getProSubscriptionPlan(
    request: src_modules_access_management_access_management_pb.GetProSubscriptionPlanRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetProSubscriptionPlanResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.GetProSubscriptionPlanResponse>;

  getProSubscriptionPlan(
    request: src_modules_access_management_access_management_pb.GetProSubscriptionPlanRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetProSubscriptionPlanResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/GetProSubscriptionPlan',
        request,
        metadata || {},
        this.methodDescriptorGetProSubscriptionPlan,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/GetProSubscriptionPlan',
    request,
    metadata || {},
    this.methodDescriptorGetProSubscriptionPlan);
  }

  methodDescriptorUpdateAvailablePlans = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/UpdateAvailablePlans',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.UpdateAvailablePlansRequest,
    src_modules_access_management_access_management_pb.UpdateAvailablePlansResponse,
    (request: src_modules_access_management_access_management_pb.UpdateAvailablePlansRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.UpdateAvailablePlansResponse.deserializeBinary
  );

  updateAvailablePlans(
    request: src_modules_access_management_access_management_pb.UpdateAvailablePlansRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.UpdateAvailablePlansResponse>;

  updateAvailablePlans(
    request: src_modules_access_management_access_management_pb.UpdateAvailablePlansRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.UpdateAvailablePlansResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.UpdateAvailablePlansResponse>;

  updateAvailablePlans(
    request: src_modules_access_management_access_management_pb.UpdateAvailablePlansRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.UpdateAvailablePlansResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/UpdateAvailablePlans',
        request,
        metadata || {},
        this.methodDescriptorUpdateAvailablePlans,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/UpdateAvailablePlans',
    request,
    metadata || {},
    this.methodDescriptorUpdateAvailablePlans);
  }

  methodDescriptorGetSubscriptionPlans = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/GetSubscriptionPlans',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.GetSubscriptionPlansRequest,
    src_modules_access_management_access_management_pb.GetSubscriptionPlansResponse,
    (request: src_modules_access_management_access_management_pb.GetSubscriptionPlansRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.GetSubscriptionPlansResponse.deserializeBinary
  );

  getSubscriptionPlans(
    request: src_modules_access_management_access_management_pb.GetSubscriptionPlansRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.GetSubscriptionPlansResponse>;

  getSubscriptionPlans(
    request: src_modules_access_management_access_management_pb.GetSubscriptionPlansRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetSubscriptionPlansResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.GetSubscriptionPlansResponse>;

  getSubscriptionPlans(
    request: src_modules_access_management_access_management_pb.GetSubscriptionPlansRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetSubscriptionPlansResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/GetSubscriptionPlans',
        request,
        metadata || {},
        this.methodDescriptorGetSubscriptionPlans,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/GetSubscriptionPlans',
    request,
    metadata || {},
    this.methodDescriptorGetSubscriptionPlans);
  }

  methodDescriptorGetCommunityAvailablePlans = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/GetCommunityAvailablePlans',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.GetCommunityAvailablePlansRequest,
    src_modules_access_management_access_management_pb.GetCommunityAvailablePlansResponse,
    (request: src_modules_access_management_access_management_pb.GetCommunityAvailablePlansRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.GetCommunityAvailablePlansResponse.deserializeBinary
  );

  getCommunityAvailablePlans(
    request: src_modules_access_management_access_management_pb.GetCommunityAvailablePlansRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.GetCommunityAvailablePlansResponse>;

  getCommunityAvailablePlans(
    request: src_modules_access_management_access_management_pb.GetCommunityAvailablePlansRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetCommunityAvailablePlansResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.GetCommunityAvailablePlansResponse>;

  getCommunityAvailablePlans(
    request: src_modules_access_management_access_management_pb.GetCommunityAvailablePlansRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetCommunityAvailablePlansResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/GetCommunityAvailablePlans',
        request,
        metadata || {},
        this.methodDescriptorGetCommunityAvailablePlans,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/GetCommunityAvailablePlans',
    request,
    metadata || {},
    this.methodDescriptorGetCommunityAvailablePlans);
  }

  methodDescriptorCommunityRoomStatusUpdate = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/CommunityRoomStatusUpdate',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.CommunityRoomStatusUpdateRequest,
    src_modules_access_management_access_management_pb.CommunityRoomStatusUpdateResponse,
    (request: src_modules_access_management_access_management_pb.CommunityRoomStatusUpdateRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.CommunityRoomStatusUpdateResponse.deserializeBinary
  );

  communityRoomStatusUpdate(
    request: src_modules_access_management_access_management_pb.CommunityRoomStatusUpdateRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.CommunityRoomStatusUpdateResponse>;

  communityRoomStatusUpdate(
    request: src_modules_access_management_access_management_pb.CommunityRoomStatusUpdateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.CommunityRoomStatusUpdateResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.CommunityRoomStatusUpdateResponse>;

  communityRoomStatusUpdate(
    request: src_modules_access_management_access_management_pb.CommunityRoomStatusUpdateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.CommunityRoomStatusUpdateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/CommunityRoomStatusUpdate',
        request,
        metadata || {},
        this.methodDescriptorCommunityRoomStatusUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/CommunityRoomStatusUpdate',
    request,
    metadata || {},
    this.methodDescriptorCommunityRoomStatusUpdate);
  }

  methodDescriptorInternalDoneDownloadingGame = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/InternalDoneDownloadingGame',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.InternalDoneDownloadingGameRequest,
    src_modules_access_management_access_management_pb.InternalDoneDownloadingGameResponse,
    (request: src_modules_access_management_access_management_pb.InternalDoneDownloadingGameRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.InternalDoneDownloadingGameResponse.deserializeBinary
  );

  internalDoneDownloadingGame(
    request: src_modules_access_management_access_management_pb.InternalDoneDownloadingGameRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.InternalDoneDownloadingGameResponse>;

  internalDoneDownloadingGame(
    request: src_modules_access_management_access_management_pb.InternalDoneDownloadingGameRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.InternalDoneDownloadingGameResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.InternalDoneDownloadingGameResponse>;

  internalDoneDownloadingGame(
    request: src_modules_access_management_access_management_pb.InternalDoneDownloadingGameRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.InternalDoneDownloadingGameResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/InternalDoneDownloadingGame',
        request,
        metadata || {},
        this.methodDescriptorInternalDoneDownloadingGame,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/InternalDoneDownloadingGame',
    request,
    metadata || {},
    this.methodDescriptorInternalDoneDownloadingGame);
  }

  methodDescriptorInternalGetGameDownloadingCancelTime = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/InternalGetGameDownloadingCancelTime',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.InternalGetGameDownloadingCancelTimeRequest,
    src_modules_access_management_access_management_pb.InternalGetGameDownloadingCancelTimeResponse,
    (request: src_modules_access_management_access_management_pb.InternalGetGameDownloadingCancelTimeRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.InternalGetGameDownloadingCancelTimeResponse.deserializeBinary
  );

  internalGetGameDownloadingCancelTime(
    request: src_modules_access_management_access_management_pb.InternalGetGameDownloadingCancelTimeRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.InternalGetGameDownloadingCancelTimeResponse>;

  internalGetGameDownloadingCancelTime(
    request: src_modules_access_management_access_management_pb.InternalGetGameDownloadingCancelTimeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.InternalGetGameDownloadingCancelTimeResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.InternalGetGameDownloadingCancelTimeResponse>;

  internalGetGameDownloadingCancelTime(
    request: src_modules_access_management_access_management_pb.InternalGetGameDownloadingCancelTimeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.InternalGetGameDownloadingCancelTimeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/InternalGetGameDownloadingCancelTime',
        request,
        metadata || {},
        this.methodDescriptorInternalGetGameDownloadingCancelTime,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/InternalGetGameDownloadingCancelTime',
    request,
    metadata || {},
    this.methodDescriptorInternalGetGameDownloadingCancelTime);
  }

  methodDescriptorGetCommunities = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/GetCommunities',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.GetCommunitiesRequest,
    src_modules_access_management_access_management_pb.GetCommunitiesResponse,
    (request: src_modules_access_management_access_management_pb.GetCommunitiesRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.GetCommunitiesResponse.deserializeBinary
  );

  getCommunities(
    request: src_modules_access_management_access_management_pb.GetCommunitiesRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.GetCommunitiesResponse>;

  getCommunities(
    request: src_modules_access_management_access_management_pb.GetCommunitiesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetCommunitiesResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.GetCommunitiesResponse>;

  getCommunities(
    request: src_modules_access_management_access_management_pb.GetCommunitiesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.GetCommunitiesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/GetCommunities',
        request,
        metadata || {},
        this.methodDescriptorGetCommunities,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/GetCommunities',
    request,
    metadata || {},
    this.methodDescriptorGetCommunities);
  }

  methodDescriptorStartSyncCommunityAdmins = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/StartSyncCommunityAdmins',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.StartSyncCommunityAdminsRequest,
    src_modules_access_management_access_management_pb.StartSyncCommunityAdminsResponse,
    (request: src_modules_access_management_access_management_pb.StartSyncCommunityAdminsRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.StartSyncCommunityAdminsResponse.deserializeBinary
  );

  startSyncCommunityAdmins(
    request: src_modules_access_management_access_management_pb.StartSyncCommunityAdminsRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.StartSyncCommunityAdminsResponse>;

  startSyncCommunityAdmins(
    request: src_modules_access_management_access_management_pb.StartSyncCommunityAdminsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.StartSyncCommunityAdminsResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.StartSyncCommunityAdminsResponse>;

  startSyncCommunityAdmins(
    request: src_modules_access_management_access_management_pb.StartSyncCommunityAdminsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.StartSyncCommunityAdminsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/StartSyncCommunityAdmins',
        request,
        metadata || {},
        this.methodDescriptorStartSyncCommunityAdmins,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/StartSyncCommunityAdmins',
    request,
    metadata || {},
    this.methodDescriptorStartSyncCommunityAdmins);
  }

  methodDescriptorStartCreateGamenightTimestamp = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/StartCreateGamenightTimestamp',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.StartCreateGamenightTimestampRequest,
    src_modules_access_management_access_management_pb.StartCreateGamenightTimestampResponse,
    (request: src_modules_access_management_access_management_pb.StartCreateGamenightTimestampRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.StartCreateGamenightTimestampResponse.deserializeBinary
  );

  startCreateGamenightTimestamp(
    request: src_modules_access_management_access_management_pb.StartCreateGamenightTimestampRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.StartCreateGamenightTimestampResponse>;

  startCreateGamenightTimestamp(
    request: src_modules_access_management_access_management_pb.StartCreateGamenightTimestampRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.StartCreateGamenightTimestampResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.StartCreateGamenightTimestampResponse>;

  startCreateGamenightTimestamp(
    request: src_modules_access_management_access_management_pb.StartCreateGamenightTimestampRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.StartCreateGamenightTimestampResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/StartCreateGamenightTimestamp',
        request,
        metadata || {},
        this.methodDescriptorStartCreateGamenightTimestamp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/StartCreateGamenightTimestamp',
    request,
    metadata || {},
    this.methodDescriptorStartCreateGamenightTimestamp);
  }

  methodDescriptorSyncCommunityAdmins = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/SyncCommunityAdmins',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.SyncCommunityAdminsRequest,
    src_modules_access_management_access_management_pb.SyncCommunityAdminsResponse,
    (request: src_modules_access_management_access_management_pb.SyncCommunityAdminsRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.SyncCommunityAdminsResponse.deserializeBinary
  );

  syncCommunityAdmins(
    request: src_modules_access_management_access_management_pb.SyncCommunityAdminsRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.SyncCommunityAdminsResponse>;

  syncCommunityAdmins(
    request: src_modules_access_management_access_management_pb.SyncCommunityAdminsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.SyncCommunityAdminsResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.SyncCommunityAdminsResponse>;

  syncCommunityAdmins(
    request: src_modules_access_management_access_management_pb.SyncCommunityAdminsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.SyncCommunityAdminsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/SyncCommunityAdmins',
        request,
        metadata || {},
        this.methodDescriptorSyncCommunityAdmins,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/SyncCommunityAdmins',
    request,
    metadata || {},
    this.methodDescriptorSyncCommunityAdmins);
  }

  methodDescriptorCreateGamenightTimestamp = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/CreateGamenightTimestamp',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.CreateGamenightTimestampRequest,
    src_modules_access_management_access_management_pb.CreateGamenightTimestampResponse,
    (request: src_modules_access_management_access_management_pb.CreateGamenightTimestampRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.CreateGamenightTimestampResponse.deserializeBinary
  );

  createGamenightTimestamp(
    request: src_modules_access_management_access_management_pb.CreateGamenightTimestampRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.CreateGamenightTimestampResponse>;

  createGamenightTimestamp(
    request: src_modules_access_management_access_management_pb.CreateGamenightTimestampRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.CreateGamenightTimestampResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.CreateGamenightTimestampResponse>;

  createGamenightTimestamp(
    request: src_modules_access_management_access_management_pb.CreateGamenightTimestampRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.CreateGamenightTimestampResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/CreateGamenightTimestamp',
        request,
        metadata || {},
        this.methodDescriptorCreateGamenightTimestamp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/CreateGamenightTimestamp',
    request,
    metadata || {},
    this.methodDescriptorCreateGamenightTimestamp);
  }

  methodDescriptorInternalReportUserState = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/InternalReportUserState',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.InternalReportUserStateRequest,
    src_modules_access_management_access_management_pb.InternalReportUserStateResponse,
    (request: src_modules_access_management_access_management_pb.InternalReportUserStateRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.InternalReportUserStateResponse.deserializeBinary
  );

  internalReportUserState(
    request: src_modules_access_management_access_management_pb.InternalReportUserStateRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.InternalReportUserStateResponse>;

  internalReportUserState(
    request: src_modules_access_management_access_management_pb.InternalReportUserStateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.InternalReportUserStateResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.InternalReportUserStateResponse>;

  internalReportUserState(
    request: src_modules_access_management_access_management_pb.InternalReportUserStateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.InternalReportUserStateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/InternalReportUserState',
        request,
        metadata || {},
        this.methodDescriptorInternalReportUserState,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/InternalReportUserState',
    request,
    metadata || {},
    this.methodDescriptorInternalReportUserState);
  }

  methodDescriptorInternalReportUserStates = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/InternalReportUserStates',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.InternalReportUserStatesRequest,
    src_modules_access_management_access_management_pb.InternalReportUserStatesResponse,
    (request: src_modules_access_management_access_management_pb.InternalReportUserStatesRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.InternalReportUserStatesResponse.deserializeBinary
  );

  internalReportUserStates(
    request: src_modules_access_management_access_management_pb.InternalReportUserStatesRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.InternalReportUserStatesResponse>;

  internalReportUserStates(
    request: src_modules_access_management_access_management_pb.InternalReportUserStatesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.InternalReportUserStatesResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.InternalReportUserStatesResponse>;

  internalReportUserStates(
    request: src_modules_access_management_access_management_pb.InternalReportUserStatesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.InternalReportUserStatesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/InternalReportUserStates',
        request,
        metadata || {},
        this.methodDescriptorInternalReportUserStates,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/InternalReportUserStates',
    request,
    metadata || {},
    this.methodDescriptorInternalReportUserStates);
  }

  methodDescriptorInternalGetIps = new grpcWeb.MethodDescriptor(
    '/access_management.AccessManagement/InternalGetIps',
    grpcWeb.MethodType.UNARY,
    src_modules_access_management_access_management_pb.InternalGetIpsRequest,
    src_modules_access_management_access_management_pb.InternalGetIpsResponse,
    (request: src_modules_access_management_access_management_pb.InternalGetIpsRequest) => {
      return request.serializeBinary();
    },
    src_modules_access_management_access_management_pb.InternalGetIpsResponse.deserializeBinary
  );

  internalGetIps(
    request: src_modules_access_management_access_management_pb.InternalGetIpsRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_modules_access_management_access_management_pb.InternalGetIpsResponse>;

  internalGetIps(
    request: src_modules_access_management_access_management_pb.InternalGetIpsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.InternalGetIpsResponse) => void): grpcWeb.ClientReadableStream<src_modules_access_management_access_management_pb.InternalGetIpsResponse>;

  internalGetIps(
    request: src_modules_access_management_access_management_pb.InternalGetIpsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_modules_access_management_access_management_pb.InternalGetIpsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/access_management.AccessManagement/InternalGetIps',
        request,
        metadata || {},
        this.methodDescriptorInternalGetIps,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/access_management.AccessManagement/InternalGetIps',
    request,
    metadata || {},
    this.methodDescriptorInternalGetIps);
  }

}

