import {
  CommunityGate as CommunityGateRPC,
  DomainGate,
  Frequency,
  InviteGate,
  OpenGate,
  Weekday,
} from '@ig/platform_sdk_web/src/modules/access_management/access_management_pb';
import dayjs from 'dayjs';
import { CommunityGate, GNDay, GNFrequency, GameNightSchedule } from '~/types/AccessManagement';

export const extractGateFromGatingList = (
  gatesList: Array<CommunityGateRPC.AsObject>
): CommunityGate => {
  let extractedGate: CommunityGate = {
    type: 'open',
    value: '',
  };

  if (gatesList.length > 0) {
    const firstGateInList = gatesList[0];

    if (firstGateInList.domainGate) {
      extractedGate = {
        type: 'domain',
        value: firstGateInList.domainGate.domain,
      };
    } else if (firstGateInList.inviteGate) {
      extractedGate = {
        type: 'invite',
        value: '',
      };
    } else {
      extractedGate = {
        type: 'open',
        value: '',
      };
    }
  }

  return extractedGate;
};

export const toCommunityGatesArray = (gate: CommunityGate): Array<CommunityGateRPC.AsObject> => {
  const gates: Array<CommunityGateRPC.AsObject> = [];
  switch (gate.type) {
    case 'domain':
      gates.push({
        domainGate: {
          domain: gate.value,
          group: '',
        },
      });
      break;
    case 'invite':
      gates.push({
        inviteGate: {
          group: '',
        },
      });
      break;
    case 'open':
      gates.push({
        openGate: {
          group: '',
        },
      });
      break;
    default:
      break;
  }

  return gates;
};

export const toRPCGatesParam = (
  gatesList: Array<CommunityGateRPC.AsObject>
): Array<CommunityGateRPC> =>
  gatesList.map((gateAsObject) => {
    const gate = new CommunityGateRPC();

    if (gateAsObject.domainGate) {
      const domainGate = new DomainGate();
      domainGate.setDomain(gateAsObject.domainGate.domain);
      gate.setDomainGate(domainGate);
    } else if (gateAsObject.inviteGate) {
      const inviteGate = new InviteGate();
      gate.setInviteGate(inviteGate);
    } else {
      const openGate = new OpenGate();
      gate.setOpenGate(openGate);
    }

    return gate;
  });

export const toGameNightAsObject = (gameNight: GameNightSchedule) => {
  const { frequency, day, startingAt } = gameNight;
  const gameNightTime = dayjs.utc(startingAt);

  const f = {
    week: Frequency.WEEK,
    fortnight: Frequency.FORTNIGHT,
    month: Frequency.MONTH,
    once: Frequency.ONCE,
  };

  const d = {
    sunday: Weekday.SUNDAY,
    monday: Weekday.MONDAY,
    tuesday: Weekday.TUESDAY,
    wednesday: Weekday.WEDNESDAY,
    thursday: Weekday.THURSDAY,
    friday: Weekday.FRIDAY,
    saturday: Weekday.SATURDAY,
  };

  return {
    frequency: f[frequency],
    weekday: d[day],
    hour: gameNightTime.hour(),
    minute: gameNightTime.minute(),
    timestamp: startingAt,
  };
};

export const fromGameNightObject = (gameNight: any): GameNightSchedule => {
  const frequencyValues = Object.keys(Frequency);
  const weekdayValues = Object.keys(Weekday);
  return {
    frequency: frequencyValues[gameNight.frequency].toLowerCase() as GNFrequency,
    day: weekdayValues[gameNight.weekday].toLowerCase() as GNDay,
    startingAt: gameNight.timestamp,
  };
};
