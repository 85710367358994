// source: src/modules/team_selection/team_selection.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var validate_validate_pb = require('../../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.team_selection.AdjustType', null, global);
goog.exportSymbol('proto.team_selection.GetTeamConfigRequest', null, global);
goog.exportSymbol('proto.team_selection.GetTeamConfigResponse', null, global);
goog.exportSymbol('proto.team_selection.InternalAddNounsAndAdjectivesRequest', null, global);
goog.exportSymbol('proto.team_selection.InternalAddNounsAndAdjectivesResponse', null, global);
goog.exportSymbol('proto.team_selection.InternalAdjustRequest', null, global);
goog.exportSymbol('proto.team_selection.InternalAdjustResponse', null, global);
goog.exportSymbol('proto.team_selection.InternalGetOrCreateTeamRequest', null, global);
goog.exportSymbol('proto.team_selection.InternalGetOrCreateTeamResponse', null, global);
goog.exportSymbol('proto.team_selection.InternalGetTeamByPlayerIdRequest', null, global);
goog.exportSymbol('proto.team_selection.InternalGetTeamByPlayerIdResponse', null, global);
goog.exportSymbol('proto.team_selection.InternalUpdateTeamConfigRequest', null, global);
goog.exportSymbol('proto.team_selection.InternalUpdateTeamConfigResponse', null, global);
goog.exportSymbol('proto.team_selection.MovePlayerRequest', null, global);
goog.exportSymbol('proto.team_selection.MovePlayerResponse', null, global);
goog.exportSymbol('proto.team_selection.SelectionMode', null, global);
goog.exportSymbol('proto.team_selection.ShuffleRequest', null, global);
goog.exportSymbol('proto.team_selection.ShuffleResponse', null, global);
goog.exportSymbol('proto.team_selection.Team', null, global);
goog.exportSymbol('proto.team_selection.UpdateGameCountRequest', null, global);
goog.exportSymbol('proto.team_selection.UpdateGameCountResponse', null, global);
goog.exportSymbol('proto.team_selection.UpdateTeamCountRequest', null, global);
goog.exportSymbol('proto.team_selection.UpdateTeamCountResponse', null, global);
goog.exportSymbol('proto.team_selection.UpdateTeamSelectionModeRequest', null, global);
goog.exportSymbol('proto.team_selection.UpdateTeamSelectionModeResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.team_selection.Team = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.team_selection.Team.repeatedFields_, null);
};
goog.inherits(proto.team_selection.Team, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.team_selection.Team.displayName = 'proto.team_selection.Team';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.team_selection.ShuffleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.team_selection.ShuffleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.team_selection.ShuffleRequest.displayName = 'proto.team_selection.ShuffleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.team_selection.ShuffleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.team_selection.ShuffleResponse.repeatedFields_, null);
};
goog.inherits(proto.team_selection.ShuffleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.team_selection.ShuffleResponse.displayName = 'proto.team_selection.ShuffleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.team_selection.MovePlayerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.team_selection.MovePlayerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.team_selection.MovePlayerRequest.displayName = 'proto.team_selection.MovePlayerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.team_selection.MovePlayerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.team_selection.MovePlayerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.team_selection.MovePlayerResponse.displayName = 'proto.team_selection.MovePlayerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.team_selection.UpdateGameCountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.team_selection.UpdateGameCountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.team_selection.UpdateGameCountRequest.displayName = 'proto.team_selection.UpdateGameCountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.team_selection.UpdateGameCountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.team_selection.UpdateGameCountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.team_selection.UpdateGameCountResponse.displayName = 'proto.team_selection.UpdateGameCountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.team_selection.UpdateTeamCountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.team_selection.UpdateTeamCountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.team_selection.UpdateTeamCountRequest.displayName = 'proto.team_selection.UpdateTeamCountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.team_selection.UpdateTeamCountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.team_selection.UpdateTeamCountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.team_selection.UpdateTeamCountResponse.displayName = 'proto.team_selection.UpdateTeamCountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.team_selection.GetTeamConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.team_selection.GetTeamConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.team_selection.GetTeamConfigRequest.displayName = 'proto.team_selection.GetTeamConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.team_selection.GetTeamConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.team_selection.GetTeamConfigResponse.repeatedFields_, null);
};
goog.inherits(proto.team_selection.GetTeamConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.team_selection.GetTeamConfigResponse.displayName = 'proto.team_selection.GetTeamConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.team_selection.UpdateTeamSelectionModeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.team_selection.UpdateTeamSelectionModeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.team_selection.UpdateTeamSelectionModeRequest.displayName = 'proto.team_selection.UpdateTeamSelectionModeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.team_selection.UpdateTeamSelectionModeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.team_selection.UpdateTeamSelectionModeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.team_selection.UpdateTeamSelectionModeResponse.displayName = 'proto.team_selection.UpdateTeamSelectionModeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.team_selection.InternalAdjustRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.team_selection.InternalAdjustRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.team_selection.InternalAdjustRequest.displayName = 'proto.team_selection.InternalAdjustRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.team_selection.InternalAdjustResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.team_selection.InternalAdjustResponse.repeatedFields_, null);
};
goog.inherits(proto.team_selection.InternalAdjustResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.team_selection.InternalAdjustResponse.displayName = 'proto.team_selection.InternalAdjustResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.team_selection.InternalGetTeamByPlayerIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.team_selection.InternalGetTeamByPlayerIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.team_selection.InternalGetTeamByPlayerIdRequest.displayName = 'proto.team_selection.InternalGetTeamByPlayerIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.team_selection.InternalGetTeamByPlayerIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.team_selection.InternalGetTeamByPlayerIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.team_selection.InternalGetTeamByPlayerIdResponse.displayName = 'proto.team_selection.InternalGetTeamByPlayerIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.team_selection.InternalAddNounsAndAdjectivesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.team_selection.InternalAddNounsAndAdjectivesRequest.repeatedFields_, null);
};
goog.inherits(proto.team_selection.InternalAddNounsAndAdjectivesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.team_selection.InternalAddNounsAndAdjectivesRequest.displayName = 'proto.team_selection.InternalAddNounsAndAdjectivesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.team_selection.InternalAddNounsAndAdjectivesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.team_selection.InternalAddNounsAndAdjectivesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.team_selection.InternalAddNounsAndAdjectivesResponse.displayName = 'proto.team_selection.InternalAddNounsAndAdjectivesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.team_selection.InternalGetOrCreateTeamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.team_selection.InternalGetOrCreateTeamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.team_selection.InternalGetOrCreateTeamRequest.displayName = 'proto.team_selection.InternalGetOrCreateTeamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.team_selection.InternalGetOrCreateTeamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.team_selection.InternalGetOrCreateTeamResponse.repeatedFields_, null);
};
goog.inherits(proto.team_selection.InternalGetOrCreateTeamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.team_selection.InternalGetOrCreateTeamResponse.displayName = 'proto.team_selection.InternalGetOrCreateTeamResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.team_selection.InternalUpdateTeamConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.team_selection.InternalUpdateTeamConfigRequest.repeatedFields_, null);
};
goog.inherits(proto.team_selection.InternalUpdateTeamConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.team_selection.InternalUpdateTeamConfigRequest.displayName = 'proto.team_selection.InternalUpdateTeamConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.team_selection.InternalUpdateTeamConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.team_selection.InternalUpdateTeamConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.team_selection.InternalUpdateTeamConfigResponse.displayName = 'proto.team_selection.InternalUpdateTeamConfigResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.team_selection.Team.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.team_selection.Team.prototype.toObject = function(opt_includeInstance) {
  return proto.team_selection.Team.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.team_selection.Team} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.Team.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    playersList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    matchId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.team_selection.Team}
 */
proto.team_selection.Team.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.team_selection.Team;
  return proto.team_selection.Team.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.team_selection.Team} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.team_selection.Team}
 */
proto.team_selection.Team.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addPlayers(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatchId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.team_selection.Team.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.team_selection.Team.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.team_selection.Team} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.Team.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPlayersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getMatchId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.team_selection.Team.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.team_selection.Team} returns this
 */
proto.team_selection.Team.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.team_selection.Team.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.team_selection.Team} returns this
 */
proto.team_selection.Team.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string group_id = 3;
 * @return {string}
 */
proto.team_selection.Team.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.team_selection.Team} returns this
 */
proto.team_selection.Team.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string players = 4;
 * @return {!Array<string>}
 */
proto.team_selection.Team.prototype.getPlayersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.team_selection.Team} returns this
 */
proto.team_selection.Team.prototype.setPlayersList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.team_selection.Team} returns this
 */
proto.team_selection.Team.prototype.addPlayers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.team_selection.Team} returns this
 */
proto.team_selection.Team.prototype.clearPlayersList = function() {
  return this.setPlayersList([]);
};


/**
 * optional string match_id = 5;
 * @return {string}
 */
proto.team_selection.Team.prototype.getMatchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.team_selection.Team} returns this
 */
proto.team_selection.Team.prototype.setMatchId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.team_selection.ShuffleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.team_selection.ShuffleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.team_selection.ShuffleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.ShuffleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.team_selection.ShuffleRequest}
 */
proto.team_selection.ShuffleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.team_selection.ShuffleRequest;
  return proto.team_selection.ShuffleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.team_selection.ShuffleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.team_selection.ShuffleRequest}
 */
proto.team_selection.ShuffleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.team_selection.ShuffleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.team_selection.ShuffleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.team_selection.ShuffleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.ShuffleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string external_id = 1;
 * @return {string}
 */
proto.team_selection.ShuffleRequest.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.team_selection.ShuffleRequest} returns this
 */
proto.team_selection.ShuffleRequest.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.team_selection.ShuffleResponse.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.team_selection.ShuffleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.team_selection.ShuffleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.team_selection.ShuffleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.ShuffleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    teamsList: jspb.Message.toObjectList(msg.getTeamsList(),
    proto.team_selection.Team.toObject, includeInstance),
    waitingList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.team_selection.ShuffleResponse}
 */
proto.team_selection.ShuffleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.team_selection.ShuffleResponse;
  return proto.team_selection.ShuffleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.team_selection.ShuffleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.team_selection.ShuffleResponse}
 */
proto.team_selection.ShuffleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 2:
      var value = new proto.team_selection.Team;
      reader.readMessage(value,proto.team_selection.Team.deserializeBinaryFromReader);
      msg.addTeams(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addWaiting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.team_selection.ShuffleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.team_selection.ShuffleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.team_selection.ShuffleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.ShuffleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTeamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.team_selection.Team.serializeBinaryToWriter
    );
  }
  f = message.getWaitingList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string external_id = 1;
 * @return {string}
 */
proto.team_selection.ShuffleResponse.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.team_selection.ShuffleResponse} returns this
 */
proto.team_selection.ShuffleResponse.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Team teams = 2;
 * @return {!Array<!proto.team_selection.Team>}
 */
proto.team_selection.ShuffleResponse.prototype.getTeamsList = function() {
  return /** @type{!Array<!proto.team_selection.Team>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.team_selection.Team, 2));
};


/**
 * @param {!Array<!proto.team_selection.Team>} value
 * @return {!proto.team_selection.ShuffleResponse} returns this
*/
proto.team_selection.ShuffleResponse.prototype.setTeamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.team_selection.Team=} opt_value
 * @param {number=} opt_index
 * @return {!proto.team_selection.Team}
 */
proto.team_selection.ShuffleResponse.prototype.addTeams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.team_selection.Team, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.team_selection.ShuffleResponse} returns this
 */
proto.team_selection.ShuffleResponse.prototype.clearTeamsList = function() {
  return this.setTeamsList([]);
};


/**
 * repeated string waiting = 3;
 * @return {!Array<string>}
 */
proto.team_selection.ShuffleResponse.prototype.getWaitingList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.team_selection.ShuffleResponse} returns this
 */
proto.team_selection.ShuffleResponse.prototype.setWaitingList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.team_selection.ShuffleResponse} returns this
 */
proto.team_selection.ShuffleResponse.prototype.addWaiting = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.team_selection.ShuffleResponse} returns this
 */
proto.team_selection.ShuffleResponse.prototype.clearWaitingList = function() {
  return this.setWaitingList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.team_selection.MovePlayerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.team_selection.MovePlayerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.team_selection.MovePlayerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.MovePlayerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    from: jspb.Message.getFieldWithDefault(msg, 2, ""),
    to: jspb.Message.getFieldWithDefault(msg, 3, ""),
    playerId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.team_selection.MovePlayerRequest}
 */
proto.team_selection.MovePlayerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.team_selection.MovePlayerRequest;
  return proto.team_selection.MovePlayerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.team_selection.MovePlayerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.team_selection.MovePlayerRequest}
 */
proto.team_selection.MovePlayerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrom(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.team_selection.MovePlayerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.team_selection.MovePlayerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.team_selection.MovePlayerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.MovePlayerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFrom();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPlayerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string external_id = 1;
 * @return {string}
 */
proto.team_selection.MovePlayerRequest.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.team_selection.MovePlayerRequest} returns this
 */
proto.team_selection.MovePlayerRequest.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string from = 2;
 * @return {string}
 */
proto.team_selection.MovePlayerRequest.prototype.getFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.team_selection.MovePlayerRequest} returns this
 */
proto.team_selection.MovePlayerRequest.prototype.setFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string to = 3;
 * @return {string}
 */
proto.team_selection.MovePlayerRequest.prototype.getTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.team_selection.MovePlayerRequest} returns this
 */
proto.team_selection.MovePlayerRequest.prototype.setTo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string player_id = 4;
 * @return {string}
 */
proto.team_selection.MovePlayerRequest.prototype.getPlayerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.team_selection.MovePlayerRequest} returns this
 */
proto.team_selection.MovePlayerRequest.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.team_selection.MovePlayerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.team_selection.MovePlayerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.team_selection.MovePlayerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.MovePlayerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.team_selection.MovePlayerResponse}
 */
proto.team_selection.MovePlayerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.team_selection.MovePlayerResponse;
  return proto.team_selection.MovePlayerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.team_selection.MovePlayerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.team_selection.MovePlayerResponse}
 */
proto.team_selection.MovePlayerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.team_selection.MovePlayerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.team_selection.MovePlayerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.team_selection.MovePlayerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.MovePlayerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.team_selection.UpdateGameCountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.team_selection.UpdateGameCountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.team_selection.UpdateGameCountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.UpdateGameCountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    games: jspb.Message.getFieldWithDefault(msg, 2, 0),
    count: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.team_selection.UpdateGameCountRequest}
 */
proto.team_selection.UpdateGameCountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.team_selection.UpdateGameCountRequest;
  return proto.team_selection.UpdateGameCountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.team_selection.UpdateGameCountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.team_selection.UpdateGameCountRequest}
 */
proto.team_selection.UpdateGameCountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGames(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.team_selection.UpdateGameCountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.team_selection.UpdateGameCountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.team_selection.UpdateGameCountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.UpdateGameCountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGames();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string external_id = 1;
 * @return {string}
 */
proto.team_selection.UpdateGameCountRequest.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.team_selection.UpdateGameCountRequest} returns this
 */
proto.team_selection.UpdateGameCountRequest.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 games = 2;
 * @return {number}
 */
proto.team_selection.UpdateGameCountRequest.prototype.getGames = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.team_selection.UpdateGameCountRequest} returns this
 */
proto.team_selection.UpdateGameCountRequest.prototype.setGames = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 count = 3;
 * @return {number}
 */
proto.team_selection.UpdateGameCountRequest.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.team_selection.UpdateGameCountRequest} returns this
 */
proto.team_selection.UpdateGameCountRequest.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.team_selection.UpdateGameCountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.team_selection.UpdateGameCountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.team_selection.UpdateGameCountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.UpdateGameCountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    gamecount: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.team_selection.UpdateGameCountResponse}
 */
proto.team_selection.UpdateGameCountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.team_selection.UpdateGameCountResponse;
  return proto.team_selection.UpdateGameCountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.team_selection.UpdateGameCountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.team_selection.UpdateGameCountResponse}
 */
proto.team_selection.UpdateGameCountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGamecount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.team_selection.UpdateGameCountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.team_selection.UpdateGameCountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.team_selection.UpdateGameCountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.UpdateGameCountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGamecount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 gameCount = 1;
 * @return {number}
 */
proto.team_selection.UpdateGameCountResponse.prototype.getGamecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.team_selection.UpdateGameCountResponse} returns this
 */
proto.team_selection.UpdateGameCountResponse.prototype.setGamecount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.team_selection.UpdateTeamCountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.team_selection.UpdateTeamCountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.team_selection.UpdateTeamCountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.UpdateTeamCountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    teams: jspb.Message.getFieldWithDefault(msg, 3, 0),
    count: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.team_selection.UpdateTeamCountRequest}
 */
proto.team_selection.UpdateTeamCountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.team_selection.UpdateTeamCountRequest;
  return proto.team_selection.UpdateTeamCountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.team_selection.UpdateTeamCountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.team_selection.UpdateTeamCountRequest}
 */
proto.team_selection.UpdateTeamCountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTeams(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.team_selection.UpdateTeamCountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.team_selection.UpdateTeamCountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.team_selection.UpdateTeamCountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.UpdateTeamCountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTeams();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string external_id = 1;
 * @return {string}
 */
proto.team_selection.UpdateTeamCountRequest.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.team_selection.UpdateTeamCountRequest} returns this
 */
proto.team_selection.UpdateTeamCountRequest.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string group_id = 2;
 * @return {string}
 */
proto.team_selection.UpdateTeamCountRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.team_selection.UpdateTeamCountRequest} returns this
 */
proto.team_selection.UpdateTeamCountRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 teams = 3;
 * @return {number}
 */
proto.team_selection.UpdateTeamCountRequest.prototype.getTeams = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.team_selection.UpdateTeamCountRequest} returns this
 */
proto.team_selection.UpdateTeamCountRequest.prototype.setTeams = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 count = 4;
 * @return {number}
 */
proto.team_selection.UpdateTeamCountRequest.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.team_selection.UpdateTeamCountRequest} returns this
 */
proto.team_selection.UpdateTeamCountRequest.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.team_selection.UpdateTeamCountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.team_selection.UpdateTeamCountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.team_selection.UpdateTeamCountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.UpdateTeamCountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.team_selection.UpdateTeamCountResponse}
 */
proto.team_selection.UpdateTeamCountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.team_selection.UpdateTeamCountResponse;
  return proto.team_selection.UpdateTeamCountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.team_selection.UpdateTeamCountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.team_selection.UpdateTeamCountResponse}
 */
proto.team_selection.UpdateTeamCountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.team_selection.UpdateTeamCountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.team_selection.UpdateTeamCountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.team_selection.UpdateTeamCountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.UpdateTeamCountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.team_selection.GetTeamConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.team_selection.GetTeamConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.team_selection.GetTeamConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.GetTeamConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.team_selection.GetTeamConfigRequest}
 */
proto.team_selection.GetTeamConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.team_selection.GetTeamConfigRequest;
  return proto.team_selection.GetTeamConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.team_selection.GetTeamConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.team_selection.GetTeamConfigRequest}
 */
proto.team_selection.GetTeamConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.team_selection.GetTeamConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.team_selection.GetTeamConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.team_selection.GetTeamConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.GetTeamConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string external_id = 1;
 * @return {string}
 */
proto.team_selection.GetTeamConfigRequest.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.team_selection.GetTeamConfigRequest} returns this
 */
proto.team_selection.GetTeamConfigRequest.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.team_selection.GetTeamConfigResponse.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.team_selection.GetTeamConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.team_selection.GetTeamConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.team_selection.GetTeamConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.GetTeamConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalD: jspb.Message.getFieldWithDefault(msg, 1, ""),
    teamsList: jspb.Message.toObjectList(msg.getTeamsList(),
    proto.team_selection.Team.toObject, includeInstance),
    waitingList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    selectionMode: jspb.Message.getFieldWithDefault(msg, 4, 0),
    maxTeamSize: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.team_selection.GetTeamConfigResponse}
 */
proto.team_selection.GetTeamConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.team_selection.GetTeamConfigResponse;
  return proto.team_selection.GetTeamConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.team_selection.GetTeamConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.team_selection.GetTeamConfigResponse}
 */
proto.team_selection.GetTeamConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalD(value);
      break;
    case 2:
      var value = new proto.team_selection.Team;
      reader.readMessage(value,proto.team_selection.Team.deserializeBinaryFromReader);
      msg.addTeams(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addWaiting(value);
      break;
    case 4:
      var value = /** @type {!proto.team_selection.SelectionMode} */ (reader.readEnum());
      msg.setSelectionMode(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxTeamSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.team_selection.GetTeamConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.team_selection.GetTeamConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.team_selection.GetTeamConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.GetTeamConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalD();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTeamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.team_selection.Team.serializeBinaryToWriter
    );
  }
  f = message.getWaitingList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getSelectionMode();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getMaxTeamSize();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string external_d = 1;
 * @return {string}
 */
proto.team_selection.GetTeamConfigResponse.prototype.getExternalD = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.team_selection.GetTeamConfigResponse} returns this
 */
proto.team_selection.GetTeamConfigResponse.prototype.setExternalD = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Team teams = 2;
 * @return {!Array<!proto.team_selection.Team>}
 */
proto.team_selection.GetTeamConfigResponse.prototype.getTeamsList = function() {
  return /** @type{!Array<!proto.team_selection.Team>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.team_selection.Team, 2));
};


/**
 * @param {!Array<!proto.team_selection.Team>} value
 * @return {!proto.team_selection.GetTeamConfigResponse} returns this
*/
proto.team_selection.GetTeamConfigResponse.prototype.setTeamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.team_selection.Team=} opt_value
 * @param {number=} opt_index
 * @return {!proto.team_selection.Team}
 */
proto.team_selection.GetTeamConfigResponse.prototype.addTeams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.team_selection.Team, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.team_selection.GetTeamConfigResponse} returns this
 */
proto.team_selection.GetTeamConfigResponse.prototype.clearTeamsList = function() {
  return this.setTeamsList([]);
};


/**
 * repeated string waiting = 3;
 * @return {!Array<string>}
 */
proto.team_selection.GetTeamConfigResponse.prototype.getWaitingList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.team_selection.GetTeamConfigResponse} returns this
 */
proto.team_selection.GetTeamConfigResponse.prototype.setWaitingList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.team_selection.GetTeamConfigResponse} returns this
 */
proto.team_selection.GetTeamConfigResponse.prototype.addWaiting = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.team_selection.GetTeamConfigResponse} returns this
 */
proto.team_selection.GetTeamConfigResponse.prototype.clearWaitingList = function() {
  return this.setWaitingList([]);
};


/**
 * optional SelectionMode selection_mode = 4;
 * @return {!proto.team_selection.SelectionMode}
 */
proto.team_selection.GetTeamConfigResponse.prototype.getSelectionMode = function() {
  return /** @type {!proto.team_selection.SelectionMode} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.team_selection.SelectionMode} value
 * @return {!proto.team_selection.GetTeamConfigResponse} returns this
 */
proto.team_selection.GetTeamConfigResponse.prototype.setSelectionMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int32 max_team_size = 5;
 * @return {number}
 */
proto.team_selection.GetTeamConfigResponse.prototype.getMaxTeamSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.team_selection.GetTeamConfigResponse} returns this
 */
proto.team_selection.GetTeamConfigResponse.prototype.setMaxTeamSize = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.team_selection.UpdateTeamSelectionModeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.team_selection.UpdateTeamSelectionModeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.team_selection.UpdateTeamSelectionModeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.UpdateTeamSelectionModeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    selectionMode: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.team_selection.UpdateTeamSelectionModeRequest}
 */
proto.team_selection.UpdateTeamSelectionModeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.team_selection.UpdateTeamSelectionModeRequest;
  return proto.team_selection.UpdateTeamSelectionModeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.team_selection.UpdateTeamSelectionModeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.team_selection.UpdateTeamSelectionModeRequest}
 */
proto.team_selection.UpdateTeamSelectionModeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 2:
      var value = /** @type {!proto.team_selection.SelectionMode} */ (reader.readEnum());
      msg.setSelectionMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.team_selection.UpdateTeamSelectionModeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.team_selection.UpdateTeamSelectionModeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.team_selection.UpdateTeamSelectionModeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.UpdateTeamSelectionModeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSelectionMode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string external_id = 1;
 * @return {string}
 */
proto.team_selection.UpdateTeamSelectionModeRequest.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.team_selection.UpdateTeamSelectionModeRequest} returns this
 */
proto.team_selection.UpdateTeamSelectionModeRequest.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SelectionMode selection_mode = 2;
 * @return {!proto.team_selection.SelectionMode}
 */
proto.team_selection.UpdateTeamSelectionModeRequest.prototype.getSelectionMode = function() {
  return /** @type {!proto.team_selection.SelectionMode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.team_selection.SelectionMode} value
 * @return {!proto.team_selection.UpdateTeamSelectionModeRequest} returns this
 */
proto.team_selection.UpdateTeamSelectionModeRequest.prototype.setSelectionMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.team_selection.UpdateTeamSelectionModeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.team_selection.UpdateTeamSelectionModeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.team_selection.UpdateTeamSelectionModeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.UpdateTeamSelectionModeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    selectionMode: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.team_selection.UpdateTeamSelectionModeResponse}
 */
proto.team_selection.UpdateTeamSelectionModeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.team_selection.UpdateTeamSelectionModeResponse;
  return proto.team_selection.UpdateTeamSelectionModeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.team_selection.UpdateTeamSelectionModeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.team_selection.UpdateTeamSelectionModeResponse}
 */
proto.team_selection.UpdateTeamSelectionModeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.team_selection.SelectionMode} */ (reader.readEnum());
      msg.setSelectionMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.team_selection.UpdateTeamSelectionModeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.team_selection.UpdateTeamSelectionModeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.team_selection.UpdateTeamSelectionModeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.UpdateTeamSelectionModeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelectionMode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional SelectionMode selection_mode = 1;
 * @return {!proto.team_selection.SelectionMode}
 */
proto.team_selection.UpdateTeamSelectionModeResponse.prototype.getSelectionMode = function() {
  return /** @type {!proto.team_selection.SelectionMode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.team_selection.SelectionMode} value
 * @return {!proto.team_selection.UpdateTeamSelectionModeResponse} returns this
 */
proto.team_selection.UpdateTeamSelectionModeResponse.prototype.setSelectionMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.team_selection.InternalAdjustRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.team_selection.InternalAdjustRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.team_selection.InternalAdjustRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.InternalAdjustRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adjustType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    playerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    gameTag: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.team_selection.InternalAdjustRequest}
 */
proto.team_selection.InternalAdjustRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.team_selection.InternalAdjustRequest;
  return proto.team_selection.InternalAdjustRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.team_selection.InternalAdjustRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.team_selection.InternalAdjustRequest}
 */
proto.team_selection.InternalAdjustRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 2:
      var value = /** @type {!proto.team_selection.AdjustType} */ (reader.readEnum());
      msg.setAdjustType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameTag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.team_selection.InternalAdjustRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.team_selection.InternalAdjustRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.team_selection.InternalAdjustRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.InternalAdjustRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdjustType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPlayerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGameTag();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string external_id = 1;
 * @return {string}
 */
proto.team_selection.InternalAdjustRequest.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.team_selection.InternalAdjustRequest} returns this
 */
proto.team_selection.InternalAdjustRequest.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AdjustType adjust_type = 2;
 * @return {!proto.team_selection.AdjustType}
 */
proto.team_selection.InternalAdjustRequest.prototype.getAdjustType = function() {
  return /** @type {!proto.team_selection.AdjustType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.team_selection.AdjustType} value
 * @return {!proto.team_selection.InternalAdjustRequest} returns this
 */
proto.team_selection.InternalAdjustRequest.prototype.setAdjustType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string player_id = 3;
 * @return {string}
 */
proto.team_selection.InternalAdjustRequest.prototype.getPlayerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.team_selection.InternalAdjustRequest} returns this
 */
proto.team_selection.InternalAdjustRequest.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string game_tag = 4;
 * @return {string}
 */
proto.team_selection.InternalAdjustRequest.prototype.getGameTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.team_selection.InternalAdjustRequest} returns this
 */
proto.team_selection.InternalAdjustRequest.prototype.setGameTag = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.team_selection.InternalAdjustResponse.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.team_selection.InternalAdjustResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.team_selection.InternalAdjustResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.team_selection.InternalAdjustResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.InternalAdjustResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalD: jspb.Message.getFieldWithDefault(msg, 1, ""),
    teamsList: jspb.Message.toObjectList(msg.getTeamsList(),
    proto.team_selection.Team.toObject, includeInstance),
    waitingList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    selectionMode: jspb.Message.getFieldWithDefault(msg, 4, 0),
    maxTeamSize: jspb.Message.getFieldWithDefault(msg, 5, 0),
    maxTeamCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    minTeamCount: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.team_selection.InternalAdjustResponse}
 */
proto.team_selection.InternalAdjustResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.team_selection.InternalAdjustResponse;
  return proto.team_selection.InternalAdjustResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.team_selection.InternalAdjustResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.team_selection.InternalAdjustResponse}
 */
proto.team_selection.InternalAdjustResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalD(value);
      break;
    case 2:
      var value = new proto.team_selection.Team;
      reader.readMessage(value,proto.team_selection.Team.deserializeBinaryFromReader);
      msg.addTeams(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addWaiting(value);
      break;
    case 4:
      var value = /** @type {!proto.team_selection.SelectionMode} */ (reader.readEnum());
      msg.setSelectionMode(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxTeamSize(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxTeamCount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinTeamCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.team_selection.InternalAdjustResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.team_selection.InternalAdjustResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.team_selection.InternalAdjustResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.InternalAdjustResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalD();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTeamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.team_selection.Team.serializeBinaryToWriter
    );
  }
  f = message.getWaitingList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getSelectionMode();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getMaxTeamSize();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getMaxTeamCount();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getMinTeamCount();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string external_d = 1;
 * @return {string}
 */
proto.team_selection.InternalAdjustResponse.prototype.getExternalD = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.team_selection.InternalAdjustResponse} returns this
 */
proto.team_selection.InternalAdjustResponse.prototype.setExternalD = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Team teams = 2;
 * @return {!Array<!proto.team_selection.Team>}
 */
proto.team_selection.InternalAdjustResponse.prototype.getTeamsList = function() {
  return /** @type{!Array<!proto.team_selection.Team>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.team_selection.Team, 2));
};


/**
 * @param {!Array<!proto.team_selection.Team>} value
 * @return {!proto.team_selection.InternalAdjustResponse} returns this
*/
proto.team_selection.InternalAdjustResponse.prototype.setTeamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.team_selection.Team=} opt_value
 * @param {number=} opt_index
 * @return {!proto.team_selection.Team}
 */
proto.team_selection.InternalAdjustResponse.prototype.addTeams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.team_selection.Team, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.team_selection.InternalAdjustResponse} returns this
 */
proto.team_selection.InternalAdjustResponse.prototype.clearTeamsList = function() {
  return this.setTeamsList([]);
};


/**
 * repeated string waiting = 3;
 * @return {!Array<string>}
 */
proto.team_selection.InternalAdjustResponse.prototype.getWaitingList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.team_selection.InternalAdjustResponse} returns this
 */
proto.team_selection.InternalAdjustResponse.prototype.setWaitingList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.team_selection.InternalAdjustResponse} returns this
 */
proto.team_selection.InternalAdjustResponse.prototype.addWaiting = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.team_selection.InternalAdjustResponse} returns this
 */
proto.team_selection.InternalAdjustResponse.prototype.clearWaitingList = function() {
  return this.setWaitingList([]);
};


/**
 * optional SelectionMode selection_mode = 4;
 * @return {!proto.team_selection.SelectionMode}
 */
proto.team_selection.InternalAdjustResponse.prototype.getSelectionMode = function() {
  return /** @type {!proto.team_selection.SelectionMode} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.team_selection.SelectionMode} value
 * @return {!proto.team_selection.InternalAdjustResponse} returns this
 */
proto.team_selection.InternalAdjustResponse.prototype.setSelectionMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int32 max_team_size = 5;
 * @return {number}
 */
proto.team_selection.InternalAdjustResponse.prototype.getMaxTeamSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.team_selection.InternalAdjustResponse} returns this
 */
proto.team_selection.InternalAdjustResponse.prototype.setMaxTeamSize = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 max_team_count = 6;
 * @return {number}
 */
proto.team_selection.InternalAdjustResponse.prototype.getMaxTeamCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.team_selection.InternalAdjustResponse} returns this
 */
proto.team_selection.InternalAdjustResponse.prototype.setMaxTeamCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 min_team_count = 7;
 * @return {number}
 */
proto.team_selection.InternalAdjustResponse.prototype.getMinTeamCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.team_selection.InternalAdjustResponse} returns this
 */
proto.team_selection.InternalAdjustResponse.prototype.setMinTeamCount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.team_selection.InternalGetTeamByPlayerIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.team_selection.InternalGetTeamByPlayerIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.team_selection.InternalGetTeamByPlayerIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.InternalGetTeamByPlayerIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    playerId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.team_selection.InternalGetTeamByPlayerIdRequest}
 */
proto.team_selection.InternalGetTeamByPlayerIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.team_selection.InternalGetTeamByPlayerIdRequest;
  return proto.team_selection.InternalGetTeamByPlayerIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.team_selection.InternalGetTeamByPlayerIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.team_selection.InternalGetTeamByPlayerIdRequest}
 */
proto.team_selection.InternalGetTeamByPlayerIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.team_selection.InternalGetTeamByPlayerIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.team_selection.InternalGetTeamByPlayerIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.team_selection.InternalGetTeamByPlayerIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.InternalGetTeamByPlayerIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlayerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string external_id = 1;
 * @return {string}
 */
proto.team_selection.InternalGetTeamByPlayerIdRequest.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.team_selection.InternalGetTeamByPlayerIdRequest} returns this
 */
proto.team_selection.InternalGetTeamByPlayerIdRequest.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string player_id = 2;
 * @return {string}
 */
proto.team_selection.InternalGetTeamByPlayerIdRequest.prototype.getPlayerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.team_selection.InternalGetTeamByPlayerIdRequest} returns this
 */
proto.team_selection.InternalGetTeamByPlayerIdRequest.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.team_selection.InternalGetTeamByPlayerIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.team_selection.InternalGetTeamByPlayerIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.team_selection.InternalGetTeamByPlayerIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.InternalGetTeamByPlayerIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    teamId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.team_selection.InternalGetTeamByPlayerIdResponse}
 */
proto.team_selection.InternalGetTeamByPlayerIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.team_selection.InternalGetTeamByPlayerIdResponse;
  return proto.team_selection.InternalGetTeamByPlayerIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.team_selection.InternalGetTeamByPlayerIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.team_selection.InternalGetTeamByPlayerIdResponse}
 */
proto.team_selection.InternalGetTeamByPlayerIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.team_selection.InternalGetTeamByPlayerIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.team_selection.InternalGetTeamByPlayerIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.team_selection.InternalGetTeamByPlayerIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.InternalGetTeamByPlayerIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeamId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string team_id = 1;
 * @return {string}
 */
proto.team_selection.InternalGetTeamByPlayerIdResponse.prototype.getTeamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.team_selection.InternalGetTeamByPlayerIdResponse} returns this
 */
proto.team_selection.InternalGetTeamByPlayerIdResponse.prototype.setTeamId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.team_selection.InternalAddNounsAndAdjectivesRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.team_selection.InternalAddNounsAndAdjectivesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.team_selection.InternalAddNounsAndAdjectivesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.team_selection.InternalAddNounsAndAdjectivesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.InternalAddNounsAndAdjectivesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    nounsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    adjectivesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.team_selection.InternalAddNounsAndAdjectivesRequest}
 */
proto.team_selection.InternalAddNounsAndAdjectivesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.team_selection.InternalAddNounsAndAdjectivesRequest;
  return proto.team_selection.InternalAddNounsAndAdjectivesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.team_selection.InternalAddNounsAndAdjectivesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.team_selection.InternalAddNounsAndAdjectivesRequest}
 */
proto.team_selection.InternalAddNounsAndAdjectivesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addNouns(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addAdjectives(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.team_selection.InternalAddNounsAndAdjectivesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.team_selection.InternalAddNounsAndAdjectivesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.team_selection.InternalAddNounsAndAdjectivesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.InternalAddNounsAndAdjectivesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNounsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getAdjectivesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated string nouns = 1;
 * @return {!Array<string>}
 */
proto.team_selection.InternalAddNounsAndAdjectivesRequest.prototype.getNounsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.team_selection.InternalAddNounsAndAdjectivesRequest} returns this
 */
proto.team_selection.InternalAddNounsAndAdjectivesRequest.prototype.setNounsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.team_selection.InternalAddNounsAndAdjectivesRequest} returns this
 */
proto.team_selection.InternalAddNounsAndAdjectivesRequest.prototype.addNouns = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.team_selection.InternalAddNounsAndAdjectivesRequest} returns this
 */
proto.team_selection.InternalAddNounsAndAdjectivesRequest.prototype.clearNounsList = function() {
  return this.setNounsList([]);
};


/**
 * repeated string adjectives = 2;
 * @return {!Array<string>}
 */
proto.team_selection.InternalAddNounsAndAdjectivesRequest.prototype.getAdjectivesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.team_selection.InternalAddNounsAndAdjectivesRequest} returns this
 */
proto.team_selection.InternalAddNounsAndAdjectivesRequest.prototype.setAdjectivesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.team_selection.InternalAddNounsAndAdjectivesRequest} returns this
 */
proto.team_selection.InternalAddNounsAndAdjectivesRequest.prototype.addAdjectives = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.team_selection.InternalAddNounsAndAdjectivesRequest} returns this
 */
proto.team_selection.InternalAddNounsAndAdjectivesRequest.prototype.clearAdjectivesList = function() {
  return this.setAdjectivesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.team_selection.InternalAddNounsAndAdjectivesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.team_selection.InternalAddNounsAndAdjectivesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.team_selection.InternalAddNounsAndAdjectivesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.InternalAddNounsAndAdjectivesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.team_selection.InternalAddNounsAndAdjectivesResponse}
 */
proto.team_selection.InternalAddNounsAndAdjectivesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.team_selection.InternalAddNounsAndAdjectivesResponse;
  return proto.team_selection.InternalAddNounsAndAdjectivesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.team_selection.InternalAddNounsAndAdjectivesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.team_selection.InternalAddNounsAndAdjectivesResponse}
 */
proto.team_selection.InternalAddNounsAndAdjectivesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.team_selection.InternalAddNounsAndAdjectivesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.team_selection.InternalAddNounsAndAdjectivesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.team_selection.InternalAddNounsAndAdjectivesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.InternalAddNounsAndAdjectivesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.team_selection.InternalGetOrCreateTeamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.team_selection.InternalGetOrCreateTeamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.team_selection.InternalGetOrCreateTeamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.InternalGetOrCreateTeamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.team_selection.InternalGetOrCreateTeamRequest}
 */
proto.team_selection.InternalGetOrCreateTeamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.team_selection.InternalGetOrCreateTeamRequest;
  return proto.team_selection.InternalGetOrCreateTeamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.team_selection.InternalGetOrCreateTeamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.team_selection.InternalGetOrCreateTeamRequest}
 */
proto.team_selection.InternalGetOrCreateTeamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.team_selection.InternalGetOrCreateTeamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.team_selection.InternalGetOrCreateTeamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.team_selection.InternalGetOrCreateTeamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.InternalGetOrCreateTeamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string external_id = 1;
 * @return {string}
 */
proto.team_selection.InternalGetOrCreateTeamRequest.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.team_selection.InternalGetOrCreateTeamRequest} returns this
 */
proto.team_selection.InternalGetOrCreateTeamRequest.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.team_selection.InternalGetOrCreateTeamResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.team_selection.InternalGetOrCreateTeamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.team_selection.InternalGetOrCreateTeamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.team_selection.InternalGetOrCreateTeamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.InternalGetOrCreateTeamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalD: jspb.Message.getFieldWithDefault(msg, 1, ""),
    teamsList: jspb.Message.toObjectList(msg.getTeamsList(),
    proto.team_selection.Team.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.team_selection.InternalGetOrCreateTeamResponse}
 */
proto.team_selection.InternalGetOrCreateTeamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.team_selection.InternalGetOrCreateTeamResponse;
  return proto.team_selection.InternalGetOrCreateTeamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.team_selection.InternalGetOrCreateTeamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.team_selection.InternalGetOrCreateTeamResponse}
 */
proto.team_selection.InternalGetOrCreateTeamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalD(value);
      break;
    case 2:
      var value = new proto.team_selection.Team;
      reader.readMessage(value,proto.team_selection.Team.deserializeBinaryFromReader);
      msg.addTeams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.team_selection.InternalGetOrCreateTeamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.team_selection.InternalGetOrCreateTeamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.team_selection.InternalGetOrCreateTeamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.InternalGetOrCreateTeamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalD();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTeamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.team_selection.Team.serializeBinaryToWriter
    );
  }
};


/**
 * optional string external_d = 1;
 * @return {string}
 */
proto.team_selection.InternalGetOrCreateTeamResponse.prototype.getExternalD = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.team_selection.InternalGetOrCreateTeamResponse} returns this
 */
proto.team_selection.InternalGetOrCreateTeamResponse.prototype.setExternalD = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Team teams = 2;
 * @return {!Array<!proto.team_selection.Team>}
 */
proto.team_selection.InternalGetOrCreateTeamResponse.prototype.getTeamsList = function() {
  return /** @type{!Array<!proto.team_selection.Team>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.team_selection.Team, 2));
};


/**
 * @param {!Array<!proto.team_selection.Team>} value
 * @return {!proto.team_selection.InternalGetOrCreateTeamResponse} returns this
*/
proto.team_selection.InternalGetOrCreateTeamResponse.prototype.setTeamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.team_selection.Team=} opt_value
 * @param {number=} opt_index
 * @return {!proto.team_selection.Team}
 */
proto.team_selection.InternalGetOrCreateTeamResponse.prototype.addTeams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.team_selection.Team, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.team_selection.InternalGetOrCreateTeamResponse} returns this
 */
proto.team_selection.InternalGetOrCreateTeamResponse.prototype.clearTeamsList = function() {
  return this.setTeamsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.team_selection.InternalUpdateTeamConfigRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.team_selection.InternalUpdateTeamConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.team_selection.InternalUpdateTeamConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.team_selection.InternalUpdateTeamConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.InternalUpdateTeamConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalD: jspb.Message.getFieldWithDefault(msg, 1, ""),
    teamsList: jspb.Message.toObjectList(msg.getTeamsList(),
    proto.team_selection.Team.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.team_selection.InternalUpdateTeamConfigRequest}
 */
proto.team_selection.InternalUpdateTeamConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.team_selection.InternalUpdateTeamConfigRequest;
  return proto.team_selection.InternalUpdateTeamConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.team_selection.InternalUpdateTeamConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.team_selection.InternalUpdateTeamConfigRequest}
 */
proto.team_selection.InternalUpdateTeamConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalD(value);
      break;
    case 2:
      var value = new proto.team_selection.Team;
      reader.readMessage(value,proto.team_selection.Team.deserializeBinaryFromReader);
      msg.addTeams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.team_selection.InternalUpdateTeamConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.team_selection.InternalUpdateTeamConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.team_selection.InternalUpdateTeamConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.InternalUpdateTeamConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalD();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTeamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.team_selection.Team.serializeBinaryToWriter
    );
  }
};


/**
 * optional string external_d = 1;
 * @return {string}
 */
proto.team_selection.InternalUpdateTeamConfigRequest.prototype.getExternalD = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.team_selection.InternalUpdateTeamConfigRequest} returns this
 */
proto.team_selection.InternalUpdateTeamConfigRequest.prototype.setExternalD = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Team teams = 2;
 * @return {!Array<!proto.team_selection.Team>}
 */
proto.team_selection.InternalUpdateTeamConfigRequest.prototype.getTeamsList = function() {
  return /** @type{!Array<!proto.team_selection.Team>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.team_selection.Team, 2));
};


/**
 * @param {!Array<!proto.team_selection.Team>} value
 * @return {!proto.team_selection.InternalUpdateTeamConfigRequest} returns this
*/
proto.team_selection.InternalUpdateTeamConfigRequest.prototype.setTeamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.team_selection.Team=} opt_value
 * @param {number=} opt_index
 * @return {!proto.team_selection.Team}
 */
proto.team_selection.InternalUpdateTeamConfigRequest.prototype.addTeams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.team_selection.Team, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.team_selection.InternalUpdateTeamConfigRequest} returns this
 */
proto.team_selection.InternalUpdateTeamConfigRequest.prototype.clearTeamsList = function() {
  return this.setTeamsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.team_selection.InternalUpdateTeamConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.team_selection.InternalUpdateTeamConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.team_selection.InternalUpdateTeamConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.InternalUpdateTeamConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.team_selection.InternalUpdateTeamConfigResponse}
 */
proto.team_selection.InternalUpdateTeamConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.team_selection.InternalUpdateTeamConfigResponse;
  return proto.team_selection.InternalUpdateTeamConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.team_selection.InternalUpdateTeamConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.team_selection.InternalUpdateTeamConfigResponse}
 */
proto.team_selection.InternalUpdateTeamConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.team_selection.InternalUpdateTeamConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.team_selection.InternalUpdateTeamConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.team_selection.InternalUpdateTeamConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.team_selection.InternalUpdateTeamConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.team_selection.AdjustType = {
  UNKNOWN_ADJUST_TYPE: 0,
  JOIN_TYPE: 1,
  LEAVE_TYPE: 2,
  GAME_CHANGE_TYPE: 3
};

/**
 * @enum {number}
 */
proto.team_selection.SelectionMode = {
  UNKNOWN_MODE: 0,
  AUTO_MODE: 1,
  MANUAL_MODE: 2
};

goog.object.extend(exports, proto.team_selection);
